export default {
  brossard: {
    district:{
      key: 'district',
      title: {label: 'Secteur', label_fr:'Secteur', label_en:'Sector'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Secteur 1' },
        { value: '2', label: 'Secteur 2' },
        { value: '3', label: 'Secteur 3' },
        { value: '4', label: 'Secteur 4' },
        { value: '5', label: 'Secteur 5' },
        { value: '6', label: 'Secteur 6' },
        { value: '7', label: 'Secteur 7' },
        { value: '8', label: 'Secteur 8' },
        { value: '9', label: 'Secteur 9' },
        { value: '10', label: 'Secteur 10' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ville de Brossard', label_fr: 'Ville de Brossard', label_en : 'City of Brossard', class: 'bgreen', icon: "brossard.png"},
        { value: '2', label: 'REM', label_fr: 'REM', label_en : 'REM', class: 'byellowgreen', icon: "rem.png"},
        { value: '3', label: 'Autres', label_fr: 'Autres', label_en : 'Others', class: 'borange', icon: "btp.png"},
        { value: '4', label: 'Événements', label_fr: 'Événements', label_en : 'Events', class: 'bblue', icon: "event.png"}
      ],
      classes: {
        'brown': '#ac702c',
        'blue_duck': '#009aa2',
        'pink': '#e97cbf',
        'green': '#00e045',
        'blue': '#8fc4e6',
        'orange': '#ff5a00',
        'bgreen': '#01b050',
        'byellowgreen': '#ffff02',
        'borange': '#ed7d31',
        'bblue': '#01b0f0'
      },
      legendImg: true
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'Avant-projet', label_fr: 'Avant-projet', label_en : 'Preliminary draft'},
        { value: '3', label: 'Plans et Devis', label_fr: 'Plans et Devis', label_en : 'Plans and Estimates'},
        { value: '4', label: 'Soumission', label_fr: 'Soumission', label_en : 'Submission'},
        { value: '5', label: 'Octroyé', label_fr: 'Octroyé', label_en : 'Granted'},
        { value: '6', label: 'En travaux', label_fr: 'En travaux', label_en : 'In progress'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Completed'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type d'entrave", label_fr:"Type d'entrave", label_en:'Type of obstruction'},
      show: true,
      showInList: true,
      search: true,
      value:[
        { value: '1', label_fr: '0 à 3 mètres', label_en : '0 to 3 m'},
        { value: '2', label_fr: '3 à 6 mètres', label_en : '3 to 6 m'},
        { value: '3', label_fr: '6 à 9 mètres', label_en : '6 to 9 m'},
        { value: '4', label_fr: 'Fermeture en alternance', label_en : 'Alternating closure'},
        { value: '5', label_fr: 'Fermeture complète', label_en : 'Complete closure'},
        { value: '6', label_fr: 'Piste cyclable', label_en : 'Bie lane'},
        { value: '7', label_fr: 'Parc ou espace vert', label_en : 'Park or green area'},
        { value: '8', label_fr: 'Trottoir', label_en : 'Sidewalk'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux (Pour avis de travaux)", label_fr:"Catégorie de travaux (Pour avis de travaux)", label_en:'Category of work (For notice of work)'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    status: {
      key: 'status',
      title: {label: "Statut", label_fr:"Statut", label_en:'Status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager", label_en:"Passanger type"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'type1', status:false, content: '', label:'Local ou usager du site', label_fr:'Local ou usager du site', label_en:'Local or site user'},
        {value:'type2', status:false, content: '', label:'Automobiliste', label_fr:'Automobiliste', label_en:'Driver'},
        {value:'type3', status:false, content: '', label:'Cycliste', label_fr:'Cycliste', label_en:'Cyclist'},
        {value:'type4', status:false, content: '', label:'Piéton', label_fr:'Piéton', label_en:'Pedestrian'},
        {value:'type5', status:false, content: '', label:'Transport collectif', label_fr:'Transport collectif', label_en:'Public transport'},
        {value:'type6', status:false, content: '', label:"Accessibilité véhicules d'urgence", label_fr:"Accessibilité véhicules d'urgence", label_en:'Emergency vehicles accessibility'},
        {value:'type7', status:false, content: '', label:'Accessibilité personnes à mobilité réduite', label_fr:'Accessibilité personnes à mobilité réduite', label_en:'Accessibility for people with reduced mobility'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
        {value: "contraflow traffic", label : "Contraflow traffic" ,label_en:"Contraflow traffic", label_fr:"Circulation à contresens"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: true,
      value: [
        { value: "", label: 'Select Mail List', label_en: 'Select Mail List', label_fr: 'Sélectionnez la liste de diffusion'},
        { value: "Adminserbro@brossard.ca,incendieoperations@longueuil.quebec,ADestasio@rtl-longueuil.qc.ca,Doreen.Assaad@brossard.ca,jmenard@autobusbaillargeon.ca,Sandra.Avakian@brossard.ca,Guy.Benedetti@brossard.ca,Catherine.Benoit@brossard.ca,bruno.champagne@postescanada.ca,Julie.Chevalier@brossard.ca,transportscolaire@csmv.qc.ca,mleduc@rsb.qc.ca,equipe.brossard@derichebourg.com,Delia.Fanucchi@brossard.ca,Garde.General@brossard.ca,Celine.Girard@brossard.ca,Patrick.Girouard@brossard.ca,JFLarocque@rtl-longueuil.qc.ca,Myles.Keiller@brossard.ca,carmen_lamarche@csmv.qc.ca,Magalie.Laviolette@brossard.ca,patricia.lemoine@brossard.ca,mdesrosiers@cetam.ca,Veronique.Maillot@brossard.ca,Mairie@brossard.ca,marie-claude.lussier@longueuil.quebec,Sebastien.Millette@brossard.ca,Alexandre.Nguyen@brossard.ca,quebj4w@postescanada.ca,siroisb@rcienvironnement.com,lsimard@raamm.org,Jean-Pierre.Richard@brossard.ca,Philippe.Sabourin@brossard.ca,Alain.Thiboutot@brossard.ca,tholland@rsb.qc.ca,Kaven.Vaillant@brossard.ca,planificationoperationnelle@longueuil.quebec", label: 'External Option', label_en: 'External Option', label_fr: 'Avis Externe'},
        { value: "Adminserbro@brossard.ca,demandesweb@brossard.ca,Melissa.Fleury@brossard.ca,Patricia.lemoine@brossard.ca", label: 'Internal Notice', label_en: 'Internal Notice', label_fr: 'Avis Interne'},
      ],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  gatineau: {
    district:{
      key: 'district',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Aylmer', label_fr: 'Aylmer', label_en: 'Aylmer'},
        { value: '2', label: 'Buckingham', label_fr: 'Buckingham', label_en: 'Buckingham'},
        { value: '3', label: 'Gatineau', label_fr: 'Gatineau', label_en: 'Gatineau'},
        { value: '4', label: 'Hull', label_fr: 'Hull', label_en: 'Hull'},
        { value: '5', label: 'Masson-Angers', label_fr: 'Masson-Angers', label_en: 'Masson-Angers'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ville - Infrastructures',label_fr: 'Ville - Infrastructures',label_en: 'City - Infrastructure', class: 'gat-green1', class1: 'gat-green1'},
        { value: '2', label: 'Ville - TP',label_fr: 'Ville - Travaux Publics',label_en: 'City - Public Works', class: 'gat-green2', class1: 'gat-green2'},
        { value: '6', label: 'Événements',label_fr: 'Événements',label_en: 'Events', class: 'gat-yellow', class1: 'gat-yellow'},
        { value: '9', label: 'Promoteurs immobiliers',label_fr: 'Promoteurs immobiliers', label_en:'Real estate promotors', class: 'gat-purple1', class1: 'gat-blue1'},
        { value: '3', label: 'RTU (HQ, Bell, Vidéotron, etc)',label_fr: 'RTU (HQ, Bell, Vidéotron, etc)',label_en: 'RTU (HQ, Bell, Videotron, etc)', class: 'gat-orange', class1: 'gat-blue1 hidden'},
        { value: '4', label: 'MTQ',label_fr: 'MTQ',label_en: 'MTQ', class: 'gat-blue1', class1: 'gat-blue1 hidden'},
        { value: '5', label: 'STO',label_fr: 'STO',label_en: 'STO', class: 'gat-green3', class1: 'gat-blue1 hidden'},
        { value: '7', label: "Mesures d'urgence",label_fr: "Mesures d'urgence",label_en: "Emergency measures", class: 'gat-red', class1: 'gat-blue1 hidden'},
        { value: '8', label: 'Autres',label_fr: 'Autres',label_en: 'Other', class: 'gat-blue2', class1: 'gat-blue1'}
      ],
      classes: {
        'gat-green1':'#00b050',
        'gat-green2':'#92d050',
        'gat-green3':'#316f7b',
        'gat-orange':'#ffc001',
        'gat-blue1':'#01b0f0',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-blue2':'#0070c0',
        'gat-yellow':'#ffff02',
        'gat-red':'#ff0000'
      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '4', label: 'PTI Infra', label_fr: 'PTI Infra', label_en : 'PTI Infra'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ],
      defaultValue: '2'
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Entrave partielle', label_fr:'Entrave partielle', label_en:'Partial obstruction'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajets ou arrêt de la STO ', label_fr:'Trajets ou arrêt de la STO ', label_en:'Routes or bus stops'},
        {value:'9', status:false, content:'', label:'Corridor scolaire entravé', label_fr:'Corridor scolaire entravé', label_en:'Blocked school corridor'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'}
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Réseau temporaire d’eau potable', label_fr:'Réseau temporaire d’eau potable', label_en:'Temporary potable water network'},
        {value:'2', status:false, content:'', label:'Interruption d’eau potable', label_fr:'Interruption d’eau potable', label_en:'Interruption of potable water'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: true,
      value: [
        { value: '1',  label: "Services municipaux", label_fr: "Services municipaux", label_en : "Roadway and paving"},
        { value: '2',  label: "Chaussée et pavage", label_fr: "Chaussée et pavage", label_en : "Chaussée et pavage"},
        { value: '3',  label: "Développement des réseaux", label_fr: "Développement des réseaux", label_en : "Network development"},
        { value: '4',  label: "Feux de circulation/éclairage", label_fr: "Feux de circulation/éclairage", label_en : "Traffic lights/ lighting"},
        { value: '5',  label: "Bâtiment", label_fr: "Bâtiment", label_en : "Building"},
        { value: '6',  label: "Parcs et espaces verts", label_fr: "Parcs et espaces verts", label_en : "Parks and green spaces"},
        { value: '7',  label: "Traitement et pompage des eaux", label_fr: "Traitement et pompage des eaux", label_en : "Water treatment and pumping"},
        { value: '8',  label: "Branchement privés", label_fr: "Branchement privés", label_en : "Private connections"},
        { value: '9', label: "Services d'utilités publiques", label_fr: "Services d'utilités publiques", label_en : "Public utility services"},
        { value: '10', label: "Autres", label_fr: "Autres", label_en : "Others"},
        { value: '11', label: 'Évènement', label_fr: 'Évènement', label_en : 'Event'},
        { value: '12', label: 'Travaux urgents', label_fr: 'Travaux urgents', label_en : 'Urgent work'}
      ]
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Type de travaux", label_fr:"Type de travaux", label_en:'Type of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Aménagement paysager", label_fr: "Aménagement paysager", label_en : "Landscaping"},
        { value: '2',  label: "Arbres - Abattage ou élagage", label_fr: "Arbres - Abattage ou élagage", label_en : "Trees - Felling or Pruning"},
        { value: '3',  label: "Bâtiment - Déneigement de toiture", label_fr: "Bâtiment - Déneigement de toiture", label_en : "Building - Roof snow removal"},
        { value: '4',  label: "Bâtiment divers - entretien", label_fr: "Bâtiment divers - entretien", label_en : "Various buildings  - Maintenance"},
        { value: '5',  label: "Bâtiment municipal - Construction", label_fr: "Bâtiment municipal - Construction", label_en : "Municipal buildings- Construction"},
        { value: '6',  label: "Bâtiment municipal - Démolition", label_fr: "Bâtiment municipal - Démolition", label_en : "Municipal buildings- Demolition"},
        { value: '7',  label: "Bâtiment municipal - Réfection", label_fr: "Bâtiment municipal - Réfection", label_en : "Municipal buildings - Refurbishment"}, 
        { value: '55', label: 'Bâtiment privé - Construction/Rénovation', label_fr: 'Bâtiment privé - Construction/Rénovation', label_en : 'Private buildings - Construction/Refurbishment'},
        { value: '8',  label: "Borne-fontaine - Inspection", label_fr: "Borne-fontaine - Inspection", label_en : "Fire hydrant - Inspection"},
        { value: '9',  label: "Branchement de services –  Réfection", label_fr: "Branchement de services –  Réfection", label_en : "Service connections –  Refurbishment"}, 
        { value: '10', label: "Branchement de services - Construction", label_fr: "Branchement de services - Construction", label_en : "Service connections - Construction"},
        { value: '54', label: 'Événement', label_fr: 'Événement', label_en : 'Event'},
        { value: '59', label: 'Fermeture préventive', label_fr: 'Fermeture préventive', label_en : 'Preventive closure'},
        { value: '11', label: "Feux de circulation - Installation", label_fr: "Feux de circulation - Installation", label_en : "Traffic lights - Installation"},
        { value: '12', label: "Feux de circulation - Réfection", label_fr: "Feux de circulation - Réfection", label_en : "Traffic lights - Refurbishment"},
        { value: '13', label: "Forage", label_fr: "Forage", label_en : "Drilling"},
        { value: '14', label: "Fossés - Nettoyage", label_fr: "Fossés - Nettoyage", label_en : "Ditch - Clean-Up"}, 
        { value: '15', label: "Fossés – Nouveau", label_fr: "Fossés – Nouveau", label_en : "Ditch - New"},
        { value: '56', label: 'Fouille exploratoire', label_fr: 'Événement', label_en : 'Exploratory digging'},
        { value: '16', label: "Mesure de modération de la circulation - Installation", label_fr: "Mesure de modération de la circulation - Installation", label_en : "Traffic moderation measures - Installation"},
        { value: '17', label: "Mesure de modération de la circulation – Réfection", label_fr: "Mesure de modération de la circulation – Réfection", label_en : "Traffic moderation measures - Refurbishment"},
        { value: '18', label: "Ministère des Transports du Québec - travaux", label_fr: "Ministère des Transports du Québec - travaux", label_en : "Quebec’s Ministry of Transportation - works"},
        { value: '19', label: "Ouvrages de béton - Construction", label_fr: "Ouvrages de béton - Construction", label_en : "Concrete structure - Construction"},
        { value: '20', label: "Ouvrages de béton – Réfection", label_fr: "Ouvrages de béton – Réfection", label_en : "Concrete structure  – Refurbishment"}, 
        { value: '21', label: "Parc - Jeu d’eau - Aménagement", label_fr: "Parc - Jeu d’eau - Aménagement", label_en : "Parc - Water games- Landscaping"}, 
        { value: '22', label: "Parc - Parcours santé - Aménagement", label_fr: "Parc - Parcours santé - Aménagement", label_en : "Parc - Fitness trail Landscaping"},
        { value: '23', label: "Parc municipal - Nouveau parc", label_fr: "Parc municipal - Nouveau parc", label_en : "Municipal Parc - New Parc"},
        { value: '24', label: "Parc municipal - Réaménagement", label_fr: "Parc municipal - Réaménagement", label_en : "Municipal Parc - Refurbishment"}, 
        { value: '25', label: "Ponceau – Installation", label_fr: "Ponceau – Installation", label_en : "Culvert – Installation"},
        { value: '57', label: 'Ponceau – Inspection', label_fr: 'Ponceau – Inspection', label_en : 'Culvert – Inspection'},
        { value: '26', label: "Ponceau – Réfection", label_fr: "Ponceau – Réfection", label_en : "Culvert – Refurbishment"},
        { value: '27', label: "Pont - Inspection", label_fr: "Pont - Inspection", label_en : "Birdge - Inspection"},
        { value: '28', label: "Pont - Réfection", label_fr: "Pont - Réfection", label_en : "Bridge - Refurbishment"},
        { value: '29', label: "Puisard - Installation", label_fr: "Puisard - Installation", label_en : "Sump - Installation"},
        { value: '30', label: "Puisard - Réfection", label_fr: "Puisard - Réfection", label_en : "Sump - Refurbishment"},
        { value: '31', label: "Réseau d'aqueduc - Bris", label_fr: "Réseau d'aqueduc - Bris", label_en : "Aqueduct network- Breakage"}, 
        { value: '32', label: "Réseau d'aqueduc - Construction", label_fr: "Réseau d'aqueduc - Construction", label_en : "Aqueduct network - Construction"},
        { value: '33', label: "Réseau d'aqueduc - Réfection", label_fr: "Réseau d'aqueduc - Réfection", label_en : "Aqueduct network- Refurbishment"},
        { value: '34', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_en : "Municipal aqueduct and storm and/or sanitary sewers services - Construction"},
        { value: '35', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Municipal aqueduct and storm and/or sanitary sewers services -  Refurbishment"},
        { value: '36', label: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_en : "Storm and/or sanitary sewers network - Construction"},
        { value: '37', label: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Storm and/or sanitary sewers network - Refubishment"},
        { value: '38', label: "Réseau routier - Construction", label_fr: "Réseau routier - Construction", label_en : "Road network- Construction"},
        { value: '39', label: "Réseau routier - Marquage de la chaussée", label: "Réseau routier - Marquage de la chaussée", label_en : "Road network - Pavement marking"}, 
        { value: '40', label: "Réseau routier - Réfection", label_fr: "Réseau routier - Réfection", label_en : "Road network - Refurbishment"},
        { value: '41', label: "Sentier récréatif - Construction", label_fr: "Sentier récréatif - Construction", label_en : "Recreational Trail - Construction"},
        { value: '42', label: "Sentier récréatif - Fermeture", label_fr: "Sentier récréatif - Fermeture", label_en : "Recreational Trail - Closure"}, 
        { value: '43', label: "Sentier récréatif - Nettoyage", label_fr: "Sentier récréatif - Nettoyage", label_en : "Recreational Trail - Clean-Up"},
        { value: '44', label: "Sentier récréatif - Réfection", label_fr: "Sentier récréatif - Réfection",label_en : "Recreational Trail - Refurbishment"}, 
        { value: '45', label: "Sentier récréatif - Zone inondée", label_fr: "Sentier récréatif - Zone inondée", label_en : "Recreational Trail - Flooded area"},
        { value: '46', label: "Services d'utilités publiques - Enfouissement", label_fr: "Services d'utilités publiques - Enfouissement", label_en : "Public Utilities Department - Landfill"},
        { value: '58', label: "Services d'utilités publiques - Inspection", label_fr: "Services d'utilités publiques - Inspection", label_en : 'Pubic utilities department - Inspection'},
        { value: '47', label: "Services d'utilités publiques - Installation", label_fr: "Services d'utilités publiques - Installation", label_en : "Public Utilities Department - Installation"}, 
        { value: '48', label: "Services publics - Réfection", label_fr: "Services publics - Réfection", label_en : "Public Works - Refurbishment"},
        { value: '49', label: "Signalisation - Installation", label_fr: "Signalisation - Installation", label_en : "Signage - Installation"},
        { value: '50', label: "Signalisation – Réfection", label_fr: "Signalisation – Réfection", label_en : "Signage – Refurbishment"},
        { value: '51', label: "Station de surpression ou de pompage - Construction", label_fr: "Station de surpression ou de pompage - Construction", label_en : "Booster or pumping station- Construction"},
        { value: '52', label: "Station de surpression ou de pompage - Réfection", label_fr: "Station de surpression ou de pompage - Réfection", label_en : "Booster or pumping station - Refurbishment"}, 
        { value: '53', label: "Usine d’eau potable - travaux", label_fr: "Usine d’eau potable - travaux", label_en : "Potable water plant - Works"},
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Entrave", label_fr: "Entrave", label_en : "Obstacles"},
        { value: '2', label: "Occupation", label_fr: "Occupation", label_en : "Occupation"},
        { value: '3', label: "Partenaires", label_fr: "Partenaires", label_en : "Partners"},
        { value: '4', label: "Non requis (projets Ville)", label_fr: "Non requis (projets Ville)", label_en : "Not required (City projects)"},
        { value: '5', label: "Résolution ou approbation CS", label_fr: "Résolution ou approbation CS", label_en : "Resolution or approval CS"},
      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'Service des infrastructures', label_fr: 'Service des infrastructures', label_en: 'Infrastructure department'},
        { value: '2', label: 'Service des travaux publics', label_fr: 'Service des travaux publics', label_en: 'Public Works department'}
      ]
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'1', label:'CANU - 311', label_fr:'CANU - 311', label_en:'CANU - 311'},
        {value:'2', label:'Travaux Publics - Égouts et aqueduc', label_fr:'Travaux Publics - Égouts et aqueduc', label_en:'Pubic Works - Sewers and aqueducts'},
        {value:'3', label:'Travaux Publics - Voirie', label_fr:'Travaux Publics - Voirie', label_en:'Pubic Works - Road'},
        {value:'4', label:'Travaux Publics - Parcs et espaces verts', label_fr:'Travaux Publics - Parcs et espaces verts', label_en:'Pubic Works - Parcs and Green spaces'},
        {value:'5', label:'Travaux Publics - Feux de chantier', label_fr:'Travaux Publics - Feux de chantier', label_en:'Pubic Works - Construction Fires'},
        {value:'6', label:'Stationnememt', label_fr:'Stationnememt', label_en:'Parking'},
        {value:'7', label:'Sécurité incendie', label_fr:'Sécurité incendie', label_en:'Fire Safety'},
        {value:'8', label:'Service de police', label_fr:'Service de police', label_en:'Police Department'},
        {value:'9', label:'Centre de services - Aylmer', label_fr:'Centre de services - Aylmer', label_en:'Service centre - Aylmer'},
        {value:'10', label:'Centre de services - Hull', label_fr:'Centre de services - Hull', label_en:'Service centre - Hull'},
        {value:'11', label:'Centre de services - Gatineau', label_fr:'Centre de services - Gatineau', label_en:'Service centre - Gatineau'},
        {value:'12', label:'Centre de services - Masson - Angers', label_fr:'Centre de services - Masson - Angers', label_en:'Service centre - Masson - Angers'},
        {value:'13', label:'Centre de services - Buckingham', label_fr:'Centre de services - Buckingham', label_en:'Service centre - Buckingham'},
        {value:'14', label:"Société de transport de l'Outaouais (STO)", label_fr:"Société de transport de l'Outaouais (STO)", label_en:"Outaouais Transportation Company (STO)"},
        {value:'15', label:'Commissions Scolaires', label_fr:'Commissions Scolaires', label_en:'School Boards'},
        {value:'16', label:'Ministère des Transports du Québec (MTQ)', label_fr:'Ministère des Transports du Québec (MTQ)', label_en:'Quebec Ministry of Transport (MTQ)'},
        {value:'17', label:"Coopérative des paramédics de l'Outaouais", label_fr:"Coopérative des paramédics de l'Outaouais", label_en:"Outaouais paramedics cooperative"},
        {value:'18', label:'Société de transport Transcollines', label_fr:'Société de transport Transcollines', label_en:'Transcollines Transportation Company'},
        {value:'19', label:'Infrastructures', label_fr:'Infrastructures', label_en:'Infrastructures'}
      ]
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager(ne pas utiliser)", label_en:"Passenger type(do not use)"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'type1', status:false, content: '', label:'Local ou usager du site', label_fr:'Local ou usager du site', label_en:'Local or site user'},
        {value:'type2', status:false, content: '', label:'Automobiliste', label_fr:'Automobiliste', label_en:'Driver'},
        {value:'type3', status:false, content: '', label:'Cycliste', label_fr:'Cycliste', label_en:'Cyclist'},
        {value:'type4', status:false, content: '', label:'Piéton', label_fr:'Piéton', label_en:'Pedestrian'},
        {value:'type5', status:false, content: '', label:'Transport collectif', label_fr:'Transport collectif', label_en:'Public transport'},
        {value:'type6', status:false, content: '', label:"Accessibilité véhicules d'urgence", label_fr:"Accessibilité véhicules d'urgence", label_en:'Emergency vehicles accessibility'},
        {value:'type7', status:false, content: '', label:'Accessibilité personnes à mobilité réduite', label_fr:'Accessibilité personnes à mobilité réduite', label_en:'Accessibility for people with reduced mobility'}
      ]
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1', label_fr: "RAU", label_en: "RAU"},
        { value: '2', label_fr: "DDR", label_en: "DDR"},
        { value: '3', label_fr: "RTU", label_en: "RTU"},
        { value: '4', label_fr: "BSP",  label_en: "BSP"},
        { value: '5', label_fr: "CSR", label_en: "CSR"},
        { value: '6', label_fr: "TPE", label_en: "TPE"},
        { value: '7', label_fr: "PI",  label_en: "PI"},
        { value: '8', label_fr: "APEP",  label_en: "APEP"},
        { value: '9', label_fr: "Événements",  label_en: "Events"},
        { value: '10', label_fr: "Autres",  label_en: "Others"}
      ]
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1', label_fr: "AEDS", label_en: "AEDS"},
        { value: '2', label_fr: "Voirie", label_en: "Voirie"},
        { value: '3', label_fr: "Édifices", label_en: "Édifices"},
        { value: '4', label_fr: "Parcs",  label_en: "Parcs"}
      ]
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
        {value: "Bike path", label : "Bike path" ,label_en:"Bike path", label_fr:"Piste cyclable"},
        {value: "Bike lane", label : "Bike lane" ,label_en:"Bike lane", label_fr:"Bande cyclable"},
        {value: "Road block", label : "Road block" ,label_en:"Road block", label_fr:"Barrage routier"},
        {value: "Work on a building", label : "Work on a building" ,label_en:"Work on a building", label_fr:"Travaux sur un bâtiment"},
        {value: "Work in a park", label : "Work in a park" ,label_en:"Work in a park", label_fr:"Travaux dans un parc"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  montroyal: {
    district:{
      key: 'district',
      title: {label: 'Arrondissements', label_fr:'District', label_en:'District'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: '1', label_fr: '1', label_en: '1'},
        { value: '2', label: 'Buckingham', label_fr: 'Buckingham', label_en: 'Buckingham'},
        { value: '3', label: 'Gatineau', label_fr: 'Gatineau', label_en: 'Gatineau'},
        { value: '4', label: 'Hull', label_fr: 'Hull', label_en: 'Hull'},
        { value: '5', label: 'Masson-Angers', label_fr: 'Masson-Angers', label_en: 'Masson-Angers'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ville - Génie',label_fr: 'Ville - Génie',label_en: 'City - Engineering', class: 'gat-blue2', class1: 'gat-blue2'},
        { value: '2', label: 'Ville - Travaux Publics',label_fr: 'Ville - Travaux Publics',label_en: 'City - Public Works', class: 'gat-green1', class1: 'gat-green1'},
        { value: '3', label: 'Évènements',label_fr: 'Événements',label_en: 'Events', class: 'gat-rose', class1: 'gat-rose'},
        { value: '4', label: 'Promoteurs immobiliers',label_fr: 'Promoteurs immobiliers', label_en:'Real estate promotors', class: 'gat-mauve', class1: 'gat-mauve'},
        { value: '5', label: 'RTU (HQ, Bell, Vidéotron, etc)',label_fr: 'RTU (HQ, Bell, Vidéotron, etc)',label_en: 'RTU (HQ, Bell, Videotron, etc)', class: 'gat-turquois', class1: 'gat-turquois'},
        { value: '6', label: 'REM',label_fr: 'REM',label_en: 'REM', class: 'gat-jaune', class1: 'gat-jaune'},
        { value: '7', label: 'MTQ',label_fr: 'MTQ',label_en: 'MTQ', class: 'gat-red', class1: 'gat-red'},
        { value: '8', label: 'STM',label_fr: 'STM',label_en: 'STM', class: 'gat-blue1', class1: 'gat-blue1'},
        { value: '9', label: "Arrondissements et villes voisines",label_fr: "Arrondissements et villes voisines",label_en: "Districts and neighboring towns", class: 'gat-lightred', class1: 'gat-lightred'},
        { value: '10', label: 'Autres',label_fr: 'Autres',label_en: 'Other', class: 'gat-purple', class1: 'gat-purple'},
        { value: '11', label: 'Promoteur Carbonleo',label_fr: 'Promoteur Carbonleo',label_en: 'Carbonleo promoter', class: 'gat-orange', class1: 'gat-orange'},
        { value: '12', label: 'Citoyen',label_fr: 'Citoyen',label_en: 'Citizen', class: 'gat-purple2', class1: 'gat-purple2'}
      ],
      classes: {
        'gat-green1':'#00b050',
        'gat-green2':'#92d050',
        'gat-green3':'#316f7b',
        'gat-orange':'#ffc001',
        'gat-blue1':'#01b0f0',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-purple2':'#AA91E4',
        'gat-blue2':'#0070c0',
        'gat-blue3':'#0E4095',
        'gat-yellow':'#ffff02',
        'gat-red':'#ff0000',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
        'gat-jaune': '#a18200'

      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '9', label: 'Urgence - en cours', label_fr: 'Urgence - en cours', label_en : 'Emergency - ongoing'},
        { value: '10', label: 'Urgence terminé', label_fr: 'Urgence terminé', label_en : 'Emergency over'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Entrave partielle', label_fr:'Entrave partielle', label_en:'Partial obstruction'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajet ou arrêt de la STM', label_fr:'Trajet ou arrêt de la STM', label_en:'Routes or Stops STM'},
        {value:'9', status:false, content:'', label:'Corridor scolaire entravé', label_fr:'Corridor scolaire entravé', label_en:'Blocked school corridor'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'},
        {value:'13', status:false, content:'', label:'Collectes des matières résiduelles', label_fr:'Collectes des matières résiduelles', label_en:'Collection of residual materials'},
        {value:'14', status:false, content:'', label:'Fermeture temporaire de services municipaux ( Parc, Chalet etc)', label_fr:'Fermeture temporaire de services municipaux ( Parc, Chalet etc)', label_en:'Temporary closure of municipal services (Park, Chalet etc)'}
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Réseau temporaire d’eau potable', label_fr:'Réseau temporaire d’eau potable', label_en:'Temporary potable water network'},
        {value:'2', status:false, content:'', label:'Interruption d’eau potable', label_fr:'Interruption d’eau potable', label_en:'Interruption of potable water'},
        {value:'3', status:false, content:'', label:'Perte de pression temporaire', label_fr:'Perte de pression temporaire', label_en:'Temporary pressure loss'},
        {value:'4', status:false, content:'', label:'Changement de couleur de l’eau - non nocif', label_fr:'Changement de couleur de l’eau - non nocif', label_en:'Water color change - not harmful'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Aménagement paysager", label_fr: "Aménagement paysager", label_en : "Landscaping"},
        { value: '2',  label: "Arbres - Abattage ou élagage", label_fr: "Arbres - Abattage ou élagage", label_en : "Trees - Felling or pruning"},
        { value: '3',  label: "Déménagement", label_fr: "Déménagement", label_en : "Move"},
        { value: '4',  label: "Bâtiment citoyen - Déneigement de toiture", label_fr: "Bâtiment citoyen - Déneigement de toiture", label_en : "Citizen building - Roof snow removal"},
        { value: '5',  label: "Bâtiment citoyen - Travaux de toiture", label_fr: "Bâtiment citoyen - Travaux de toiture", label_en : "Citizen building - Roofing works"},
        { value: '6',  label: "Bâtiment citoyen - Nettoyage de fenêtres", label_fr: "Bâtiment citoyen - Nettoyage de fenêtres", label_en : "Citizen building - Window cleaning"},
        { value: '7',  label: "Bâtiment municipaux - Entretien", label_fr: "Bâtiment municipaux - Entretien", label_en : "Municipal buildings - Maintenance"}, 
        { value: '8',  label: "Bâtiment municipal - Construction", label_fr: "Bâtiment municipal - Construction", label_en : "Municipal Building - Construction"},
        { value: '9',  label: "Bâtiment municipal - Démolition", label_fr: "Bâtiment municipal - Démolition", label_en : "Municipal building - Demolition"}, 
        { value: '10', label: "Bâtiment municipal - Réfection/Rénovation", label_fr: "Bâtiment municipal - Réfection/Rénovation", label_en : "Municipal Building - Repair/Renovation"},
        { value: '11', label: "Réseau d'aqueduc - Borne-fontaine - mesure pression", label_fr: "Réseau d'aqueduc - Borne-fontaine - mesure pression", label_en : "Aqueduct network - Fire hydrant - pressure measurement"},
        { value: '12', label: "Réseau d'aqueduc - Borne-fontaine - Inspection", label_fr: "Réseau d'aqueduc - Borne-fontaine - Inspection", label_en : "Aqueduct network - Fire hydrant - Inspection"},
        { value: '13', label: "Réseau d'aqueduc - Borne-fontaine - Peinture", label_fr: "Réseau d'aqueduc - Borne-fontaine - Peinture", label_en : "Aqueduct network - Fire hydrant - Painting"},
        { value: '14', label: "Réseau d'aqueduc - Borne-fontaine - Réfection/Remplacement", label_fr: "Réseau d'aqueduc - Borne-fontaine - Réfection/Remplacement", label_en : "Aqueduct network - Fire hydrant - Repair/Replacement"}, 
        { value: '15', label: "Branchement de services – Réfection", label_fr: "Branchement de services – Réfection", label_en : "Service connection – Repair"},
        { value: '16', label: "Branchement de services - Remplacement", label_fr: "Branchement de services - Remplacement", label_en : "Service Connection - Replacement"},
        { value: '17', label: "Branchement de services - Nouveau", label_fr: "Branchement de services - Nouveau", label_en : "Service connection - New"},
        { value: '18', label: "Feux de circulation - Installation", label_fr: "Feux de circulation - Installation", label_en : "Traffic lights - Installation"}, 
        { value: '19', label: "Feux de circulation - Réfection/ou entretien", label_fr: "Feux de circulation - Réfection/ou entretien", label_en : "Traffic lights - Repair/or maintenance"},
        { value: '20', label: "Forage", label_fr: "Forage", label_en : "Drilling"}, 
        { value: '21', label: "Mesure de mitigation de la circulation - Installation", label_fr: "Mesure de mitigation de la circulation - Installation", label_en : "Traffic Mitigation Measure - Installation"}, 
        { value: '22', label: "Ministère des Transports du Québec - Travaux", label_fr: "Ministère des Transports du Québec - Travaux", label_en : "Quebec Ministry of Transport - Works"},
        { value: '23', label: "Ouvrages de béton - Construction", label_fr: "Ouvrages de béton - Construction", label_en : "Concrete works - Construction"},
        { value: '24', label: "Ouvrages de béton – Réfection", label_fr: "Ouvrages de béton – Réfection", label_en : "Concrete structures – Repairs"}, 
        { value: '25', label: "Parc - Jeux d’eau - Aménagement", label_fr: "Parc - Jeux d’eau - Aménagement", label_en : "Park - Water games - Development"},
        { value: '26', label: "Parc - Jeux de Parc - Aménagement", label_fr: "Parc - Jeux de Parc - Aménagement", label_en : "Park - Park Games - Development"},
        { value: '27', label: "Parc - Sentiers - Aménagement", label_fr: "Parc - Sentiers - Aménagement", label_en : "Park - Trails - Development"},
        { value: '28', label: "Parc - Sentiers - Entretien", label_fr: "Parc - Sentiers - Entretien", label_en : "Park - Trails - Maintenance"},
        { value: '29', label: "Parc - Nouveau parc", label_fr: "Parc - Nouveau parc", label_en : "Park - New Park"},
        { value: '30', label: "Parc- Réaménagement", label_fr: "Parc- Réaménagement", label_en : "Park- Redevelopment"},
        { value: '31', label: "Pont / viaduc / passerelle - Inspection", label_fr: "Pont / viaduc / passerelle - Inspection", label_en : "Bridge / Viaduct / Walkway - Inspection"}, 
        { value: '32', label: "Pont / viaduc / passerelle - Réfection", label_fr: "Pont / viaduc / passerelle - Réfection", label_en : "Bridge / viaduct / walkway - Rehabilitation"},
        { value: '33', label: "Réseau d'égout unitaire - Puisard - Installation", label_fr: "Réseau d'égout unitaire - Puisard - Installation", label_en : "Combined sewer system - Sump - Installation"},
        { value: '34', label: "Réseau d'égout unitaire - Puisard - Réfection/Remplacement", label_fr: "Réseau d'égout unitaire - Puisard - Réfection/Remplacement", label_en : "Combined sewer system - Sump - Repair/Replacement"},
        { value: '35', label: "Réseau d'aqueduc - Bris", label_fr: "Réseau d'aqueduc - Bris", label_en : "Waterworks - Bris"},
        { value: '36', label: "Réseau d'aqueduc - Construction", label_fr: "Réseau d'aqueduc - Construction", label_en : "Waterworks - Construction"},
        { value: '37', label: "Réseau d'aqueduc - Réfection/Remplacement", label_fr: "Réseau d'aqueduc - Réfection/Remplacement", label_en : "Waterworks - Repair/Replacement"},
        { value: '38', label: "Réseau d'aqueduc - Réhabilitation par chemisage structurale", label_fr: "Réseau d'aqueduc - Réhabilitation par chemisage structurale", label_en : "Aqueduct network - Rehabilitation by structural lining"},
        { value: '39', label: "Réseau d'aqueduc - Vanne - Réfection/Remplacement", label_fr: "Réseau d'aqueduc - Vanne - Réfection/Remplacement", label_en : "Aqueduct network - Gate - Repair/Replacement"}, 
        { value: '40', label: "Réseau d'aqueduc et d'égout unitaire - Construction", label_fr: "Réseau d'aqueduc et d'égout unitaire - Construction", label_en : "Combined water and sewer system - Construction"},
        { value: '41', label: "Réseau d'aqueduc et d'égout unitaire - Réfection/Remplacement", label_fr: "Réseau d'aqueduc et d'égout unitaire - Réfection/Remplacement", label_en : "Combined water and sewer system - Repair/Replacement"},
        { value: '42', label: "Réseau d'aqueduc et d'égout unitaire - Réhabilitation par chemisage structural", label_fr: "Réseau d'aqueduc et d'égout unitaire - Réhabilitation par chemisage structural", label_en : "Waterworks and combined sewer system - Rehabilitation by structural lining"}, 
        { value: '43', label: "Réseau d'égout unitaire - Construction", label_fr: "Réseau d'égout unitaire - Construction", label_en : "Combined sewer system - Construction"},
        { value: '44', label: "Réseau d'égout unitaire - Réhabilitation par chemisage strutural", label_fr: "Réseau d'égout unitaire - Réhabilitation par chemisage strutural", label_en : "Combined sewer network - Rehabilitation by structural lining"}, 
        { value: '45', label: "Réseau d'égout unitaire - Réfection/Remplacement", label_fr: "Réseau d'égout unitaire - Réfection/Remplacement", label_en : "Combined Sewer System - Rehabilitation/Replacement"},
        { value: '46', label: "Réseau d'égout unitaire - Regard d'égout - Installation", label_fr: "Réseau d'égout unitaire - Regard d'égout - Installation", label_en : "Combined Sewer System - Manhole - Installation"},
        { value: '47', label: "Réseau d'égout unitaire - Regard d'égout - Réfection/Remplacement", label_fr: "Réseau d'égout unitaire - Regard d'égout - Réfection/Remplacement", label_en : "Combined sewer system - Manhole - Rehabilitation/Replacement"}, 
        { value: '48', label: "Réseau d'égout unitaire - Nettoyage et inspection", label_fr: "Réseau d'égout unitaire - Nettoyage et inspection", label_en : "Combined Sewer System - Cleaning and Inspection"},
        { value: '49', label: "Réseau routier - Reconstruction", label_fr: "Réseau routier - Reconstruction", label_en : "Road network - Reconstruction"},
        { value: '50', label: "Réseau routier - Resurfaçage", label_fr: "Réseau routier - Resurfaçage", label_en : "Road network - Resurfacing"},
        { value: '51', label: "Réseau routier - Marquage de la chaussée", label_fr: "Réseau routier - Marquage de la chaussée", label_en : "Road network - Pavement marking"},
        { value: '52', label: "Réseau routier - Scellement de fissure", label_fr: "Réseau routier - Scellement de fissure", label_en : "Road network - Crack sealing"}, 
        { value: '53', label: "Réseau routier - Réfection/Réparation", label_fr: "Réseau routier - Réfection/Réparation", label_en : "Road network - Rehabilitation/Repair"},
        { value: '54', label: "Réseau routier - Trottoir- reconstruction", label_fr: "Réseau routier - Trottoir- reconstruction", label_en : "Road network - sidewalk - reconstruction"},
        { value: '55', label: "Services d'utilités publiques - Enfouissement", label_fr: "Services d'utilités publiques - Enfouissement", label_en : "Utilities - Landfill"},
        { value: '56', label: "Services d'utilités publiques - Installation", label_fr: "Services d'utilités publiques - Installation", label_en : "Utilities - Installation"},
        { value: '57', label: "Services d'utilités publiques - Réfection/Remplacement", label_fr: "Services d'utilités publiques - Réfection/Remplacement", label_en : "Utilities - Rehabilitation/Replacement"},
        { value: '58', label: "Signalisation - Installation", label_fr: "Signalisation - Installation", label_en : "Signage - Installation"},
        { value: '59', label: "Signalisation – Réfection", label_fr: "Signalisation – Réfection", label_en : "Signage – Repairs"},
        { value: '60', label: "Poste de chloration d’eau potable - Entretien", label_fr: "Poste de chloration d’eau potable - Entretien", label_en : "Drinking water chlorination station - Maintenance"},
        { value: '61', label: "Poste de chloration d'eau potable - Rénovation", label_fr: "Poste de chloration d'eau potable - Rénovation", label_en : "Drinking water chlorination station - Renovation"},
        { value: '62', label: "Évènement", label_fr: "Évènement", label_en : "Event"},
        { value: '63', label: 'Évènements municipaux', label_fr: 'Évènements municipaux', label_en : 'Municipal events'},
        { value: '64', label: 'Évènements citoyens – Fêtes de rue', label_fr: 'Évènements citoyens – Fêtes de rue', label_en : 'Citizen events – Street parties'},
        { value: '65', label: 'Évènement – Organismes caritatifs', label_fr: 'Évènement – Organismes caritatifs', label_en : 'Event – Charities'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Entrave du domaine public", label_fr: "Entrave du domaine public", label_en : "Obstruction of the public domain"},
        { value: '2', label: "Utilisation de borne-fontaine", label_fr: "Utilisation de borne-fontaine", label_en : "Use of fire hydrant"},
        { value: '3', label: "Permis de tranchée", label_fr: "Permis de tranchée", label_en : "Trench permit"},
        { value: '4', label: "Urbanisme", label_fr: "Urbanisme", label_en : "Town planning"},
        { value: '5', label: "Permis de déplacement de poteau ou lampadaire", label_fr: "Permis de déplacement de poteau ou lampadaire", label_en : "Permit to move pole or lamp post"},
        { value: '6', label: "Permis de branchement d'égout et d'eau potable", label_fr: "Permis de branchement d'égout et d'eau potable", label_en : "Sewer and drinking water connection permit"},
        { value: '7', label: "Permis de rejet à l'égout municipal", label_fr: "Permis de rejet à l'égout municipal", label_en : "Municipal Sewer Discharge Permit"},
        { value: '8', label: "Non-requis (projet ville)", label_fr: "Non-requis (projet ville)", label_en : "Not required (city project)"},
        { value: '9', label: "Non-requis - approbation DG", label_fr: "Non-requis - approbation DG", label_en : "Not required - DG approval"},
      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager", label_en:"Passenger type"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'type1', status:false, content: '', label:'Local ou usager du site', label_fr:'Local ou usager du site', label_en:'Local or site user'},
        {value:'type2', status:false, content: '', label:'Automobiliste', label_fr:'Automobiliste', label_en:'Driver'},
        {value:'type3', status:false, content: '', label:'Cycliste', label_fr:'Cycliste', label_en:'Cyclist'},
        {value:'type4', status:false, content: '', label:'Piéton', label_fr:'Piéton', label_en:'Pedestrian'},
        {value:'type5', status:false, content: '', label:'Transport collectif', label_fr:'Transport collectif', label_en:'Public transport'},
        {value:'type6', status:false, content: '', label:"Accessibilité véhicules d'urgence", label_fr:"Accessibilité véhicules d'urgence", label_en:'Emergency vehicles accessibility'},
        {value:'type7', status:false, content: '', label:'Accessibilité personnes à mobilité réduite', label_fr:'Accessibilité personnes à mobilité réduite', label_en:'Accessibility for people with reduced mobility'}
      ]
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"}, label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: true,
      value: [
        { value: "", label: 'Select Mail List', label_en: 'Select Mail List', label_fr: 'Sélectionnez la liste de diffusion'},
        { value: "Luc.Perreault@ville.mont-royal.qc.ca, Bruno.Passarelli@ville.mont-royal.qc.ca,Richard.Duguay@ville.mont-royal.qc.ca, alexandre.fortin-caillauld@ville.mont-royal.qc.ca ,jose.forget@ville.mont-royal.qc.ca , Saad.Safi@ville.mont-royal.qc.ca, Nathalie.Dufresne@ville.mont-royal.qc.ca , Karine.Jean@ville.mont-royal.qc.ca , Ramia.Ghantous@ville.mont-royal.qc.ca ,Edison.Ramirez@ville.mont-royal.qc.ca , dispatch@ville.mont-royal.qc.ca , Ian.Mainville@ville.mont-royal.qc.ca , Andrew.Milukow@ville.mont-royal.qc.ca , Daniel.Lepore@ville.mont-royal.qc.ca ,Benoit.Richer@ville.mont-royal.qc.ca , Vincent.Gasparini@ville.mont-royal.qc.ca ,Valerie.Petit@ville.mont-royal.qc.ca , reception.th@ville.mont-royal.qc.ca , Alain.Cote@ville.mont-royal.qc.ca , servicestechniques@ville.mont-royal.qc.ca , Ginette.Leclair@ville.mont-royal.qc.ca , jean-nicolas.thibodeau@ville.mont-royal.qc.ca , Ann-Lise.Annoual@ville.mont-royal.qc.ca ,Roxane.Laroche-Paquet@ville.mont-royal.qc.ca , hala.gebrine@ville.mont-royal.qc.ca,mike.nelli@ville.mont-royal.qc.ca,yan.fortin@ville.mont-royal.qc.ca,Tanya.Antebi@ville.mont-royal.qc.ca,Marie-Claude.Parent@ville.mont-royal.qc.ca, May.El-Azzi@ville.mont-royal.qc.ca, melanie.kechayan@ville.mont-royal.qc.ca, samuel.picard@ville.mont-royal.qc.ca", label: 'Info-worksVMR', label_en: 'Info-worksVMR', label_fr: 'Info-travauxVMR'},
        { value: "Luc.Perreault@ville.mont-royal.qc.ca, Bruno.Passarelli@ville.mont-royal.qc.ca,Richard.Duguay@ville.mont-royal.qc.ca, alexandre.fortin-caillauld@ville.mont-royal.qc.ca ,jose.forget@ville.mont-royal.qc.ca , Saad.Safi@ville.mont-royal.qc.ca, Nathalie.Dufresne@ville.mont-royal.qc.ca , Edison.Ramirez@ville.mont-royal.qc.ca , dispatch@ville.mont-royal.qc.ca ,Ian.Mainville@ville.mont-royal.qc.ca , Andrew.Milukow@ville.mont-royal.qc.ca ,Daniel.Lepore@ville.mont-royal.qc.ca , Benoit.Richer@ville.mont-royal.qc.ca, hala.gebrine@ville.mont-royal.qc.ca, mike.nelli@ville.mont-royal.qc.ca, servicestechniques@ville.mont-royal.qc.ca ,Ginette.Leclair@ville.mont-royal.qc.ca , jean-nicolas.thibodeau@ville.mont-royal.qc.ca , Ann-Lise.Annoual@ville.mont-royal.qc.ca,yan.fortin@ville.mont-royal.qc.ca, melanie.kechayan@ville.mont-royal.qc.ca, samuel.picard@ville.mont-royal.qc.ca", label: 'Info-permit', label_en: 'Info-permit', label_fr: 'Info-permis'},
      ],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: true,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: true,
      showInList: false,
      value: []
    }
  },
  quebec: {
    district: {
      key: 'district',
      title: {label: 'Arrondissements', label_fr:'Arrondissements', label_en:'Borough'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Beauport' },
        { value: '2', label: 'Charlesbourg' },
        { value: '3', label: 'La Cité-Limoilou' },
        { value: '4', label: 'La Haute-Saint-Charles' },
        { value: '5', label: 'Les Rivières' },
        { value: '6', label: 'Sainte-Foy-Sillery-Cap-Rouge' }
      ]
    },
    city:{
      key: 'city',
      title: {label: "Ville", label_fr:"Ville", label_en:'City'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Beaconsfield'},
        { value: '2', label: 'Côte-Saint-Luc'},
        { value: '3', label: 'Dollard-des-Ormeaux'},
        { value: '4', label: 'Dorval'},
        { value: '5', label: 'Hampstead'},
        { value: '6', label: 'Kirkland'},
        { value: '7', label: 'Montréal-Est'},
        { value: '8', label: 'Montréal-Ouest'},
        { value: '9', label: 'Mont-Royal'},
        { value: '10', label: 'Pointe-Claire'},
        { value: '11', label: 'Sainte-Anne-de-Bellevue'},
        { value: '12', label: 'Senneville'},
        { value: '13', label: 'Westmount'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Gaz', label_fr: 'Gaz', label_en : 'Gas'},
        { value: '2', label: 'Hydro', label_fr: 'Hydro', label_en : 'Hydro'},
        { value: '3', label: 'Télécom', label_fr: 'Télécom', label_en : 'Telecom'},
        { value: '4', label: 'Ville', label_fr: 'Ville', label_en : 'City'}
      ],
      classes:{},
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
          { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
          { value: '2', label: 'Avant-projet', label_fr: 'Avant-projet', label_en : 'Preliminary draft'},
          { value: '3', label: 'Plans et Devis', label_fr: 'Plans et Devis', label_en : 'Plans and Estimates'},
          { value: '4', label: 'Soumission', label_fr: 'Soumission', label_en : 'Submission'},
          { value: '5', label: 'Octroyé', label_fr: 'Octroyé', label_en : 'Granted'},
          { value: '6', label: 'En travaux', label_fr: 'En travaux', label_en : 'In progress'},
          { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Completed'},
          { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Nature des Travaux", label_fr:"Nature des Travaux", label_en:'Type of project'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label_fr: 'Travaux-publics', label_en : 'Public Works'},
        { value: '2', label_fr: 'Réfection-souterraine', label_en : 'Underground restoration'},
        { value: '3', label_fr: 'Éclairage', label_en : 'Lighting'},
        { value: '4', label_fr: 'Réfection de trottoirs', label_en : 'Sidewalk restoration'},
        { value: '5', label_fr: 'Réfection de surface', label_en : 'Surface restoration'},
        { value: '6', label_fr: 'Entretien', label_en : 'Maintenance'},
        { value: '7', label_fr: 'Evènement', label_en : 'Event'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux (Pour avis de travaux)", label_fr:"Catégorie de travaux (Pour avis de travaux)", label_en:'Category of work (For notice of work)'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    status: {
      key: 'status',
      title: {label: "Statut", label_fr:"Statut", label_en:'Status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager", label_en:"Passanger type"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"}, ,
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  toronto: {
    district:{
      key: 'district',
      title: {label: 'Arrondissements', label_fr:'Arrondissements', label_en:'Borough'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Etobicoke' },
        { value: '2', label: 'North York' },
        { value: '3', label: 'Scarborough' },
        { value: '4', label: 'York' },
        { value: '5', label: 'East York' },
        { value: '6', label: 'Downtown' }
      ]
    },
    city:{
      key: 'city',
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Etobicoke' },
        { value: '2', label: 'North York' },
        { value: '3', label: 'Scarborough' },
        { value: '4', label: 'York' },
        { value: '5', label: 'East York' },
        { value: '6', label: 'Downtown' }
      ],
      classes:{},
      legendImg: false
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Gaz', label_fr: 'Gaz', label_en : 'Gas'},
        { value: '2',  label: 'Hydro', label_fr: 'Hydro', label_en : 'Hydro'},
        { value: '3',  label: 'Télécom', label_fr: 'Télécom', label_en : 'Telecom'},
        { value: '4',  label: 'Ville', label_fr: 'Ville', label_en : 'City'},
        { value: '5',  label: 'Événement', label_fr: 'Événement', label_en : 'Event'}
      ]
    },
    step:{
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'Avant-projet', label_fr: 'Avant-projet', label_en : 'Preliminary draft'},
        { value: '3', label: 'Plans et Devis', label_fr: 'Plans et Devis', label_en : 'Plans and Estimates'},
        { value: '4', label: 'Soumission', label_fr: 'Soumission', label_en : 'Submission'},
        { value: '5', label: 'Octroyé', label_fr: 'Octroyé', label_en : 'Granted'},
        { value: '6', label: 'En travaux', label_fr: 'En travaux', label_en : 'In progress'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Completed'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Nature des Travaux", label_fr:"Nature des Travaux", label_en:'Type of project'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Travaux-publics', label_fr: 'Travaux-publics', label_en : 'Public Works'},
        { value: '2', label: 'Réfection-souterraine', label_fr: 'Réfection-souterraine', label_en : 'Underground restoration'},
        { value: '3', label: 'Éclairage', label_fr: 'Éclairage', label_en : 'Lighting'},
        { value: '4', label: 'Réfection de trottoirs', label_fr: 'Réfection de trottoirs', label_en : 'Sidewalk restoration'},
        { value: '5', label: 'Réfection de surface', label_fr: 'Réfection de surface', label_en : 'Surface restoration'},
        { value: '6', label: 'Entretien', label_fr: 'Entretien', label_en : 'Maintenance'},
        { value: '7', label: 'Evènement',  label_fr: 'Evènement', label_en : 'Event'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux (Pour avis de travaux)", label_fr:"Catégorie de travaux (Pour avis de travaux)", label_en:'Category of work (For notice of work)'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    status: {
      key: 'status',
      title: {label: "Statut", label_fr:"Statut", label_en:'Status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approuvé', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'En-approbation', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager", label_en:"Passanger type"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"}, ,
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  vancouver: {
    district:{
      key: 'district',
      title: {label: 'Arrondissements', label_fr:'Arrondissements', label_en:'Borough'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Arbutus Ridge' },
        { value: '2', label: 'Downtown' },
        { value: '3', label: 'Dunbar-Southlands' },
        { value: '4', label: 'Fairview' },
        { value: '5', label: 'Grandview-Woodland' },
        { value: '6', label: 'Hastings-Sunrise' },
        { value: '7', label: 'Kensington-Cedar Cottage' },
        { value: '8', label: 'Kerrisdale' },
        { value: '9', label: 'Killarney' },
        { value: '10', label: 'Kitsilano' },
        { value: '11', label: 'Marpole' },
        { value: '12', label: 'Mount Pleasant' },
        { value: '13', label: 'Oakridge' },
        { value: '14', label: 'Renfrew-Collingwood' },
        { value: '15', label: 'Riley Park' },
        { value: '16', label: 'Shaughnessy' },
        { value: '17', label: 'South Cambie' },
        { value: '18', label: 'Strathcona' },
        { value: '19', label: 'Sunset' },
        { value: '20', label: 'Victoria-Fraserview' },
        { value: '21', label: 'West End' },
        { value: '22', label: 'West Point Grey' }
      ]
    },
    city: {
      key: 'city',
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Vancouver'},
        { value: '2', label: 'Anmore'},
        { value: '3', label: 'Belcarra'},
        { value: '4', label: 'Bowen Island Municipality'},
        { value: '5', label: 'Burnaby'},
        { value: '6', label: 'Coquitlam'},
        { value: '7', label: 'Corporation of Delta'},
        { value: '8', label: 'Area A'},
        { value: '9', label: 'Langley'},
        { value: '10', label: 'Lions BayMaple Ridge'},
        { value: '11', label: 'New Westminster'},
        { value: '12', label: 'North Vancouver'},
        { value: '13', label: 'Pitt Meadows'},
        { value: '14', label: 'Port Coquitlam'},
        { value: '15', label: 'Port Moody'},
        { value: '16', label: 'Richmond'},
        { value: '17', label: 'Surrey'},
        { value: '18', label: 'West Vancouver'},
        { value: '19', label: 'White Rock'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Gaz', label_fr: 'Gaz', label_en : 'Gas'},
        { value: '2',  label: 'Hydro', label_fr: 'Hydro', label_en : 'Hydro'},
        { value: '3',  label: 'Télécom', label_fr: 'Télécom', label_en : 'Telecom'},
        { value: '4',  label: 'Ville', label_fr: 'Ville', label_en : 'City'},
        { value: '5',  label: 'Événement', label_fr: 'Événement', label_en : 'Event'}
      ],
      classes:{},
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'Avant-projet', label_fr: 'Avant-projet', label_en : 'Preliminary draft'},
        { value: '3', label: 'Plans et Devis', label_fr: 'Plans et Devis', label_en : 'Plans and Estimates'},
        { value: '4', label: 'Soumission', label_fr: 'Soumission', label_en : 'Submission'},
        { value: '5', label: 'Octroyé', label_fr: 'Octroyé', label_en : 'Granted'},
        { value: '6', label: 'En travaux', label_fr: 'En travaux', label_en : 'In progress'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Completed'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Nature des Travaux", label_fr:"Nature des Travaux", label_en:'Type of project'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Travaux-publics', label_fr: 'Travaux-publics', label_en : 'Public Works'},
        { value: '2', label: 'Réfection-souterraine', label_fr: 'Réfection-souterraine', label_en : 'Underground restoration'},
        { value: '3', label: 'Éclairage', label_fr: 'Éclairage', label_en : 'Lighting'},
        { value: '4', label: 'Réfection de trottoirs', label_fr: 'Réfection de trottoirs', label_en : 'Sidewalk restoration'},
        { value: '5', label: 'Réfection de surface', label_fr: 'Réfection de surface', label_en : 'Surface restoration'},
        { value: '6', label: 'Entretien', label_fr: 'Entretien', label_en : 'Maintenance'},
        { value: '7', label: 'Evènement',  label_fr: 'Evènement', label_en : 'Event'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux (Pour avis de travaux)", label_fr:"Catégorie de travaux (Pour avis de travaux)", label_en:'Category of work (For notice of work)'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    status: {
      key: 'status',
      title: {label: "Statut", label_fr:"Statut", label_en:'Status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approuvé', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'En-approbation', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager", label_en:"Passanger type"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"}, ,
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  canada: {
    district:{
      key: 'district',
      title: {label: 'Arrondissements', label_fr:'Arrondissements', label_en:'Borough'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ahuntsic-Cartierville' },
        { value: '2', label: 'Anjou' },
        { value: '3', label: 'Côte-des-Neiges—Notre-Dame-de-Grâce' },
        { value: '4', label: 'Lachine' },
        { value: '5', label: 'LaSalle' },
        { value: '6', label: 'Le Plateau-Mont-Royal' },
        { value: '7', label: 'Le Sud-Ouest' },
        { value: '8', label: "L'Île-Bizard–Sainte-Geneviève" },
        { value: '9', label: 'Mercier–Hochelaga-Maisonneuve' },
        { value: '10', label: 'Montréal-Nord' },
        { value: '11', label: 'Outremont' },
        { value: '12', label: 'Pierrefonds-Roxboro' },
        { value: '13', label: 'Rivière-des-Prairies–Pointe-aux-Trembles' },
        { value: '14', label: 'Rosemont–La Petite-Patrie' },
        { value: '15', label: 'Saint-Laurent' },
        { value: '16', label: 'Saint-Léonard' },
        { value: '17', label: 'Verdun' },
        { value: '18', label: 'Ville-Marie' },
        { value: '19', label: 'Villeray–Saint-Michel–Parc-Extension' }
      ]
    },
    city:{
      key: 'city',
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Beaconsfield'},
        { value: '2', label: 'Côte-Saint-Luc'},
        { value: '3', label: 'Dollard-des-Ormeaux'},
        { value: '4', label: 'Dorval'},
        { value: '5', label: 'Hampstead'},
        { value: '6', label: 'Kirkland'},
        { value: '7', label: 'Montréal'},
        { value: '8', label: 'Laval'},
        { value: '9', label: 'Brossard'},
        { value: '10', label: 'Longueuil'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Gaz', label_fr: 'Gaz', label_en : 'Gas'},
        { value: '2',  label: 'Hydro', label_fr: 'Hydro', label_en : 'Hydro'},
        { value: '3',  label: 'Télécom', label_fr: 'Télécom', label_en : 'Telecom'},
        { value: '4',  label: 'Ville', label_fr: 'Ville', label_en : 'City'},
        { value: '5',  label: 'Provincial', label_fr: 'Provincial', label_en : 'Provencial'},
        { value: '6',  label: 'Fédéral', label_fr: 'Fédéral', label_en : 'Federal'},
        { value: '7',  label: 'Autre', label_fr: 'Autre', label_en : 'Other'},
      ],
      classes: {},
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'Avant-projet', label_fr: 'Avant-projet', label_en : 'Preliminary draft'},
        { value: '3', label: 'Plans et Devis', label_fr: 'Plans et Devis', label_en : 'Plans and Estimates'},
        { value: '4', label: 'Soumission', label_fr: 'Soumission', label_en : 'Submission'},
        { value: '5', label: 'Octroyé', label_fr: 'Octroyé', label_en : 'Granted'},
        { value: '6', label: 'En travaux', label_fr: 'En travaux', label_en : 'In progress'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Completed'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Nature des Travaux", label_fr:"Nature des Travaux", label_en:'Type of project'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Travaux-publics', label_fr: 'Travaux-publics', label_en : 'Public Works'},
        { value: '2', label: 'Réfection-souterraine', label_fr: 'Réfection-souterraine', label_en : 'Underground restoration'},
        { value: '3', label: 'Éclairage', label_fr: 'Éclairage', label_en : 'Lighting'},
        { value: '4', label: 'Travaux préparatoire', label_fr: 'Travaux préparatoire', label_en : 'Preparatory work'},
        { value: '5', label: 'Réfection de trottoirs', label_fr: 'Réfection de trottoirs', label_en : 'Sidewalk restoration'},
        { value: '6', label: 'Réfection de surface', label_fr: 'Réfection de surface', label_en : 'Surface restoration'},
        { value: '7', label: 'Entretien', label_fr: 'Entretien', label_en : 'Maintenance'}      
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux (Pour avis de travaux)", label_fr:"Catégorie de travaux (Pour avis de travaux)", label_en:'Category of work (For notice of work)'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    status: {
      key: 'status',
      title: {label: "Statut", label_fr:"Statut", label_en:'Status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager", label_en:"Passanger type"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'type1', status:false, content: '', label:'Local ou usager du site', label_fr:'Local ou usager du site', label_en:'Local or site user'},
        {value:'type2', status:false, content: '', label:'Automobiliste', label_fr:'Automobiliste', label_en:'Driver'},
        {value:'type3', status:false, content: '', label:'Cycliste', label_fr:'Cycliste', label_en:'Cyclist'},
        {value:'type4', status:false, content: '', label:'Piéton', label_fr:'Piéton', label_en:'Pedestrian'},
        {value:'type5', status:false, content: '', label:'Transport collectif', label_fr:'Transport collectif', label_en:'Public transport'},
        {value:'type6', status:false, content: '', label:"Accessibilité véhicules d'urgence", label_fr:"Accessibilité véhicules d'urgence", label_en:'Emergency vehicles accessibility'},
        {value:'type7', status:false, content: '', label:'Accessibilité personnes à mobilité réduite', label_fr:'Accessibilité personnes à mobilité réduite', label_en:'Accessibility for people with reduced mobility'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  can: {
    district:{
      key: 'district',
      title: {label: 'Arrondissements', label_fr:'Arrondissements', label_en:'Borough'},
      show: true,
      search: true,
      value: [
        { value: '1', label: 'Ahuntsic-Cartierville' },
        { value: '2', label: 'Anjou' },
        { value: '3', label: 'Côte-des-Neiges—Notre-Dame-de-Grâce' },
        { value: '4', label: 'Lachine' },
        { value: '5', label: 'LaSalle' },
        { value: '6', label: 'Le Plateau-Mont-Royal' },
        { value: '7', label: 'Le Sud-Ouest' },
        { value: '8', label: "L'Île-Bizard–Sainte-Geneviève" },
        { value: '9', label: 'Mercier–Hochelaga-Maisonneuve' },
        { value: '10', label: 'Montréal-Nord' },
        { value: '11', label: 'Outremont' },
        { value: '12', label: 'Pierrefonds-Roxboro' },
        { value: '13', label: 'Rivière-des-Prairies–Pointe-aux-Trembles' },
        { value: '14', label: 'Rosemont–La Petite-Patrie' },
        { value: '15', label: 'Saint-Laurent' },
        { value: '16', label: 'Saint-Léonard' },
        { value: '17', label: 'Verdun' },
        { value: '18', label: 'Ville-Marie' },
        { value: '19', label: 'Villeray–Saint-Michel–Parc-Extension' }
      ]
    },
    city:{
      key: 'city',
      show: true,
      search: true,
      value: [
        { value: '1', label: 'Beaconsfield'},
        { value: '2', label: 'Côte-Saint-Luc'},
        { value: '3', label: 'Dollard-des-Ormeaux'},
        { value: '4', label: 'Dorval'},
        { value: '5', label: 'Hampstead'},
        { value: '6', label: 'Kirkland'},
        { value: '7', label: 'Montréal'},
        { value: '8', label: 'Laval'},
        { value: '9', label: 'Brossard'},
        { value: '10', label: 'Longueuil'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      search: true,
      value: [
        { value: '1', label: 'Gaz', label_fr: 'Gaz', label_en : 'Gas'},
        { value: '2',  label: 'Hydro', label_fr: 'Hydro', label_en : 'Hydro'},
        { value: '3',  label: 'Télécom', label_fr: 'Télécom', label_en : 'Telecom'},
        { value: '4',  label: 'Ville', label_fr: 'Ville', label_en : 'City'},
        { value: '5',  label: 'Provincial', label_fr: 'Provincial', label_en : 'Provencial'},
        { value: '6',  label: 'Fédéral', label_fr: 'Fédéral', label_en : 'Federal'},
        { value: '7',  label: 'Autre', label_fr: 'Autre', label_en : 'Other'},
      ],
      classes: {},
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'Avant-projet', label_fr: 'Avant-projet', label_en : 'Preliminary draft'},
        { value: '3', label: 'Plans et Devis', label_fr: 'Plans et Devis', label_en : 'Plans and Estimates'},
        { value: '4', label: 'Soumission', label_fr: 'Soumission', label_en : 'Submission'},
        { value: '5', label: 'Octroyé', label_fr: 'Octroyé', label_en : 'Granted'},
        { value: '6', label: 'En travaux', label_fr: 'En travaux', label_en : 'In progress'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Completed'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Nature des Travaux", label_fr:"Nature des Travaux", label_en:'Type of project'},
      show: true,
      search: true,
      value: [
        { value: '1', label: 'Travaux-publics', label_fr: 'Travaux-publics', label_en : 'Public Works'},
        { value: '2', label: 'Réfection-souterraine', label_fr: 'Réfection-souterraine', label_en : 'Underground restoration'},
        { value: '3', label: 'Éclairage', label_fr: 'Éclairage', label_en : 'Lighting'},
        { value: '4', label: 'Travaux préparatoire', label_fr: 'Travaux préparatoire', label_en : 'Preparatory work'},
        { value: '5', label: 'Réfection de trottoirs', label_fr: 'Réfection de trottoirs', label_en : 'Sidewalk restoration'},
        { value: '6', label: 'Réfection de surface', label_fr: 'Réfection de surface', label_en : 'Surface restoration'},
        { value: '7', label: 'Entretien', label_fr: 'Entretien', label_en : 'Maintenance'}      
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux (Pour avis de travaux)", label_fr:"Catégorie de travaux (Pour avis de travaux)", label_en:'Category of work (For notice of work)'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    status: {
      key: 'status',
      title: {label: "Statut", label_fr:"Statut", label_en:'Status'},
      show: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager", label_en:"Passanger type"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'type1', status:false, content: '', label:'Local ou usager du site', label_fr:'Local ou usager du site', label_en:'Local or site user'},
        {value:'type2', status:false, content: '', label:'Automobiliste', label_fr:'Automobiliste', label_en:'Driver'},
        {value:'type3', status:false, content: '', label:'Cycliste', label_fr:'Cycliste', label_en:'Cyclist'},
        {value:'type4', status:false, content: '', label:'Piéton', label_fr:'Piéton', label_en:'Pedestrian'},
        {value:'type5', status:false, content: '', label:'Transport collectif', label_fr:'Transport collectif', label_en:'Public transport'},
        {value:'type6', status:false, content: '', label:"Accessibilité véhicules d'urgence", label_fr:"Accessibilité véhicules d'urgence", label_en:'Emergency vehicles accessibility'},
        {value:'type7', status:false, content: '', label:'Accessibilité personnes à mobilité réduite', label_fr:'Accessibilité personnes à mobilité réduite', label_en:'Accessibility for people with reduced mobility'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  us: {
    district: {
      key: 'district',
      title: {label: 'Arrondissements', label_fr:'Arrondissements', label_en:'Borough'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Beauport' },
        { value: '2', label: 'Charlesbourg' },
        { value: '3', label: 'La Cité-Limoilou' },
        { value: '4', label: 'La Haute-Saint-Charles' },
        { value: '5', label: 'Les Rivières' },
        { value: '6', label: 'Sainte-Foy-Sillery-Cap-Rouge' }
      ]
    },
    city:{
      key: 'city',
      title: {label: "Ville", label_fr:"Ville", label_en:'City'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Beaconsfield'},
        { value: '2', label: 'Côte-Saint-Luc'},
        { value: '3', label: 'Dollard-des-Ormeaux'},
        { value: '4', label: 'Dorval'},
        { value: '5', label: 'Hampstead'},
        { value: '6', label: 'Kirkland'},
        { value: '7', label: 'Montréal-Est'},
        { value: '8', label: 'Montréal-Ouest'},
        { value: '9', label: 'Mont-Royal'},
        { value: '10', label: 'Pointe-Claire'},
        { value: '11', label: 'Sainte-Anne-de-Bellevue'},
        { value: '12', label: 'Senneville'},
        { value: '13', label: 'Westmount'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Gaz', label_fr: 'Gaz', label_en : 'Gas'},
        { value: '2', label: 'Hydro', label_fr: 'Hydro', label_en : 'Hydro'},
        { value: '3', label: 'Télécom', label_fr: 'Télécom', label_en : 'Telecom'},
        { value: '4', label: 'Ville', label_fr: 'Ville', label_en : 'City'}
      ],
      classes:{},
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
          { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
          { value: '2', label: 'Avant-projet', label_fr: 'Avant-projet', label_en : 'Preliminary draft'},
          { value: '3', label: 'Plans et Devis', label_fr: 'Plans et Devis', label_en : 'Plans and Estimates'},
          { value: '4', label: 'Soumission', label_fr: 'Soumission', label_en : 'Submission'},
          { value: '5', label: 'Octroyé', label_fr: 'Octroyé', label_en : 'Granted'},
          { value: '6', label: 'En travaux', label_fr: 'En travaux', label_en : 'In progress'},
          { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Completed'},
          { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Nature des Travaux", label_fr:"Nature des Travaux", label_en:'Type of project'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label_fr: 'Travaux-publics', label_en : 'Public Works'},
        { value: '2', label_fr: 'Réfection-souterraine', label_en : 'Underground restoration'},
        { value: '3', label_fr: 'Éclairage', label_en : 'Lighting'},
        { value: '4', label_fr: 'Réfection de trottoirs', label_en : 'Sidewalk restoration'},
        { value: '5', label_fr: 'Réfection de surface', label_en : 'Surface restoration'},
        { value: '6', label_fr: 'Entretien', label_en : 'Maintenance'},
        { value: '7', label_fr: 'Evènement', label_en : 'Event'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux (Pour avis de travaux)", label_fr:"Catégorie de travaux (Pour avis de travaux)", label_en:'Category of work (For notice of work)'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    status: {
      key: 'status',
      title: {label: "Statut", label_fr:"Statut", label_en:'Status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager", label_en:"Passanger type"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  gis: {
    district:{
      key: 'district',
      title: {label: 'District', label_fr:'District', label_en:'District'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'District 1' },
        { value: '2', label: 'District 2' },
        { value: '3', label: 'District 3' },
        { value: '4', label: 'District 4' },
        { value: '5', label: 'District 5' },
        { value: '6', label: 'District 6' },
        { value: '7', label: 'District 7' },
        { value: '8', label: 'District 8' },
        { value: '9', label: 'District 9' },
        { value: '10', label: 'District 10' },
        { value: '11', label: 'District 11' },
        { value: '12', label: 'District 12' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'Aylmer', label_fr: 'Aylmer', label_en: 'Aylmer'},
        { value: '2', label: 'Buckingham', label_fr: 'Buckingham', label_en: 'Buckingham'},
        { value: '3', label: 'Gatineau', label_fr: 'Gatineau', label_en: 'Gatineau'},
        { value: '4', label: 'Hull', label_fr: 'Hull', label_en: 'Hull'},
        { value: '5', label: 'Masson-Angers', label_fr: 'Masson-Angers', label_en: 'Masson-Angers'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ville - Infrastructures',label_fr: 'Ville - Infrastructures',label_en: 'City - Infrastructure', class: 'gat-orange-light', class1: 'gat-orange-light'},
        { value: '2', label: 'Ville - TP',label_fr: 'Ville - Travaux Publics',label_en: 'City - Public Works', class: 'gat-orange-light2', class1: 'gat-orange-light2'},
        { value: '6', label: 'Événements',label_fr: 'Événements',label_en: 'Events', class: 'gat-pink-light', class1: 'gat-pink-light'},
        { value: '9', label: 'Promoteurs immobiliers',label_fr: 'Promoteurs immobiliers', label_en:'Real estate promotors', class: 'gat-purple1', class1: 'gat-purple1'},
        { value: '3', label: 'RTU (HQ, Bell, Vidéotron, etc)',label_fr: 'RTU (HQ, Bell, Vidéotron, etc)',label_en: 'RTU (HQ, Bell, Videotron, etc)', class: 'gat-green-dark', class1: 'gat-green-dark'},
        { value: '4', label: 'MTQ',label_fr: 'MTQ',label_en: 'MTQ', class: 'gat-blue-dark', class1: 'gat-blue-dark'},
        { value: '5', label: 'Public transit',label_fr: 'ST',label_en: 'Public transit', class: 'gat-green3', class1: 'gat-green3'},
        { value: '7', label: "Mesures d'urgence",label_fr: "Mesures d'urgence",label_en: "Emergency measures", class: 'gat-red-light', class1: 'gat-red-light'},
        { value: '10', label: 'Administration ferroviaire (CN, CP, etc.)',label_fr: 'Administration ferroviaire (CN, CP, etc.)',label_en: 'Railway administration (CN, CP, etc)', class: 'gat-green-dark', class1: 'gat-green-dark'},
        { value: '8', label: 'Autres',label_fr: 'Autres',label_en: 'Other', class: 'gat-green-light', class1: 'gat-green-light'}
      ],
      classes: {
        'gat-orange-light':'#ED732E',
        'gat-orange-light2':'#F4B754',
        'gat-green3':'#316f7b',
        'gat-green-dark':'#2E6B4D',
        'gat-blue-dark':'#1C02DE',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-green-light':'#84B938',
        'gat-pink-light':'#ee7deb',
        'gat-red-light':'#E8464A',
        'gat-purple2':'#AA91E4',
        'gat-blue3':'#0E4095',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '4', label: 'PTI Infra', label_fr: 'PTI Infra', label_en : 'PTI Infra'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Entrave partielle', label_fr:'Entrave partielle', label_en:'Partial obstruction'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajets ou arrêt de la ST', label_fr:'Trajets ou arrêt de la ST', label_en:'Routes or bus stops'},
        {value:'9', status:false, content:'', label:'Corridor scolaire entravé', label_fr:'Corridor scolaire entravé', label_en:'Blocked school corridor'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'}
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Réseau temporaire d’eau potable', label_fr:'Réseau temporaire d’eau potable', label_en:'Temporary potable water network'},
        {value:'2', status:false, content:'', label:'Interruption d’eau potable', label_fr:'Interruption d’eau potable', label_en:'Interruption of potable water'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Interdiction de stationnement - complète", label_fr: "Interdiction de stationnement - complète", label_en : "Parking interdiction - complete"},
        { value: '2',  label: "Interdiction de stationnement - partielle", label_fr: "Interdiction de stationnement - partielle", label_en : "Parking interdiction - partial"},
      ]
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Aménagement paysager", label_fr: "Aménagement paysager", label_en : "Landscaping"},
        { value: '2',  label: "Arbres - Abattage ou élagage", label_fr: "Arbres - Abattage ou élagage", label_en : "Trees - Felling or Pruning"},
        { value: '3',  label: "Bâtiment - Déneigement de toiture", label_fr: "Bâtiment - Déneigement de toiture", label_en : "Building - Roof snow removal"},
        { value: '4',  label: "Bâtiment divers - entretien", label_fr: "Bâtiment divers - entretien", label_en : "Various buildings  - Maintenance"},
        { value: '5',  label: "Bâtiment municipal - Construction", label_fr: "Bâtiment municipal - Construction", label_en : "Municipal buildings - Construction"},
        { value: '6',  label: "Bâtiment municipal - Démolition", label_fr: "Bâtiment municipal - Démolition", label_en : "Municipal buildings - Demolition"},
        { value: '7',  label: "Bâtiment municipal - Réfection", label_fr: "Bâtiment municipal - Réfection", label_en : "Municipal buildings - Refurbishment"}, 
        { value: '8',  label: "Borne-fontaine - Inspection", label_fr: "Borne-fontaine - Inspection", label_en : "Fire hydrant - Inspection"},
        { value: '9',  label: "Branchement de services –  Réfection", label_fr: "Branchement de services –  Réfection", label_en : "Service connections –  Refurbishment"}, 
        { value: '10', label: "Branchement de services - Construction", label_fr: "Branchement de services - Construction", label_en : "Service connections - Construction"},
        { value: '11', label: "Feux de circulation - Installation", label_fr: "Feux de circulation - Installation", label_en : "Traffic lights - Installation"},
        { value: '12', label: "Feux de circulation - Réfection", label_fr: "Feux de circulation - Réfection", label_en : "Traffic lights - Refurbishment"},
        { value: '13', label: "Forage", label_fr: "Forage", label_en : "Drilling"},
        { value: '14', label: "Fossés - Nettoyage", label_fr: "Fossés - Nettoyage", label_en : "Ditch - Clean-Up"}, 
        { value: '15', label: "Fossés – Nouveau", label_fr: "Fossés – Nouveau", label_en : "Ditch - New"},
        { value: '16', label: "Mesure de modération de la circulation - Installation", label_fr: "Mesure de modération de la circulation - Installation", label_en : "Traffic moderation measures - Installation"},
        { value: '17', label: "Mesure de modération de la circulation – Réfection", label_fr: "Mesure de modération de la circulation – Réfection", label_en : "Traffic moderation measures - Refurbishment"},
        { value: '18', label: "Ministère des Transports du Québec - travaux", label_fr: "Ministère des Transports du Québec - travaux", label_en : "Quebec’s Ministry of Transportation - Works"}, 
        { value: '19', label: "Ouvrages de béton - Construction", label_fr: "Ouvrages de béton - Construction", label_en : "Concrete structure - Construction"},
        { value: '20', label: "Ouvrages de béton – Réfection", label_fr: "Ouvrages de béton – Réfection", label_en : "Concrete structure  – Refurbishment"}, 
        { value: '21', label: "Parc - Jeu d’eau - Aménagement", label_fr: "Parc - Jeu d’eau - Aménagement", label_en : "Parc - Water games - Landscaping"}, 
        { value: '22', label: "Parc - Parcours santé - Aménagement", label_fr: "Parc - Parcours santé - Aménagement", label_en : "Parc - Fitness trail - Landscaping"},
        { value: '23', label: "Parc municipal - Nouveau parc", label_fr: "Parc municipal - Nouveau parc", label_en : "Municipal Parc - New Parc"},
        { value: '24', label: "Parc municipal - Réaménagement", label_fr: "Parc municipal - Réaménagement", label_en : "Municipal Parc - Refurbishment"}, 
        { value: '25', label: "Ponceau – Installation", label_fr: "Ponceau – Installation", label_en : "Culvert – Installation"},
        { value: '26', label: "Ponceau – Réfection", label_fr: "Ponceau – Réfection", label_en : "Culvert – Refurbishment"},
        { value: '27', label: "Pont - Inspection", label_fr: "Pont - Inspection", label_en : "Birdge - Inspection"},
        { value: '28', label: "Pont - Réfection", label_fr: "Pont - Réfection", label_en : "Bridge - Refurbishment"},
        { value: '29', label: "Puisard - Installation", label_fr: "Puisard - Installation", label_en : "Sump - Installation"},
        { value: '30', label: "Puisard - Réfection", label_fr: "Puisard - Réfection", label_en : "Sump - Refurbishment"},
        { value: '31', label: "Réseau d'aqueduc - Bris", label_fr: "Réseau d'aqueduc - Bris", label_en : "Aqueduct network - Breakage"}, 
        { value: '32', label: "Réseau d'aqueduc - Construction", label_fr: "Réseau d'aqueduc - Construction", label_en : "Aqueduct network - Construction"},
        { value: '33', label: "Réseau d'aqueduc - Réfection", label_fr: "Réseau d'aqueduc - Réfection", label_en : "Aqueduct network - Refurbishment"},
        { value: '34', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_en : "Municipal aqueduct and storm and/or sanitary sewers services - Construction"},
        { value: '35', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Municipal aqueduct and storm and/or sanitary sewers services -  Refurbishment"},
        { value: '36', label: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_en : "Storm and/or sanitary sewers network - Construction"},
        { value: '37', label: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Storm and/or sanitary sewers network - Refubishment"},
        { value: '38', label: "Réseau routier - Construction", label_fr: "Réseau routier - Construction", label_en : "Road network - Construction"},
        { value: '39', label: "Réseau routier - Marquage de la chaussée", label: "Réseau routier - Marquage de la chaussée", label_en : "Road network - Pavement marking"}, 
        { value: '40', label: "Réseau routier - Réfection", label_fr: "Réseau routier - Réfection", label_en : "Road network - Refurbishment"},
        { value: '41', label: "Sentier récréatif - Construction", label_fr: "Sentier récréatif - Construction", label_en : "Recreational Trail - Construction"},
        { value: '42', label: "Sentier récréatif - Fermeture", label_fr: "Sentier récréatif - Fermeture", label_en : "Recreational Trail - Closure"}, 
        { value: '43', label: "Sentier récréatif - Nettoyage", label_fr: "Sentier récréatif - Nettoyage", label_en : "Recreational Trail - Clean-Up"},
        { value: '44', label: "Sentier récréatif - Réfection", label_fr: "Sentier récréatif - Réfection",label_en : "Recreational Trail - Refurbishment"}, 
        { value: '45', label: "Sentier récréatif - Zone inondée", label_fr: "Sentier récréatif - Zone inondée", label_en : "Recreational Trail - Flooded area"},
        { value: '46', label: "Services d'utilités publiques - Enfouissement", label_fr: "Services d'utilités publiques - Enfouissement", label_en : "Public Utilities Department - Landfill"},
        { value: '47', label: "Services d'utilités publiques - Installation", label_fr: "Services d'utilités publiques - Installation", label_en : "Public Utilities Department - Installation"}, 
        { value: '48', label: "Services publics - Réfection", label_fr: "Services publics - Réfection", label_en : "Public Works - Refurbishment"},
        { value: '49', label: "Signalisation - Installation", label_fr: "Signalisation - Installation", label_en : "Signage - Installation"},
        { value: '50', label: "Signalisation – Réfection", label_fr: "Signalisation – Réfection", label_en : "Signage – Refurbishment"},
        { value: '51', label: "Station de surpression ou de pompage - Construction", label_fr: "Station de surpression ou de pompage - Construction", label_en : "Booster or pumping station - Construction"},
        { value: '52', label: "Station de surpression ou de pompage - Réfection", label_fr: "Station de surpression ou de pompage - Réfection", label_en : "Booster or pumping station - Refurbishment"}, 
        { value: '53', label: "Usine d’eau potable - travaux", label_fr: "Usine d’eau potable - travaux", label_en : "Potable water plant - Works"},
        { value: '54', label: 'Événement', label_fr: 'Événement', label_en : 'Event'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'Permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Entrave", label_fr: "Entrave", label_en : "Obstacles"},
        { value: '2', label: "Occupation", label_fr: "Occupation", label_en : "Occupation"},
        { value: '3', label: "Partenaires", label_fr: "Partenaires", label_en : "Partners"},
        { value: '4', label: "Non requis (projets Ville)", label_fr: "Non requis (projets Ville)", label_en : "Not required (City projects)"},
        { value: '5', label: "Résolution ou approbation CS", label_fr: "Résolution ou approbation CS", label_en : "Resolution or approval CS"},
      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'1', label:'311', label_fr:'311', label_en:'311'},
        {value:'2', label:'Commissions Scolaires', label_fr:'Commissions Scolaires', label_en:'School Boards'},
        {value:'3', label:"Coopérative des paramédics", label_fr:"Coopérative des paramédics", label_en:"Paramedics cooperative"},
        {value:'4', label:'Ministère des Transports du Québec (MTQ)', label_fr:'Ministère des Transports du Québec (MTQ)', label_en:'Quebec Ministry of Transport (MTQ)'},
        {value:'5', label:'RTU', label_fr:'RTU', label_en:'RTU'},
        {value:'6', label:'Sécurité incendie', label_fr:'Sécurité incendie', label_en:'Fire Safety'},
        {value:'7', label:'Service de police', label_fr:'Service de police', label_en:'Police Department'},
        {value:'8', label:'Société de transport', label_fr:'Société de transport', label_en:'Transportation Company'},
        {value:'9', label:'Stationnememt', label_fr:'Stationnememt', label_en:'Parking'},
      ]
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager(ne pas utiliser)", label_en:"Passenger type(do not use)"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  repentigny: {
    district:{
      key: 'district',
      title: {label: 'District', label_fr:'District', label_en:'District'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'District 1' },
        { value: '2', label: 'District 2' },
        { value: '3', label: 'District 3' },
        { value: '4', label: 'District 4' },
        { value: '5', label: 'District 5' },
        { value: '6', label: 'District 6' },
        { value: '7', label: 'District 7' },
        { value: '8', label: 'District 8' },
        { value: '9', label: 'District 9' },
        { value: '10', label: 'District 10' },
        { value: '11', label: 'District 11' },
        { value: '12', label: 'District 12' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'Aylmer', label_fr: 'Aylmer', label_en: 'Aylmer'},
        { value: '2', label: 'Buckingham', label_fr: 'Buckingham', label_en: 'Buckingham'},
        { value: '3', label: 'Gatineau', label_fr: 'Gatineau', label_en: 'Gatineau'},
        { value: '4', label: 'Hull', label_fr: 'Hull', label_en: 'Hull'},
        { value: '5', label: 'Masson-Angers', label_fr: 'Masson-Angers', label_en: 'Masson-Angers'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ville - Infrastructures',label_fr: 'Ville - Infrastructures',label_en: 'City - Infrastructure', class: 'gat-orange-light', class1: 'gat-orange-light'},
        { value: '2', label: 'Ville - TP',label_fr: 'Ville - Travaux Publics',label_en: 'City - Public Works', class: 'gat-orange-light2', class1: 'gat-orange-light2'},
        { value: '6', label: 'Événements',label_fr: 'Événements',label_en: 'Events', class: 'gat-pink-light', class1: 'gat-pink-light'},
        { value: '9', label: 'Promoteurs immobiliers',label_fr: 'Promoteurs immobiliers', label_en:'Real estate promotors', class: 'gat-purple1', class1: 'gat-purple1'},
        { value: '3', label: 'RTU (HQ, Bell, Vidéotron, etc)',label_fr: 'RTU (HQ, Bell, Vidéotron, etc)',label_en: 'RTU (HQ, Bell, Videotron, etc)', class: 'gat-green-dark', class1: 'gat-green-dark'},
        { value: '4', label: 'MTQ',label_fr: 'MTQ',label_en: 'MTQ', class: 'gat-blue-dark', class1: 'gat-blue-dark'},
        { value: '5', label: 'Public transit',label_fr: 'ST',label_en: 'Public transit', class: 'gat-green3', class1: 'gat-green3'},
        { value: '7', label: "Mesures d'urgence",label_fr: "Mesures d'urgence",label_en: "Emergency measures", class: 'gat-red-light', class1: 'gat-red-light'},
        { value: '10', label: 'Administration ferroviaire (CN, CP, etc.)',label_fr: 'Administration ferroviaire (CN, CP, etc.)',label_en: 'Railway administration (CN, CP, etc)', class: 'gat-green-dark', class1: 'gat-green-dark'},
        { value: '8', label: 'Autres',label_fr: 'Autres',label_en: 'Other', class: 'gat-green-light', class1: 'gat-green-light'}
      ],
      classes: {
        'gat-orange-light':'#ED732E',
        'gat-orange-light2':'#F4B754',
        'gat-green3':'#316f7b',
        'gat-green-dark':'#2E6B4D',
        'gat-blue-dark':'#1C02DE',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-green-light':'#84B938',
        'gat-pink-light':'#ee7deb',
        'gat-red-light':'#E8464A',
        'gat-purple2':'#AA91E4',
        'gat-blue3':'#0E4095',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
        'gat-jaune': '#2E6B4D'
      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '4', label: 'PTI Infra', label_fr: 'PTI Infra', label_en : 'PTI Infra'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Entrave partielle', label_fr:'Entrave partielle', label_en:'Partial obstruction'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajets ou arrêt de la ST', label_fr:'Trajets ou arrêt de la ST', label_en:'Routes or bus stops'},
        {value:'9', status:false, content:'', label:'Corridor scolaire entravé', label_fr:'Corridor scolaire entravé', label_en:'Blocked school corridor'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'}
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Réseau temporaire d’eau potable', label_fr:'Réseau temporaire d’eau potable', label_en:'Temporary potable water network'},
        {value:'2', status:false, content:'', label:'Interruption d’eau potable', label_fr:'Interruption d’eau potable', label_en:'Interruption of potable water'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Interdiction de stationnement - complète", label_fr: "Interdiction de stationnement - complète", label_en : "Parking interdiction - complete"},
        { value: '2',  label: "Interdiction de stationnement - partielle", label_fr: "Interdiction de stationnement - partielle", label_en : "Parking interdiction - partial"},
      ]
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Aménagement paysager", label_fr: "Aménagement paysager", label_en : "Landscaping"},
        { value: '2',  label: "Arbres - Abattage ou élagage", label_fr: "Arbres - Abattage ou élagage", label_en : "Trees - Felling or Pruning"},
        { value: '3',  label: "Bâtiment - Déneigement de toiture", label_fr: "Bâtiment - Déneigement de toiture", label_en : "Building - Roof snow removal"},
        { value: '4',  label: "Bâtiment divers - entretien", label_fr: "Bâtiment divers - entretien", label_en : "Various buildings  - Maintenance"},
        { value: '5',  label: "Bâtiment municipal - Construction", label_fr: "Bâtiment municipal - Construction", label_en : "Municipal buildings - Construction"},
        { value: '6',  label: "Bâtiment municipal - Démolition", label_fr: "Bâtiment municipal - Démolition", label_en : "Municipal buildings - Demolition"},
        { value: '7',  label: "Bâtiment municipal - Réfection", label_fr: "Bâtiment municipal - Réfection", label_en : "Municipal buildings - Refurbishment"}, 
        { value: '8',  label: "Borne-fontaine - Inspection", label_fr: "Borne-fontaine - Inspection", label_en : "Fire hydrant - Inspection"},
        { value: '9',  label: "Branchement de services –  Réfection", label_fr: "Branchement de services –  Réfection", label_en : "Service connections –  Refurbishment"}, 
        { value: '10', label: "Branchement de services - Construction", label_fr: "Branchement de services - Construction", label_en : "Service connections - Construction"},
        { value: '11', label: "Feux de circulation - Installation", label_fr: "Feux de circulation - Installation", label_en : "Traffic lights - Installation"},
        { value: '12', label: "Feux de circulation - Réfection", label_fr: "Feux de circulation - Réfection", label_en : "Traffic lights - Refurbishment"},
        { value: '13', label: "Forage", label_fr: "Forage", label_en : "Drilling"},
        { value: '14', label: "Fossés - Nettoyage", label_fr: "Fossés - Nettoyage", label_en : "Ditch - Clean-Up"}, 
        { value: '15', label: "Fossés – Nouveau", label_fr: "Fossés – Nouveau", label_en : "Ditch - New"},
        { value: '16', label: "Mesure de modération de la circulation - Installation", label_fr: "Mesure de modération de la circulation - Installation", label_en : "Traffic moderation measures - Installation"},
        { value: '17', label: "Mesure de modération de la circulation – Réfection", label_fr: "Mesure de modération de la circulation – Réfection", label_en : "Traffic moderation measures - Refurbishment"},
        { value: '18', label: "Ministère des Transports du Québec - travaux", label_fr: "Ministère des Transports du Québec - travaux", label_en : "Quebec’s Ministry of Transportation - Works"}, 
        { value: '19', label: "Ouvrages de béton - Construction", label_fr: "Ouvrages de béton - Construction", label_en : "Concrete structure - Construction"},
        { value: '20', label: "Ouvrages de béton – Réfection", label_fr: "Ouvrages de béton – Réfection", label_en : "Concrete structure  – Refurbishment"}, 
        { value: '21', label: "Parc - Jeu d’eau - Aménagement", label_fr: "Parc - Jeu d’eau - Aménagement", label_en : "Parc - Water games - Landscaping"}, 
        { value: '22', label: "Parc - Parcours santé - Aménagement", label_fr: "Parc - Parcours santé - Aménagement", label_en : "Parc - Fitness trail - Landscaping"},
        { value: '23', label: "Parc municipal - Nouveau parc", label_fr: "Parc municipal - Nouveau parc", label_en : "Municipal Parc - New Parc"},
        { value: '24', label: "Parc municipal - Réaménagement", label_fr: "Parc municipal - Réaménagement", label_en : "Municipal Parc - Refurbishment"}, 
        { value: '25', label: "Ponceau – Installation", label_fr: "Ponceau – Installation", label_en : "Culvert – Installation"},
        { value: '26', label: "Ponceau – Réfection", label_fr: "Ponceau – Réfection", label_en : "Culvert – Refurbishment"},
        { value: '27', label: "Pont - Inspection", label_fr: "Pont - Inspection", label_en : "Birdge - Inspection"},
        { value: '28', label: "Pont - Réfection", label_fr: "Pont - Réfection", label_en : "Bridge - Refurbishment"},
        { value: '29', label: "Puisard - Installation", label_fr: "Puisard - Installation", label_en : "Sump - Installation"},
        { value: '30', label: "Puisard - Réfection", label_fr: "Puisard - Réfection", label_en : "Sump - Refurbishment"},
        { value: '31', label: "Réseau d'aqueduc - Bris", label_fr: "Réseau d'aqueduc - Bris", label_en : "Aqueduct network - Breakage"}, 
        { value: '32', label: "Réseau d'aqueduc - Construction", label_fr: "Réseau d'aqueduc - Construction", label_en : "Aqueduct network - Construction"},
        { value: '33', label: "Réseau d'aqueduc - Réfection", label_fr: "Réseau d'aqueduc - Réfection", label_en : "Aqueduct network - Refurbishment"},
        { value: '34', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_en : "Municipal aqueduct and storm and/or sanitary sewers services - Construction"},
        { value: '35', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Municipal aqueduct and storm and/or sanitary sewers services -  Refurbishment"},
        { value: '36', label: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_en : "Storm and/or sanitary sewers network - Construction"},
        { value: '37', label: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Storm and/or sanitary sewers network - Refubishment"},
        { value: '38', label: "Réseau routier - Construction", label_fr: "Réseau routier - Construction", label_en : "Road network - Construction"},
        { value: '39', label: "Réseau routier - Marquage de la chaussée", label: "Réseau routier - Marquage de la chaussée", label_en : "Road network - Pavement marking"}, 
        { value: '40', label: "Réseau routier - Réfection", label_fr: "Réseau routier - Réfection", label_en : "Road network - Refurbishment"},
        { value: '41', label: "Sentier récréatif - Construction", label_fr: "Sentier récréatif - Construction", label_en : "Recreational Trail - Construction"},
        { value: '42', label: "Sentier récréatif - Fermeture", label_fr: "Sentier récréatif - Fermeture", label_en : "Recreational Trail - Closure"}, 
        { value: '43', label: "Sentier récréatif - Nettoyage", label_fr: "Sentier récréatif - Nettoyage", label_en : "Recreational Trail - Clean-Up"},
        { value: '44', label: "Sentier récréatif - Réfection", label_fr: "Sentier récréatif - Réfection",label_en : "Recreational Trail - Refurbishment"}, 
        { value: '45', label: "Sentier récréatif - Zone inondée", label_fr: "Sentier récréatif - Zone inondée", label_en : "Recreational Trail - Flooded area"},
        { value: '46', label: "Services d'utilités publiques - Enfouissement", label_fr: "Services d'utilités publiques - Enfouissement", label_en : "Public Utilities Department - Landfill"},
        { value: '47', label: "Services d'utilités publiques - Installation", label_fr: "Services d'utilités publiques - Installation", label_en : "Public Utilities Department - Installation"}, 
        { value: '48', label: "Services publics - Réfection", label_fr: "Services publics - Réfection", label_en : "Public Works - Refurbishment"},
        { value: '49', label: "Signalisation - Installation", label_fr: "Signalisation - Installation", label_en : "Signage - Installation"},
        { value: '50', label: "Signalisation – Réfection", label_fr: "Signalisation – Réfection", label_en : "Signage – Refurbishment"},
        { value: '51', label: "Station de surpression ou de pompage - Construction", label_fr: "Station de surpression ou de pompage - Construction", label_en : "Booster or pumping station - Construction"},
        { value: '52', label: "Station de surpression ou de pompage - Réfection", label_fr: "Station de surpression ou de pompage - Réfection", label_en : "Booster or pumping station - Refurbishment"}, 
        { value: '53', label: "Usine d’eau potable - travaux", label_fr: "Usine d’eau potable - travaux", label_en : "Potable water plant - Works"},
        { value: '54', label: 'Événement', label_fr: 'Événement', label_en : 'Event'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'Permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Entrave", label_fr: "Entrave", label_en : "Obstacles"},
        { value: '2', label: "Occupation", label_fr: "Occupation", label_en : "Occupation"},
        { value: '3', label: "Partenaires", label_fr: "Partenaires", label_en : "Partners"},
        { value: '4', label: "Non requis (projets Ville)", label_fr: "Non requis (projets Ville)", label_en : "Not required (City projects)"},
        { value: '5', label: "Résolution ou approbation CS", label_fr: "Résolution ou approbation CS", label_en : "Resolution or approval CS"},
      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'1', label:'311', label_fr:'311', label_en:'311'},
        {value:'2', label:'Commissions Scolaires', label_fr:'Commissions Scolaires', label_en:'School Boards'},
        {value:'3', label:"Coopérative des paramédics", label_fr:"Coopérative des paramédics", label_en:"Paramedics cooperative"},
        {value:'4', label:'Ministère des Transports du Québec (MTQ)', label_fr:'Ministère des Transports du Québec (MTQ)', label_en:'Quebec Ministry of Transport (MTQ)'},
        {value:'5', label:'RTU', label_fr:'RTU', label_en:'RTU'},
        {value:'6', label:'Sécurité incendie', label_fr:'Sécurité incendie', label_en:'Fire Safety'},
        {value:'7', label:'Service de police', label_fr:'Service de police', label_en:'Police Department'},
        {value:'8', label:'Société de transport', label_fr:'Société de transport', label_en:'Transportation Company'},
        {value:'9', label:'Stationnememt', label_fr:'Stationnememt', label_en:'Parking'},
      ]
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager(ne pas utiliser)", label_en:"Passenger type(do not use)"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  boisbriand: {
    district:{
      key: 'district',
      title: {label: 'District', label_fr:'District', label_en:'District'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'District 1' },
        { value: '2', label: 'District 2' },
        { value: '3', label: 'District 3' },
        { value: '4', label: 'District 4' },
        { value: '5', label: 'District 5' },
        { value: '6', label: 'District 6' },
        { value: '7', label: 'District 7' },
        { value: '8', label: 'District 8' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'Aylmer', label_fr: 'Aylmer', label_en: 'Aylmer'},
        { value: '2', label: 'Buckingham', label_fr: 'Buckingham', label_en: 'Buckingham'},
        { value: '3', label: 'Gatineau', label_fr: 'Gatineau', label_en: 'Gatineau'},
        { value: '4', label: 'Hull', label_fr: 'Hull', label_en: 'Hull'},
        { value: '5', label: 'Masson-Angers', label_fr: 'Masson-Angers', label_en: 'Masson-Angers'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ville - Infrastructures',label_fr: 'Ville - Infrastructures',label_en: 'City - Infrastructure', class: 'gat-green1', class1: 'gat-green1'},
        { value: '2', label: 'Ville - TP',label_fr: 'Ville - Travaux Publics',label_en: 'City - Public Works', class: 'gat-green2', class1: 'gat-green2'},
        { value: '6', label: 'Événements',label_fr: 'Événements',label_en: 'Events', class: 'gat-yellow', class1: 'gat-yellow'},
        { value: '9', label: 'Promoteurs immobiliers',label_fr: 'Promoteurs immobiliers', label_en:'Real estate promotors', class: 'gat-purple1', class1: 'gat-purple1'},
        { value: '3', label: 'RTU (HQ, Bell, Vidéotron, etc)',label_fr: 'RTU (HQ, Bell, Vidéotron, etc)',label_en: 'RTU (HQ, Bell, Videotron, etc)', class: 'gat-orange', class1: 'gat-orange'},
        { value: '4', label: 'MTQ',label_fr: 'MTQ',label_en: 'MTQ', class: 'gat-blue1', class1: 'gat-blue1'},
        { value: '5', label: 'EXO',label_fr: 'EXO',label_en: 'EXO', class: 'gat-green3', class1: 'gat-green3'},
        { value: '7', label: "Mesures d'urgence",label_fr: "Mesures d'urgence",label_en: "Emergency measures", class: 'gat-red', class1: 'gat-red'},
        { value: '10', label: 'Administration ferroviaire (CN, CP, etc.)',label_fr: 'Administration ferroviaire (CN, CP, etc.)',label_en: 'Railway administration (CN, CP, etc)', class: 'gat-jaune', class1: 'gat-jaune'},
        { value: '8', label: 'Autres',label_fr: 'Autres',label_en: 'Other', class: 'gat-blue2', class1: 'gat-blue2'}
      ],
      classes: {
        'gat-green1':'#00b050',
        'gat-green2':'#92d050',
        'gat-green3':'#316f7b',
        'gat-orange':'#ffc001',
        'gat-blue1':'#01b0f0',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-blue2':'#0070c0',
        'gat-yellow':'#ffff02',
        'gat-red':'#ff0000',
        'gat-purple2':'#AA91E4',
        'gat-blue3':'#0E4095',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
        'gat-jaune': '#a18200'
      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '4', label: 'PTI Infra', label_fr: 'PTI Infra', label_en : 'PTI Infra'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Entrave partielle', label_fr:'Entrave partielle', label_en:'Partial obstruction'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajets ou arrêt de la EXO ', label_fr:'Trajets ou arrêt de la EXO ', label_en:'Routes or Stops EXO'},
        {value:'9', status:false, content:'', label:'Corridor scolaire entravé', label_fr:'Corridor scolaire entravé', label_en:'Blocked school corridor'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'}
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Réseau temporaire d’eau potable', label_fr:'Réseau temporaire d’eau potable', label_en:'Temporary potable water network'},
        {value:'2', status:false, content:'', label:'Interruption d’eau potable', label_fr:'Interruption d’eau potable', label_en:'Interruption of potable water'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Interdiction de stationnement - complète", label_fr: "Interdiction de stationnement - complète", label_en : "Parking interdiction - complete"},
        { value: '2',  label: "Interdiction de stationnement - partielle", label_fr: "Interdiction de stationnement - partielle", label_en : "Parking interdiction - partial"},
      ]
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Aménagement paysager", label_fr: "Aménagement paysager", label_en : "Landscaping"},
        { value: '2',  label: "Arbres - Abattage ou élagage", label_fr: "Arbres - Abattage ou élagage", label_en : "Trees - Felling or Pruning"},
        { value: '3',  label: "Bâtiment - Déneigement de toiture", label_fr: "Bâtiment - Déneigement de toiture", label_en : "Building - Roof snow removal"},
        { value: '4',  label: "Bâtiment divers - entretien", label_fr: "Bâtiment divers - entretien", label_en : "Various buildings  - Maintenance"},
        { value: '5',  label: "Bâtiment municipal - Construction", label_fr: "Bâtiment municipal - Construction", label_en : "Municipal buildings - Construction"},
        { value: '6',  label: "Bâtiment municipal - Démolition", label_fr: "Bâtiment municipal - Démolition", label_en : "Municipal buildings - Demolition"},
        { value: '7',  label: "Bâtiment municipal - Réfection", label_fr: "Bâtiment municipal - Réfection", label_en : "Municipal buildings - Refurbishment"}, 
        { value: '8',  label: "Borne-fontaine - Inspection", label_fr: "Borne-fontaine - Inspection", label_en : "Fire hydrant - Inspection"},
        { value: '9',  label: "Branchement de services –  Réfection", label_fr: "Branchement de services –  Réfection", label_en : "Service connections –  Refurbishment"}, 
        { value: '10', label: "Branchement de services - Construction", label_fr: "Branchement de services - Construction", label_en : "Service connections - Construction"},
        { value: '11', label: "Feux de circulation - Installation", label_fr: "Feux de circulation - Installation", label_en : "Traffic lights - Installation"},
        { value: '12', label: "Feux de circulation - Réfection", label_fr: "Feux de circulation - Réfection", label_en : "Traffic lights - Refurbishment"},
        { value: '13', label: "Forage", label_fr: "Forage", label_en : "Drilling"},
        { value: '14', label: "Fossés - Nettoyage", label_fr: "Fossés - Nettoyage", label_en : "Ditch - Clean-Up"}, 
        { value: '15', label: "Fossés – Nouveau", label_fr: "Fossés – Nouveau", label_en : "Ditch - New"},
        { value: '16', label: "Mesure de modération de la circulation - Installation", label_fr: "Mesure de modération de la circulation - Installation", label_en : "Traffic moderation measures - Installation"},
        { value: '17', label: "Mesure de modération de la circulation – Réfection", label_fr: "Mesure de modération de la circulation – Réfection", label_en : "Traffic moderation measures - Refurbishment"},
        { value: '18', label: "Ministère des Transports du Québec - travaux", label_fr: "Ministère des Transports du Québec - travaux", label_en : "Quebec’s Ministry of Transportation - Works"}, 
        { value: '19', label: "Ouvrages de béton - Construction", label_fr: "Ouvrages de béton - Construction", label_en : "Concrete structure - Construction"},
        { value: '20', label: "Ouvrages de béton – Réfection", label_fr: "Ouvrages de béton – Réfection", label_en : "Concrete structure  – Refurbishment"}, 
        { value: '21', label: "Parc - Jeu d’eau - Aménagement", label_fr: "Parc - Jeu d’eau - Aménagement", label_en : "Parc - Water games - Landscaping"}, 
        { value: '22', label: "Parc - Parcours santé - Aménagement", label_fr: "Parc - Parcours santé - Aménagement", label_en : "Parc - Fitness trail - Landscaping"},
        { value: '23', label: "Parc municipal - Nouveau parc", label_fr: "Parc municipal - Nouveau parc", label_en : "Municipal Parc - New Parc"},
        { value: '24', label: "Parc municipal - Réaménagement", label_fr: "Parc municipal - Réaménagement", label_en : "Municipal Parc - Refurbishment"}, 
        { value: '25', label: "Ponceau – Installation", label_fr: "Ponceau – Installation", label_en : "Culvert – Installation"},
        { value: '26', label: "Ponceau – Réfection", label_fr: "Ponceau – Réfection", label_en : "Culvert – Refurbishment"},
        { value: '27', label: "Pont - Inspection", label_fr: "Pont - Inspection", label_en : "Birdge - Inspection"},
        { value: '28', label: "Pont - Réfection", label_fr: "Pont - Réfection", label_en : "Bridge - Refurbishment"},
        { value: '29', label: "Puisard - Installation", label_fr: "Puisard - Installation", label_en : "Sump - Installation"},
        { value: '30', label: "Puisard - Réfection", label_fr: "Puisard - Réfection", label_en : "Sump - Refurbishment"},
        { value: '31', label: "Réseau d'aqueduc - Bris", label_fr: "Réseau d'aqueduc - Bris", label_en : "Aqueduct network - Breakage"}, 
        { value: '32', label: "Réseau d'aqueduc - Construction", label_fr: "Réseau d'aqueduc - Construction", label_en : "Aqueduct network - Construction"},
        { value: '33', label: "Réseau d'aqueduc - Réfection", label_fr: "Réseau d'aqueduc - Réfection", label_en : "Aqueduct network - Refurbishment"},
        { value: '34', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_en : "Municipal aqueduct and storm and/or sanitary sewers services - Construction"},
        { value: '35', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Municipal aqueduct and storm and/or sanitary sewers services -  Refurbishment"},
        { value: '36', label: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_en : "Storm and/or sanitary sewers network - Construction"},
        { value: '37', label: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Storm and/or sanitary sewers network - Refubishment"},
        { value: '38', label: "Réseau routier - Construction", label_fr: "Réseau routier - Construction", label_en : "Road network - Construction"},
        { value: '39', label: "Réseau routier - Marquage de la chaussée", label: "Réseau routier - Marquage de la chaussée", label_en : "Road network - Pavement marking"}, 
        { value: '40', label: "Réseau routier - Réfection", label_fr: "Réseau routier - Réfection", label_en : "Road network - Refurbishment"},
        { value: '41', label: "Sentier récréatif - Construction", label_fr: "Sentier récréatif - Construction", label_en : "Recreational Trail - Construction"},
        { value: '42', label: "Sentier récréatif - Fermeture", label_fr: "Sentier récréatif - Fermeture", label_en : "Recreational Trail - Closure"}, 
        { value: '43', label: "Sentier récréatif - Nettoyage", label_fr: "Sentier récréatif - Nettoyage", label_en : "Recreational Trail - Clean-Up"},
        { value: '44', label: "Sentier récréatif - Réfection", label_fr: "Sentier récréatif - Réfection",label_en : "Recreational Trail - Refurbishment"}, 
        { value: '45', label: "Sentier récréatif - Zone inondée", label_fr: "Sentier récréatif - Zone inondée", label_en : "Recreational Trail - Flooded area"},
        { value: '46', label: "Services d'utilités publiques - Enfouissement", label_fr: "Services d'utilités publiques - Enfouissement", label_en : "Public Utilities Department - Landfill"},
        { value: '47', label: "Services d'utilités publiques - Installation", label_fr: "Services d'utilités publiques - Installation", label_en : "Public Utilities Department - Installation"}, 
        { value: '48', label: "Services publics - Réfection", label_fr: "Services publics - Réfection", label_en : "Public Works - Refurbishment"},
        { value: '49', label: "Signalisation - Installation", label_fr: "Signalisation - Installation", label_en : "Signage - Installation"},
        { value: '50', label: "Signalisation – Réfection", label_fr: "Signalisation – Réfection", label_en : "Signage – Refurbishment"},
        { value: '51', label: "Station de surpression ou de pompage - Construction", label_fr: "Station de surpression ou de pompage - Construction", label_en : "Booster or pumping station - Construction"},
        { value: '52', label: "Station de surpression ou de pompage - Réfection", label_fr: "Station de surpression ou de pompage - Réfection", label_en : "Booster or pumping station - Refurbishment"}, 
        { value: '53', label: "Usine d’eau potable - travaux", label_fr: "Usine d’eau potable - travaux", label_en : "Potable water plant - Works"},
        { value: '54', label: 'Événement', label_fr: 'Événement', label_en : 'Event'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'Permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Entrave", label_fr: "Entrave", label_en : "Obstacles"},
        { value: '2', label: "Occupation", label_fr: "Occupation", label_en : "Occupation"},
        { value: '3', label: "Partenaires", label_fr: "Partenaires", label_en : "Partners"},
        { value: '4', label: "Non requis (projets Ville)", label_fr: "Non requis (projets Ville)", label_en : "Not required (City projects)"},
        { value: '5', label: "Résolution ou approbation CS", label_fr: "Résolution ou approbation CS", label_en : "Resolution or approval CS"},
      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'2', label:'Commissions Scolaires', label_fr:'Commissions Scolaires', label_en:'School Boards'},
        {value:'3', label:"Coopérative des paramédics", label_fr:"Coopérative des paramédics", label_en:"Paramedics cooperative"},
        {value:'4', label:'Ministère des Transports du Québec (MTQ)', label_fr:'Ministère des Transports du Québec (MTQ)', label_en:'Quebec Ministry of Transport (MTQ)'},
        {value:'5', label:'RTU', label_fr:'RTU', label_en:'RTU'},
        {value:'6', label:'Sécurité incendie', label_fr:'Sécurité incendie', label_en:'Fire Safety'},
        {value:'7', label:'Service de police', label_fr:'Service de police', label_en:'Police Department'},
        {value:'8', label:'Société de transport', label_fr:'Société de transport', label_en:'Transportation Company'},
        {value:'9', label:'Stationnememt', label_fr:'Stationnememt', label_en:'Parking'},
      ]
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager(ne pas utiliser)", label_en:"Passenger type(do not use)"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  hampstead: {
    district:{
      key: 'district',
      title: {label: 'District', label_fr:'District', label_en:'District'},
      show: false,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'District 1' },
        { value: '2', label: 'District 2' },
        { value: '3', label: 'District 3' },
        { value: '4', label: 'District 4' },
        { value: '5', label: 'District 5' },
        { value: '6', label: 'District 6' },
        { value: '7', label: 'District 7' },
        { value: '8', label: 'District 8' },
        { value: '9', label: 'District 9' },
        { value: '10', label: 'District 10' },
        { value: '11', label: 'District 11' },
        { value: '12', label: 'District 12' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'Aylmer', label_fr: 'Aylmer', label_en: 'Aylmer'},
        { value: '2', label: 'Buckingham', label_fr: 'Buckingham', label_en: 'Buckingham'},
        { value: '3', label: 'Gatineau', label_fr: 'Gatineau', label_en: 'Gatineau'},
        { value: '4', label: 'Hull', label_fr: 'Hull', label_en: 'Hull'},
        { value: '5', label: 'Masson-Angers', label_fr: 'Masson-Angers', label_en: 'Masson-Angers'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Travaux publics Hampstead',label_fr: 'Travaux publics Hampstead',label_en: 'Hampstead public works', class: 'gat-orange-light', class1: 'gat-orange-light'},
        { value: '2', label: 'Entreprise contractée par Hampstead',label_fr: 'Entreprise contractée par Hampstead',label_en: 'Hampstead contracted company', class: 'gat-orange-light2', class1: 'gat-orange-light2'},
        { value: '6', label: 'Événements',label_fr: 'Événements',label_en: 'Events', class: 'gat-pink-light', class1: 'gat-pink-light'},
        { value: '7', label: "Urgence",label_fr: "Urgence",label_en: "Emergency", class: 'gat-red-light', class1: 'gat-red-light'},
        { value: '3', label: 'Entreprise externe (Hydro-Québec, Bell, etc.)',label_fr: 'Entreprise externe (Hydro-Québec, Bell, etc.)',label_en: 'External company (Hydro-Québec, Bell, etc.)', class: 'gat-green-dark', class1: 'gat-green-dark'},
        { value: '8', label: 'Autres',label_fr: 'Autres',label_en: 'Other', class: 'gat-green-light', class1: 'gat-green-light'}
      ],
      classes: {
        'gat-orange-light':'#ED732E',
        'gat-orange-light2':'#F4B754',
        'gat-green3':'#316f7b',
        'gat-green-dark':'#2E6B4D',
        'gat-blue1':'#01b0f0',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-green-light':'#84B938',
        'gat-pink-light':'#ee7deb',
        'gat-red-light':'#E8464A',
        'gat-purple2':'#AA91E4',
        'gat-blue3':'#0E4095',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
        'gat-jaune': '#a18200'
      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '4', label: 'PTI Infra', label_fr: 'PTI Infra', label_en : 'PTI Infra'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Entrave partielle', label_fr:'Entrave partielle', label_en:'Partial obstruction'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajets ou arrêt de la ST ', label_fr:'Trajets ou arrêt de la ST ', label_en:'Routes or Stops ST'},
        {value:'9', status:false, content:'', label:'Corridor scolaire entravé', label_fr:'Corridor scolaire entravé', label_en:'Blocked school corridor'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'}
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Réseau temporaire d’eau potable', label_fr:'Réseau temporaire d’eau potable', label_en:'Temporary potable water network'},
        {value:'2', status:false, content:'', label:'Interruption d’eau potable', label_fr:'Interruption d’eau potable', label_en:'Interruption of potable water'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Interdiction de stationnement - complète", label_fr: "Interdiction de stationnement - complète", label_en : "Parking interdiction - complete"},
        { value: '2',  label: "Interdiction de stationnement - partielle", label_fr: "Interdiction de stationnement - partielle", label_en : "Parking interdiction - partial"},
      ]
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Aménagement paysager", label_fr: "Aménagement paysager", label_en : "Landscaping"},
        { value: '2',  label: "Arbres - Abattage ou élagage", label_fr: "Arbres - Abattage ou élagage", label_en : "Trees - Felling or Pruning"},
        { value: '3',  label: "Bâtiment - Déneigement de toiture", label_fr: "Bâtiment - Déneigement de toiture", label_en : "Building - Roof snow removal"},
        { value: '4',  label: "Bâtiment divers - entretien", label_fr: "Bâtiment divers - entretien", label_en : "Various buildings  - Maintenance"},
        { value: '5',  label: "Bâtiment municipal - Construction", label_fr: "Bâtiment municipal - Construction", label_en : "Municipal buildings - Construction"},
        { value: '6',  label: "Bâtiment municipal - Démolition", label_fr: "Bâtiment municipal - Démolition", label_en : "Municipal buildings - Demolition"},
        { value: '7',  label: "Bâtiment municipal - Réfection", label_fr: "Bâtiment municipal - Réfection", label_en : "Municipal buildings - Refurbishment"}, 
        { value: '8',  label: "Borne-fontaine - Inspection", label_fr: "Borne-fontaine - Inspection", label_en : "Fire hydrant - Inspection"},
        { value: '9',  label: "Branchement de services –  Réfection", label_fr: "Branchement de services –  Réfection", label_en : "Service connections –  Refurbishment"}, 
        { value: '10', label: "Branchement de services - Construction", label_fr: "Branchement de services - Construction", label_en : "Service connections - Construction"},
        { value: '11', label: "Feux de circulation - Installation", label_fr: "Feux de circulation - Installation", label_en : "Traffic lights - Installation"},
        { value: '12', label: "Feux de circulation - Réfection", label_fr: "Feux de circulation - Réfection", label_en : "Traffic lights - Refurbishment"},
        { value: '13', label: "Forage", label_fr: "Forage", label_en : "Drilling"},
        { value: '14', label: "Fossés - Nettoyage", label_fr: "Fossés - Nettoyage", label_en : "Ditch - Clean-Up"}, 
        { value: '15', label: "Fossés – Nouveau", label_fr: "Fossés – Nouveau", label_en : "Ditch - New"},
        { value: '16', label: "Mesure de modération de la circulation - Installation", label_fr: "Mesure de modération de la circulation - Installation", label_en : "Traffic moderation measures - Installation"},
        { value: '17', label: "Mesure de modération de la circulation – Réfection", label_fr: "Mesure de modération de la circulation – Réfection", label_en : "Traffic moderation measures - Refurbishment"},
        { value: '18', label: "Ministère des Transports du Québec - travaux", label_fr: "Ministère des Transports du Québec - travaux", label_en : "Quebec’s Ministry of Transportation - Works"}, 
        { value: '19', label: "Ouvrages de béton - Construction", label_fr: "Ouvrages de béton - Construction", label_en : "Concrete structure - Construction"},
        { value: '20', label: "Ouvrages de béton – Réfection", label_fr: "Ouvrages de béton – Réfection", label_en : "Concrete structure  – Refurbishment"}, 
        { value: '21', label: "Parc - Jeu d’eau - Aménagement", label_fr: "Parc - Jeu d’eau - Aménagement", label_en : "Parc - Water games - Landscaping"}, 
        { value: '22', label: "Parc - Parcours santé - Aménagement", label_fr: "Parc - Parcours santé - Aménagement", label_en : "Parc - Fitness trail - Landscaping"},
        { value: '23', label: "Parc municipal - Nouveau parc", label_fr: "Parc municipal - Nouveau parc", label_en : "Municipal Parc - New Parc"},
        { value: '24', label: "Parc municipal - Réaménagement", label_fr: "Parc municipal - Réaménagement", label_en : "Municipal Parc - Refurbishment"}, 
        { value: '25', label: "Ponceau – Installation", label_fr: "Ponceau – Installation", label_en : "Culvert – Installation"},
        { value: '26', label: "Ponceau – Réfection", label_fr: "Ponceau – Réfection", label_en : "Culvert – Refurbishment"},
        { value: '27', label: "Pont - Inspection", label_fr: "Pont - Inspection", label_en : "Birdge - Inspection"},
        { value: '28', label: "Pont - Réfection", label_fr: "Pont - Réfection", label_en : "Bridge - Refurbishment"},
        { value: '29', label: "Puisard - Installation", label_fr: "Puisard - Installation", label_en : "Sump - Installation"},
        { value: '30', label: "Puisard - Réfection", label_fr: "Puisard - Réfection", label_en : "Sump - Refurbishment"},
        { value: '31', label: "Réseau d'aqueduc - Bris", label_fr: "Réseau d'aqueduc - Bris", label_en : "Aqueduct network - Breakage"}, 
        { value: '32', label: "Réseau d'aqueduc - Construction", label_fr: "Réseau d'aqueduc - Construction", label_en : "Aqueduct network - Construction"},
        { value: '33', label: "Réseau d'aqueduc - Réfection", label_fr: "Réseau d'aqueduc - Réfection", label_en : "Aqueduct network - Refurbishment"},
        { value: '34', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_en : "Municipal aqueduct and storm and/or sanitary sewers services - Construction"},
        { value: '35', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Municipal aqueduct and storm and/or sanitary sewers services -  Refurbishment"},
        { value: '36', label: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_en : "Storm and/or sanitary sewers network - Construction"},
        { value: '37', label: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Storm and/or sanitary sewers network - Refubishment"},
        { value: '38', label: "Réseau routier - Construction", label_fr: "Réseau routier - Construction", label_en : "Road network - Construction"},
        { value: '39', label: "Réseau routier - Marquage de la chaussée", label: "Réseau routier - Marquage de la chaussée", label_en : "Road network - Pavement marking"}, 
        { value: '40', label: "Réseau routier - Réfection", label_fr: "Réseau routier - Réfection", label_en : "Road network - Refurbishment"},
        { value: '41', label: "Sentier récréatif - Construction", label_fr: "Sentier récréatif - Construction", label_en : "Recreational Trail - Construction"},
        { value: '42', label: "Sentier récréatif - Fermeture", label_fr: "Sentier récréatif - Fermeture", label_en : "Recreational Trail - Closure"}, 
        { value: '43', label: "Sentier récréatif - Nettoyage", label_fr: "Sentier récréatif - Nettoyage", label_en : "Recreational Trail - Clean-Up"},
        { value: '44', label: "Sentier récréatif - Réfection", label_fr: "Sentier récréatif - Réfection",label_en : "Recreational Trail - Refurbishment"}, 
        { value: '45', label: "Sentier récréatif - Zone inondée", label_fr: "Sentier récréatif - Zone inondée", label_en : "Recreational Trail - Flooded area"},
        { value: '46', label: "Services d'utilités publiques - Enfouissement", label_fr: "Services d'utilités publiques - Enfouissement", label_en : "Public Utilities Department - Landfill"},
        { value: '47', label: "Services d'utilités publiques - Installation", label_fr: "Services d'utilités publiques - Installation", label_en : "Public Utilities Department - Installation"}, 
        { value: '48', label: "Services publics - Réfection", label_fr: "Services publics - Réfection", label_en : "Public Works - Refurbishment"},
        { value: '49', label: "Signalisation - Installation", label_fr: "Signalisation - Installation", label_en : "Signage - Installation"},
        { value: '50', label: "Signalisation – Réfection", label_fr: "Signalisation – Réfection", label_en : "Signage – Refurbishment"},
        { value: '51', label: "Station de surpression ou de pompage - Construction", label_fr: "Station de surpression ou de pompage - Construction", label_en : "Booster or pumping station - Construction"},
        { value: '52', label: "Station de surpression ou de pompage - Réfection", label_fr: "Station de surpression ou de pompage - Réfection", label_en : "Booster or pumping station - Refurbishment"}, 
        { value: '53', label: "Usine d’eau potable - travaux", label_fr: "Usine d’eau potable - travaux", label_en : "Potable water plant - Works"},
        { value: '54', label: 'Événement', label_fr: 'Événement', label_en : 'Event'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'Permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Entrave", label_fr: "Entrave", label_en : "Obstacles"},
        { value: '2', label: "Occupation", label_fr: "Occupation", label_en : "Occupation"},
        { value: '3', label: "Partenaires", label_fr: "Partenaires", label_en : "Partners"},
        { value: '4', label: "Non requis (projets Ville)", label_fr: "Non requis (projets Ville)", label_en : "Not required (City projects)"},
        { value: '5', label: "Résolution ou approbation CS", label_fr: "Résolution ou approbation CS", label_en : "Resolution or approval CS"},
      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'1', label:'311', label_fr:'311', label_en:'311'},
        {value:'2', label:'Commissions Scolaires', label_fr:'Commissions Scolaires', label_en:'School Boards'},
        {value:'3', label:"Coopérative des paramédics", label_fr:"Coopérative des paramédics", label_en:"Paramedics cooperative"},
        {value:'4', label:'Ministère des Transports du Québec (MTQ)', label_fr:'Ministère des Transports du Québec (MTQ)', label_en:'Quebec Ministry of Transport (MTQ)'},
        {value:'5', label:'RTU', label_fr:'RTU', label_en:'RTU'},
        {value:'6', label:'Sécurité incendie', label_fr:'Sécurité incendie', label_en:'Fire Safety'},
        {value:'7', label:'Service de police', label_fr:'Service de police', label_en:'Police Department'},
        {value:'8', label:'Société de transport', label_fr:'Société de transport', label_en:'Transportation Company'},
        {value:'9', label:'Stationnememt', label_fr:'Stationnememt', label_en:'Parking'},
      ]
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager(ne pas utiliser)", label_en:"Passenger type(do not use)"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  aquadata: {
    district:{
      key: 'district',
      title: {label: 'District', label_fr:'District', label_en:'District'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'District 1' },
        { value: '2', label: 'District 2' },
        { value: '3', label: 'District 3' },
        { value: '4', label: 'District 4' },
        { value: '5', label: 'District 5' },
        { value: '6', label: 'District 6' },
        { value: '7', label: 'District 7' },
        { value: '8', label: 'District 8' },
        { value: '9', label: 'District 9' },
        { value: '10', label: 'District 10' },
        { value: '11', label: 'District 11' },
        { value: '12', label: 'District 12' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'Aylmer', label_fr: 'Aylmer', label_en: 'Aylmer'},
        { value: '2', label: 'Buckingham', label_fr: 'Buckingham', label_en: 'Buckingham'},
        { value: '3', label: 'Gatineau', label_fr: 'Gatineau', label_en: 'Gatineau'},
        { value: '4', label: 'Hull', label_fr: 'Hull', label_en: 'Hull'},
        { value: '5', label: 'Masson-Angers', label_fr: 'Masson-Angers', label_en: 'Masson-Angers'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ville - Infrastructures',label_fr: 'Ville - Infrastructures',label_en: 'City - Infrastructure', class: 'gat-green1', class1: 'gat-green1'},
        { value: '2', label: 'Ville - TP',label_fr: 'Ville - Travaux Publics',label_en: 'City - Public Works', class: 'gat-green2', class1: 'gat-green2'},
        { value: '6', label: 'Événements',label_fr: 'Événements',label_en: 'Events', class: 'gat-yellow', class1: 'gat-yellow'},
        { value: '9', label: 'Promoteurs immobiliers',label_fr: 'Promoteurs immobiliers', label_en:'Real estate promotors', class: 'gat-purple1', class1: 'gat-purple1'},
        { value: '3', label: 'RTU (HQ, Bell, Vidéotron, etc)',label_fr: 'RTU (HQ, Bell, Vidéotron, etc)',label_en: 'RTU (HQ, Bell, Videotron, etc)', class: 'gat-orange', class1: 'gat-orange'},
        { value: '4', label: 'MTQ',label_fr: 'MTQ',label_en: 'MTQ', class: 'gat-blue1', class1: 'gat-blue1'},
        { value: '5', label: 'Public transit',label_fr: 'ST',label_en: 'Public transit', class: 'gat-green3', class1: 'gat-green3'},
        { value: '7', label: "Mesures d'urgence",label_fr: "Mesures d'urgence",label_en: "Emergency measures", class: 'gat-red', class1: 'gat-red'},
        { value: '10', label: 'Administration ferroviaire (CN, CP, etc.)',label_fr: 'Administration ferroviaire (CN, CP, etc.)',label_en: 'Railway administration (CN, CP, etc)', class: 'gat-jaune', class1: 'gat-jaune'},
        { value: '8', label: 'Autres',label_fr: 'Autres',label_en: 'Other', class: 'gat-blue2', class1: 'gat-blue2'}
      ],
      classes: {
        'gat-green1':'#00b050',
        'gat-green2':'#92d050',
        'gat-green3':'#316f7b',
        'gat-orange':'#ffc001',
        'gat-blue1':'#01b0f0',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-blue2':'#0070c0',
        'gat-yellow':'#ffff02',
        'gat-red':'#ff0000',
        'gat-purple2':'#AA91E4',
        'gat-blue3':'#0E4095',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
        'gat-jaune': '#a18200'
      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '4', label: 'PTI Infra', label_fr: 'PTI Infra', label_en : 'PTI Infra'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Entrave partielle', label_fr:'Entrave partielle', label_en:'Partial obstruction'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajets ou arrêt de la ST ', label_fr:'Trajets ou arrêt de la ST ', label_en:'Routes or Stops ST'},
        {value:'9', status:false, content:'', label:'Corridor scolaire entravé', label_fr:'Corridor scolaire entravé', label_en:'Blocked school corridor'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'}
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Réseau temporaire d’eau potable', label_fr:'Réseau temporaire d’eau potable', label_en:'Temporary potable water network'},
        {value:'2', status:false, content:'', label:'Interruption d’eau potable', label_fr:'Interruption d’eau potable', label_en:'Interruption of potable water'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Aménagement paysager", label_fr: "Aménagement paysager", label_en : "Landscaping"},
        { value: '2',  label: "Arbres - Abattage ou élagage", label_fr: "Arbres - Abattage ou élagage", label_en : "Trees - Felling or Pruning"},
        { value: '3',  label: "Bâtiment - Déneigement de toiture", label_fr: "Bâtiment - Déneigement de toiture", label_en : "Building - Roof snow removal"},
        { value: '4',  label: "Bâtiment divers - entretien", label_fr: "Bâtiment divers - entretien", label_en : "Various buildings  - Maintenance"},
        { value: '5',  label: "Bâtiment municipal - Construction", label_fr: "Bâtiment municipal - Construction", label_en : "Municipal buildings - Construction"},
        { value: '6',  label: "Bâtiment municipal - Démolition", label_fr: "Bâtiment municipal - Démolition", label_en : "Municipal buildings - Demolition"},
        { value: '7',  label: "Bâtiment municipal - Réfection", label_fr: "Bâtiment municipal - Réfection", label_en : "Municipal buildings - Refurbishment"}, 
        { value: '8',  label: "Borne-fontaine - Inspection", label_fr: "Borne-fontaine - Inspection", label_en : "Fire hydrant - Inspection"},
        { value: '9',  label: "Branchement de services –  Réfection", label_fr: "Branchement de services –  Réfection", label_en : "Service connections –  Refurbishment"}, 
        { value: '10', label: "Branchement de services - Construction", label_fr: "Branchement de services - Construction", label_en : "Service connections - Construction"},
        { value: '11', label: "Feux de circulation - Installation", label_fr: "Feux de circulation - Installation", label_en : "Traffic lights - Installation"},
        { value: '12', label: "Feux de circulation - Réfection", label_fr: "Feux de circulation - Réfection", label_en : "Traffic lights - Refurbishment"},
        { value: '13', label: "Forage", label_fr: "Forage", label_en : "Drilling"},
        { value: '14', label: "Fossés - Nettoyage", label_fr: "Fossés - Nettoyage", label_en : "Ditch - Clean-Up"}, 
        { value: '15', label: "Fossés – Nouveau", label_fr: "Fossés – Nouveau", label_en : "Ditch - New"},
        { value: '16', label: "Mesure de modération de la circulation - Installation", label_fr: "Mesure de modération de la circulation - Installation", label_en : "Traffic moderation measures - Installation"},
        { value: '17', label: "Mesure de modération de la circulation – Réfection", label_fr: "Mesure de modération de la circulation – Réfection", label_en : "Traffic moderation measures - Refurbishment"},
        { value: '18', label: "Ministère des Transports du Québec - travaux", label_fr: "Ministère des Transports du Québec - travaux", label_en : "Quebec’s Ministry of Transportation - Works"}, 
        { value: '19', label: "Ouvrages de béton - Construction", label_fr: "Ouvrages de béton - Construction", label_en : "Concrete structure - Construction"},
        { value: '20', label: "Ouvrages de béton – Réfection", label_fr: "Ouvrages de béton – Réfection", label_en : "Concrete structure  – Refurbishment"}, 
        { value: '21', label: "Parc - Jeu d’eau - Aménagement", label_fr: "Parc - Jeu d’eau - Aménagement", label_en : "Parc - Water games - Landscaping"}, 
        { value: '22', label: "Parc - Parcours santé - Aménagement", label_fr: "Parc - Parcours santé - Aménagement", label_en : "Parc - Fitness trail - Landscaping"},
        { value: '23', label: "Parc municipal - Nouveau parc", label_fr: "Parc municipal - Nouveau parc", label_en : "Municipal Parc - New Parc"},
        { value: '24', label: "Parc municipal - Réaménagement", label_fr: "Parc municipal - Réaménagement", label_en : "Municipal Parc - Refurbishment"}, 
        { value: '25', label: "Ponceau – Installation", label_fr: "Ponceau – Installation", label_en : "Culvert – Installation"},
        { value: '26', label: "Ponceau – Réfection", label_fr: "Ponceau – Réfection", label_en : "Culvert – Refurbishment"},
        { value: '27', label: "Pont - Inspection", label_fr: "Pont - Inspection", label_en : "Birdge - Inspection"},
        { value: '28', label: "Pont - Réfection", label_fr: "Pont - Réfection", label_en : "Bridge - Refurbishment"},
        { value: '29', label: "Puisard - Installation", label_fr: "Puisard - Installation", label_en : "Sump - Installation"},
        { value: '30', label: "Puisard - Réfection", label_fr: "Puisard - Réfection", label_en : "Sump - Refurbishment"},
        { value: '31', label: "Réseau d'aqueduc - Bris", label_fr: "Réseau d'aqueduc - Bris", label_en : "Aqueduct network - Breakage"}, 
        { value: '32', label: "Réseau d'aqueduc - Construction", label_fr: "Réseau d'aqueduc - Construction", label_en : "Aqueduct network - Construction"},
        { value: '33', label: "Réseau d'aqueduc - Réfection", label_fr: "Réseau d'aqueduc - Réfection", label_en : "Aqueduct network - Refurbishment"},
        { value: '34', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_en : "Municipal aqueduct and storm and/or sanitary sewers services - Construction"},
        { value: '35', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Municipal aqueduct and storm and/or sanitary sewers services -  Refurbishment"},
        { value: '36', label: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_en : "Storm and/or sanitary sewers network - Construction"},
        { value: '37', label: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Storm and/or sanitary sewers network - Refubishment"},
        { value: '38', label: "Réseau routier - Construction", label_fr: "Réseau routier - Construction", label_en : "Road network - Construction"},
        { value: '39', label: "Réseau routier - Marquage de la chaussée", label: "Réseau routier - Marquage de la chaussée", label_en : "Road network - Pavement marking"}, 
        { value: '40', label: "Réseau routier - Réfection", label_fr: "Réseau routier - Réfection", label_en : "Road network - Refurbishment"},
        { value: '41', label: "Sentier récréatif - Construction", label_fr: "Sentier récréatif - Construction", label_en : "Recreational Trail - Construction"},
        { value: '42', label: "Sentier récréatif - Fermeture", label_fr: "Sentier récréatif - Fermeture", label_en : "Recreational Trail - Closure"}, 
        { value: '43', label: "Sentier récréatif - Nettoyage", label_fr: "Sentier récréatif - Nettoyage", label_en : "Recreational Trail - Clean-Up"},
        { value: '44', label: "Sentier récréatif - Réfection", label_fr: "Sentier récréatif - Réfection",label_en : "Recreational Trail - Refurbishment"}, 
        { value: '45', label: "Sentier récréatif - Zone inondée", label_fr: "Sentier récréatif - Zone inondée", label_en : "Recreational Trail - Flooded area"},
        { value: '46', label: "Services d'utilités publiques - Enfouissement", label_fr: "Services d'utilités publiques - Enfouissement", label_en : "Public Utilities Department - Landfill"},
        { value: '47', label: "Services d'utilités publiques - Installation", label_fr: "Services d'utilités publiques - Installation", label_en : "Public Utilities Department - Installation"}, 
        { value: '48', label: "Services publics - Réfection", label_fr: "Services publics - Réfection", label_en : "Public Works - Refurbishment"},
        { value: '49', label: "Signalisation - Installation", label_fr: "Signalisation - Installation", label_en : "Signage - Installation"},
        { value: '50', label: "Signalisation – Réfection", label_fr: "Signalisation – Réfection", label_en : "Signage – Refurbishment"},
        { value: '51', label: "Station de surpression ou de pompage - Construction", label_fr: "Station de surpression ou de pompage - Construction", label_en : "Booster or pumping station - Construction"},
        { value: '52', label: "Station de surpression ou de pompage - Réfection", label_fr: "Station de surpression ou de pompage - Réfection", label_en : "Booster or pumping station - Refurbishment"}, 
        { value: '53', label: "Usine d’eau potable - travaux", label_fr: "Usine d’eau potable - travaux", label_en : "Potable water plant - Works"},
        { value: '54', label: 'Événement', label_fr: 'Événement', label_en : 'Event'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'Permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Entrave", label_fr: "Entrave", label_en : "Obstacles"},
        { value: '2', label: "Occupation", label_fr: "Occupation", label_en : "Occupation"},
        { value: '3', label: "Partenaires", label_fr: "Partenaires", label_en : "Partners"},
        { value: '4', label: "Non requis (projets Ville)", label_fr: "Non requis (projets Ville)", label_en : "Not required (City projects)"},
        { value: '5', label: "Résolution ou approbation CS", label_fr: "Résolution ou approbation CS", label_en : "Resolution or approval CS"},
      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'1', label:'311', label_fr:'311', label_en:'311'},
        {value:'2', label:'Commissions Scolaires', label_fr:'Commissions Scolaires', label_en:'School Boards'},
        {value:'3', label:"Coopérative des paramédics", label_fr:"Coopérative des paramédics", label_en:"Paramedics cooperative"},
        {value:'4', label:'Ministère des Transports du Québec (MTQ)', label_fr:'Ministère des Transports du Québec (MTQ)', label_en:'Quebec Ministry of Transport (MTQ)'},
        {value:'5', label:'RTU', label_fr:'RTU', label_en:'RTU'},
        {value:'6', label:'Sécurité incendie', label_fr:'Sécurité incendie', label_en:'Fire Safety'},
        {value:'7', label:'Service de police', label_fr:'Service de police', label_en:'Police Department'},
        {value:'8', label:'Société de transport', label_fr:'Société de transport', label_en:'Transportation Company'},
        {value:'9', label:'Stationnememt', label_fr:'Stationnememt', label_en:'Parking'},
      ]
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager(ne pas utiliser)", label_en:"Passenger type(do not use)"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: true,
      search: true,
      value:[
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  bell: {
    district:{
      key: 'district',
      title: {label: 'Secteur', label_fr:'Secteur', label_en:'Sector'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Secteur 1' },
        { value: '2', label: 'Secteur 2' },
        { value: '3', label: 'Secteur 3' },
        { value: '4', label: 'Secteur 4' },
        { value: '5', label: 'Secteur 5' },
        { value: '6', label: 'Secteur 6' },
        { value: '7', label: 'Secteur 7' },
        { value: '8', label: 'Secteur 8' },
        { value: '9', label: 'Secteur 9' },
        { value: '10', label: 'Secteur 10' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Municipal', label_fr: 'Municipal', label_en : 'Municipal', class: 'bgreen', icon: "brossard.png"},
        { value: '2', label: 'MTO', label_fr: 'MTO', label_en : 'MTO', class: 'byellowgreen', icon: "rem.png"},
        { value: '3', label: 'Cloche', label_fr: 'Cloche', label_en : 'Bell', class: 'borange', icon: "btp.png"},
        { value: '4', label: 'Metrolinx', label_fr: 'Metrolinx', label_en : 'Metrolinx', class: 'bblue', icon: "event.png"},
        { value: '5', label: 'Activité routière locale', label_fr: 'Activité routière locale', label_en : 'Local Road Activity', class: 'bgreen', icon: "brossard.png"},
        { value: '6', label: 'Promoteur immobilier', label_fr: 'Promoteur immobilier', label_en : 'Real Estate Developer', class: 'byellowgreen', icon: "rem.png"},
        { value: '7', label: 'Privé', label_fr: 'Privé', label_en : 'Private', class: 'borange', icon: "btp.png"},
        { value: '8', label: 'Autre', label_fr: 'Autre', label_en : 'Other', class: 'bblue', icon: "event.png"}
      ],
      classes: {
        'brown': '#ac702c',
        'blue_duck': '#009aa2',
        'pink': '#e97cbf',
        'green': '#00e045',
        'blue': '#8fc4e6',
        'orange': '#ff5a00',
        'bgreen': '#01b050',
        'byellowgreen': '#ffff02',
        'borange': '#ed7d31',
        'bblue': '#01b0f0'
      },
      legendImg: true
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'En conception', label_fr: 'En conception', label_en : 'In design'},
        { value: '2', label: "Appel d'offres/devis", label_fr: "Appel d'offres/devis", label_en : 'Tender/Quote'},
        { value: '3', label: 'Facturé', label_fr: 'Facturé', label_en : 'Billed'},
        { value: '4', label: 'Tel que construit', label_fr: 'Tel que construit', label_en : 'As built'},
        { value: '5', label: 'Attente du consentement', label_fr: 'Attente du consentement', label_en : 'Waiting consent'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'Permit Type'},
      show: true,
      showInList: true,
      search: true,
      value:[
        { value: '1', label_fr: 'Consentement municipal', label_en : 'Municipal consent'},
        { value: '2', label_fr: 'Chemin de fer et application', label_en : 'Railway & Enforcem'},
        { value: '3', label_fr: 'Environnement', label_en : 'Environment'},
        { value: '4', label_fr: 'PE', label_en : 'MOU'},
        { value: '5', label_fr: 'Non requis', label_en : 'Not required'},
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux (Pour avis de travaux)", label_fr:"Catégorie de travaux (Pour avis de travaux)", label_en:'Category of work (For notice of work)'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Demande", label_fr: "Demande", label_en : "Demand"},
        { value: '2',  label: "Stratégique", label_fr: "Stratégique", label_en : "Strategic"},
        { value: '3',  label: "Fibre optique", label_fr: "Fibre optique", label_en : "Fiber optic"},
        { value: '4',  label: "Obligatoire", label_fr: "Obligatoire", label_en : "Mandatory"},
        { value: '5',  label: "Entretien", label_fr: "Entretien", label_en : "Maintenance"},
        { value: '6',  label: "Interdiction de stationnement - complète", label_fr: "Interdiction de stationnement - complète", label_en : "Parking interdiction - complete"},
        { value: '7',  label: "Interdiction de stationnement - partielle", label_fr: "Interdiction de stationnement - partielle", label_en : "Parking interdiction - partial"},
      ]    
    },
    status: {
      key: 'status',
      title: {label: "Statut", label_fr:"Statut", label_en:'Status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager", label_en:"Passanger type"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'type1', status:false, content: '', label:'Local ou usager du site', label_fr:'Local ou usager du site', label_en:'Local or site user'},
        {value:'type2', status:false, content: '', label:'Automobiliste', label_fr:'Automobiliste', label_en:'Driver'},
        {value:'type3', status:false, content: '', label:'Cycliste', label_fr:'Cycliste', label_en:'Cyclist'},
        {value:'type4', status:false, content: '', label:'Piéton', label_fr:'Piéton', label_en:'Pedestrian'},
        {value:'type5', status:false, content: '', label:'Transport collectif', label_fr:'Transport collectif', label_en:'Public transport'},
        {value:'type6', status:false, content: '', label:"Accessibilité véhicules d'urgence", label_fr:"Accessibilité véhicules d'urgence", label_en:'Emergency vehicles accessibility'},
        {value:'type7', status:false, content: '', label:'Accessibilité personnes à mobilité réduite', label_fr:'Accessibilité personnes à mobilité réduite', label_en:'Accessibility for people with reduced mobility'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  sjsr: {
    district:{
      key: 'district',
      title: {label: 'District', label_fr:'District', label_en:'District'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'District 1' },
        { value: '2', label: 'District 2' },
        { value: '3', label: 'District 3' },
        { value: '4', label: 'District 4' },
        { value: '5', label: 'District 5' },
        { value: '6', label: 'District 6' },
        { value: '7', label: 'District 7' },
        { value: '8', label: 'District 8' },
        { value: '9', label: 'District 9' },
        { value: '10', label: 'District 10' },
        { value: '11', label: 'District 11' },
        { value: '12', label: 'District 12' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Saint-Jean', label_fr: 'Saint-Jean', label_en: 'Saint-Jean'},
        { value: '2', label: 'Saint-Luc', label_fr: 'Saint-Luc', label_en: 'Saint-Luc'},
        { value: '3', label: "L'Acadie", label_fr: "L'Acadie", label_en: "L'Acadie"},
        { value: '4', label: 'Iberville', label_fr: 'Iberville', label_en: 'Iberville'},
        { value: '5', label: 'Saint-Athanase', label_fr: 'Saint-Athanase', label_en: 'Saint-Athanase'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ville - Infrastructures',label_fr: 'Ville - Infrastructures',label_en: 'City - Infrastructure', class: 'gat-orange-light', class1: 'gat-orange-light'},
        { value: '2', label: 'Ville - TP',label_fr: 'Ville - Travaux Publics',label_en: 'City - Public Works', class: 'gat-orange-light2', class1: 'gat-orange-light2'},
        { value: '6', label: 'Événements',label_fr: 'Événements',label_en: 'Events', class: 'gat-pink-light', class1: 'gat-pink-light'},
        // { value: '9', label: 'Promoteurs immobiliers',label_fr: 'Promoteurs immobiliers', label_en:'Real estate promotors', class: 'gat-purple1', class1: 'gat-purple1'},
        { value: '3', label: 'RTU (HQ, Bell, Vidéotron, etc)',label_fr: 'RTU (HQ, Bell, Vidéotron, etc)',label_en: 'RTU (HQ, Bell, Videotron, etc)', class: 'gat-green-dark', class1: 'gat-green-dark'},
        { value: '4', label: 'MTMD',label_fr: 'MTMD',label_en: 'MTMD', class: 'gat-blue-dark', class1: 'gat-blue-dark'},
        // { value: '5', label: 'ST',label_fr: 'ST',label_en: 'ST', class: 'gat-green3', class1: 'gat-green3'},
        { value: '7', label: "Mesures d'urgence",label_fr: "Mesures d'urgence",label_en: "Emergency measures", class: 'gat-red-light', class1: 'gat-red-light'},
        { value: '10', label: 'Administration ferroviaire (CN, CP, etc.)',label_fr: 'Administration ferroviaire (CN, CP, etc.)',label_en: 'Railway administration (CN, CP, etc)', class: 'gat-green-dark', class1: 'gat-green-dark'},
        { value: '8', label: 'Autres',label_fr: 'Autres',label_en: 'Other', class: 'gat-green-light', class1: 'gat-green-light'}
      ],
      classes: {
        'gat-orange-light':'#ED732E',
        'gat-orange-light2':'#F4B754',
        'gat-green3':'#316f7b',
        'gat-green-dark':'#2E6B4D',
        'gat-blue-dark':'#1C02DE',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-green-light':'#84B938',
        'gat-pink-light':'#ee7deb',
        'gat-red-light':'#E8464A',
        'gat-purple2':'#AA91E4',
        'gat-blue3':'#0E4095',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
        'gat-jaune': '#2E6B4D'
      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Entrave partielle', label_fr:'Entrave partielle', label_en:'Partial obstruction'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajets ou arrêt de la ST', label_fr:'Trajets ou arrêt de la ST', label_en:'Routes or bus stops'},
        {value:'9', status:false, content:'', label:'Corridor scolaire entravé', label_fr:'Corridor scolaire entravé', label_en:'Blocked school corridor'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'}
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Interdiction de stationnement - complète", label_fr: "Interdiction de stationnement - complète", label_en : "Parking interdiction - complete"},
        { value: '2',  label: "Interdiction de stationnement - partielle", label_fr: "Interdiction de stationnement - partielle", label_en : "Parking interdiction - partial"},
      ]
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Aménagement paysager", label_fr: "Aménagement paysager", label_en : "Landscaping"},
        { value: '2',  label: "Arbres - Abattage ou élagage", label_fr: "Arbres - Abattage ou élagage", label_en : "Trees - Felling or Pruning"},
        { value: '56',  label: "Travaux bâtiment", label_fr: "Travaux bâtiment", label_en : "Building work"},
        { value: '57',  label: "Branchement de services (Entretien ou Installation)", label_fr: "Branchement de services (Entretien ou Installation)", label_en : "Service connections (Maintenance or installation)"}, 
        { value: '58', label: "Feux de circulation (Entretien ou installation)", label_fr: "Feux de circulation (Entretien ou installation)", label_en : "Traffic lights (Maintenance or installation)"},
        { value: '13', label: "Forage", label_fr: "Forage", label_en : "Drilling"},
        { value: '59', label: "Fossés (Travaux divers)", label_fr: "Fossés (Travaux divers)", label_en : "Ditch (Miscellaneous work)"}, 
        { value: '60', label: "Ouvrage de béton", label_fr: "Ouvrage de béton", label_en : "Concrete structure"},
        { value: '61', label: "Parc municipal - Divers travaux", label_fr: "Parc municipal - Divers travaux", label_en : "Municipal Park (Miscellaneous work)"}, 
        { value: '62', label: "Ponceau (Entretien ou installation)", label_fr: "Ponceau (Entretien ou installation)", label_en : "Culvert (Maintenance or installation)"},
        { value: '27', label: "Pont - Inspection", label_fr: "Pont - Inspection", label_en : "Birdge - Inspection"},
        { value: '28', label: "Pont - Réfection", label_fr: "Pont - Réfection", label_en : "Bridge - Refurbishment"},
        { value: '63', label: "Réseau routier (Entretien divers)", label_fr: "Réseau routier (Entretien divers)", label_en : "Road network (Miscellaneous maintenance)"},
        { value: '31', label: "Bris infrastructures souterrainnes (Égoût, aqueduc, etc)", label_fr: "Bris infrastructures souterrainnes (Égoût, aqueduc, etc)", label_en : "Underground infrastructure breakage (Sewerage, aqueduct, etc)"}, 
        { value: '64', label: "Infrastructures souterraines (Égout, aqueduc, etc.) - Travaux divers", label_fr: "Infrastructures souterraines (Égout, aqueduc, etc.) - Travaux divers", label_en : "Underground infrastructure (Sewerage, aqueduct, etc) - Miscellaneous work"},
        { value: '65', label: "Réseau routier (Construction, réfection et travaux connexes)", label_fr: "Réseau routier (Construction, réfection et travaux connexes)", label_en : "Road network (Construction, refurbishment and related works)"},
        { value: '66', label: "Sentier récréatif", label_fr: "Sentier récréatif", label_en : "Recreational Trail"},
        { value: '67', label: "Service d'utilités publiques", label_fr: "Service d'utilités publiques", label_en : "Public Utilities Department"},
        { value: '68', label: "Autre", label_fr: "Autre", label_en : "Other"},
        { value: '69', label: "Station de suspention ou de pompage", label_fr: "Station de suspention ou de pompage", label_en : "Booster or pumping station"},
        { value: '53', label: "Usine d’eau potable - travaux", label_fr: "Usine d’eau potable - travaux", label_en : "Potable water plant - Works"},
        { value: '55', label: "Usine d'épuration - Travaux", label_fr: "Usine d'épuration - Travaux", label_en : 'Wastewater treatment plant - Works'},
        { value: '54', label: 'Événement', label_fr: 'Événement', label_en : 'Event'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'Permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Entrave", label_fr: "Entrave", label_en : "Obstacles"},
        { value: '2', label: "Occupation du domaine public", label_fr: "Occupation du domaine public", label_en : "Public domain occupation"},
        // { value: '3', label: "Partenaires", label_fr: "Partenaires", label_en : "Partners"},
        // { value: '5', label: "Résolution ou approbation CS", label_fr: "Résolution ou approbation CS", label_en : "Resolution or approval CS"},
        { value: '6', label: "Consentement RTU", label_fr: "Consentement RTU", label_en : "Agreement RTU"},
        { value: '7', label: "Autre", label_fr: "Autre", label_en : "Other"},
        { value: '4', label: "Non requis", label_fr: "Non requis", label_en : "Not required"},

      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'1', label:'311', label_fr:'311', label_en:'311'},
        {value:'2', label:'Commissions Scolaires', label_fr:'Commissions Scolaires', label_en:'School Boards'},
        {value:'3', label:"Coopérative des paramédics", label_fr:"Coopérative des paramédics", label_en:"Paramedics cooperative"},
        {value:'4', label:'Ministère des Transports du Québec (MTQ)', label_fr:'Ministère des Transports du Québec (MTQ)', label_en:'Quebec Ministry of Transport (MTQ)'},
        {value:'5', label:'RTU', label_fr:'RTU', label_en:'RTU'},
        {value:'6', label:'Sécurité incendie', label_fr:'Sécurité incendie', label_en:'Fire Safety'},
        {value:'7', label:'Service de police', label_fr:'Service de police', label_en:'Police Department'},
        {value:'8', label:'Société de transport', label_fr:'Société de transport', label_en:'Transportation Company'},
        {value:'9', label:'Stationnememt', label_fr:'Stationnememt', label_en:'Parking'},
      ]
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager(ne pas utiliser)", label_en:"Passenger type(do not use)"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: true,
      value: [
        { value: "", label: 'Select Mail List', label_en: 'Select Mail List', label_fr: 'Sélectionnez la liste de diffusion'},
        { value: "agent.rh@sjsr.ca,chefdequipe@alerte.cc,catransport@csdhr.qc.ca,celine.falardeau@csdhr.qc.ca,louise.st-laurent@compo.qc.ca,jean-francois.rancourt@postescanada.ca,julie.brassard@csdhr.qc.ca,M.Leclerc@sjsr.ca,martin.bouchard@postescanada.ca,transportadapte@videotron.ca,plussier@ambdemers.com,rph@videotron.ca,slebrun@ambdemers.com,repartition.stjean@transdev.ca,cacommunications@cssdhr.gouv.qc.ca", label: 'External Option', label_en: 'External Option', label_fr: 'Avis Externe'},
        { value: "communications@sjsr.ca", label: 'Communications', label_en: 'Communications', label_fr: 'Communication'},
        { value: "s.senecal@sjsr.ca", label: 'Centrale TP', label_en: 'Centrale TP', label_fr: 'Centrale TP'},
        { value: "groupetravauxmunicipaux@sjsr.ca", label: 'Municipal Works Group', label_en: 'Municipal Works Group', label_fr: 'Groupe Travaux Municipaux'},
        { value: "groupemediaslocaux@sjsr.ca", label: 'Local Media Group', label_en: 'Local Media Group', label_fr: 'Groupe Médias Locaux'},
        { value: "m.claveau@sjsr.ca,s.thiry@sjsr.ca,J.Groleau@sjsr.ca,P.Dueck@sjsr.ca,l.poulin@sjsr.ca,P.Coulombe@sjsr.ca,m.girard@sjsr.ca,b.mongeon@sjsr.ca,M.Lambert@sjsr.ca,t.garcia@sjsr.ca,s.marquis@sjsr.ca", label: 'Test List', label_en: 'Test List', label_fr: 'Test List'},
        { value: "harsh@opatechnologies.com,melissa@opatechnologies.com,caroline@opatechnologies.com", label: 'Test List', label_en: 'Test List OPA', label_fr: 'Test List OPA'}
      ],
    },
    departments: {
      key: 'departments',
      title: {label: "departments", label_fr:"Départements", label_en:'Departments'},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'1', phone: '450 359-2439', poste: '0',  label:'Service des infrastructures et gestion des eaux', label_fr:'Service des infrastructures et gestion des eaux', label_en:'Infrastructure and Water Management Service'},
        {value:'2', phone: '450 357-2238', poste: '0',  label:'Service des travaux publics', label_fr:'Service des travaux publics', label_en:'Public Works Department'},
        {value:'3', phone: '450 357-2157', poste: '2157',  label:'Service de la culture, du développement social et du loisir', label_fr:'Service de la culture, du développement social et du loisir', label_en:'Department of Culture, Social Development and Leisure'},

      ]
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  safelane: {
    district:{
      key: 'district',
      title: {label: 'District', label_fr:'District', label_en:'District'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'District 1' },
        { value: '2', label: 'District 2' },
        { value: '3', label: 'District 3' },
        { value: '4', label: 'District 4' },
        { value: '5', label: 'District 5' },
        { value: '6', label: 'District 6' },
        { value: '7', label: 'District 7' },
        { value: '8', label: 'District 8' },
        { value: '9', label: 'District 9' },
        { value: '10', label: 'District 10' },
        { value: '11', label: 'District 11' },
        { value: '12', label: 'District 12' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'Aylmer', label_fr: 'Aylmer', label_en: 'Aylmer'},
        { value: '2', label: 'Buckingham', label_fr: 'Buckingham', label_en: 'Buckingham'},
        { value: '3', label: 'Gatineau', label_fr: 'Gatineau', label_en: 'Gatineau'},
        { value: '4', label: 'Hull', label_fr: 'Hull', label_en: 'Hull'},
        { value: '5', label: 'Masson-Angers', label_fr: 'Masson-Angers', label_en: 'Masson-Angers'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ville - Infrastructures',label_fr: 'Ville - Infrastructures',label_en: 'City - Infrastructure', class: 'gat-green1', class1: 'gat-green1'},
        { value: '2', label: 'Ville - TP',label_fr: 'Ville - Travaux Publics',label_en: 'City - Public Works', class: 'gat-green2', class1: 'gat-green2'},
        { value: '6', label: 'Événements',label_fr: 'Événements',label_en: 'Events', class: 'gat-yellow', class1: 'gat-yellow'},
        { value: '9', label: 'Promoteurs immobiliers',label_fr: 'Promoteurs immobiliers', label_en:'Real estate promotors', class: 'gat-purple1', class1: 'gat-purple1'},
        { value: '3', label: 'RTU (HQ, Bell, Vidéotron, etc)',label_fr: 'RTU (HQ, Bell, Vidéotron, etc)',label_en: 'RTU (HQ, Bell, Videotron, etc)', class: 'gat-orange', class1: 'gat-orange'},
        { value: '4', label: 'MTQ',label_fr: 'MTQ',label_en: 'MTQ', class: 'gat-blue1', class1: 'gat-blue1'},
        { value: '5', label: 'Public transit',label_fr: 'ST',label_en: 'Public transit', class: 'gat-green3', class1: 'gat-green3'},
        { value: '7', label: "Mesures d'urgence",label_fr: "Mesures d'urgence",label_en: "Emergency measures", class: 'gat-red', class1: 'gat-red'},
        { value: '10', label: 'Administration ferroviaire (CN, CP, etc.)',label_fr: 'Administration ferroviaire (CN, CP, etc.)',label_en: 'Railway administration (CN, CP, etc)', class: 'gat-jaune', class1: 'gat-jaune'},
        { value: '8', label: 'Autres',label_fr: 'Autres',label_en: 'Other', class: 'gat-blue2', class1: 'gat-blue2'}
      ],
      classes: {
        'gat-green1':'#00b050',
        'gat-green2':'#92d050',
        'gat-green3':'#316f7b',
        'gat-orange':'#ffc001',
        'gat-blue1':'#01b0f0',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-blue2':'#0070c0',
        'gat-yellow':'#ffff02',
        'gat-red':'#ff0000',
        'gat-purple2':'#AA91E4',
        'gat-blue3':'#0E4095',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
        'gat-jaune': '#a18200'
      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '4', label: 'PTI Infra', label_fr: 'PTI Infra', label_en : 'PTI Infra'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Entrave partielle', label_fr:'Entrave partielle', label_en:'Partial obstruction'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajets ou arrêt de la ST', label_fr:'Trajets ou arrêt de la ST', label_en:'Routes or bus stops'},
        {value:'9', status:false, content:'', label:'Corridor scolaire entravé', label_fr:'Corridor scolaire entravé', label_en:'Blocked school corridor'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'}
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Réseau temporaire d’eau potable', label_fr:'Réseau temporaire d’eau potable', label_en:'Temporary potable water network'},
        {value:'2', status:false, content:'', label:'Interruption d’eau potable', label_fr:'Interruption d’eau potable', label_en:'Interruption of potable water'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Interdiction de stationnement - complète", label_fr: "Interdiction de stationnement - complète", label_en : "Parking interdiction - complete"},
        { value: '2',  label: "Interdiction de stationnement - partielle", label_fr: "Interdiction de stationnement - partielle", label_en : "Parking interdiction - partial"},
      ]
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Aménagement paysager", label_fr: "Aménagement paysager", label_en : "Landscaping"},
        { value: '2',  label: "Arbres - Abattage ou élagage", label_fr: "Arbres - Abattage ou élagage", label_en : "Trees - Felling or Pruning"},
        { value: '3',  label: "Bâtiment - Déneigement de toiture", label_fr: "Bâtiment - Déneigement de toiture", label_en : "Building - Roof snow removal"},
        { value: '4',  label: "Bâtiment divers - entretien", label_fr: "Bâtiment divers - entretien", label_en : "Various buildings  - Maintenance"},
        { value: '5',  label: "Bâtiment municipal - Construction", label_fr: "Bâtiment municipal - Construction", label_en : "Municipal buildings - Construction"},
        { value: '6',  label: "Bâtiment municipal - Démolition", label_fr: "Bâtiment municipal - Démolition", label_en : "Municipal buildings - Demolition"},
        { value: '7',  label: "Bâtiment municipal - Réfection", label_fr: "Bâtiment municipal - Réfection", label_en : "Municipal buildings - Refurbishment"}, 
        { value: '8',  label: "Borne-fontaine - Inspection", label_fr: "Borne-fontaine - Inspection", label_en : "Fire hydrant - Inspection"},
        { value: '9',  label: "Branchement de services –  Réfection", label_fr: "Branchement de services –  Réfection", label_en : "Service connections –  Refurbishment"}, 
        { value: '10', label: "Branchement de services - Construction", label_fr: "Branchement de services - Construction", label_en : "Service connections - Construction"},
        { value: '11', label: "Feux de circulation - Installation", label_fr: "Feux de circulation - Installation", label_en : "Traffic lights - Installation"},
        { value: '12', label: "Feux de circulation - Réfection", label_fr: "Feux de circulation - Réfection", label_en : "Traffic lights - Refurbishment"},
        { value: '13', label: "Forage", label_fr: "Forage", label_en : "Drilling"},
        { value: '14', label: "Fossés - Nettoyage", label_fr: "Fossés - Nettoyage", label_en : "Ditch - Clean-Up"}, 
        { value: '15', label: "Fossés – Nouveau", label_fr: "Fossés – Nouveau", label_en : "Ditch - New"},
        { value: '16', label: "Mesure de modération de la circulation - Installation", label_fr: "Mesure de modération de la circulation - Installation", label_en : "Traffic moderation measures - Installation"},
        { value: '17', label: "Mesure de modération de la circulation – Réfection", label_fr: "Mesure de modération de la circulation – Réfection", label_en : "Traffic moderation measures - Refurbishment"},
        { value: '18', label: "Ministère des Transports du Québec - travaux", label_fr: "Ministère des Transports du Québec - travaux", label_en : "Quebec’s Ministry of Transportation - Works"}, 
        { value: '19', label: "Ouvrages de béton - Construction", label_fr: "Ouvrages de béton - Construction", label_en : "Concrete structure - Construction"},
        { value: '20', label: "Ouvrages de béton – Réfection", label_fr: "Ouvrages de béton – Réfection", label_en : "Concrete structure  – Refurbishment"}, 
        { value: '21', label: "Parc - Jeu d’eau - Aménagement", label_fr: "Parc - Jeu d’eau - Aménagement", label_en : "Parc - Water games - Landscaping"}, 
        { value: '22', label: "Parc - Parcours santé - Aménagement", label_fr: "Parc - Parcours santé - Aménagement", label_en : "Parc - Fitness trail - Landscaping"},
        { value: '23', label: "Parc municipal - Nouveau parc", label_fr: "Parc municipal - Nouveau parc", label_en : "Municipal Parc - New Parc"},
        { value: '24', label: "Parc municipal - Réaménagement", label_fr: "Parc municipal - Réaménagement", label_en : "Municipal Parc - Refurbishment"}, 
        { value: '25', label: "Ponceau – Installation", label_fr: "Ponceau – Installation", label_en : "Culvert – Installation"},
        { value: '26', label: "Ponceau – Réfection", label_fr: "Ponceau – Réfection", label_en : "Culvert – Refurbishment"},
        { value: '27', label: "Pont - Inspection", label_fr: "Pont - Inspection", label_en : "Birdge - Inspection"},
        { value: '28', label: "Pont - Réfection", label_fr: "Pont - Réfection", label_en : "Bridge - Refurbishment"},
        { value: '29', label: "Puisard - Installation", label_fr: "Puisard - Installation", label_en : "Sump - Installation"},
        { value: '30', label: "Puisard - Réfection", label_fr: "Puisard - Réfection", label_en : "Sump - Refurbishment"},
        { value: '31', label: "Réseau d'aqueduc - Bris", label_fr: "Réseau d'aqueduc - Bris", label_en : "Aqueduct network - Breakage"}, 
        { value: '32', label: "Réseau d'aqueduc - Construction", label_fr: "Réseau d'aqueduc - Construction", label_en : "Aqueduct network - Construction"},
        { value: '33', label: "Réseau d'aqueduc - Réfection", label_fr: "Réseau d'aqueduc - Réfection", label_en : "Aqueduct network - Refurbishment"},
        { value: '34', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_en : "Municipal aqueduct and storm and/or sanitary sewers services - Construction"},
        { value: '35', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Municipal aqueduct and storm and/or sanitary sewers services -  Refurbishment"},
        { value: '36', label: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_en : "Storm and/or sanitary sewers network - Construction"},
        { value: '37', label: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Storm and/or sanitary sewers network - Refubishment"},
        { value: '38', label: "Réseau routier - Construction", label_fr: "Réseau routier - Construction", label_en : "Road network - Construction"},
        { value: '39', label: "Réseau routier - Marquage de la chaussée", label: "Réseau routier - Marquage de la chaussée", label_en : "Road network - Pavement marking"}, 
        { value: '40', label: "Réseau routier - Réfection", label_fr: "Réseau routier - Réfection", label_en : "Road network - Refurbishment"},
        { value: '41', label: "Sentier récréatif - Construction", label_fr: "Sentier récréatif - Construction", label_en : "Recreational Trail - Construction"},
        { value: '42', label: "Sentier récréatif - Fermeture", label_fr: "Sentier récréatif - Fermeture", label_en : "Recreational Trail - Closure"}, 
        { value: '43', label: "Sentier récréatif - Nettoyage", label_fr: "Sentier récréatif - Nettoyage", label_en : "Recreational Trail - Clean-Up"},
        { value: '44', label: "Sentier récréatif - Réfection", label_fr: "Sentier récréatif - Réfection",label_en : "Recreational Trail - Refurbishment"}, 
        { value: '45', label: "Sentier récréatif - Zone inondée", label_fr: "Sentier récréatif - Zone inondée", label_en : "Recreational Trail - Flooded area"},
        { value: '46', label: "Services d'utilités publiques - Enfouissement", label_fr: "Services d'utilités publiques - Enfouissement", label_en : "Public Utilities Department - Landfill"},
        { value: '47', label: "Services d'utilités publiques - Installation", label_fr: "Services d'utilités publiques - Installation", label_en : "Public Utilities Department - Installation"}, 
        { value: '48', label: "Services publics - Réfection", label_fr: "Services publics - Réfection", label_en : "Public Works - Refurbishment"},
        { value: '49', label: "Signalisation - Installation", label_fr: "Signalisation - Installation", label_en : "Signage - Installation"},
        { value: '50', label: "Signalisation – Réfection", label_fr: "Signalisation – Réfection", label_en : "Signage – Refurbishment"},
        { value: '51', label: "Station de surpression ou de pompage - Construction", label_fr: "Station de surpression ou de pompage - Construction", label_en : "Booster or pumping station - Construction"},
        { value: '52', label: "Station de surpression ou de pompage - Réfection", label_fr: "Station de surpression ou de pompage - Réfection", label_en : "Booster or pumping station - Refurbishment"}, 
        { value: '53', label: "Usine d’eau potable - travaux", label_fr: "Usine d’eau potable - travaux", label_en : "Potable water plant - Works"},
        { value: '54', label: 'Événement', label_fr: 'Événement', label_en : 'Event'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'Permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Entrave", label_fr: "Entrave", label_en : "Obstacles"},
        { value: '2', label: "Occupation", label_fr: "Occupation", label_en : "Occupation"},
        { value: '3', label: "Partenaires", label_fr: "Partenaires", label_en : "Partners"},
        { value: '4', label: "Non requis (projets Ville)", label_fr: "Non requis (projets Ville)", label_en : "Not required (City projects)"},
        { value: '5', label: "Résolution ou approbation CS", label_fr: "Résolution ou approbation CS", label_en : "Resolution or approval CS"},
      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'1', label:'311', label_fr:'311', label_en:'311'},
        {value:'2', label:'Commissions Scolaires', label_fr:'Commissions Scolaires', label_en:'School Boards'},
        {value:'3', label:"Coopérative des paramédics", label_fr:"Coopérative des paramédics", label_en:"Paramedics cooperative"},
        {value:'4', label:'Ministère des Transports du Québec (MTQ)', label_fr:'Ministère des Transports du Québec (MTQ)', label_en:'Quebec Ministry of Transport (MTQ)'},
        {value:'5', label:'RTU', label_fr:'RTU', label_en:'RTU'},
        {value:'6', label:'Sécurité incendie', label_fr:'Sécurité incendie', label_en:'Fire Safety'},
        {value:'7', label:'Service de police', label_fr:'Service de police', label_en:'Police Department'},
        {value:'8', label:'Société de transport', label_fr:'Société de transport', label_en:'Transportation Company'},
        {value:'9', label:'Stationnememt', label_fr:'Stationnememt', label_en:'Parking'},
      ]
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager(ne pas utiliser)", label_en:"Passenger type(do not use)"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  roadworks: {
    district:{
      key: 'district',
      title: {label: 'District', label_fr:'District', label_en:'District'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'District 1' },
        { value: '2', label: 'District 2' },
        { value: '3', label: 'District 3' },
        { value: '4', label: 'District 4' },
        { value: '5', label: 'District 5' },
        { value: '6', label: 'District 6' },
        { value: '7', label: 'District 7' },
        { value: '8', label: 'District 8' },
        { value: '9', label: 'District 9' },
        { value: '10', label: 'District 10' },
        { value: '11', label: 'District 11' },
        { value: '12', label: 'District 12' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'Aylmer', label_fr: 'Aylmer', label_en: 'Aylmer'},
        { value: '2', label: 'Buckingham', label_fr: 'Buckingham', label_en: 'Buckingham'},
        { value: '3', label: 'Gatineau', label_fr: 'Gatineau', label_en: 'Gatineau'},
        { value: '4', label: 'Hull', label_fr: 'Hull', label_en: 'Hull'},
        { value: '5', label: 'Masson-Angers', label_fr: 'Masson-Angers', label_en: 'Masson-Angers'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ville - Infrastructures',label_fr: 'Ville - Infrastructures',label_en: 'City - Infrastructure', class: 'gat-green1', class1: 'gat-green1'},
        { value: '2', label: 'Ville - TP',label_fr: 'Ville - Travaux Publics',label_en: 'City - Public Works', class: 'gat-green2', class1: 'gat-green2'},
        { value: '6', label: 'Événements',label_fr: 'Événements',label_en: 'Events', class: 'gat-yellow', class1: 'gat-yellow'},
        { value: '9', label: 'Promoteurs immobiliers',label_fr: 'Promoteurs immobiliers', label_en:'Real estate promotors', class: 'gat-purple1', class1: 'gat-purple1'},
        { value: '3', label: 'RTU (HQ, Bell, Vidéotron, etc)',label_fr: 'RTU (HQ, Bell, Vidéotron, etc)',label_en: 'RTU (HQ, Bell, Videotron, etc)', class: 'gat-orange', class1: 'gat-orange'},
        { value: '4', label: 'MTQ',label_fr: 'MTQ',label_en: 'MTQ', class: 'gat-blue1', class1: 'gat-blue1'},
        { value: '5', label: 'Public transit',label_fr: 'ST',label_en: 'Public transit', class: 'gat-green3', class1: 'gat-green3'},
        { value: '7', label: "Mesures d'urgence",label_fr: "Mesures d'urgence",label_en: "Emergency measures", class: 'gat-red', class1: 'gat-red'},
        { value: '10', label: 'Administration ferroviaire (CN, CP, etc.)',label_fr: 'Administration ferroviaire (CN, CP, etc.)',label_en: 'Railway administration (CN, CP, etc)', class: 'gat-jaune', class1: 'gat-jaune'},
        { value: '8', label: 'Autres',label_fr: 'Autres',label_en: 'Other', class: 'gat-blue2', class1: 'gat-blue2'}
      ],
      classes: {
        'gat-green1':'#00b050',
        'gat-green2':'#92d050',
        'gat-green3':'#316f7b',
        'gat-orange':'#2E6B4D',
        'gat-blue1':'#1C02DE',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-blue2':'#84B938',
        'gat-yellow':'#EE7DEB',
        'gat-red':'#E8464A',
        'gat-purple2':'#AA91E4',
        'gat-blue3':'#0E4095',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
        'gat-jaune': '#2E6B4D'
      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '4', label: 'PTI Infra', label_fr: 'PTI Infra', label_en : 'PTI Infra'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Entrave partielle', label_fr:'Entrave partielle', label_en:'Partial obstruction'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajets ou arrêt de la ST', label_fr:'Trajets ou arrêt de la ST', label_en:'Routes or bus stops'},
        {value:'9', status:false, content:'', label:'Corridor scolaire entravé', label_fr:'Corridor scolaire entravé', label_en:'Blocked school corridor'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'}
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Réseau temporaire d’eau potable', label_fr:'Réseau temporaire d’eau potable', label_en:'Temporary potable water network'},
        {value:'2', status:false, content:'', label:'Interruption d’eau potable', label_fr:'Interruption d’eau potable', label_en:'Interruption of potable water'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Interdiction de stationnement - complète", label_fr: "Interdiction de stationnement - complète", label_en : "Parking interdiction - complete"},
        { value: '2',  label: "Interdiction de stationnement - partielle", label_fr: "Interdiction de stationnement - partielle", label_en : "Parking interdiction - partial"},
      ]
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Aménagement paysager", label_fr: "Aménagement paysager", label_en : "Landscaping"},
        { value: '2',  label: "Arbres - Abattage ou élagage", label_fr: "Arbres - Abattage ou élagage", label_en : "Trees - Felling or Pruning"},
        { value: '3',  label: "Bâtiment - Déneigement de toiture", label_fr: "Bâtiment - Déneigement de toiture", label_en : "Building - Roof snow removal"},
        { value: '4',  label: "Bâtiment divers - entretien", label_fr: "Bâtiment divers - entretien", label_en : "Various buildings  - Maintenance"},
        { value: '5',  label: "Bâtiment municipal - Construction", label_fr: "Bâtiment municipal - Construction", label_en : "Municipal buildings - Construction"},
        { value: '6',  label: "Bâtiment municipal - Démolition", label_fr: "Bâtiment municipal - Démolition", label_en : "Municipal buildings - Demolition"},
        { value: '7',  label: "Bâtiment municipal - Réfection", label_fr: "Bâtiment municipal - Réfection", label_en : "Municipal buildings - Refurbishment"}, 
        { value: '8',  label: "Borne-fontaine - Inspection", label_fr: "Borne-fontaine - Inspection", label_en : "Fire hydrant - Inspection"},
        { value: '9',  label: "Branchement de services –  Réfection", label_fr: "Branchement de services –  Réfection", label_en : "Service connections –  Refurbishment"}, 
        { value: '10', label: "Branchement de services - Construction", label_fr: "Branchement de services - Construction", label_en : "Service connections - Construction"}, 
        { value: '11', label: "Feux de circulation - Installation", label_fr: "Feux de circulation - Installation", label_en : "Traffic lights - Installation"},
        { value: '12', label: "Feux de circulation - Réfection", label_fr: "Feux de circulation - Réfection", label_en : "Traffic lights - Refurbishment"},
        { value: '13', label: "Forage", label_fr: "Forage", label_en : "Drilling"},
        { value: '14', label: "Fossés - Nettoyage", label_fr: "Fossés - Nettoyage", label_en : "Ditch - Clean-Up"}, 
        { value: '15', label: "Fossés – Nouveau", label_fr: "Fossés – Nouveau", label_en : "Ditch - New"}, 
        { value: '16', label: "Mesure de modération de la circulation - Installation", label_fr: "Mesure de modération de la circulation - Installation", label_en : "Traffic moderation measures - Installation"},
        { value: '17', label: "Mesure de modération de la circulation – Réfection", label_fr: "Mesure de modération de la circulation – Réfection", label_en : "Traffic moderation measures - Refurbishment"},
        { value: '18', label: "Ministère des Transports du Québec - travaux", label_fr: "Ministère des Transports du Québec - travaux", label_en : "Quebec’s Ministry of Transportation - Works"}, 
        { value: '19', label: "Ouvrages de béton - Construction", label_fr: "Ouvrages de béton - Construction", label_en : "Concrete structure - Construction"},
        { value: '20', label: "Ouvrages de béton – Réfection", label_fr: "Ouvrages de béton – Réfection", label_en : "Concrete structure  – Refurbishment"}, 
        { value: '21', label: "Parc - Jeu d’eau - Aménagement", label_fr: "Parc - Jeu d’eau - Aménagement", label_en : "Parc - Water games - Landscaping"}, 
        { value: '22', label: "Parc - Parcours santé - Aménagement", label_fr: "Parc - Parcours santé - Aménagement", label_en : "Parc - Fitness trail - Landscaping"},
        { value: '23', label: "Parc municipal - Nouveau parc", label_fr: "Parc municipal - Nouveau parc", label_en : "Municipal Parc - New Parc"},
        { value: '24', label: "Parc municipal - Réaménagement", label_fr: "Parc municipal - Réaménagement", label_en : "Municipal Parc - Refurbishment"}, 
        { value: '25', label: "Ponceau – Installation", label_fr: "Ponceau – Installation", label_en : "Culvert – Installation"},
        { value: '26', label: "Ponceau – Réfection", label_fr: "Ponceau – Réfection", label_en : "Culvert – Refurbishment"},
        { value: '27', label: "Pont - Inspection", label_fr: "Pont - Inspection", label_en : "Birdge - Inspection"},
        { value: '28', label: "Pont - Réfection", label_fr: "Pont - Réfection", label_en : "Bridge - Refurbishment"},
        { value: '29', label: "Puisard - Installation", label_fr: "Puisard - Installation", label_en : "Sump - Installation"},
        { value: '30', label: "Puisard - Réfection", label_fr: "Puisard - Réfection", label_en : "Sump - Refurbishment"},
        { value: '31', label: "Réseau d'aqueduc - Bris", label_fr: "Réseau d'aqueduc - Bris", label_en : "Aqueduct network - Breakage"}, 
        { value: '32', label: "Réseau d'aqueduc - Construction", label_fr: "Réseau d'aqueduc - Construction", label_en : "Aqueduct network - Construction"},
        { value: '33', label: "Réseau d'aqueduc - Réfection", label_fr: "Réseau d'aqueduc - Réfection", label_en : "Aqueduct network - Refurbishment"},
        { value: '34', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Construction", label_en : "Municipal aqueduct and storm and/or sanitary sewers services - Construction"},
        { value: '35', label: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'aqueduc et d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Municipal aqueduct and storm and/or sanitary sewers services -  Refurbishment"},
        { value: '36', label: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Construction", label_en : "Storm and/or sanitary sewers network - Construction"},
        { value: '37', label: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_fr: "Réseau d'égouts pluvial et/ou sanitaire - Réfection", label_en : "Storm and/or sanitary sewers network - Refubishment"},
        { value: '38', label: "Réseau routier - Construction", label_fr: "Réseau routier - Construction", label_en : "Road network - Construction"},
        { value: '39', label: "Réseau routier - Marquage de la chaussée", label: "Réseau routier - Marquage de la chaussée", label_en : "Road network - Pavement marking"}, 
        { value: '40', label: "Réseau routier - Réfection", label_fr: "Réseau routier - Réfection", label_en : "Road network - Refurbishment"},
        { value: '41', label: "Sentier récréatif - Construction", label_fr: "Sentier récréatif - Construction", label_en : "Recreational Trail - Construction"},
        { value: '42', label: "Sentier récréatif - Fermeture", label_fr: "Sentier récréatif - Fermeture", label_en : "Recreational Trail - Closure"}, 
        { value: '43', label: "Sentier récréatif - Nettoyage", label_fr: "Sentier récréatif - Nettoyage", label_en : "Recreational Trail - Clean-Up"},
        { value: '44', label: "Sentier récréatif - Réfection", label_fr: "Sentier récréatif - Réfection",label_en : "Recreational Trail - Refurbishment"}, 
        { value: '45', label: "Sentier récréatif - Zone inondée", label_fr: "Sentier récréatif - Zone inondée", label_en : "Recreational Trail - Flooded area"},
        { value: '46', label: "Services d'utilités publiques - Enfouissement", label_fr: "Services d'utilités publiques - Enfouissement", label_en : "Public Utilities Department - Landfill"},
        { value: '47', label: "Services d'utilités publiques - Installation", label_fr: "Services d'utilités publiques - Installation", label_en : "Public Utilities Department - Installation"}, 
        { value: '48', label: "Services publics - Réfection", label_fr: "Services publics - Réfection", label_en : "Public Works - Refurbishment"},
        { value: '49', label: "Signalisation - Installation", label_fr: "Signalisation - Installation", label_en : "Signage - Installation"},
        { value: '50', label: "Signalisation – Réfection", label_fr: "Signalisation – Réfection", label_en : "Signage – Refurbishment"},
        { value: '51', label: "Station de surpression ou de pompage - Construction", label_fr: "Station de surpression ou de pompage - Construction", label_en : "Booster or pumping station - Construction"},
        { value: '52', label: "Station de surpression ou de pompage - Réfection", label_fr: "Station de surpression ou de pompage - Réfection", label_en : "Booster or pumping station - Refurbishment"}, 
        { value: '53', label: "Usine d’eau potable - travaux", label_fr: "Usine d’eau potable - travaux", label_en : "Potable water plant - Works"},
        { value: '54', label: 'Événement', label_fr: 'Événement', label_en : 'Event'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'Permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Entrave", label_fr: "Entrave", label_en : "Obstacles"},
        { value: '2', label: "Occupation", label_fr: "Occupation", label_en : "Occupation"},
        { value: '3', label: "Partenaires", label_fr: "Partenaires", label_en : "Partners"},
        { value: '4', label: "Non requis (projets Ville)", label_fr: "Non requis (projets Ville)", label_en : "Not required (City projects)"},
        { value: '5', label: "Résolution ou approbation CS", label_fr: "Résolution ou approbation CS", label_en : "Resolution or approval CS"},
      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'1', label:'311', label_fr:'311', label_en:'311'},
        {value:'2', label:'Commissions Scolaires', label_fr:'Commissions Scolaires', label_en:'School Boards'},
        {value:'3', label:"Coopérative des paramédics", label_fr:"Coopérative des paramédics", label_en:"Paramedics cooperative"},
        {value:'4', label:'Ministère des Transports du Québec (MTQ)', label_fr:'Ministère des Transports du Québec (MTQ)', label_en:'Quebec Ministry of Transport (MTQ)'},
        {value:'5', label:'RTU', label_fr:'RTU', label_en:'RTU'},
        {value:'6', label:'Sécurité incendie', label_fr:'Sécurité incendie', label_en:'Fire Safety'},
        {value:'7', label:'Service de police', label_fr:'Service de police', label_en:'Police Department'},
        {value:'8', label:'Société de transport', label_fr:'Société de transport', label_en:'Transportation Company'},
        {value:'9', label:'Stationnememt', label_fr:'Stationnememt', label_en:'Parking'},
      ]
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager(ne pas utiliser)", label_en:"Passenger type(do not use)"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  cigo: {
    district:{
      key: 'district',
      title: {label: 'Arrondissements', label_fr:'District', label_en:'District'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: '1', label_fr: '1', label_en: '1'},
        { value: '2', label: 'Buckingham', label_fr: 'Buckingham', label_en: 'Buckingham'},
        { value: '3', label: 'Gatineau', label_fr: 'Gatineau', label_en: 'Gatineau'},
        { value: '4', label: 'Hull', label_fr: 'Hull', label_en: 'Hull'},
        { value: '5', label: 'Masson-Angers', label_fr: 'Masson-Angers', label_en: 'Masson-Angers'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ville - Génie',label_fr: 'Ville - Génie',label_en: 'City - Engineering', class: 'gat-blue2', class1: 'gat-blue2'},
        { value: '2', label: 'Ville - Travaux Publics',label_fr: 'Ville - Travaux Publics',label_en: 'City - Public Works', class: 'gat-green1', class1: 'gat-green1'},
        { value: '3', label: 'Évènements',label_fr: 'Événements',label_en: 'Events', class: 'gat-rose', class1: 'gat-rose'},
        { value: '4', label: 'Promoteurs immobiliers',label_fr: 'Promoteurs immobiliers', label_en:'Real estate promotors', class: 'gat-mauve', class1: 'gat-mauve'},
        { value: '5', label: 'RTU (HQ, Bell, Vidéotron, etc)',label_fr: 'RTU (HQ, Bell, Vidéotron, etc)',label_en: 'RTU (HQ, Bell, Videotron, etc)', class: 'gat-turquois', class1: 'gat-turquois'},
        { value: '6', label: 'REM',label_fr: 'REM',label_en: 'REM', class: 'gat-jaune', class1: 'gat-jaune'},
        { value: '7', label: 'MTQ',label_fr: 'MTQ',label_en: 'MTQ', class: 'gat-red', class1: 'gat-red'},
        { value: '8', label: 'STM',label_fr: 'STM',label_en: 'STM', class: 'gat-blue1', class1: 'gat-blue1'},
        { value: '9', label: "Arrondissements et villes voisines",label_fr: "Arrondissements et villes voisines",label_en: "Districts and neighboring towns", class: 'gat-lightred', class1: 'gat-lightred'},
        { value: '10', label: 'Autres',label_fr: 'Autres',label_en: 'Other', class: 'gat-purple', class1: 'gat-purple'},
        { value: '11', label: 'Promoteur Carbonleo',label_fr: 'Promoteur Carbonleo',label_en: 'Carbonleo promoter', class: 'gat-orange', class1: 'gat-orange'},
        { value: '12', label: 'Citoyen',label_fr: 'Citoyen',label_en: 'Citizen', class: 'gat-purple2', class1: 'gat-purple2'}
      ],
      classes: {
        'gat-green1':'#00b050',
        'gat-green2':'#92d050',
        'gat-green3':'#316f7b',
        'gat-orange':'#ffc001',
        'gat-blue1':'#01b0f0',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-purple2':'#AA91E4',
        'gat-blue2':'#0070c0',
        'gat-blue3':'#0E4095',
        'gat-yellow':'#ffff02',
        'gat-red':'#ff0000',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
        'gat-jaune': '#a18200'

      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '9', label: 'Urgence - en cours', label_fr: 'Urgence - en cours', label_en : 'Emergency - ongoing'},
        { value: '10', label: 'Urgence terminé', label_fr: 'Urgence terminé', label_en : 'Emergency over'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Entrave partielle', label_fr:'Entrave partielle', label_en:'Partial obstruction'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajet ou arrêt de la STM', label_fr:'Trajet ou arrêt de la STM', label_en:'Routes or Stops STM'},
        {value:'9', status:false, content:'', label:'Corridor scolaire entravé', label_fr:'Corridor scolaire entravé', label_en:'Blocked school corridor'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'},
        {value:'13', status:false, content:'', label:'Collectes des matières résiduelles', label_fr:'Collectes des matières résiduelles', label_en:'Collection of residual materials'},
        {value:'14', status:false, content:'', label:'Fermeture temporaire de services municipaux ( Parc, Chalet etc)', label_fr:'Fermeture temporaire de services municipaux ( Parc, Chalet etc)', label_en:'Temporary closure of municipal services (Park, Chalet etc)'}
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Réseau temporaire d’eau potable', label_fr:'Réseau temporaire d’eau potable', label_en:'Temporary potable water network'},
        {value:'2', status:false, content:'', label:'Interruption d’eau potable', label_fr:'Interruption d’eau potable', label_en:'Interruption of potable water'},
        {value:'3', status:false, content:'', label:'Perte de pression temporaire', label_fr:'Perte de pression temporaire', label_en:'Temporary pressure loss'},
        {value:'4', status:false, content:'', label:'Changement de couleur de l’eau - non nocif', label_fr:'Changement de couleur de l’eau - non nocif', label_en:'Water color change - not harmful'}
      ]
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Aménagement paysager", label_fr: "Aménagement paysager", label_en : "Landscaping"},
        { value: '2',  label: "Arbres - Abattage ou élagage", label_fr: "Arbres - Abattage ou élagage", label_en : "Trees - Felling or pruning"},
        { value: '3',  label: "Déménagement", label_fr: "Déménagement", label_en : "Move"},
        { value: '4',  label: "Bâtiment citoyen - Déneigement de toiture", label_fr: "Bâtiment citoyen - Déneigement de toiture", label_en : "Citizen building - Roof snow removal"},
        { value: '5',  label: "Bâtiment citoyen - Travaux de toiture", label_fr: "Bâtiment citoyen - Travaux de toiture", label_en : "Citizen building - Roofing works"},
        { value: '6',  label: "Bâtiment citoyen - Nettoyage de fenêtres", label_fr: "Bâtiment citoyen - Nettoyage de fenêtres", label_en : "Citizen building - Window cleaning"},
        { value: '7',  label: "Bâtiment municipaux - Entretien", label_fr: "Bâtiment municipaux - Entretien", label_en : "Municipal buildings - Maintenance"}, 
        { value: '8',  label: "Bâtiment municipal - Construction", label_fr: "Bâtiment municipal - Construction", label_en : "Municipal Building - Construction"},
        { value: '9',  label: "Bâtiment municipal - Démolition", label_fr: "Bâtiment municipal - Démolition", label_en : "Municipal building - Demolition"}, 
        { value: '10', label: "Bâtiment municipal - Réfection/Rénovation", label_fr: "Bâtiment municipal - Réfection/Rénovation", label_en : "Municipal Building - Repair/Renovation"},
        { value: '11', label: "Réseau d'aqueduc - Borne-fontaine - mesure pression", label_fr: "Réseau d'aqueduc - Borne-fontaine - mesure pression", label_en : "Aqueduct network - Fire hydrant - pressure measurement"},
        { value: '12', label: "Réseau d'aqueduc - Borne-fontaine - Inspection", label_fr: "Réseau d'aqueduc - Borne-fontaine - Inspection", label_en : "Aqueduct network - Fire hydrant - Inspection"},
        { value: '13', label: "Réseau d'aqueduc - Borne-fontaine - Peinture", label_fr: "Réseau d'aqueduc - Borne-fontaine - Peinture", label_en : "Aqueduct network - Fire hydrant - Painting"},
        { value: '14', label: "Réseau d'aqueduc - Borne-fontaine - Réfection/Remplacement", label_fr: "Réseau d'aqueduc - Borne-fontaine - Réfection/Remplacement", label_en : "Aqueduct network - Fire hydrant - Repair/Replacement"}, 
        { value: '15', label: "Branchement de services – Réfection", label_fr: "Branchement de services – Réfection", label_en : "Service connection – Repair"},
        { value: '16', label: "Branchement de services - Remplacement", label_fr: "Branchement de services - Remplacement", label_en : "Service Connection - Replacement"},
        { value: '17', label: "Branchement de services - Nouveau", label_fr: "Branchement de services - Nouveau", label_en : "Service connection - New"},
        { value: '18', label: "Feux de circulation - Installation", label_fr: "Feux de circulation - Installation", label_en : "Traffic lights - Installation"}, 
        { value: '19', label: "Feux de circulation - Réfection/ou entretien", label_fr: "Feux de circulation - Réfection/ou entretien", label_en : "Traffic lights - Repair/or maintenance"},
        { value: '20', label: "Forage", label_fr: "Forage", label_en : "Drilling"}, 
        { value: '21', label: "Mesure de mitigation de la circulation - Installation", label_fr: "Mesure de mitigation de la circulation - Installation", label_en : "Traffic Mitigation Measure - Installation"}, 
        { value: '22', label: "Ministère des Transports du Québec - Travaux", label_fr: "Ministère des Transports du Québec - Travaux", label_en : "Quebec Ministry of Transport - Works"},
        { value: '23', label: "Ouvrages de béton - Construction", label_fr: "Ouvrages de béton - Construction", label_en : "Concrete works - Construction"},
        { value: '24', label: "Ouvrages de béton – Réfection", label_fr: "Ouvrages de béton – Réfection", label_en : "Concrete structures – Repairs"}, 
        { value: '25', label: "Parc - Jeux d’eau - Aménagement", label_fr: "Parc - Jeux d’eau - Aménagement", label_en : "Park - Water games - Development"},
        { value: '26', label: "Parc - Jeux de Parc - Aménagement", label_fr: "Parc - Jeux de Parc - Aménagement", label_en : "Park - Park Games - Development"},
        { value: '27', label: "Parc - Sentiers - Aménagement", label_fr: "Parc - Sentiers - Aménagement", label_en : "Park - Trails - Development"},
        { value: '28', label: "Parc - Sentiers - Entretien", label_fr: "Parc - Sentiers - Entretien", label_en : "Park - Trails - Maintenance"},
        { value: '29', label: "Parc - Nouveau parc", label_fr: "Parc - Nouveau parc", label_en : "Park - New Park"},
        { value: '30', label: "Parc- Réaménagement", label_fr: "Parc- Réaménagement", label_en : "Park- Redevelopment"},
        { value: '31', label: "Pont / viaduc / passerelle - Inspection", label_fr: "Pont / viaduc / passerelle - Inspection", label_en : "Bridge / Viaduct / Walkway - Inspection"}, 
        { value: '32', label: "Pont / viaduc / passerelle - Réfection", label_fr: "Pont / viaduc / passerelle - Réfection", label_en : "Bridge / viaduct / walkway - Rehabilitation"},
        { value: '33', label: "Réseau d'égout unitaire - Puisard - Installation", label_fr: "Réseau d'égout unitaire - Puisard - Installation", label_en : "Combined sewer system - Sump - Installation"},
        { value: '34', label: "Réseau d'égout unitaire - Puisard - Réfection/Remplacement", label_fr: "Réseau d'égout unitaire - Puisard - Réfection/Remplacement", label_en : "Combined sewer system - Sump - Repair/Replacement"},
        { value: '35', label: "Réseau d'aqueduc - Bris", label_fr: "Réseau d'aqueduc - Bris", label_en : "Waterworks - Bris"},
        { value: '36', label: "Réseau d'aqueduc - Construction", label_fr: "Réseau d'aqueduc - Construction", label_en : "Waterworks - Construction"},
        { value: '37', label: "Réseau d'aqueduc - Réfection/Remplacement", label_fr: "Réseau d'aqueduc - Réfection/Remplacement", label_en : "Waterworks - Repair/Replacement"},
        { value: '38', label: "Réseau d'aqueduc - Réhabilitation par chemisage structurale", label_fr: "Réseau d'aqueduc - Réhabilitation par chemisage structurale", label_en : "Aqueduct network - Rehabilitation by structural lining"},
        { value: '39', label: "Réseau d'aqueduc - Vanne - Réfection/Remplacement", label_fr: "Réseau d'aqueduc - Vanne - Réfection/Remplacement", label_en : "Aqueduct network - Gate - Repair/Replacement"}, 
        { value: '40', label: "Réseau d'aqueduc et d'égout unitaire - Construction", label_fr: "Réseau d'aqueduc et d'égout unitaire - Construction", label_en : "Combined water and sewer system - Construction"},
        { value: '41', label: "Réseau d'aqueduc et d'égout unitaire - Réfection/Remplacement", label_fr: "Réseau d'aqueduc et d'égout unitaire - Réfection/Remplacement", label_en : "Combined water and sewer system - Repair/Replacement"},
        { value: '42', label: "Réseau d'aqueduc et d'égout unitaire - Réhabilitation par chemisage structural", label_fr: "Réseau d'aqueduc et d'égout unitaire - Réhabilitation par chemisage structural", label_en : "Waterworks and combined sewer system - Rehabilitation by structural lining"}, 
        { value: '43', label: "Réseau d'égout unitaire - Construction", label_fr: "Réseau d'égout unitaire - Construction", label_en : "Combined sewer system - Construction"},
        { value: '44', label: "Réseau d'égout unitaire - Réhabilitation par chemisage strutural", label_fr: "Réseau d'égout unitaire - Réhabilitation par chemisage strutural", label_en : "Combined sewer network - Rehabilitation by structural lining"}, 
        { value: '45', label: "Réseau d'égout unitaire - Réfection/Remplacement", label_fr: "Réseau d'égout unitaire - Réfection/Remplacement", label_en : "Combined Sewer System - Rehabilitation/Replacement"},
        { value: '46', label: "Réseau d'égout unitaire - Regard d'égout - Installation", label_fr: "Réseau d'égout unitaire - Regard d'égout - Installation", label_en : "Combined Sewer System - Manhole - Installation"},
        { value: '47', label: "Réseau d'égout unitaire - Regard d'égout - Réfection/Remplacement", label_fr: "Réseau d'égout unitaire - Regard d'égout - Réfection/Remplacement", label_en : "Combined sewer system - Manhole - Rehabilitation/Replacement"}, 
        { value: '48', label: "Réseau d'égout unitaire - Nettoyage et inspection", label_fr: "Réseau d'égout unitaire - Nettoyage et inspection", label_en : "Combined Sewer System - Cleaning and Inspection"},
        { value: '49', label: "Réseau routier - Reconstruction", label_fr: "Réseau routier - Reconstruction", label_en : "Road network - Reconstruction"},
        { value: '50', label: "Réseau routier - Resurfaçage", label_fr: "Réseau routier - Resurfaçage", label_en : "Road network - Resurfacing"},
        { value: '51', label: "Réseau routier - Marquage de la chaussée", label_fr: "Réseau routier - Marquage de la chaussée", label_en : "Road network - Pavement marking"},
        { value: '52', label: "Réseau routier - Scellement de fissure", label_fr: "Réseau routier - Scellement de fissure", label_en : "Road network - Crack sealing"}, 
        { value: '53', label: "Réseau routier - Réfection/Réparation", label_fr: "Réseau routier - Réfection/Réparation", label_en : "Road network - Rehabilitation/Repair"},
        { value: '54', label: "Réseau routier - Trottoir- reconstruction", label_fr: "Réseau routier - Trottoir- reconstruction", label_en : "Road network - sidewalk - reconstruction"},
        { value: '55', label: "Services d'utilités publiques - Enfouissement", label_fr: "Services d'utilités publiques - Enfouissement", label_en : "Utilities - Landfill"},
        { value: '56', label: "Services d'utilités publiques - Installation", label_fr: "Services d'utilités publiques - Installation", label_en : "Utilities - Installation"},
        { value: '57', label: "Services d'utilités publiques - Réfection/Remplacement", label_fr: "Services d'utilités publiques - Réfection/Remplacement", label_en : "Utilities - Rehabilitation/Replacement"},
        { value: '58', label: "Signalisation - Installation", label_fr: "Signalisation - Installation", label_en : "Signage - Installation"},
        { value: '59', label: "Signalisation – Réfection", label_fr: "Signalisation – Réfection", label_en : "Signage – Repairs"},
        { value: '60', label: "Poste de chloration d’eau potable - Entretien", label_fr: "Poste de chloration d’eau potable - Entretien", label_en : "Drinking water chlorination station - Maintenance"},
        { value: '61', label: "Poste de chloration d'eau potable - Rénovation", label_fr: "Poste de chloration d'eau potable - Rénovation", label_en : "Drinking water chlorination station - Renovation"},
        { value: '62', label: "Évènement", label_fr: "Évènement", label_en : "Event"},
        { value: '63', label: 'Évènements municipaux', label_fr: 'Évènements municipaux', label_en : 'Municipal events'},
        { value: '64', label: 'Évènements citoyens – Fêtes de rue', label_fr: 'Évènements citoyens – Fêtes de rue', label_en : 'Citizen events – Street parties'},
        { value: '65', label: 'Évènement – Organismes caritatifs', label_fr: 'Évènement – Organismes caritatifs', label_en : 'Event – Charities'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Entrave du domaine public", label_fr: "Entrave du domaine public", label_en : "Obstruction of the public domain"},
        { value: '2', label: "Utilisation de borne-fontaine", label_fr: "Utilisation de borne-fontaine", label_en : "Use of fire hydrant"},
        { value: '3', label: "Permis de tranchée", label_fr: "Permis de tranchée", label_en : "Trench permit"},
        { value: '4', label: "Urbanisme", label_fr: "Urbanisme", label_en : "Town planning"},
        { value: '5', label: "Permis de déplacement de poteau ou lampadaire", label_fr: "Permis de déplacement de poteau ou lampadaire", label_en : "Permit to move pole or lamp post"},
        { value: '6', label: "Permis de branchement d'égout et d'eau potable", label_fr: "Permis de branchement d'égout et d'eau potable", label_en : "Sewer and drinking water connection permit"},
        { value: '7', label: "Permis de rejet à l'égout municipal", label_fr: "Permis de rejet à l'égout municipal", label_en : "Municipal Sewer Discharge Permit"},
        { value: '8', label: "Non-requis (projet ville)", label_fr: "Non-requis (projet ville)", label_en : "Not required (city project)"},
        { value: '9', label: "Non-requis - approbation DG", label_fr: "Non-requis - approbation DG", label_en : "Not required - DG approval"},
      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager", label_en:"Passenger type"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: true,
      showInList: false,
      search: false,
      value: [
        {value:'type1', status:false, content: '', label:'Local ou usager du site', label_fr:'Local ou usager du site', label_en:'Local or site user'},
        {value:'type2', status:false, content: '', label:'Automobiliste', label_fr:'Automobiliste', label_en:'Driver'},
        {value:'type3', status:false, content: '', label:'Cycliste', label_fr:'Cycliste', label_en:'Cyclist'},
        {value:'type4', status:false, content: '', label:'Piéton', label_fr:'Piéton', label_en:'Pedestrian'},
        {value:'type5', status:false, content: '', label:'Transport collectif', label_fr:'Transport collectif', label_en:'Public transport'},
        {value:'type6', status:false, content: '', label:"Accessibilité véhicules d'urgence", label_fr:"Accessibilité véhicules d'urgence", label_en:'Emergency vehicles accessibility'},
        {value:'type7', status:false, content: '', label:'Accessibilité personnes à mobilité réduite', label_fr:'Accessibilité personnes à mobilité réduite', label_en:'Accessibility for people with reduced mobility'}
      ]
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"}, label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: true,
      value: [
        { value: "", label: 'Select Mail List', label_en: 'Select Mail List', label_fr: 'Sélectionnez la liste de diffusion'},
        { value: "Luc.Perreault@ville.mont-royal.qc.ca, Bruno.Passarelli@ville.mont-royal.qc.ca,Richard.Duguay@ville.mont-royal.qc.ca, alexandre.fortin-caillauld@ville.mont-royal.qc.ca ,jose.forget@ville.mont-royal.qc.ca , Saad.Safi@ville.mont-royal.qc.ca, Nathalie.Dufresne@ville.mont-royal.qc.ca , Karine.Jean@ville.mont-royal.qc.ca , Ramia.Ghantous@ville.mont-royal.qc.ca ,Edison.Ramirez@ville.mont-royal.qc.ca , dispatch@ville.mont-royal.qc.ca , Ian.Mainville@ville.mont-royal.qc.ca , Andrew.Milukow@ville.mont-royal.qc.ca , Daniel.Lepore@ville.mont-royal.qc.ca ,Benoit.Richer@ville.mont-royal.qc.ca , Vincent.Gasparini@ville.mont-royal.qc.ca ,Valerie.Petit@ville.mont-royal.qc.ca , reception.th@ville.mont-royal.qc.ca , Alain.Cote@ville.mont-royal.qc.ca , servicestechniques@ville.mont-royal.qc.ca , Ginette.Leclair@ville.mont-royal.qc.ca , jean-nicolas.thibodeau@ville.mont-royal.qc.ca , Ann-Lise.Annoual@ville.mont-royal.qc.ca ,Roxane.Laroche-Paquet@ville.mont-royal.qc.ca , hala.gebrine@ville.mont-royal.qc.ca,mike.nelli@ville.mont-royal.qc.ca,yan.fortin@ville.mont-royal.qc.ca,Tanya.Antebi@ville.mont-royal.qc.ca,Marie-Claude.Parent@ville.mont-royal.qc.ca, May.El-Azzi@ville.mont-royal.qc.ca", label: 'Info-worksVMR', label_en: 'Info-worksVMR', label_fr: 'Info-travauxVMR'},
        { value: "Luc.Perreault@ville.mont-royal.qc.ca, Bruno.Passarelli@ville.mont-royal.qc.ca,Richard.Duguay@ville.mont-royal.qc.ca, alexandre.fortin-caillauld@ville.mont-royal.qc.ca ,jose.forget@ville.mont-royal.qc.ca , Saad.Safi@ville.mont-royal.qc.ca, Nathalie.Dufresne@ville.mont-royal.qc.ca , Edison.Ramirez@ville.mont-royal.qc.ca , dispatch@ville.mont-royal.qc.ca ,Ian.Mainville@ville.mont-royal.qc.ca , Andrew.Milukow@ville.mont-royal.qc.ca ,Daniel.Lepore@ville.mont-royal.qc.ca , Benoit.Richer@ville.mont-royal.qc.ca, hala.gebrine@ville.mont-royal.qc.ca, mike.nelli@ville.mont-royal.qc.ca, servicestechniques@ville.mont-royal.qc.ca ,Ginette.Leclair@ville.mont-royal.qc.ca , jean-nicolas.thibodeau@ville.mont-royal.qc.ca , Ann-Lise.Annoual@ville.mont-royal.qc.ca,yan.fortin@ville.mont-royal.qc.ca", label: 'Info-permit', label_en: 'Info-permit', label_fr: 'Info-permis'},
      ],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: true,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: true,
      showInList: false,
      value: []
    }
  },
  mississauga: {
    district:{
      key: 'district',
      title: {label: 'District', label_fr:'District', label_en:'District'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'District 1' , label_fr: 'District 1' , label_en: 'District 1' },
        { value: '2', label: 'District 2' , label_fr: 'District 2' , label_en: 'District 2' },
        { value: '3', label: 'District 3' , label_fr: 'District 3' , label_en: 'District 3' },
        { value: '4', label: 'District 4' , label_fr: 'District 4' , label_en: 'District 4' },
        { value: '5', label: 'District 5' , label_fr: 'District 5' , label_en: 'District 5' },
        { value: '6', label: 'District 6' , label_fr: 'District 6' , label_en: 'District 6' },
        { value: '7', label: 'District 7' , label_fr: 'District 7' , label_en: 'District 7' },
        { value: '8', label: 'District 8' , label_fr: 'District 8' , label_en: 'District 8' },
        { value: '9', label: 'District 9' , label_fr: 'District 9' , label_en: 'District 9' },
        { value: '10', label: 'District 10' , label_fr: 'District 10' , label_en: 'District 10' },
        { value: '11', label: 'District 11' , label_fr: 'District 11' , label_en: 'District 11' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'Saint-Jean', label_fr: 'Saint-Jean', label_en: 'Saint-Jean'},
        { value: '2', label: 'Saint-Luc', label_fr: 'Saint-Luc', label_en: 'Saint-Luc'},
        { value: '3', label: "L'Acadie", label_fr: "L'Acadie", label_en: "L'Acadie"},
        { value: '4', label: 'Iberville', label_fr: 'Iberville', label_en: 'Iberville'},
        { value: '5', label: 'Saint-Athanase', label_fr: 'Saint-Athanase', label_en: 'Saint-Athanase'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Ville - Infrastructures',label_fr: 'Ville - Infrastructures',label_en: 'City - Infrastructure', class: 'gat-orange-light', class1: 'gat-orange-light'},
        { value: '2', label: 'Ville - TP',label_fr: 'Ville - Travaux Publics',label_en: 'City - Public Works', class: 'gat-orange-light', class1: 'gat-orange-light'},
        { value: '6', label: 'Événements',label_fr: 'Événements',label_en: 'Events', class: 'gat-orange-light2', class1: 'gat-orange-light2'},
        { value: '3', label: 'Utilitaires',label_fr: 'Utilitaires',label_en: 'Utilities', class: 'gat-blue-dark', class1: 'gat-blue-dark'},
        { value: '4', label: 'MTO',label_fr: 'MTO',label_en: 'MTO', class: 'gat-orange-light', class1: 'gat-orange-light'},
        { value: '7', label: "Mesures d'urgence",label_fr: "Mesures d'urgence",label_en: "Emergency measures", class: 'gat-red-light', class1: 'gat-red-light'},
        { value: '10', label: 'Administration ferroviaire',label_fr: 'Administration ferroviaire',label_en: 'Railway administration', class: 'gat-green-dark', class1: 'gat-green-dark'},
        { value: '8', label: 'Autres',label_fr: 'Autres',label_en: 'Other', class: 'gat-orange-light', class1: 'gat-orange-light'}
      ],
      classes: {
        'gat-orange-light':'#ED732E',
        'gat-orange-light2':'#F4B754',
        'gat-green3':'#316f7b',
        'gat-green-dark':'#32CD32',
        'gat-blue-dark':'#1C02DE',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-green-light':'#84B938',
        'gat-pink-light':'#ee7deb',
        'gat-red-light':'#E8464A',
        'gat-purple2':'#AA91E4',
        'gat-blue3':'#0E4095',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
        'gat-jaune': '#2E6B4D'
      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Entrave partielle', label_fr:'Entrave partielle', label_en:'Partial obstruction'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajets ou arrêt de la ST', label_fr:'Trajets ou arrêt de la ST', label_en:'Routes or bus stops'},
        {value:'9', status:false, content:'', label:'Corridor scolaire entravé', label_fr:'Corridor scolaire entravé', label_en:'Blocked school corridor'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'}
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Interdiction de stationnement - complète", label_fr: "Interdiction de stationnement - complète", label_en : "Parking interdiction - complete"},
        { value: '2',  label: "Interdiction de stationnement - partielle", label_fr: "Interdiction de stationnement - partielle", label_en : "Parking interdiction - partial"},
      ]
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Aménagement paysager", label_fr: "Aménagement paysager", label_en : "Landscaping"},
        { value: '2',  label: "Arbres - Abattage ou élagage", label_fr: "Arbres - Abattage ou élagage", label_en : "Trees - Felling or Pruning"},
        { value: '56',  label: "Travaux bâtiment", label_fr: "Travaux bâtiment", label_en : "Building work"},
        { value: '57',  label: "Branchement de services (Entretien ou Installation)", label_fr: "Branchement de services (Entretien ou Installation)", label_en : "Service connections (Maintenance or installation)"}, 
        { value: '58', label: "Feux de circulation (Entretien ou installation)", label_fr: "Feux de circulation (Entretien ou installation)", label_en : "Traffic lights (Maintenance or installation)"},
        { value: '13', label: "Forage", label_fr: "Forage", label_en : "Drilling"},
        { value: '59', label: "Fossés (Travaux divers)", label_fr: "Fossés (Travaux divers)", label_en : "Ditch (Miscellaneous work)"}, 
        { value: '60', label: "Ouvrage de béton", label_fr: "Ouvrage de béton", label_en : "Concrete structure"},
        { value: '61', label: "Parc municipal - Divers travaux", label_fr: "Parc municipal - Divers travaux", label_en : "Municipal Park (Miscellaneous work)"}, 
        { value: '62', label: "Ponceau (Entretien ou installation)", label_fr: "Ponceau (Entretien ou installation)", label_en : "Culvert (Maintenance or installation)"},
        { value: '27', label: "Pont - Inspection", label_fr: "Pont - Inspection", label_en : "Birdge - Inspection"},
        { value: '28', label: "Pont - Réfection", label_fr: "Pont - Réfection", label_en : "Bridge - Refurbishment"},
        { value: '63', label: "Réseau routier (Entretien divers)", label_fr: "Réseau routier (Entretien divers)", label_en : "Road network (Miscellaneous maintenance)"},
        { value: '31', label: "Bris infrastructures souterrainnes (Égoût, aqueduc, etc)", label_fr: "Bris infrastructures souterrainnes (Égoût, aqueduc, etc)", label_en : "Underground infrastructure breakage (Sewerage, aqueduct, etc)"}, 
        { value: '64', label: "Infrastructures souterraines (Égout, aqueduc, etc.) - Travaux divers", label_fr: "Infrastructures souterraines (Égout, aqueduc, etc.) - Travaux divers", label_en : "Underground infrastructure (Sewerage, aqueduct, etc) - Miscellaneous work"},
        { value: '65', label: "Réseau routier (Construction, réfection et travaux connexes)", label_fr: "Réseau routier (Construction, réfection et travaux connexes)", label_en : "Road network (Construction, refurbishment and related works)"},
        { value: '66', label: "Sentier récréatif", label_fr: "Sentier récréatif", label_en : "Recreational Trail"},
        { value: '67', label: "Service d'utilités publiques", label_fr: "Service d'utilités publiques", label_en : "Public Utilities Department"},
        { value: '68', label: "Autre", label_fr: "Autre", label_en : "Other"},
        { value: '69', label: "Station de suspention ou de pompage", label_fr: "Station de suspention ou de pompage", label_en : "Booster or pumping station"},
        { value: '53', label: "Usine d’eau potable - travaux", label_fr: "Usine d’eau potable - travaux", label_en : "Potable water plant - Works"},
        { value: '55', label: "Usine d'épuration - Travaux", label_fr: "Usine d'épuration - Travaux", label_en : 'Wastewater treatment plant - Works'},
        { value: '54', label: 'Événement', label_fr: 'Événement', label_en : 'Event'}
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'Permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Entrave", label_fr: "Entrave", label_en : "Obstacles"},
        { value: '2', label: "Occupation du domaine public", label_fr: "Occupation du domaine public", label_en : "Public domain occupation"},
        // { value: '3', label: "Partenaires", label_fr: "Partenaires", label_en : "Partners"},
        // { value: '5', label: "Résolution ou approbation CS", label_fr: "Résolution ou approbation CS", label_en : "Resolution or approval CS"},
        { value: '6', label: "Consentement RTU", label_fr: "Consentement RTU", label_en : "Agreement RTU"},
        { value: '7', label: "Autre", label_fr: "Autre", label_en : "Other"},
        { value: '4', label: "Non requis", label_fr: "Non requis", label_en : "Not required"},

      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Distribution par", label_fr:"Distribution par", label_en:'Distribution by'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    dista: {
      key: 'dista',
      title: {label: "Distribution à", label_fr:"Distribution à", label_en:'Distribution to'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager(ne pas utiliser)", label_en:"Passenger type(do not use)"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service des infrastructures", label_fr:"Service des infrastructures", label_en:'Infrastructure Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: true,
      value: [
        { value: "harsh@opatechnologies.com,melissa@opatechnologies.com,caroline@opatechnologies.com", label: 'Test List', label_en: 'Test List OPA', label_fr: 'Test List OPA'}
      ],
    },
    departments: {
      key: 'departments',
      title: {label: "departments", label_fr:"Départements", label_en:'Departments'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  admtl: {
    district:{
      key: 'district',
      title: {label: 'District', label_fr:'District', label_en:'District'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'District 1' },
        { value: '2', label: 'District 2' },
        { value: '3', label: 'District 3' },
        { value: '4', label: 'District 4' },
        { value: '5', label: 'District 5' },
        { value: '6', label: 'District 6' },
        { value: '7', label: 'District 7' },
        { value: '8', label: 'District 8' },
        { value: '9', label: 'District 9' },
        { value: '10', label: 'District 10' },
        { value: '11', label: 'District 11' },
        { value: '12', label: 'District 12' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'Aylmer', label_fr: 'Aylmer', label_en: 'Aylmer'},
        { value: '2', label: 'Buckingham', label_fr: 'Buckingham', label_en: 'Buckingham'},
        { value: '3', label: 'Gatineau', label_fr: 'Gatineau', label_en: 'Gatineau'},
        { value: '4', label: 'Hull', label_fr: 'Hull', label_en: 'Hull'},
        { value: '5', label: 'Masson-Angers', label_fr: 'Masson-Angers', label_en: 'Masson-Angers'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        {value:'1', label:'Opération', label_fr:'Opération', label_en:'Operation' ,class: 'gat-orange-light', class1: 'gat-orange-light'},
        {value:'2', label:'Stationnement - Transport au sol', label_fr:'Stationnement - Transport au sol', label_en:'Parking - Ground transportation' ,class: 'gat-orange-light2', class1: 'gat-orange-light2'},
        {value:'3', label:'Sûreté et lutte contre les incendies', label_fr:'Sûreté et lutte contre les incendies', label_en:'Safety and firefighting' ,class: 'gat-green3', class1: 'gat-green3'},
        {value:'4', label:'Commercial', label_fr:'Commercial', label_en:'Commercial' ,class: 'gat-green-dark', class1: 'gat-green-dark'},
        {value:'5', label:'Architecture', label_fr:'Architecture', label_en:'Architecture' ,class: 'gat-blue1', class1: 'gat-blue1'},
        {value:'6', label:'Infrastructure aéroportuaires', label_fr:'Infrastructure aéroportuaires', label_en:'Airport infrastructure' ,class: 'gat-purple', class1: 'gat-purple'},
        {value:'7', label:'REM', label_fr:'REM', label_en:'REM' ,class: 'gat-purple1', class1: 'gat-purple1'},
        {value:'8', label:'Développement durable', label_fr:'Développement durable', label_en:'Sustainable development' ,class: 'gat-green-light', class1: 'gat-green-light'},
        {value:'9', label:'Développement immobilier', label_fr:'Développement immobilier', label_en:'Real estate development' ,class: 'gat-pink-light', class1: 'gat-pink-light'},
        {value:'10', label:'Entretien air', label_fr:'Entretien air', label_en:'Air maintenance' ,class: 'gat-purple2', class1: 'gat-purple2'},
        {value:'11', label:'Expérience client et marketing', label_fr:'Expérience client et marketing', label_en:'Customer experience and marketing' ,class: 'gat-blue3', class1: 'gat-blue3'},
        {value:'12', label:"Mesures d'urgence et excellence opérationnelle", label_fr:"Mesures d'urgence et excellence opérationnelle", label_en:'Emergency measures and operational excellence' ,class: 'gat-lightred', class1: 'gat-lightred'},
        {value:'13', label:"Opérations air", label_fr:"Opérations air", label_en:'Air operations' ,class: 'gat-rose', class1: 'gat-rose'},
        {value:'14', label:'Planification aéroportuaire', label_fr:'Planification aéroportuaire', label_en:'Airport planning' ,class: 'gat-mauve', class1: 'gat-mauve'},
        {value:'15', label:"Planification et services à l'entretien", label_fr:"Planification et services à l'entretien", label_en:'Planning and maintenance services' ,class: 'gat-turquois', class1: 'gat-turquois'},
        {value:'16', label:'Planification opérations air', label_fr:'Planification opérations air', label_en:'Air operations planning' ,class: 'gat-jaune', class1: 'gat-jaune'},
        {value:'17', label:'TI', label_fr:'TI', label_en:'TI' ,class: 'gat-orange-light', class1: 'gat-orange-light'},
        {value:'18', label:'Cycle de vie', label_fr:'Cycle de vie', label_en:'Life cycle' ,class: 'gat-red-light', class1: 'gat-red-light'},
      ],
      classes: {
        'gat-orange-light':'#ED732E',
        'gat-orange-light2':'#F4B754',
        'gat-green3':'#316f7b',
        'gat-green-dark':'#2E6B4D',
        'gat-blue1':'#01b0f0',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-green-light':'#84B938',
        'gat-pink-light':'#ee7deb',
        'gat-red-light':'#E8464A',
        'gat-purple2':'#AA91E4',
        'gat-blue3':'#0E4095',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
        'gat-jaune': '#a18200'
      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '9', label: 'Urgence', label_fr: 'Urgence', label_en : 'Emergency'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Fermeture partielle', label_fr:'Fermeture partielle', label_en:'Partial closure'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'13', status:false, content:'', label:'Trajet de la sureté', label_fr:'Trajet de la sureté', label_en:'Safety route '},
        {value:'14', status:false, content:'', label:'Arrêt de la sureté', label_fr:'Arrêt de la sureté', label_en:'Safety stop'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajets ou arrêt de la ST ', label_fr:'Trajets ou arrêt de la ST ', label_en:'Routes or Stops ST'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'},
        {value:'16', status:false, content:'', label:'Plan de signalisation', label_fr:'Plan de signalisation', label_en:'Signaling plan'},
        {value:'15', status:false, content:'', label:'Entrave ponctuelle', label_fr:'Entrave ponctuelle', label_en:'One-off hindrance'},
        {value:'17', status:false, content:'', label:'Fermeture de nuit', label_fr:'Fermeture de nuit', label_en:'Night closing'},
        {value:'18', status:false, content:'', label:'Transit bagages – Capacité réduite', label_fr:'Transit bagages – Capacité réduite', label_en:'Baggage transit – Reduced capacity'},
        {value:'19', status:false, content:'', label:'Interruption d’eau potable', label_fr:'Interruption d’eau potable', label_en:'Interruption of potable water'},
        {value:'20', status:false, content:'', label:'Interruption électricité', label_fr:'Interruption électricité', label_en:'Power outage'},
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Service Impacté", label_fr:"Service Impacté", label_en:'Service Impacted'},
      show: true,
      showInList: true,
      search: false,
      value: [
        { value: '1', label: "Air", label_fr: "Air", label_en : "Air"},
        { value: '2', label: "Aérogare", label_fr: "Aérogare", label_en : "Airport"},
        { value: '3', label: "Navette", label_fr: "Navette", label_en : "Shuttle"},
        { value: '4', label: "Débarcadères arrivées", label_fr: "Débarcadères arrivées", label_en : "Arrivals landing stages"},
        { value: '5', label: "Débarcadères départs", label_fr: "Débarcadères départs", label_en : "Departures landing stages"},
        { value: '6', label: "Débarcadères professionnels", label_fr: "Débarcadères professionnels", label_en : "Professional landing stages"},
        { value: '7', label: "Débarcadères express", label_fr: "Débarcadères express", label_en : "Express landing stages"},
        { value: '8', label: "Stationnement", label_fr: "Stationnement", label_en : "Parking"},
        { value: '9', label: "Cité de Dorval", label_fr: "Cité de Dorval", label_en : "City of Dorval"},

      ]
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Signalisation", label_fr: "Signalisation", label_en : "Signaling"},
        { value: '2',  label: "Signalétique", label_fr: "Signalétique", label_en : "Signaling"},
        { value: '3',  label: "Marquage", label_fr: "Marquage", label_en : "Marking"},
        { value: '4',  label: "Réfection surface", label_fr: "Réfection surface", label_en : "Surface repair"},
        { value: '5',  label: "Réfection profondeur", label_fr: "Réfection profondeur", label_en : "Depth repair"},
        { value: '6',  label: "Réaménagement", label_fr: "Réaménagement", label_en : "Redevelopment"},
        { value: '7',  label: "Verdissement", label_fr: "Verdissement", label_en : "Greening"},
        { value: '8',  label: "Entretien léger", label_fr: "Entretien léger", label_en : "Light maintenance"},
        { value: '9',  label: "Réparation", label_fr: "Réparation", label_en : "Repair"},
        { value: '10',  label: "Travaux mineurs", label_fr: "Travaux mineurs", label_en : "Minor works"},
        { value: '11',  label: "Maintien", label_fr: "Maintien", label_en : "Maintenance"},
        { value: '12',  label: "Électrique", label_fr: "Électrique", label_en : "Electrical"},
        { value: '13',  label: "Télécom", label_fr: "Télécom", label_en : "Telecom"},
        { value: '14',  label: "Équipement", label_fr: "Équipement", label_en : "Equipment"},
       
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'Permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Permis ATH", label_fr: "Permis ATH", label_en : "ATH permit"},
        { value: '2', label: "Permis ATZR", label_fr: "Permis ATZR", label_en : "ATZR permit"},
        { value: '3', label: "LandUse", label_fr: "LandUse", label_en : "LandUse"},
      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Site", label_fr:"Site", label_en:'Site'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Dorval", label_fr: "Dorval", label_en : "Dorval"},
        { value: '2', label: "Mirabel", label_fr: "Mirabel", label_en : "Mirabel"},
      ]
    },
    dista: {
      key: 'dista',
      title: {label: "Client", label_fr:"Client", label_en:'Client'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager(ne pas utiliser)", label_en:"Passenger type(do not use)"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service Impacté", label_fr:"Service Impacté", label_en:'Service Impacted'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
  admtldev: {
    district:{
      key: 'district',
      title: {label: 'District', label_fr:'District', label_en:'District'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'District 1' },
        { value: '2', label: 'District 2' },
        { value: '3', label: 'District 3' },
        { value: '4', label: 'District 4' },
        { value: '5', label: 'District 5' },
        { value: '6', label: 'District 6' },
        { value: '7', label: 'District 7' },
        { value: '8', label: 'District 8' },
        { value: '9', label: 'District 9' },
        { value: '10', label: 'District 10' },
        { value: '11', label: 'District 11' },
        { value: '12', label: 'District 12' }
      ]
    },
    city:{
      key: 'city',
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    sector: {
      key: 'sector',
      title: {label: "Secteur", label_fr:"Secteur", label_en:'Sector'},
      show: false,
      showInList: false,
      search: false,
      value: [
        { value: '1', label: 'Aylmer', label_fr: 'Aylmer', label_en: 'Aylmer'},
        { value: '2', label: 'Buckingham', label_fr: 'Buckingham', label_en: 'Buckingham'},
        { value: '3', label: 'Gatineau', label_fr: 'Gatineau', label_en: 'Gatineau'},
        { value: '4', label: 'Hull', label_fr: 'Hull', label_en: 'Hull'},
        { value: '5', label: 'Masson-Angers', label_fr: 'Masson-Angers', label_en: 'Masson-Angers'}
      ]
    },
    entity:{
      key: 'entity',
      title: {label: "Entité", label_fr:"Entité", label_en:'Entity'},
      show: true,
      showInList: true,
      search: true,
      value: [
        {value:'1', label:'Opération', label_fr:'Opération', label_en:'Operation' ,class: 'gat-orange-light', class1: 'gat-orange-light'},
        {value:'2', label:'Stationnement - Transport au sol', label_fr:'Stationnement - Transport au sol', label_en:'Parking - Ground transportation' ,class: 'gat-orange-light2', class1: 'gat-orange-light2'},
        {value:'3', label:'Sûreté et lutte contre les incendies', label_fr:'Sûreté et lutte contre les incendies', label_en:'Safety and firefighting' ,class: 'gat-green3', class1: 'gat-green3'},
        {value:'4', label:'Commercial', label_fr:'Commercial', label_en:'Commercial' ,class: 'gat-green-dark', class1: 'gat-green-dark'},
        {value:'5', label:'Architecture', label_fr:'Architecture', label_en:'Architecture' ,class: 'gat-blue1', class1: 'gat-blue1'},
        {value:'6', label:'Infrastructure aéroportuaires', label_fr:'Infrastructure aéroportuaires', label_en:'Airport infrastructure' ,class: 'gat-purple', class1: 'gat-purple'},
        {value:'7', label:'REM', label_fr:'REM', label_en:'REM' ,class: 'gat-purple1', class1: 'gat-purple1'},
        {value:'8', label:'Développement durable', label_fr:'Développement durable', label_en:'Sustainable development' ,class: 'gat-green-light', class1: 'gat-green-light'},
        {value:'9', label:'Développement immobilier', label_fr:'Développement immobilier', label_en:'Real estate development' ,class: 'gat-pink-light', class1: 'gat-pink-light'},
        {value:'10', label:'Entretien air', label_fr:'Entretien air', label_en:'Air maintenance' ,class: 'gat-purple2', class1: 'gat-purple2'},
        {value:'11', label:'Expérience client et marketing', label_fr:'Expérience client et marketing', label_en:'Customer experience and marketing' ,class: 'gat-blue3', class1: 'gat-blue3'},
        {value:'12', label:"Mesures d'urgence et excellence opérationnelle", label_fr:"Mesures d'urgence et excellence opérationnelle", label_en:'Emergency measures and operational excellence' ,class: 'gat-lightred', class1: 'gat-lightred'},
        {value:'13', label:"Opérations air", label_fr:"Opérations air", label_en:'Air operations' ,class: 'gat-rose', class1: 'gat-rose'},
        {value:'14', label:'Planification aéroportuaire', label_fr:'Planification aéroportuaire', label_en:'Airport planning' ,class: 'gat-mauve', class1: 'gat-mauve'},
        {value:'15', label:"Planification et services à l'entretien", label_fr:"Planification et services à l'entretien", label_en:'Planning and maintenance services' ,class: 'gat-turquois', class1: 'gat-turquois'},
        {value:'16', label:'Planification opérations air', label_fr:'Planification opérations air', label_en:'Air operations planning' ,class: 'gat-jaune', class1: 'gat-jaune'},
        {value:'17', label:'TI', label_fr:'TI', label_en:'TI' ,class: 'gat-orange-light', class1: 'gat-orange-light'},
        {value:'18', label:'Cycle de vie', label_fr:'Cycle de vie', label_en:'Life cycle' ,class: 'gat-red-light', class1: 'gat-red-light'},
      ],
      classes: {
        'gat-orange-light':'#ED732E',
        'gat-orange-light2':'#F4B754',
        'gat-green3':'#316f7b',
        'gat-green-dark':'#2E6B4D',
        'gat-blue1':'#01b0f0',
        'gat-purple':'#7030a0',
        'gat-purple1':'#5b2e8f',
        'gat-green-light':'#84B938',
        'gat-pink-light':'#ee7deb',
        'gat-red-light':'#E8464A',
        'gat-purple2':'#AA91E4',
        'gat-blue3':'#0E4095',
        'gat-lightred':'#FFCCCB',
        'gat-rose':'#FF007F',
        'gat-mauve':'#BB85AB',
        'gat-turquois':'#30D5C8',
        'gat-jaune': '#a18200'
      },
      legendImg: false
    },
    step: {
      key: 'step',
      title: {label: "Étape du projet", label_fr:"Étape du projet", label_en:'Project phase'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Planifié', label_fr: 'Planifié', label_en : 'Planned'},
        { value: '2', label: 'En-cours', label_fr: 'En-cours', label_en : 'In progress'},
        { value: '3', label: 'Complété', label_fr: 'Complété', label_en : 'Completed'},
        { value: '7', label: 'Terminé', label_fr: 'Terminé', label_en : 'Finished'},
        { value: '9', label: 'Urgence', label_fr: 'Urgence', label_en : 'Emergency'},
        { value: '8', label: 'Annulé', label_fr: 'Annulé', label_en : 'Cancelled'}
      ]
    },
    trafficImpact: {
      key: 'trafficImpact',
      title: {label: "Impacts - Gestion de la circulation", label_fr:"Impacts - Gestion de la circulation", label_en:'Impacts - Traffic Management'},
      title1: {label: "Impacts - Précisions", label_fr:"Impacts - Précisions", label_en:'Impacts - Details'},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value:'1', status:false, content:'', label:'Fermeture complète', label_fr:'Fermeture complète', label_en:'Complete closure'},
        {value:'2', status:false, content:'', label:'Fermeture partielle', label_fr:'Fermeture partielle', label_en:'Partial closure'},
        {value:'3', status:false, content:'', label:'Circulation locale seulement', label_fr:'Circulation locale seulement', label_en:'Local Traffic only'},
        {value:'4', status:false, content:'', label:'Modification de stationnement', label_fr:'Modification de stationnement', label_en:'Parking modification'},
        {value:'5', status:false, content:'', label:'Trajet de détour', label_fr:'Trajet de détour', label_en:'Detour route'},
        {value:'13', status:false, content:'', label:'Trajet de la sureté', label_fr:'Trajet de la sureté', label_en:'Safety route '},
        {value:'14', status:false, content:'', label:'Arrêt de la sureté', label_fr:'Arrêt de la sureté', label_en:'Safety stop'},
        {value:'6', status:false, content:'', label:'Présence de signaleurs', label_fr:'Présence de signaleurs', label_en:'Presence of flaggers'},
        {value:'7', status:false, content:'', label:'Accès aux commerces maintenu', label_fr:'Accès aux commerces maintenu', label_en:'Access to shops maintained'},
        {value:'8', status:false, content:'', label:'Trajets ou arrêt de la ST ', label_fr:'Trajets ou arrêt de la ST ', label_en:'Routes or Stops ST'},
        {value:'10', status:false, content:'', label:'Réduction de la vitesse légale', label_fr:'Réduction de la vitesse légale', label_en:'Reduced legal speed limit'},
        {value:'11', status:false, content:'', label:'Fermeture de trottoir', label_fr:'Fermeture de trottoir', label_en:'Closure of sidewalk'},
        {value:'12', status:false, content:'', label:'Fermeture de piste cyclable', label_fr:'Fermeture de piste cyclable', label_en:'Closure of cycling path'},
        {value:'16', status:false, content:'', label:'Plan de signalisation', label_fr:'Plan de signalisation', label_en:'Signaling plan'},
        {value:'15', status:false, content:'', label:'Entrave ponctuelle', label_fr:'Entrave ponctuelle', label_en:'One-off hindrance'},
        {value:'17', status:false, content:'', label:'Fermeture de nuit', label_fr:'Fermeture de nuit', label_en:'Night closing'},
        {value:'18', status:false, content:'', label:'Transit bagages – Capacité réduite', label_fr:'Transit bagages – Capacité réduite', label_en:'Baggage transit – Reduced capacity'},
        {value:'19', status:false, content:'', label:'Interruption d’eau potable', label_fr:'Interruption d’eau potable', label_en:'Interruption of potable water'},
        {value:'20', status:false, content:'', label:'Interruption électricité', label_fr:'Interruption électricité', label_en:'Power outage'},
      ]
    },
    portableWater: {
      key: 'portableWater',
      title: {label: "Impacts - Alimentation en eau potable", label_fr:"Impacts - Alimentation en eau potable", label_en:'Impacts - Potable water supply'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    workCategory: {
      key: 'workCategory',
      title: {label: "Service Impacté", label_fr:"Service Impacté", label_en:'Service Impacted'},
      show: true,
      showInList: true,
      search: false,
      value: [
        { value: '1', label: "Air", label_fr: "Air", label_en : "Air"},
        { value: '2', label: "Aérogare", label_fr: "Aérogare", label_en : "Airport"},
        { value: '3', label: "Navette", label_fr: "Navette", label_en : "Shuttle"},
        { value: '4', label: "Débarcadères arrivées", label_fr: "Débarcadères arrivées", label_en : "Arrivals landing stages"},
        { value: '5', label: "Débarcadères départs", label_fr: "Débarcadères départs", label_en : "Departures landing stages"},
        { value: '6', label: "Débarcadères professionnels", label_fr: "Débarcadères professionnels", label_en : "Professional landing stages"},
        { value: '7', label: "Débarcadères express", label_fr: "Débarcadères express", label_en : "Express landing stages"},
        { value: '8', label: "Stationnement", label_fr: "Stationnement", label_en : "Parking"},
        { value: '9', label: "Cité de Dorval", label_fr: "Cité de Dorval", label_en : "City of Dorval"},

      ]
    },
    status: {
      key: 'status',
      title: {label: "Statut projet", label_fr:"Statut projet", label_en:'Projet status'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: 'Approved', label_fr: 'Approuvé', label_en: 'Approved'},
        { value: '2', label: 'In-approval', label_fr: 'En-approbation', label_en: 'In-approval'},
        { value: '3', label: 'Not approved', label_fr: 'Non-approuvé', label_en: 'Not approved'}
      ]
    },
    workType1: {
      key: 'workType1',
      title: {label: "Catégorie de travaux", label_fr:"Catégorie de travaux", label_en:'Category of work'},
      show: true,
      showInList: false,
      search: false,
      value: [
        { value: '1',  label: "Signalisation", label_fr: "Signalisation", label_en : "Signaling"},
        { value: '2',  label: "Signalétique", label_fr: "Signalétique", label_en : "Signaling"},
        { value: '3',  label: "Marquage", label_fr: "Marquage", label_en : "Marking"},
        { value: '4',  label: "Réfection surface", label_fr: "Réfection surface", label_en : "Surface repair"},
        { value: '5',  label: "Réfection profondeur", label_fr: "Réfection profondeur", label_en : "Depth repair"},
        { value: '6',  label: "Réaménagement", label_fr: "Réaménagement", label_en : "Redevelopment"},
        { value: '7',  label: "Verdissement", label_fr: "Verdissement", label_en : "Greening"},
        { value: '8',  label: "Entretien léger", label_fr: "Entretien léger", label_en : "Light maintenance"},
        { value: '9',  label: "Réparation", label_fr: "Réparation", label_en : "Repair"},
        { value: '10',  label: "Travaux mineurs", label_fr: "Travaux mineurs", label_en : "Minor works"},
        { value: '11',  label: "Maintien", label_fr: "Maintien", label_en : "Maintenance"},
        { value: '12',  label: "Électrique", label_fr: "Électrique", label_en : "Electrical"},
        { value: '13',  label: "Télécom", label_fr: "Télécom", label_en : "Telecom"},
        { value: '14',  label: "Équipement", label_fr: "Équipement", label_en : "Equipment"},
       
      ]
    },
    workType: {
      key: 'workType',
      title: {label: "Type de permis", label_fr:"Type de permis", label_en:'Permit type'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Permis ATH", label_fr: "Permis ATH", label_en : "ATH permit"},
        { value: '2', label: "Permis ATZR", label_fr: "Permis ATZR", label_en : "ATZR permit"},
        { value: '3', label: "LandUse", label_fr: "LandUse", label_en : "LandUse"},
      ]
    },
    distp: {
      key: 'distp',
      title: {label: "Site", label_fr:"Site", label_en:'Site'},
      show: true,
      showInList: true,
      search: true,
      value: [
        { value: '1', label: "Dorval", label_fr: "Dorval", label_en : "Dorval"},
        { value: '2', label: "Mirabel", label_fr: "Mirabel", label_en : "Mirabel"},
      ]
    },
    dista: {
      key: 'dista',
      title: {label: "Client", label_fr:"Client", label_en:'Client'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    passanger: {
      key: 'passanger',
      title: {label: "Type d'usager", label_fr:"Type d'usager(ne pas utiliser)", label_en:"Passenger type(do not use)"},
      title1: {label: "Type d'impact", label_fr:"Type d'impact", label_en:"Impact type"},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    serviceInfra: {
      key: 'serviceInfra',
      title: {label: "Service Impacté", label_fr:"Service Impacté", label_en:'Service Impacted'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    servicePublic: {
      key: 'servicePublic',
      title: {label: "Service des travaux publics", label_fr:"Service des travaux publics", label_en:'Public Works Department'},
      show: false,
      showInList: false,
      search: false,
      value: []
    },
    typeOfClosure : {
      key: "typeOfClosure",
      title : {label: "Type of Closure", label_en: "Type of Closure", label_fr:"Type de fermeture"},
      show: true,
      showInList: false,
      search: true,
      value: [
        {value: "complete", label: "Complete", label_en:"Complete", label_fr:"Complète"},
        {value: "right", label: "Right way", label_en:"Right way", label_fr:"Voie de droite"},
        {value: "left", label: "Left Way", label_en:"Left Way", label_fr:"Voies de gauche"},
        {value: "alternate", label: "Alternate", label_en:"Alternate", label_fr:"En alternance"},
        {value: "curve", label: "Curve",  label_en:"Curve", label_fr:"Normal"},
        {value: "centerLane", label : "Center Lane", label_en:"Center Lane", label_fr:"Courbe"},
        {value: "sidewalk", label: "Sidewalk", label_en:"Sidewalk", label_fr:"Voie centrale"},
        {value: "partial", label : "Partial" ,label_en:"Partial", label_fr:"Trottoir"},
      ]
    },
    mailList: {
      key: "mailList",
      title: { label: "", label_fr: "", label_en: "" },
      show: false,
      value: [],
    },
    estimatedCost: {
      key: "estimatedCost",
      title: { label: "Estimated Cost", label_fr: "Estimated Cost", label_en: "Estimated Cost" },
      show: false,
      showInList: false,
      value: []
    },
    requestDate: {
      key: "requestDate",
      title: { label: "Request Date", label_fr: "Request Date", label_en: "Request Date" },
      show: false,
      showInList: false,
      value: []
    },
    authorizeDate: {
      key: "authorizeDate",
      title: { label: "Authorize Date", label_fr: "Authorize Date", label_en: "Authorize Date" },
      show: false,
      showInList: false,
      value: []
    }
  },
};