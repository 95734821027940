<template>
  <div>
    <modal :show.sync="modalMap" :advancedSettings.sync="advancedSettings" type="custom-map" :scrollToBottom="false" v-on:hideModal="$emit('closeMapModal')">
      <h4 slot="header" class="title title-up"></h4>
      <template slot="close-button">
        <button type="button" class="close" @click="$emit('closeMapModal')" data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
      </template>
      <div class="map-content">
        <!-- <help-map></help-map> -->
        <div id="viewDiv"></div>
        <!-- <div class="select-entrave">
          <h4 class="text-center" v-html='entrave.label'></h4>
        </div> -->
      </div>
      <template slot="footer">
          <!--<base-button type="secondary" @click="$emit('closeMapModal')">Fermer</base-button>-->
          <div>
            <base-switch v-model="advancedSettings" on-text="" off-text=""></base-switch>
            <span> {{ $t('mapPage.advancedSettings') }}</span>
          </div>
          <div>
            <!--<base-button type="danger" class="btn-margin-right" @click="test()" >Test</base-button>-->
            <base-button type="danger" class="btn-margin-right" @click="clearSelect()" >{{ $t('mapPage.btnReset') }}</base-button>
            <base-button type="primary" @click="saveMap()" >{{ $t('mapPage.btnSave') }}</base-button>
          </div>
      </template>
      <template slot="filters">
        <advanced-filters v-bind:conflictsBuffer="conflictsBuffer" v-bind:mapLoad="mapLoad" v-bind:layer="layer" v-bind:loading="loading" v-bind:date="date" v-bind:ProjectsConflict="ProjectsConflict"
                          v-on:getAdvancedProjects="getAdvancedProjects($event)"
                          v-on:closeAdvancedTab="closeAdvancedTab()"
                          v-on:onChangeLayer="onChangeLayer($event)"
                          v-on:resetAdvancedProjects="resetAdvancedProjects($event)"></advanced-filters>
      </template>
      </modal>
  </div>
</template>
<script>
import {Modal} from 'src/components';
import { loadModules } from 'esri-loader';
import {BaseSwitch} from 'src/components/index';

import HelpMap from './HelpMap.vue';
import AdvancedFilters from './ModalMapAdvancedFilters.vue';
import LoadProjects from '../shared/MapLoadProjects.js';
import RoadNetwork from '../shared/roadNetwork';
import Features from '../shared/features';

export default {
  name: 'modal-map-component',
  components: {
    Modal,
    BaseSwitch,
    HelpMap,
    AdvancedFilters
  },
  props: ['modalMap', 'selectedEntrave', 'uuid', 'date', 'perimeter', 'mapMode','layer','name'],
  data () {
    return {
      selectMode : "segment",
      loading: false,
      modeFilter: false,
      advancedSettings: false,
      mapLoad: false,
      projects: [],
      projectsLayer: null,
      entrave: {
        name: '',
        label: '',
        value:'',
        selected: {
          path : [],
          startPoint : null,
          endPoint   : null,
          streetsTrack : []
        },
        type: 'linear'
      },
      instance: null,
      map: null,
      view: null,
      drawLayer: null,
      downKeys : {
        isDown  : false,
        control : false,
        char    : ''
      },
      layerStreets: null,
      searchLayer : null,
      busQbcLayer : null,
      possibleSegments: [],
      
      /*selectedSegments: {
        path : [],
        startPoint : null,
        endPoint   : null,
        streetsTrack : []
      },*/
      mode:0,
      FeatureLayer : null,
      geometryEngine: null,
      geodesicUtils : null,
      webMercatorUtils : null,
      spatialReference:null,
      viewArmPMEventHdlr:null,
      Graphic:null,
      SimpleLineSymbol:null,
      Polyline:null,
      Point : null,
      Field : null,
      layerView:null,
      Extent: null,
      hovered: [],
      drawColors: {
        drawing : '#00d6b4',
        selected: '#1f8ef1',
        search  : '#97f0b9'
      },
      distanceUnit : "meters",
      selectionDirectionArrowMarker : null,
      selectionStartMark : null,
      selectionEndMark   : null,
      pointSelectMark    : null,
      pointerPosMark : null,
      entravesList : [],
      conflictsRadius : 10, //default 10 units conflict radius
      conflictsUnits : "meters",
      ProjectsConflict: [],
      conflictsBuffer : null,
      conflictsGraph: null,
      searchBuffer : null,
      searchGraph  : null,
      state : '',
      features: [],
    };
  },
  created() {
    this.instance = JSON.parse(window.localStorage.getItem('instance'));
    this.state = window.localStorage.getItem('state') || "";
    this.features = Features[this.instance.city];
  },
  watch: {
    modalMap(val) {
      if (val) { this.loadMap(); this.advancedSettings=false; } else { this.resetModel(); }
    },
    advancedSettings(val) {
      if (!val) {
        let filters = {
          publish: true,
          nopublish: false,
          ranges: this.date,
          entrave:true,
          closing: false,
          detour: false
        };
        this.getAdvancedProjects([filters, 'project']);
      }
    }
  },
  methods: {
    closeModal() {
      $emit('closeMapModal');
    },
    resetModel () {
      this.selectMode = "segment";
      this.entrave = {
        label: '',
        value: '',
        selected : {
          path : [],
          startPoint : null,
          endPoint   : null,
          streetsTrack : []
        },
        type: 'linear'
      };
      this.distanceUnit = "kilometers";
      this.map=null;
      this.view=null;
      this.drawLayer=null;
      this.projectsLayer=null;
      this.downKeys = {
        isDown  : false,
        control : false,
        char    : ''
      };
      this.layerStreets=null;
      this.searchLayer= null;
      this.busQbcLayer=null;
      this.possibleSegments=[];
      
      /*this.selectedSegments={
        path : [],
        startPoint : null,
        endPoint   : null,
        streetsTrack : []
      };*/
      this.mode=0;
      
      //this.advancedSettings=false;
      this.mapLoad=false;
      this.modeFilter=false;
      this.loading=false;
      
      this.FeatureLayer = null;
      this.geometryEngine=null;
      this.geodesicUtils=null;
      this.webMercatorUtils=null;
      this.spatialReference=null;
      this.viewArmPMEventHdlr=null;
      this.Graphic=null;
      this.SimpleLineSymbol=null;
      this.Polyline=null;
      this.Point=null;
      this.Field=null;
      this.layerView=null;
      this.Extent=null;
      this.hovered=[];
      this.entravesList=[];
      this.conflictsBuffer = null;
      this.conflictsGraph  = null;
      this.searchBuffer = null;
      this.searchGraph = null;
    },
    clearSelect() {
      this.resetModel();
      this.loadMap();
    },
    reverseSelect() {
      this.reversePath();
    },
    initLoad(Map, BaseMap, MapView, FeatureLayer, SimpleLineSymbol, GraphicsLayer, geodesicUtils, Field, Polyline, Point, Graphic, geometryEngine, webMercatorUtils, spatialReference, Extent,VectorTileLayer,Sketch) {
      
      let user = JSON.parse(window.localStorage.getItem('user'));
      let location = [user.location.longitude, user.location.latitude];
      if(RoadNetwork[this.instance.city].isMultiCity){
        location = [RoadNetwork[this.instance.city].city[this.state].location[0],RoadNetwork[this.instance.city].city[this.state].location[1]];
      }
      //let type = (user.appsettings && user.appsettings.map && user.appsettings.map.typeMap) || 'osm';
      var basemap;
      if (user.appsettings.map && user.appsettings.map.modeMap && user.appsettings.map.modeMap==="1") {
        basemap = new BaseMap({
          portalItem: {id: user.appsettings.map.typeMap}
        });
      }
      else if (user.appsettings.map && user.appsettings.map.modeMap && user.appsettings.map.modeMap==="2"){
        basemap = new BaseMap({
          baseLayers: [
            new VectorTileLayer({
              portalItem: {
                id: user.appsettings.map.typeMap
              }
            })
          ]
        });
      } 
      else {
        basemap = (user.appsettings.map && user.appsettings.map.typeMap);
      }
      
      this.map = new Map({
        basemap: basemap
      });
      
      this.view = new MapView({
          container: "viewDiv",
          map: this.map,
          center: location,
          zoom: 17,
          spatialReference: 102100,
          highlightOptions: {
            color: [255, 255, 255, 0],
            haloOpacity: 0,
            fillOpacity: 0
          }
      });
      
      this.layerStreets = new FeatureLayer({
        url : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].url:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].url:RoadNetwork[this.instance.city].url),
        visible : true,
        outFields:(RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].fields : ((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].fields:RoadNetwork[this.instance.city].fields) 
      });

      
    

      this.FeatureLayer = FeatureLayer;
      this.drawLayer = new GraphicsLayer();
      this.projectsLayer = new GraphicsLayer();
      this.Graphic=Graphic;
      this.SimpleLineSymbol = SimpleLineSymbol;
      this.Polyline=Polyline;
      this.Point=Point;
      this.Field=Field;
      this.geometryEngine=geometryEngine;
      this.geodesicUtils=geodesicUtils;
      this.webMercatorUtils=webMercatorUtils;
      this.spatialReference=spatialReference;
      this.Extent=Extent;
      // this.map.add(this.layerStreets);
      this.map.add(this.projectsLayer);
      this.map.add(this.drawLayer);
      this.entravesList = [];
      this.conflictsBuffer = null;
      this.searchBuffer = null;
      this.searchGraph  = null;

     

      console.log(this.uuid)
      console.log(this.selectedEntrave)
      console.log(this.mapMode)
      console.log(this.layer)
    },
    addSearch(Search,Locator) {
      // Search widget
      //var addSearchFun = function(){let search = new Search({
      let search = new Search({
          view : this.view,
          autoSelect : true,
          label : "Some Label",
          locationEnabled : false,
          minSuggestCharacters : 3,
          popupEnabled : false,
          searchAllEnabled : true,
          declaredClass: 'hello',
          includeDefaultSources : (!!this.features.searchSource && this.features.searchSource.isDefaultESRI) || false,
          resultGraphicEnabled : true,
          sources : [],
          resultSymbol :{
                type: "picture-marker",
                url: window.location.protocol + '//'+ window.location.host +  "/static/img/icons/pin.png",
                size: 24,
                width: 24,
                height: 24,
                xoffset: 0,
                yoffset: 0
              }
      });
      if(!!this.features.searchSource && this.features.searchSource.isQuebec){
        search.sources.push(
          {
              locator : new Locator({
                url: "https://servicescarto.mern.gouv.qc.ca/pes/rest/services/Territoire/AdressesQuebec_Geocodage/GeocodeServer"
              }),
              singleLineFieldName: "SingleLine",
              name: "Adresses Quebec Geocodage",
              placeholder: this.$i18n.t('mapPage.serchePlaceHolder'),
              maxResults: 50,
              maxSuggestions: 6,
              outFields: ["*"],
              resultGraphicEnabled: true,
              resultSymbol :{
                type: "picture-marker",
                url: window.location.protocol + '//'+ window.location.host +  "/static/img/icons/pin.png",
                size: 24,
                width: 24,
                height: 24,
                xoffset: 0,
                yoffset: 0
              } 
            }
        )
      }

      
        this.view.ui.add(search, "top-right");
    },
    
    loadMap() {
      loadModules([
      "esri/Map",
      "esri/Basemap",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/symbols/SimpleLineSymbol",
      "esri/layers/GraphicsLayer",
      "esri/geometry/Extent",
      "esri/layers/support/Field",
      "esri/geometry/Polyline",
      "esri/geometry/Point",
      "esri/tasks/support/Query",
      "esri/geometry/geometryEngine",
      "esri/geometry/support/geodesicUtils",
      "esri/Graphic",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/SpatialReference",
      "esri/widgets/Search",
      "esri/layers/VectorTileLayer",
      "esri/tasks/Locator",
      "esri/widgets/Sketch"])
      .then(([Map, BaseMap, MapView, FeatureLayer, SimpleLineSymbol, GraphicsLayer, Extent, Field, Polyline, Point, Query, geometryEngine, geodesicUtils, Graphic, webMercatorUtils, spatialReference, Search,VectorTileLayer,Locator,Sketch]) => {
        let _this=this; 
        _this.initLoad(Map, BaseMap, MapView, FeatureLayer, SimpleLineSymbol, GraphicsLayer, geodesicUtils, Field, Polyline, Point, Graphic, geometryEngine, webMercatorUtils, spatialReference, Extent,VectorTileLayer,Sketch);
        const sketch = new Sketch({
          layer: _this.drawLayer,
          view: _this.view,
          creationMode: "update"
        });
        _this.view.ui.add(sketch, "bottom-trailing");
        _this.view.focus();
        _this.view.when(()=>{
            _this.addSearch(Search,Locator);
        })

        if (_this.selectedEntrave && _this.selectedEntrave.selected.path.length > 0 ) {
          if(_this.selectedEntrave.type=='ponctuel') {
            _this.drawSelectedPoint();
          } else if(_this.selectedEntrave.type=='polygon') {
            _this.drawPolygonEntrave(Graphic);
          }else{
            _this.drawSelectedEntrave(); 

          }
        }

      });   
    },
   
    
      
    closeAdvancedTab() {
      this.resetAdvancedProjects();
      this.advancedSettings=false;
    },
    onChangeLayer(value){
      this.layer = value;
      this.$emit('onChangeLayer', this.layer);
      this.loadMap();
    },
    resetAdvancedProjects() {
      this.projects=[];
      this.ProjectsConflict=[];
      this.loading=false;
      if (this.projectsLayer) this.projectsLayer.removeAll();
    },
    getAdvancedProjects(params) {
      this.resetAdvancedProjects();
      this.loading=true;
      this.getProjects(params[0], params[1]);
    },
    checkConflicts(filters){
      this.ProjectsConflict=LoadProjects.checkConflicts('entrave', filters, this.uuid, this.projects, this.conflictsBuffer, this.conflictsUnits, this.geometryEngine);
    },
    saveMap(){
      if (!this.drawLayer) return;
      const graphics = this.drawLayer.graphics.map(g => g.toJSON());
      console.log(graphics);
      this.entrave.name=this.name,
      this.entrave.label=this.name,
      this.entrave.value=this.name,
      this.entrave.selected.path=graphics._items,
      this.entrave.type="polygon",

      this.$emit('saveMapModal', this.entrave);
    },
    drawPolygonEntrave(Graphic){
      console.log("this is polygon")
      // const graphics = JSON.parse(this.selectedEntrave.selected.path);
      console.log(this.selectedEntrave.selected.path)
      this.drawLayer.removeAll();
      this.selectedEntrave.selected.path.forEach(graphic => {
        this.drawLayer.add(Graphic.fromJSON(graphic));
      });
      this.view.goTo(this.drawLayer.graphics.toArray());

    }
  }
  
}
</script>
<style>
 
  div.modal-custom-map div.modal-dialog {
    max-width: 84%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 920px !important;
  }

  div.modal-custom-map div.modal-body div.map-content {
    min-height: 720px;
  }

  .modal-content .modal-header button.close {
    top: 5px !important;
  }
  .modal-footer {
    position: relative;
  }
  div.modal-footer {
    border-top: 1px solid #b0b0b1 !important;
    padding-top: 16px !important;
  }
  div.right-btn {
    position: absolute;
    right: 30px;
    top: -35px;
  }
  
  ul.esri-search__suggestions-list li,
  ul.esri-search__suggestions-list li strong {
    color: #000 !important;
  }
  #viewDiv {
    padding: 0;
    margin: 0;
    height: 90%;
    width: 100%;
  }
  .select-entrave {
    height: 10%;
    padding: 10px;
    overflow-y: scroll;
  }
  .select-entrave h4{
    color: rgb(82, 95, 127) !important;
  }
  .modal-body {
    padding: 0px;
  }
  .btn-margin-right {
    margin-right: 10px !important;
  }
  .paths-keys {
    font-weight: bold;
  }
  .paths-distance {
    font-weight: 600;
  }
  div.map-content .esri-popup__header-title {
    color: black;
    font-weight: bold;
  }
</style>
