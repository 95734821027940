<template>
  <div>
    <!--<h3 class="info-text">{{ $t('projectForm.titleInformation') }}</h3>-->
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6 project-label">
        <base-input
          name="name"
          required
          v-bind:label="$t('projectForm.labelProjectName')"
          v-bind:placeholder="$t('projectForm.placeholderProjectName')"
          v-model="informations.name"
          disabled
          v-validate="modelValidations.name"
          :error="getError('name')">
        </base-input>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 alphanumeric">
        <base-input
          name="reference"
          :disabled="statusOfUser"
          v-bind:label="$t('projectForm.labelProjectRef')"
          v-bind:placeholder="$t('projectForm.placeholderProjectRef')"
          v-model="informations.reference">
        </base-input>
      </div>
    </div>

    <div class="row" >
      <div v-if="filters.requestDate.show" class="col-sm-6 col-md-6 col-lg-6 project-label">
        <base-input
           v-bind:label="$t('projectForm.requestDate')">
          <el-date-picker 
            type="date"
            v-bind:label="$t('projectForm.requestDate')"
            v-bind:placeholder="$t('projectForm.requestDate')"
            v-model="informations.requestDate">
          </el-date-picker>
        </base-input>
      </div>
      <div v-if="filters.authorizeDate.show" class="col-sm-6 col-md-6 col-lg-6 project-label">
        <base-input
        v-bind:label="$t('projectForm.authorizeDate')">
          <el-date-picker
            type="date"
            v-bind:label="$t('projectForm.authorizeDate')"
            v-bind:placeholder="$t('projectForm.authorizeDate')"
            v-model="informations.authorizeDate">
          </el-date-picker>
        </base-input>
      </div>
    </div>


    <div class="row" v-if="filters.estimatedCost.show">
      <div class="col-sm-6 col-md-6 col-lg-6 project-label project-label-cost">
        <base-input
          name="reference"
          type="number"
          @blur="convertCurrency"
          :disabled="statusOfUser"
          v-bind:label="$t('projectForm.cost')"
          v-bind:placeholder="$t('projectForm.cost')"
          v-model="informations.cost">
        </base-input>
      </div>
    </div>

    <div class="row margin-top-10">
      <div class="col-12 richtext-wrapper">
        <label>{{ $t('projectForm.labelComment') }}</label><label v-if="city == 'sjsr'"> * </label>
          <span v-if="statusOfUser"  v-html="informations.comment"></span>
          <div id="quill-editor-comment"></div>
          <label class="error" v-show="newErrors.comment">{{ $t('projectForm.commentMandatory') }}</label>
      </div>
      <div class="col-12 project-label richtext-wrapper mt-3">
        <label>{{ $t('projectForm.labelNotes') }}</label>
          <span v-if="statusOfUser"  v-html="informations.notes"></span>
          <div id="quill-editor-notes" style="background: #e3e3e3" disabled></div>
      </div>    
    </div>
    
    <div class="row info-divider"></div>
    <label class="label-title">{{ $t('quickCreate.collapseTitle.title3') }}</label>
    <div v-if="!!features.stakeholderAutocomplete && features.stakeholderAutocomplete.show">
      <StakeholderComponent :informations="informations" />
    </div>  
    <div v-else>
      <div class="row margin-top-20">
      <div class="col-sm-6">
        <base-input
          name="inchargeof"
          :disabled="statusOfUser"
          v-bind:label="$t('projectForm.labelInChargeOf')"
          v-bind:placeholder="$t('projectForm.placeholderInChargeOf')"
          v-model="informations.inchargeof">
        </base-input>
      </div>
      <div class="col-sm-6">
        <base-input
          name="phone"
          :disabled="statusOfUser"
          v-bind:label="$t('projectForm.labelPhone')"
          v-bind:placeholder="$t('projectForm.placeholderPhone')"
          v-model="informations.phone">
        </base-input>
      </div>
      <div class="col-sm-6">
        <base-input
          name="role"
          :disabled="statusOfUser"
          v-bind:label="$t('projectForm.labelRole')"
          v-bind:placeholder="$t('projectForm.placeholderRole')"
          v-model="informations.role">
        </base-input>
      </div>
      <div class="col-sm-6">
        <base-input
          name="email"
          :disabled="statusOfUser"
          v-bind:label="$t('projectForm.labelEmail')"
          v-bind:placeholder="$t('projectForm.placeholderEmail')"
          v-model="informations.email">
        </base-input>
      </div>
    </div> 
    <div class="row margin-top-20">
      <div class="col-sm-12 col-md-12 col-lg-12 add-agent-container margin-top-20" v-if="!statusOfUser">
        <label>{{ $t('projectForm.labelAnotherAgent') }}</label>
        <div class="agent-add-btn" @click="addAgent()">
          <i class="tim-icons icon-simple-add"></i>
          <span></span>
        </div>
      </div>
    </div>
    <div class="row margin-top-20 margin-bottom-20">
      <agent-component v-for="(item,index) in informations.agents" v-bind:index="index" v-bind:item="item" v-bind:key="index" v-on:removeAgent="removeAgent($event)" v-bind:statusOfUser="statusOfUser" >
      </agent-component>
    </div>
    </div>
     
    <div v-show="filters.serviceInfra.show || filters.servicePublic.show" class="row info-divider"></div>
    <div v-show="filters.serviceInfra.show || filters.servicePublic.show" class="row margin-top-20">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <label class="label-title">{{ $t('projectForm.workingLabel1') }}*</label>
      </div>
      <div v-show="filters.serviceInfra.show" class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ $t('projectForm.workingLabel2') }}</label>
        <el-select multiple
            @input="changeWorkCategory('serviceWorking')"
            class="select-primary"
            name="serviceInfra"
            :disabled="statusOfUser"
            v-bind:placeholder="$t('projectForm.workingLabel2')"
            v-model="informations.serviceInfra">
          <el-option
            v-for="option in filters.serviceInfra.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
        <label class="error" v-show="newErrors.serviceWorking">{{ $t('projectForm.serviceWorkingMandatory') }}</label>
      </div>
      <div v-show="filters.servicePublic.show" class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ $t('projectForm.workingLabel3') }}</label>
        <el-select multiple
            @input="changeWorkCategory('serviceWorking')"
            class="select-primary"
            :disabled="statusOfUser"
            name="servicePublic"
            v-bind:placeholder="$t('projectForm.workingLabel3')"
            v-model="informations.servicePublic">
          <el-option
            v-for="option in filters.servicePublic.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
        <label class="error" v-show="newErrors.serviceWorking">{{ $t('projectForm.serviceWorkingMandatory') }}</label>
      </div>
    </div>
    <div class="row info-divider"></div>
    <div class="row margin-top-10">
      <div v-if='filters.step.show' class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ $t('projectForm.labelProjectStep') }} *</label>
        <!--
        <el-select
          class="select-primary select-inline"
          v-bind:class="{ 'has-danger': errors.has('step')}"
          name="step"
          required
          size="large"
          v-bind:placeholder="$t('projectForm.placeholderProjectStep')"
          :error="getError('step')"
          v-validate="modelValidations.step"
          v-model="informations.step">
          -->
        <el-select
          class="select-primary select-inline"
          name="step"
          size="large"
          clearable
          :disabled="statusOfUser"
          v-bind:placeholder="$t('projectForm.placeHolderField')"
          v-validate="modelValidations.step"
          v-model="informations.step">
          <el-option
            v-for="option in filters.step.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
        <label class="error" v-show="errors.has('step')">{{ errors.first('step') }}</label>
      </div>
      <div v-if='filters.entity.show' class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ $t('projectForm.labelProjectEntity') }}</label>
        <!--
        <el-select  multiple
          class="select-primary"
          v-bind:class="{ 'has-danger': errors.has('entity')}"
          name="entity"
          required
          size="large"
          v-bind:placeholder="$t('projectForm.placeholderProjectEntity')"
          :error="getError('entity')"
          v-validate="modelValidations.entity"
          v-model="informations.entity">
        -->
        <el-select  multiple
          class="select-primary"
          name="entity"
          size="large"
          :disabled="statusOfUser"
          v-bind:placeholder="$t('projectForm.placeHolderField')"
          v-model="informations.entity">
          <el-option
            v-for="option in filters.entity.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
        <label class="error" v-show="errors.has('entity')">{{ errors.first('entity') }}</label>
      </div>
    </div>
    <div class="row margin-top-10">
      <div v-if='filters.status.show' class="col-sm-6 col-md-6 col-lg-6">
        <!--<label>{{ $t('projectForm.labelProjectStatus') }}</label>-->
        <label>{{ filters.status.title.label }}</label>
        <!--
        <el-select
          class="select-primary select-inline"
          v-bind:class="{ 'has-danger': errors.has('status')}"
          name="status"
          required
          size="large"
          v-bind:placeholder="$t('projectForm.placeholderProjectStatus')"
          :error="getError('status')"
          v-validate="modelValidations.status"
          v-model="informations.status">
        -->
        <el-select
          class="select-primary select-inline"
          name="status"
          size="large"
          :disabled="statusOfUser"
          clearable
          v-bind:placeholder="$t('projectForm.placeHolderField')"
          v-model="informations.status">
          <el-option
            v-for="option in filters.status.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
        <label class="error" v-show="errors.has('status')">{{ errors.first('status') }}</label>
      </div>
      <div v-if='filters.workType1.show' class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.workType1.title.label }} </label> <label v-if="city == 'sjsr'"> * </label>
          <el-select multiple
            @input="changeWorkCategory('workType1')"
            class="select-primary"
            name="workType1"
            :disabled="statusOfUser"
            v-bind:placeholder="$t('projectForm.placeHolderField')"
            v-model="informations.workType1">
          <el-option
            v-for="option in filters.workType1.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
        <label class="error" v-show="newErrors.workType1">{{ $t('projectForm.workType1Mandatory') }}</label>


      </div>
      <div v-if='filters.workCategory.show' class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.workCategory.title.label }}</label>
        <el-select multiple
          @input="changeWorkCategory('workCategory')"
          class="select-primary"
          name="workCategory"
          :disabled="statusOfUser"
          v-bind:placeholder="$t('projectForm.placeHolderField')"
          v-model="informations.workCategory">
        <el-option
          v-for="option in filters.workCategory.value"
          class="select-primary"
          :value="option.value"
          :label="option.label"
          :key="option.label">
          </el-option>
        </el-select>
        <label class="error" v-show="newErrors.workCategory">{{ $t('projectForm.workCategoryMandatory') }}</label>
      </div>
      <div v-if='filters.workType.show' class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.workType.title.label }} </label>
        <el-select  multiple
          class="select-primary"
          size="large"
          :disabled="statusOfUser"
          v-bind:placeholder="$t('projectForm.placeHolderField')"
          v-model="informations.workType">
          <el-option
            v-for="option in filters.workType.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
      </div>

      <div v-if='filters.distp.show' class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.distp.title.label }} </label>
        <el-select
          class="select-primary"
          size="large"
          clearable
          :disabled="statusOfUser"
          v-bind:placeholder="$t('projectForm.placeHolderField')"
          v-model="informations.distp">
          <el-option
            v-for="option in filters.distp.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="row margin-top-10">
      <div v-if='filters.district.show' class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.district.title.label }}</label>
        <!--
        <el-select multiple
          class="select-primary"
          v-bind:class="{ 'has-danger': errors.has('district')}"
          name="district"
          required
          v-bind:placeholder="$t('projectForm.placeholderProjectDistrict')"
          error="getError('district')"
          v-validate="modelValidations.district"
          v-model="informations.district">
          -->
          <el-select multiple
            class="select-primary"
            name="district"
            :disabled="statusOfUser"
            v-bind:placeholder="$t('projectForm.placeHolderField')"
            v-model="informations.district">
          <el-option
            v-for="option in filters.district.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
        <label class="error" v-show="errors.has('district')">{{ errors.first('district') }}</label>
      </div>
     
      <div v-if='filters.city.show' class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ $t('projectForm.labelProjectCity') }}</label>
        <el-select multiple
          class="select-primary"
          size="large"
          name="city" 
          :disabled="statusOfUser"
          v-bind:placeholder="$t('projectForm.placeholderProjectCity')"
          v-model="informations.city">
          <el-option
            v-for="option in filters.city.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
      </div>
      <div v-if='!!filters.departments && filters.departments.show' class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.departments.title.label }}*</label>
        <el-select 
          @input="changeWorkCategory('departments')"
          class="select-primary"
          size="large"
          clearable
          :disabled="statusOfUser"
          v-bind:placeholder="$t('projectForm.placeholderProjectWorkType')"
          v-model="informations.departments">
          <el-option
            v-for="option in filters.departments.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
        <label class="error" v-show="newErrors.departments">{{ $t('projectForm.departmentMandatory') }}</label>
      </div>
    </div>
    <div class="row margin-top-10">
      <div v-if='filters.sector.show' class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.sector.title.label }}* </label>
        <el-select  multiple
          @input="changeWorkCategory('sector')"
          class="select-primary"
          size="large"
          :disabled="statusOfUser"
          v-bind:placeholder="$t('projectForm.placeholderSector')"
          v-model="informations.sector">
          <el-option
            v-for="option in filters.sector.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
        <label class="error" v-show="newErrors.sector">{{ $t('projectForm.sectorMandatory') }}</label>
      </div>
    </div>
    <div class="row">
     
      <div v-if='filters.dista.show' class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.dista.title.label }} </label>
        <el-select  multiple
          class="select-primary"
          size="large"
          clearable
          :disabled="statusOfUser"
          v-bind:placeholder="$t('projectForm.placeHolderField')"
          v-model="informations.dista">
          <el-option
            v-for="option in filters.dista.value"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label">
          </el-option>
        </el-select>
      </div>
    </div>
    <div v-if="filters.trafficImpact.show || filters.portableWater.show" class="row info-divider"></div>
    <div v-if="city==='repentigny' || city==='hampstead' || city==='boisbriand' || city==='mississauga' || city==='admtl'">
      <label v-if="filters.trafficImpact.show || filters.portableWater.show" class="label-title" v-html="$t('projectForm.impactTitle1')"></label>
    </div>
    <div v-else>
      <label v-if="filters.trafficImpact.show || filters.portableWater.show" class="label-title" v-html="$t('projectForm.impactTitle')"></label>
    </div>
    <div v-if="filters.trafficImpact.show" class="row margin-top-20">
      <div class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.trafficImpact.title.label }}</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.trafficImpact.title1.label }}</label>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row" v-for="(item,index) in informations.trafficImpact.value" v-bind:index="index" v-bind:item="item" v-bind:key="index">
          <div class="col-sm-6 col-md-6 col-lg-6">
            <base-checkbox v-model="item.status" @input="check('trafficImpact', item.value, $event)" :checked="item.status" :disabled="statusOfUser"> {{ formatLabel(item) }}  </base-checkbox>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <base-input name="item.value" v-model="item.content" :disabled="!item.status" ></base-input>
          </div>
        </div>
      </div>
    </div>
    <div v-if="filters.portableWater.show" class="row margin-top-20">
      <div class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.portableWater.title.label }}</label>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row" v-for="(item,index) in informations.portableWater.value" v-bind:index="index" v-bind:item="item" v-bind:key="index">
          <div class="col-sm-6 col-md-6 col-lg-6">
            <base-checkbox v-model="item.status" @input="check('portableWater', item.value, $event)" :checked="item.status" :disabled="statusOfUser"> {{ formatLabel(item) }} </base-checkbox>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <base-input name="item.value" v-model="item.content" :disabled="!item.status" ></base-input>
          </div>
        </div>
      </div>
    </div>
    <div v-if="filters.passanger.show" class="row info-divider"></div>
    <div v-if="filters.passanger.show" class="row margin-top-20">
      <div class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.passanger.title.label }}</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <label>{{ filters.passanger.title1.label }}</label>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row" v-for="(item,index) in informations.passanger.value" v-bind:index="index" v-bind:item="item" v-bind:key="index">
          <div class="col-sm-6 col-md-6 col-lg-6">
            <base-checkbox v-model="item.status" @input="check('passanger', item.value, $event)" :checked="item.status" :disabled="statusOfUser"> {{ formatLabel(item) }} </base-checkbox>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <base-input name="item.value" v-model="item.content" :disabled="!item.status" ></base-input>
          </div>
        </div>
      </div>
    </div>
    <div v-if="features.communicationToolsOnProjectInformationPageWhileAddingOrUpdating.show" class="row margin-top-20">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row info-divider"></div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <label>{{ $t('projectForm.labelProjectCommunication1') }}</label>
        <div class="row margin-left--30">
          <div class="col-sm-12 col-md-12 col-lg-12" style="display: inherit;">
            <div v-if="features.communicationToolsOnProjectInformationPageWhileAddingOrUpdating.internalCommunication.show" class="col-sm-4 col-md-4 col-lg-4">
              <base-checkbox v-model="informations.internalCommunication" :checked="informations.internalCommunication" :disabled="statusOfUser">{{ $t('projectForm.labelInternalCommunication') }}</base-checkbox>
            </div>
            <div v-if="features.communicationToolsOnProjectInformationPageWhileAddingOrUpdating.citizenOpinions.show" class="col-sm-4 col-md-4 col-lg-4">
              <base-checkbox v-model="informations.citizenOpinions" :checked="informations.citizenOpinions" :disabled="statusOfUser">{{ $t('projectForm.labelProjectcitizenOpinions') }}</base-checkbox>
            </div>
            <div v-if="features.communicationToolsOnProjectInformationPageWhileAddingOrUpdating.externalCommunication.show" class="col-sm-4 col-md-4 col-lg-4">
              <base-checkbox v-model="informations.externalCommunication" :checked="informations.externalCommunication" :disabled="statusOfUser">{{ $t('projectForm.labelExternalCommunication') }}</base-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-divider"></div>
    <!-- <div v-if="city==='gatineau' || city==='montroyal'" class="row margin-top-20">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <label> {{ $t('projectForm.labelProjectCommunication2') }}</label>
        <div class="row margin-left--30">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <base-checkbox v-model="informations.publicMap" :checked="informations.publicWork" :disabled="statusOfUser">{{ $t('projectForm.labelPublicMap') }}</base-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 margin-top-20">
        <label>{{ $t('projectForm.labelProjectCommunication3') }}</label>
        <div class="row margin-left--30">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <base-checkbox v-model="informations.publish" :checked="informations.publish" :disabled="statusOfUser">{{ $t('projectForm.labelPublishPublic') }}</base-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row margin-top-20" v-if="features.publishProjectToPublicMap.show">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row margin-left--30">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="col-sm-4 col-md-4 col-lg-4">
              <base-checkbox v-if="features.publishProjectToPublicMap.publishToCitizen.show"  v-model="informations.publish" :checked="informations.publish"  :disabled="true">{{ $t('projectForm.labelProjectPublish1') }}</base-checkbox>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
              <base-checkbox v-if="features.publishProjectToPublicMap.publishProjectPhase.show" v-model="informations.publicMapWork" :checked="informations.publicMapWork" :disabled="true">{{ $t('projectForm.labelPublicMapWork') }}</base-checkbox>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <base-checkbox v-if="features.publishProjectToPublicMap.publishProjectWorkNotice.show" v-model="informations.publishReport" :checked="informations.publishReport" :disabled="true">{{ $t('projectForm.labelProjectPublicAvis') }}</base-checkbox>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <base-checkbox v-if="features.publishProjectToPublicMap.publishProjectToWaze.show" class="waze-checkebox" v-model="informations.typeMap.waze" :checked="informations.typeMap.waze" :disabled="true">{{ $t('projectForm.labelProjectMapType1') }}</base-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row info-divider"></div>
    <div class="row margin-top-20">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <label>{{ $t('projectForm.labelProjectDocuments') }}</label>
        <div class="row margin-left--30">
          <div class="col-sm-6 col-md-6 col-lg-6" style="display: inherit;">
            <div class="col-sm-4 col-md-4 col-lg-4">
              <base-checkbox v-model="informations.documents.allowed" :checked="informations.documents.allowed" :disabled="statusOfUser" >{{ $t('projectForm.labelProjectDocumentAllowed') }}</base-checkbox>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <base-checkbox v-model="informations.documents.plan" :checked="informations.documents.plan" :disabled="statusOfUser" >{{ $t('projectForm.labelProjectDocumentPlan') }}</base-checkbox>
            </div>
            <div style="display: flex;" class="col-sm-4 col-md-4 col-lg-4">
              <span style="margin-top: 8px;">{{ $t('projectForm.labelProjectDocumentOther') }}</span>
              <base-input style="margin-left: 10px;" name="other" v-model="informations.documents.other" :disabled="statusOfUser" ></base-input>
            </div>
            <div v-if="this.$route.query && this.$route.query.uuid">
                <base-button simple type="primary" class="attach-button" @click="modals.modalAttach = true">
                    <i class="tim-icons icon-attach-87"></i>{{ $t('projectForm.attach') }}
                </base-button>
                <modal :show.sync="modals.modalAttach" modal-classes="modal-dialog-centered" :scrollToBottom="false" v-on:hideModal="modals.modalAttach = false">
                  <template slot="header">
                      <h5 class="modal-title" id="exampleModalLabel"></h5>
                  </template>
                  <template slot="close-button">
                    <button type="button" class="close" @click="modals.modalAttach = false" data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
                  </template>
                    <div class="padding-model">
                      <ArchiveForm :projectId="this.$route.query.uuid" :modalStatus="modals.modalAttach" @update="updateModal" :archiveData="archiveToBeUpdatedData" :archives="archives"></ArchiveForm>
                    </div>
              </modal>
            </div>
          </div>
        </div>
          <ArchivesTable :componentName="componentName" v-if="archives.length" :tableData="archives" :loading="loading" :updateModal="updateModal" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import VuePellEditor from 'vue-pell-editor';
Vue.use(VuePellEditor);
import { Select, Option } from 'element-ui';
import { BaseCheckbox} from 'src/components/index';
import AgentComponent from './AgentComponent.vue';
import {DatePicker, TimeSelect} from 'element-ui'
import {Modal} from 'src/components';
import ArchiveForm from '../archives/ArchiveForm.vue';
import { Table, TableColumn } from 'element-ui';
import { BaseTable } from '@/components';
import { ArchivesTable} from 'src/components/index';
import features from './../shared/features';
import quillEditorToolbar from './../shared/quillEditorToolbar';
import StakeholderComponent from './new/StakeholderComponent.vue';

export default {
  components: {
    BaseTable,
    [Option.name]: Option,
    [Select.name]: Select,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    BaseCheckbox,
    AgentComponent,
    Modal,
    ArchiveForm,
    ArchivesTable,
    StakeholderComponent,
  },
  props: ['name', 'informations', 'filters', 'statusOfUser', 'archives'],
  created() {
    let instance = JSON.parse(window.localStorage.getItem('instance'));
    this.city=instance.city;
    this.features = features[this.city];
  },
  mounted() {
    let _this = this;
    let  instance = JSON.parse(window.localStorage.getItem('instance'));
    _this.city = instance.city;
    let user = JSON.parse(window.localStorage.getItem('user'));
    let keys = Object.keys(_this.filters);
    keys.forEach((key) => {
      _this.filters[key].value.forEach((element) => {
        if (user.lang=='fr') {
          if (element.label_fr) {
            element.label=element.label_fr;
          }
        } else {
          if (element.label_en) {
            element.label=element.label_en;
          }
        }
      });
      let e = _this.filters[key];
      if (user.lang=='fr') {
        if (e.title && e.title.label_fr) {
          e.title.label=e.title.label_fr;
        }
        if (e.title1 && e.title1.label_fr) {
          e.title1.label=e.title1.label_fr;
        }
      } else {
        if (e.title && e.title.label_en) {
          e.title.label=e.title.label_en;
        }
        if (e.title1 && e.title1.label_en) {
          e.title1.label=e.title1.label_en;
        }
      }
    });
    /*
    let input = document.querySelector('div.alphanumeric input');
    input.addEventListener('keypress', function(e) {
      //let charCode = e.which;
      //if ((charCode > 31 && (charCode < 42 || charCode > 57)) && charCode !== 46) {
      if ((e.which < 65 || e.which > 122) && (e.which < 48 || e.which > 57)) {
        e.preventDefault();
      }
    });
    */
    _this.quillEditorComment = new Quill('#quill-editor-comment', {
      modules: {
        toolbar: quillEditorToolbar
      },
      theme: 'snow'
    });
    _this.quillEditorComment.on('text-change', function() {
      _this.informations.comment = _this.quillEditorComment.root.innerHTML;
      _this.changeWorkCategory('comment');
    });

    _this.quillEditorNotes = new Quill('#quill-editor-notes', {
      modules: {
        toolbar: quillEditorToolbar
      },
      theme: 'snow'
    });
    _this.quillEditorNotes.on('text-change', function() {
      _this.informations.notes = _this.quillEditorNotes.root.innerHTML;
    });
    _this.quillEditorNotes.enable(false);
  },
  data() {
    return {
      content: "",
      newErrors: {
        sector: false,
        serviceWorking: false,
        departments: false,
        workType1:false,
        comment: false,
      },
      componentName:'InformationStep',
      
      
     
      modelValidations: {
        name: {
          required: true
        },
        district: {
          required: true
        },
        step: {
          required: true
        },
        entity: {
          required: true
        },
        status: {
          required: true
        }
      },
      modals:{
        modalAttach: false
      },
      archiveToBeUpdatedData: null,
      features: null,
      quillEditorComment: null,
      quillEditorNotes: null,
    };
  },
  methods: {
    updateModal(v, archive){
      this.archiveToBeUpdatedData = archive
      this.modals.modalAttach = v;
    },
    changeWorkCategory(type) {
      this.newErrors[type]=false;
    },
    formatLabel(item) {
      let user = JSON.parse(window.localStorage.getItem('user'));
      if (user.lang=='fr'){
        return item.label_fr;
      } else {
        return item.label_en;
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      let _this = this;
      return _this.$validator.validateAll().then(res => {
        if (_this.filters.sector && _this.filters.sector.show && ((_this.informations.sector===undefined) || (_this.informations.sector.length==0))) {
          _this.newErrors.sector=true;
          res=false;
        }
        if (_this.filters.departments && _this.filters.departments.show && ((_this.informations.departments===undefined) || (_this.informations.departments.length==0))) {
          _this.newErrors.departments=true;
          res=false;
        }
        if ((_this.filters.serviceInfra || _this.filters.servicePublic) && 
            (_this.filters.serviceInfra.show || _this.filters.servicePublic.show) && 
            (((_this.informations.serviceInfra===undefined) && (_this.informations.servicePublic===undefined)) || ((_this.informations.serviceInfra.length+_this.informations.servicePublic.length)==0))) {
          _this.newErrors.serviceWorking=true;
          res=false;
        }
        if(this.city == "sjsr"){
          if (_this.filters.workType1 && _this.filters.workType1.show && ((_this.informations.workType1===undefined) || (_this.informations.workType1.length==0))) {
            _this.newErrors.workType1=true;
            res=false;
          }
          if (((_this.informations.comment===undefined) || (_this.informations.comment.length==0))) {
            _this.newErrors.comment=true;
            res=false;
          }
        }

        _this.$emit('on-validated', res, 'information', _this.informations);
        return res;
      });
    },
    check(type, status, target) {
      let filter;
      if (type==='passanger') {
        filter=this.informations.passanger.value;
      } else if (type==='portableWater') {
        filter=this.informations.portableWater.value;
      } else if (type==='trafficImpact') {
        filter=this.informations.trafficImpact.value;
      }
      filter.forEach(elem => {
        if (elem.value == status && !target) { 
          elem.content='';
        }
      });
    },
    addAgent() {
      this.informations.agents.push({
        inchargeof:'',
        email:'',
        role:'',
        phone:''
      });
    },
    removeAgent(e) {
      this.informations.agents.splice(e[0], 1);
    },
    convertCurrency(){
      const floatValue = parseFloat(this.informations.cost);
      if (!isNaN(floatValue)) {
        // Ceiling round off
        this.informations.cost = Math.ceil(floatValue);
      } else {
        this.informations.cost = null;
      }
    }
  },
  watch: {
    informations(value) {
      if(value.hasOwnProperty("comment") && value.comment != "" && typeof value.comment != undefined) {
        this.quillEditorComment.clipboard.dangerouslyPasteHTML(value.comment);
      }
      if(value.hasOwnProperty("notes") && value.notes != "" && typeof value.notes != undefined) {
        this.quillEditorNotes.clipboard.dangerouslyPasteHTML(value.notes);
      }
    }
  }
};
</script>
<style>
  td.published-content {
    text-align: center;
  }
  td.published-content i {
    color: rgb(204, 102, 51);
  }
  td.published-content i.success {
    color: #00f2c3;
  }
  .margin-top-10 {
    margin-top: 10px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .custom-actionbar {
    background-color: transparent !important;
    border-bottom: 1px solid rgb(43, 53, 83);
  }
  .custom-actionbar button.pell-button {
    color: #d3d7e9 !important;
  }
  .vp-editor {
    border: 1px solid rgb(43, 53, 83);
    border-radius: 0.4285rem;
    font-size: 0.75rem;
    -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    height: 150px;
  }
  .pell-content {
    height: 100px !important;
    color: #1d253b !important;
  }
  .vp-editor:focus {
    border: 1px solid #cd51e0 !important;
  }
  .pell-button {
    width: 40px;
  }
  .select-inline {
    display: inline !important;
  }
  span.el-input__suffix,
  span.el-input__prefix {
    display: none;
  }
  .el-date-editor.el-input .el-input__inner {
    padding-left: 20px !important;
    color: #e14eca !important;
  }
  div.el-select {
    width: 100%;
  }
  i.el-tag__close.el-icon-close,
  span.el-select__tags-text {
    color: #FFF !important;
  }
  i.el-tag__close.el-icon-close:hover {
    color: #e14eca !important;
  }
  /*
  body.white-content .select-primary.el-select .el-input input {
    border-color: #8d919c !important;
  }
  */
  body.white-content .select-primary.el-select .el-input input {
    color: #e14eca;
  }
  
  body.white-content .select-primary.el-select .el-input input {
    color: #e14eca !important;
  }

  .select-primary.el-select .el-input input {
    color: rgba(255, 255, 255, 0.8);
  }
  div.el-select.select-primary.has-danger div.el-input.el-input--suffix input.el-input__inner,
  div.el-select.select-primary.has-danger div.el-input.el-input--suffix input.el-input__inner:hover {
    border: none !important;
    color: #b9afcf !important;
  }
  .select-primary.el-select .el-input:hover input {
    border-color: none !important;
  }
  .el-select .el-input.is-focus .el-input__inner{
    /*border: 1px solid rgb(43, 53, 83);*/
  }
  
  .el-select.select-primary.has-danger::after {
    content: "" !important;
  }
  .el-select.select-primary.has-danger div.el-input.el-input--suffix{
    border: 1px solid #f33620 !important;
    color: #ec250d;
    background-color: rgba(222, 222, 222, 0.1);
    border-radius: 5px;
  }
  div.info-divider {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-bottom: 19px;
    border-bottom: 4px solid #eee;
    margin-left: 0px;
    margin-right: 0px;
  }
  div.add-agent-container {
    display: block ruby;
  }

  div.add-agent-container label {
    font-weight:bold;
  }

  .agent-add-btn {
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-size: .8em;
    /*position: absolute;
    top: 15px;
    right: 15px;*/
    margin-left: 20px;
    color: #fff;
    text-align: center;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #fd5d93;
    cursor: pointer;
  }
  div.margin-bottom-20 {
    margin-bottom: 20px;
  }
  label.label-title {
    font-weight: bold;
  }
  div.margin-left--30 {
    margin-left: -30px;
  }
  div.padding-model{
    padding: 1.5rem;
  }
  div.form-check.waze-checkebox.disabled {
    display: block;
  }
  .card-wizard .disabled {
    display: block;
  }
  .attach-button{
    margin:0 !important;
  }
  tbody.archive-table-body tr td.btn-container {
    visibility: hidden;
    opacity: 0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
  }
  tbody.archive-table-body tr:hover td.btn-container {
    position: relative;
    visibility: visible;
    opacity: 1;
  }
  tbody.archive-table-body tr td.btn-container div {
    width: 200px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  div.card.archives-card div.card-body .progress-container .progress .progress-bar .progress-value {
    /*top: -18px !important;*/
  }
  div.card.archives-card tbody.archive-table-body div.progress-container.progress-primary  {
    margin-top: 0px !important;
  }
  div.card.archives-card tbody.archive-table-body tr td p{
    margin-bottom: 2px;
  }
  div.card.archives-card tbody.archive-table-body tr td p.title {
    padding-top: 0px !important;
  }

.project-label-cost input::-webkit-outer-spin-button,
.project-label-cost input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.project-label-cost input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.richtext-wrapper{
  min-height: 200px;
  height: max-content;
}
</style>