<template>
  <div>
    <modal :show.sync="modalMap" :advancedSettings.sync="advancedSettings" type="custom-map" :scrollToBottom="false" v-on:hideModal="$emit('closeMapModal')">
      <h4 slot="header" class="title title-up"></h4>
      <template slot="close-button">
        <button type="button" class="close" @click="$emit('closeMapModal')" data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
      </template>
      <div class="map-content">
        <!-- <help-map></help-map> -->
        <div id="detourView"></div>
        <div class="select-entrave">
          <h4 class="text-center" v-html='detour.label'></h4>
        </div>
      </div>
      <template slot="footer">
          <!--<base-button type="secondary" @click="$emit('closeMapModal')">Fermer</base-button>-->
          <div>
            <!-- <base-switch v-model="advancedSettings" on-text="" off-text=""></base-switch>
            <span> {{ $t('mapPage.advancedSettings') }}</span> -->
          </div>
          <div class="">
            <base-button type="danger" class="btn-margin-right" @click="clearSelect()" >{{ $t('mapPage.btnReset') }}</base-button>
            <!--<base-button type="info" class="btn-margin-right" @click="reverseSelect()" >Inverser</base-button>-->
            <base-button type="primary" @click="saveMap()" >{{ $t('mapPage.btnSave') }}</base-button>
          </div>
      </template>
      <template slot="filters">
        <advanced-filters v-bind:conflictsBuffer="conflictsBuffer" v-bind:mapLoad="mapLoad" v-bind:layer="layer" v-bind:loading="loading" v-bind:date="date" v-bind:selectedElement="selectedDetour"
                          v-bind:ProjectsConflict="ProjectsConflict"
                          v-on:getAdvancedProjects="getAdvancedProjects($event)"
                          v-on:closeAdvancedTab="closeAdvancedTab()"
                          v-on:onChangeLayer="onChangeLayer($event)"
                          v-on:resetAdvancedProjects="resetAdvancedProjects($event)"></advanced-filters>
      </template>
    </modal>
  </div>
</template>
<script>
import {Modal} from 'src/components';
import { loadModules } from 'esri-loader';
import {BaseSwitch} from 'src/components/index';

import HelpMap from './HelpMap.vue';
import AdvancedFilters from './ModalMapAdvancedFilters.vue';
import LoadProjects from '../shared/MapLoadProjects.js';
import RoadNetwork from '../shared/roadNetwork';
import Features from '../shared/features';

export default {
  name: 'modal-map-detour-component',
  components: {
    Modal,
    BaseSwitch,
    HelpMap,
    AdvancedFilters
  },
  props: ['modalMap', 'closing', 'selectedDetour', 'uuid', 'date', 'perimeter', 'mapMode','layer'],
  computed: {
  },
  data () {
    return {
      loading: false,
      modeFilter: false,
      advancedSettings: false,
      mapLoad: false,
      projects: [],
      projectsLayer: null,
      detour: {
        label:'',
        value:'',
        selected: {
          path : [],
          startPoint : null,
          endPoint   : null,
          streetsTrack : []
        },
        type: 'linear'
      },
      instance: null,
      map: null,
      view: null,
      drawLayer: null,
      downKeys  : {
        isDown  : false,
        control : false,
        char    : ''
      },
      entravesLayer:null,
      layerStreets: null,
      possibleSegments: [],
      Polyline:null,
      Point:null,
      mode:0,
      geometryEngine: null,
      geodesicUtils : null,
      webMercatorUtils : null,
      viewArmPMEventHdlr:null,
      Graphic:null,
      layerView:null,
      Extent: null,
      spatialReference :null,
      hovered: [],
      drawColors: {
        drawing: '#00d6b4',
        selected: '#1f8ef1',
        closing : '#54727b',
        search  : '#97f0b9'
      },
      selectionDirectionArrowMarker : null,
      selectionStartMark : null,
      conflictsRadius : 10, //default 10 units conflict radius
      conflictsUnits : "meters",
      ProjectsConflict: [],
      conflictsBuffer : null,
      conflictsGraph: null,
      
      searchBuffer : null,
      searchGraph  : null,
      state: '',
      features: [],
    };
  },
  created() {
    this.instance = JSON.parse(window.localStorage.getItem('instance'));
    this.state = window.localStorage.getItem('state') || "";
    this.features = Features[this.instance.city];
  },
  watch: {
    modalMap(val) {
      if (val) { this.loadMap(); this.advancedSettings=false; } else { this.resetModel(); }
    },
    advancedSettings(val) {
      if (!val) {
        let filters = {
          publish: true,
          nopublish: false,
          ranges: this.date,
          entrave:true,
          closing: false,
          detour: false
        };
        this.getAdvancedProjects([filters, 'project']);
      }
    }
  },
  methods: {
    closeModal() {
      $emit('closeMapModal');
    },
    resetModel () {
      this.detour = {
        label: '',
        value : '',
        selected: {
          startPoint : null,
          endPoint   : null,
          path       : [],
          streetsTrack : []
        },
        type: 'linear'
      };

      this.map=null;
      this.view=null;

      this.drawLayer=null;
      this.downKeys = {
        isDown  : false,
        control : false,
        char    : ''
      };
      this.Polyline=null;
      this.Point=null;
      
      this.entravesLayer=null;
      this.projectsLayer=null;
      
      this.layerStreets=null;
      this.possibleSegments=[];

      this.mode=0;
      //this.advancedSettings=false;
      this.mapLoad=false;
      this.modeFilter=false;
      this.loading=false;
      
      this.geometryEngine=null;
      this.geodesicUtils=null;
      this.webMercatorUtils=null;
      this.viewArmPMEventHdlr=null;
      this.Graphic=null;
      this.layerView=null;
      this.Extent=null;
      this.hovered=[];
      this.conflictsBuffer = null;
      this.conflictsGraph  = null;
      this.searchBuffer = null;
      this.searchGraph  = null;
    },
    clearSelect() {
      this.resetModel();
      this.loadMap();
    },
    reverseSelect() {
      this.reversePath();
    },
    initLoad(Map, BaseMap, MapView, FeatureLayer, GraphicsLayer, Graphic, geometryEngine, Extent, Polyline, Point, geodesicUtils, webMercatorUtils, spatialReference, VectorTileLayer) {
      
      let user = JSON.parse(window.localStorage.getItem('user'));
      let location = [user.location.longitude, user.location.latitude];
      if(RoadNetwork[this.instance.city].isMultiCity){
        location = [RoadNetwork[this.instance.city].city[this.state].location[0],RoadNetwork[this.instance.city].city[this.state].location[1]];
      }
      //let type = (user.appsettings && user.appsettings.map && user.appsettings.map.typeMap) || 'osm';
      var basemap;
      if (user.appsettings.map && user.appsettings.map.modeMap && user.appsettings.map.modeMap==="1") {
        basemap = new BaseMap({
          portalItem: {id: user.appsettings.map.typeMap}
        });
      }
      else if (user.appsettings.map && user.appsettings.map.modeMap && user.appsettings.map.modeMap==="2"){
        basemap = new BaseMap({
          baseLayers: [
            new VectorTileLayer({
              portalItem: {
                id: user.appsettings.map.typeMap
              }
            })
          ]
        });
      }
      else {
        basemap = (user.appsettings.map && user.appsettings.map.typeMap) || 'osm'; 
      }

      this.map = new Map({
        basemap : basemap
      });
      
      this.view = new MapView({
          container: "detourView",
          map: this.map,
          center: location,
          zoom: 15,
          spatialReference: 102100,
          highlightOptions: {
            color: [255, 255, 255, 0],
            haloOpacity: 0,
            fillOpacity: 0
          }
      });
      
      this.layerStreets = new FeatureLayer({
        url : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].url:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].url:RoadNetwork[this.instance.city].url),
        visible : true,
        outFields: (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].fields : ((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].fields:RoadNetwork[this.instance.city].fields)

      });

      this.drawLayer = new GraphicsLayer();
      this.entravesLayer = new GraphicsLayer();
      this.projectsLayer = new GraphicsLayer();
      this.Graphic=Graphic;
      this.Polyline=Polyline;
      this.Point=Point;
      this.geometryEngine=geometryEngine;
      this.geodesicUtils=geodesicUtils;
      this.webMercatorUtils=webMercatorUtils;
      this.spatialReference=spatialReference;
      this.Extent=Extent;
      // this.map.add(this.layerStreets);
      this.map.add(this.projectsLayer);
      this.map.add(this.entravesLayer);
      this.map.add(this.drawLayer);
      
      this.conflictsBuffer = null;
      this.searchBuffer = null;
      this.searchGraph  = null;
    },
    addSearch(Search,Locator) {
      // Search widget
      let search = new Search({
        view : this.view,
        autoSelect : true,
        label : "Some Label",
        locationEnabled : false,
        minSuggestCharacters : 3,
        popupEnabled : false,
        searchAllEnabled : true,
        declaredClass: 'hello',
        includeDefaultSources : (!!this.features.searchSource && this.features.searchSource.isDefaultESRI) || false,
        resultGraphicEnabled : true,
        sources : [],
        resultSymbol :{
                type: "picture-marker",
                url: window.location.protocol + '//'+ window.location.host +  "/static/img/icons/pin.png",
                size: 24,
                width: 24,
                height: 24,
                xoffset: 0,
                yoffset: 0
              }
      });
      if(!!this.features.searchSource && this.features.searchSource.isQuebec){
        search.sources.push(
          {
              locator : new Locator({
                url: "https://servicescarto.mern.gouv.qc.ca/pes/rest/services/Territoire/AdressesQuebec_Geocodage/GeocodeServer"
              }),
              singleLineFieldName: "SingleLine",
              name: "Adresses Quebec Geocodage",
              placeholder: this.$i18n.t('mapPage.serchePlaceHolder'),
              maxResults: 50,
              maxSuggestions: 6,
              outFields: ["*"],
              resultGraphicEnabled: true,
              resultSymbol :{
                type: "picture-marker",
                url: window.location.protocol + '//'+ window.location.host +  "/static/img/icons/pin.png",
                size: 24,
                width: 24,
                height: 24,
                xoffset: 0,
                yoffset: 0
              } 
            }
        )
      }

      if(!!this.features.searchSource && this.features.searchSource.isDefaultCenterline){
        search.sources.push(
          {
            layer : this.layerStreets,
            searchFields : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].searchFields:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].searchFields:RoadNetwork[this.instance.city].searchFields),
            displayField : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].displayField:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].displayField:RoadNetwork[this.instance.city].displayField),
            exactMatch   : false,
            prefix: "",
            suffix: "",
            maxResults: 50,
            maxSuggestions: 6,
            name         : "Rue",
            outFields    : ["*"],
            placeholder  : this.$i18n.t('mapPage.serchePlaceHolder'),
            resultGraphicEnabled : true
          }
        )
      }
      let _this = this;
      /*search.on("search-complete", function(event){
        _this.cleanSearchGraph();
        let paths = [], dists = [];
        
        paths = event.results[0].results.map(function(result){
          dists.push(5);
          return result.feature.geometry;
        });
        if (paths.length > 0){
          _this.searchBuffer = _this.geometryEngine.geodesicBuffer(paths, dists, _this.conflictsUnits, true)[0];
          var g = new _this.Graphic({
            geometry : _this.searchBuffer,
            visible : true,
            symbol  : {
              type: "simple-fill",
              color: _this.drawColors.search,
              style: "backward-diagonal",
              outline: {  // autocasts as new SimpleLineSymbol()
                color: _this.drawColors.search,
                width: 3
              }
            }
          });
          _this.drawLayer.add(g);
          _this.searchGraph = g;
        }
        
      });*/
      this.view.ui.add(search, "top-right");
    },
    
 
    drawClosing(Graphic) {
        this.closing.selected.path.forEach(graphic => {
          this.entravesLayer.add(Graphic.fromJSON(graphic));
        });
      this.view.goTo(this.entravesLayer.graphics.toArray());
        
    },
    
    loadMap() {
      loadModules([
      "esri/Map",
      "esri/Basemap",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/layers/GraphicsLayer",
      "esri/geometry/Extent",
      "esri/geometry/Polyline",
      "esri/geometry/Point",
      "esri/tasks/support/Query",
      "esri/geometry/geometryEngine",
      "esri/Graphic",
      "esri/widgets/Search",
      "esri/geometry/support/geodesicUtils",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/SpatialReference",
      "esri/layers/VectorTileLayer",
      "esri/tasks/Locator",
      "esri/widgets/Sketch",
      "esri/widgets/Sketch/SketchViewModel",])
      .then(([Map, BaseMap, MapView, FeatureLayer, GraphicsLayer, Extent, Polyline, Point, Query, geometryEngine, Graphic, Search, geodesicUtils, webMercatorUtils, spatialReference,VectorTileLayer,Locator, Sketch, SketchViewModel]) => {
        let _this=this; 
        this.initLoad(Map, BaseMap, MapView, FeatureLayer, GraphicsLayer, Graphic, geometryEngine, Extent, Polyline, Point, geodesicUtils, webMercatorUtils, spatialReference, VectorTileLayer);
        
        
        const sketch = new Sketch({
          layer: _this.drawLayer,
          view: _this.view,
          creationMode: "update",
        });

        sketch.viewModel.pointSymbol = {
          type: "simple-marker",
          style: "circle",
          color: "rgba(247, 146, 30,0.5)",
          size: "8px",
          outline: {
            color: "white",
            width: 1
          }
        };

        sketch.viewModel.polygonSymbol = {
          type: "simple-fill",
          color: "rgba(247, 146, 30,0.5)",
          style: "solid",
          outline: {
            color: "white",
            width: 1
          }
        };

        sketch.viewModel.polylineSymbol = {
          type: "simple-line",
          color: "rgba(247, 146, 30,0.5)",
          width: "2",
          style: "dash"
        };
        _this.view.ui.add(sketch, "bottom-trailing");
        _this.view.focus();
        _this.view.when(()=>{
            _this.addSearch(Search,Locator);
        })

        this.drawClosing(Graphic);
        console.log("closing",this.closing)

        if (_this.selectedDetour && _this.selectedDetour.selected.path.length > 0 ) {
            _this.drawPolygonDetour(Graphic);
        }
       
      });   
    },
    drawPolygonDetour(Graphic){
      console.log("this is polygon")
      // const graphics = JSON.parse(this.selectedDetour.selected.path);
      console.log(this.selectedDetour.selected.path)
      this.drawLayer.removeAll();
      this.selectedDetour.selected.path.forEach(graphic => {
        this.drawLayer.add(Graphic.fromJSON(graphic));
      });
      this.view.goTo(this.drawLayer.graphics.toArray());

    },
    saveMap(){
      if (!this.drawLayer) return;
      const graphics = this.drawLayer.graphics.map(g => g.toJSON());
      this.detour.label=this.closing.label,
      this.detour.value=this.closing.label,
      this.detour.selected.path=graphics._items,
      this.detour.type="polygon",

      this.$emit('saveMapModal', this.detour)
    }
  },
  mounted() {}
}
</script>
<style>
  .modal-content .modal-header button.close {
    top: 5px !important;
  }
  
  div.modal-footer {
    border-top: 1px solid #b0b0b1 !important;
    padding-top: 16px !important;
  }
  
  ul.esri-search__suggestions-list li,
  ul.esri-search__suggestions-list li strong {
    color: #000 !important;
  }
   #detourView {
    padding: 0;
    margin: 0;
    height: 80%;
    width: 100%;
  }
  .select-closing {
    height: 20%
  }
</style>