<template>
  <div class="">
    <div class="mt-3 text-right">
      <base-button class="" v-if="isAdmin || isUser || isUserRes" @click="$router.push('/projects/create')" type="primary">
         {{ $t('projectList.btnNewProject') }}
      </base-button>
      <base-button v-if="!!features.quickCreateProject && features.quickCreateProject.show && (isAdmin || isUser || isUserRes)" class=""  @click="$router.push('/projects/quick/create')" type="info">
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" height="16px"  fill="#fff" class="bi bi-lightning-fill mr-1"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"></path> </g></svg> 
        {{ $t('quickCreate.btnQuickCreate') }} 
      </base-button>
      <!-- <base-button v-if="city==='gis' || city==='roadworks'" class="import-users" @click="getAllUUIDS" type="default">
        {{ $t('projectList.btnExportGeoJson') }}
      </base-button> -->
      <base-button v-if="features.exportProjectButtonOnProjectListingHeader.show"  class="import-users" @click="exportProjectsList" type="default">
        {{ $t('projectList.btnExport') }}
      </base-button>
    </div>
    <div class="row mt-3">
      <card>
        <template slot="header">
          <h3 class="card-title"></h3>
        </template>
        <tabs type="primary" v-on:activateTab="activateTab($event)">
          <tab-pane id="projects" v-bind:label="$t('projectList.ProjectsList')">
            <card class="projects-card" card-body-classes="table-full-width">
              <div slot="header" class="row">
                <div class="col-sm-3 col-md-3 col-lg-3 list-project-tags">
                  <el-select v-model="value" multiple v-bind:placeholder="$t('projectList.placeHoldFilter') + '...'" popper-class="filters-project" @remove-tag="removeTags()" @visible-change="visibleChange($event)">
                    <el-option-group
                      :class="filters-project-group"
                      v-for="group in options"
                      :key="group.key"
                      :label="group.label">
                      <el-option
                        :class=""
                        v-for="item in group.options"
                        :key="item.value"
                        :label="item.label"
                        :value="`${group.key}.${item.value}`">
                      </el-option>
                    </el-option-group>
                  </el-select>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3">
                  <base-input
                    v-bind:placeholder="$t('projectForm.search')"
                    v-model="filters.tags.search"
                    @keyup="onSearchInputChange"
                    class="search-input"
                  >
                  </base-input>
                </div>
                <!-- <div  class="col-sm-1 col-md-1 col-lg-1">
                </div> -->
                <div  class="col-sm-2 col-md-2 col-lg-2 list-project-type">
                  <el-select
                    class="select-primary select-inline"
                    name="published"
                    size="large"
                    @change="onChangePublish"
                    v-model="published">
                    <el-option
                      v-for="option in publishFilter"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4 list-project-date">
                  <base-input name="date">
                    <el-date-picker
                      type="datetimerange"
                      value-format="timestamp"
                      @change="onChangeDate"
                      range-separator="-"
                      format="yyyy-MM-dd HH:mm"
                      lang="fr"
                      v-bind:start-placeholder="$t('projectForm.labelProjectStart')"
                      v-bind:end-placeholder="$t('projectForm.labelProjectEnd')"
                      v-model="date">
                    </el-date-picker>
                  </base-input>
                </div>
              </div>
              <div class="loading" v-if="loading">
                <img src="../../../public/img/loading.gif" />
              </div>
              <div v-else>
                <div v-show="tableData.length==0">
                  <base-alert type="info"> {{ $t('projectList.noProjects') }}</base-alert>
                </div>
                <div v-show="tableData.length > 0" style="overflow-x: auto;">
                  <base-table :data="tableData" thead-classes="text-primary project-table-head" tbody-classes="project-table-body">
                    <template slot="columns" slot-scope="{ columns }">
                      <th v-if="isAdmin || isUser" width="30">
                        <base-checkbox v-model="selectAll" class="projects-selected" @input='onSelectAll($event)' ></base-checkbox>
                      </th>
                      <th width="20"></th>
                      <th></th>
                      <th width="300"><span class="column" @click="handleColumnSorting('project')">{{ $t('projectList.labelProject') }}</span><span  v-if="sortBy.column === 'project'"><ArrowIcon :direction="arrowDirection" size="16" /></span></th>
                      <th v-if="tags.district.show && tags.district.showInList">{{ tags.district.title.label }}</th>
                      <th v-if="tags.step.show && tags.step.showInList"><span class="column" @click="handleColumnSorting('project_phase')">{{ $t('projectList.labelProjectStep') }}</span><span v-if="sortBy.column === 'project_phase'"><ArrowIcon :direction="arrowDirection" size="16" /></span></th>
                      <th v-if="tags.entity.show && tags.entity.showInList">{{ $t('projectList.labelProjectEntity') }}</th>
                      <th v-if="tags.city.show && tags.city.showInList">{{ $t('projectList.labelProjectCity') }}</th>
                      <th v-if="tags.status.show && tags.status.showInList"><span class="column" @click="handleColumnSorting('status')">{{ $t('projectList.labelProjectStatus') }}</span><span v-if="sortBy.column === 'status'"><ArrowIcon :direction="arrowDirection" size="16" /></span></th>
                      <th v-if="tags.workType.show && tags.workType.showInList">{{ tags.workType.title.label }}</th>
                      <th v-if="tags.sector.show && tags.sector.showInList">{{ tags.sector.title.label }}</th>
                      <th v-if="tags.distp.show && tags.distp.showInList">{{ tags.distp.title.label }}</th>
                      <th v-if="tags.dista.show && tags.dista.showInList">{{ tags.dista.title.label }}</th>
                      <th v-if="tags.passanger.show && tags.passanger.showInList">{{ tags.passanger.title.label }}</th>
                      <th v-if="tags.trafficImpact.show && tags.trafficImpact.showInList">{{ tags.trafficImpact.title.label }}</th>
                      <th v-if="tags.portableWater.show && tags.portableWater.showInList">{{ tags.portableWater.title.label }}</th>
                      <th v-if="tags.workType1.show && tags.workType1.showInList">{{ tags.workType1.title.label }}</th>
                      <th v-if="tags.serviceInfra.show && tags.serviceInfra.showInList">{{ tags.serviceInfra.title.label }}</th>
                      <th v-if="tags.servicePublic.show && tags.servicePublic.showInList">{{ tags.servicePublic.title.label }}</th>
                      <th width="300"><span class="column" @click="handleColumnSorting('period')">{{ $t('projectList.labelProjectDuration') }}</span><span v-if="sortBy.column === 'period'"><ArrowIcon :direction="arrowDirection" size="16" /></span></th>
                      <th v-if="isAdmin || isUser" width="0" class="text-right btn-header"></th>
                    </template>
                
                    <template slot-scope="{ row, index }" >
                      <td v-if="isAdmin || isUser"><base-checkbox value="row.uuid" class="projects-selected" @input='onSelectProject($event, row.uuid)' ></base-checkbox></td>
                      <td>
                        <div class="icon-row">
                          <span v-if="isExceed(row)"><i style="color: #cc6633;" class="tim-icons icon-alert-circle-exc"></i></span>
                          <span v-if="isQuick(row) && !!features.quickCreateProject && features.quickCreateProject.show">
                            <i>
                              <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" height="16px"  fill="#5175ed" class="bi bi-lightning-fill"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"></path> </g></svg>
                            </i>
                          </span>
                          <span v-if="isDraftState(row) && !!features.quickCreateProject && features.quickCreateProject.show">
                            <i class="tim-icons el-icon-edit-outline icon-draft"></i>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div style="width: 30px;height: 34px;" v-html="row.icon"></div>
                      </td>
                      <td>
                        <!--<p @click="showInMap(row.uuid)" class="title">{{ row.name }}</p>-->
                        <p v-if="isAdmin || isUser || isUserRes" class="title" @click="updateProject(row)" style="cursor: pointer !important;" >{{ row.name }}</p>
                        <p v-else class="title" @click="viewProject(row.uuid)" style="cursor: pointer !important;" >{{ row.name }}</p>
                        <p class="text-muted" v-html="row.subTitle"></p>
                      </td>
                      <td  v-for="key in listFilters" v-bind:key="key" >
                        {{ row.data.informations[key] }}
                      </td>
                      <td >
                        {{ $t('mapPage.from')}}: {{ row.sdate | formatDate }} {{ $t('mapPage.to')}} {{ row.edate | formatDate }}
                      </td>
                      <td v-if="isAdmin || isUser"  >
                        
                        <div class="text-right btn-container">
                          <el-tooltip
                            v-bind:content="$t('projectList.edit')"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                            <base-button
                              :type="'success'"
                              icon
                              size="sm"
                              @click.native="updateProject(row)"
                              class="btn-link">
                              <i class="tim-icons icon-pencil"></i>
                            </base-button>
                          </el-tooltip>
                          <el-tooltip
                            v-if="features.projectReminderButtonInProjectListing.show"
                            v-show="canAlert(row)"
                            v-bind:content="$t('projectList.alert')"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                            <base-button
                              :type="'info'"
                              icon
                              size="sm"
                              @click.native="showModalAlert(row)"
                              class="btn-link">
                              <i style="" class="tim-icons icon-time-alarm"></i>
                            </base-button>
                          </el-tooltip>
                          <el-tooltip
                            v-show="canEmail(row)"
                            v-bind:content="$t('projectList.report')"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                            <base-button
                              :type="'warning'"
                              icon
                              size="sm"
                              @click.native="showModalReport(row.uuid)" 
                              class="btn-link">
                              <i class="tim-icons icon-email-85"></i>
                            </base-button>
                          </el-tooltip>
                          <el-tooltip
                            v-show="isShare"
                            v-bind:content="$t('projectList.share')"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                            <base-button
                              :type="'info'"
                              icon
                              size="sm"
                              @click.native="shareProject1(row.uuid)"
                              class="btn-link">
                              <i style="" class="fas fa-share"></i>
                            </base-button>
                          </el-tooltip>
                          <el-tooltip
                            v-if="isAdmin || isUser"
                            v-show="false"
                            v-bind:content="$t('projectList.archive')"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                            <base-button
                              :type="'primary'"
                              icon
                              size="sm"
                              @click.native="archiveProject(row.uuid)"
                              class="btn-link">
                              <i class="tim-icons icon-book-bookmark"></i>
                            </base-button>
                          </el-tooltip>
                          <el-tooltip
                            content="Supprimer"
                            v-bind:content="$t('projectList.delete')"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                            <base-button
                              :type="'danger'"
                              icon
                              size="sm"
                              @click.native="deleteProject(row.uuid)"
                              class="btn-link">
                              <i class="tim-icons icon-trash-simple"></i>
                            </base-button>
                          </el-tooltip>
                          <el-tooltip
                            v-if="features.exportProjectButtonInProjectListing.show" 
                            content="Supprimer"
                            v-bind:content="$t('projectList.Export')"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                            <base-button
                              :type="'primary'"
                              icon
                              size="sm"
                              @click.native="exportProjectGeoJson(row.uuid)"
                              class="btn-link">
                              <i class="tim-icons icon-globe-2"></i>
                            </base-button>
                          </el-tooltip>
                        </div>
                      </td>
                    </template>
                  </base-table>
                </div>
              </div>
              <div  slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <div v-show="tableData.length > 0" class="col-sm-3 list-project-action" style="display: block ruby;">
                  <el-select
                    clearable
                    v-if="isAdmin || isUser"
                    placeholder="Action..."
                    class="select-primary select-inline"
                    name="actions"
                    size="large"
                    v-model="action"
                    @change="onChangeAction">
                    <el-option
                      v-for="option in [{value: 'delete', label: $t('projectList.delete'), show: true}]"
                      v-show='option.show'
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                      <base-button
                              :type="'danger'"
                              icon
                              size="sm"
                              class="btn-link">
                              <i class="tim-icons icon-trash-simple"></i>
                            </base-button>
                      {{option.label}}
                    </el-option>
                    <el-option
                      v-for="option in [{value: 'shared', label: $t('projectList.share'), show: isShare},]"
                      v-show='option.show'
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                      <base-button
                              :type="'info'"
                              icon
                              size="sm"
                              class="btn-link">
                              <i class="fas fa-share"></i>
                            </base-button>
                          {{option.label}}
                    </el-option>
                  </el-select>
                  <div style="margin-left: 10px; margin-top:12px"> <i style="color: #cc6633;" class="tim-icons icon-alert-circle-exc"></i> &nbsp;{{ $t('mapPage.exceed') }}</div>
                  <div style="margin-left: 10px; margin-top:12px"> 
                    <i>
                      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" height="16px"  fill="#5175ed" class="bi bi-lightning-fill"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"></path> </g></svg>
                    </i> &nbsp;{{ $t('mapPage.quickCreate') }}
                  </div>
                  <div style="margin-left: 10px; margin-top:12px"> <i  class="tim-icons el-icon-edit-outline icon-draft"></i> &nbsp;{{ $t('mapPage.isDraft') }}</div>
                </div>
                <div v-show="tableData.length > 0" class="paginate-container">
                  <p class="card-category"> {{ $t('projectList.pageResultsFrom') }} {{ from + 1 }} {{ $t('projectList.pageResultsTo') }} {{ to }} {{ $t('projectList.pageResultsOn') }} {{ total }}</p>
                  <base-pagination
                    v-show="tableData.length > 0"
                    class="pagination-no-border"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total">
                  </base-pagination>
                </div>
              </div>
            </card>
          </tab-pane>
          <tab-pane  v-if="isAdmin || isUser  || isViewer" id="conflicts" v-bind:label="$t('projectList.ProjectsConflict')">
            <projects-conflicts v-bind:showConflicts="showConflicts" /></projects-conflicts>
          </tab-pane>
        </tabs>
      </card>
      <share-project v-if="isAdmin || isUser" v-bind:viewerRes="viewerRes" v-bind:userRes="userRes" v-bind:shareProject='shareProject' v-bind:selectedProject='selectedProject'  v-on:hideShareProject="hideShareProject($event)" /></app-settings>
      <report-project v-bind:reportProject="reportProject" v-bind:reportProjectUUID="reportProjectUUID" v-bind:reportProjectInfo="reportProjectInfo" v-on:hideReportProject="hideReportProject($event)" /></report-project>  
      <alert-project :key="componentKey" v-bind:alertProject='alertProject' v-bind:sDate="sDate" v-bind:eDate="eDate" v-bind:alertProjectUUID="alertProjectUUID" v-bind:alertProjectPublish="alertProjectPublish" v-on:hideAlertProject="hideAlertProject($event)" /></alert-project>
    </div>
    <modal :show.sync="exportProject" modal-classes="modal-dialog-centered" :scrollToBottom="false" v-on:hideModal="exportProject = false">
        <template slot="header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
        </template>
        <template slot="close-button">
          <button type="button" class="close" @click="exportProject = false" data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
        </template>
        <div class="padding-model center-btn">
          <base-button  v-if="features.exportProjectButtonOnProjectListingHeader.exportCsv.show" class="import-users-margin" @click="exportCsv" type="default">
          {{ $t('projectList.Export') }} CSV
          </base-button>
          <base-button v-if="features.exportProjectButtonOnProjectListingHeader.exportXls.show" class="import-users-margin" @click="exportXls" type="default">
          {{ $t('projectList.Export') }} XLS
          </base-button>
          <base-button v-if="features.exportProjectButtonOnProjectListingHeader.exportGeojson.show" class="import-users-margin" @click="getAllUUIDS" type="default">
          {{ $t('projectList.Export') }} GeoJSON
          </base-button>
        </div>
    </modal>
  </div>
</template>
<script>
import axios from 'axios';
import { Table, TableColumn, Select, Option, OptionGroup, TimeSelect, DatePicker } from 'element-ui';
import { BasePagination, TabPane, Tabs, BaseAlert, ArrowIcon } from 'src/components';
import { BaseTable } from '@/components';
import swal from 'sweetalert2';

import AlertProject from './AlertProject';
import ShareProject from './ShareProject';
import ReportProject from './ReportProject';
import ProjectsConflicts from './ProjectsConflicts';

import tags from './../shared/filters';
import features from './../shared/features';
import moment from 'moment';
import icons from './../shared/icons';
import { Modal } from 'src/components';
import {Badge} from 'src/components'
import helpersDrawProject from '../helpers/helpersDrawProject';


var filtersKeys = ['district', 'typeOfClosure', 'step', 'entity', 'city', 'status', 'workType', 'sector', 'distp', 'dista', 'passanger', 'trafficImpact', 'portableWater', 'workType1', 'serviceInfra', 'servicePublic'];

export default {
  components: {
    Modal,
    TabPane,
    Tabs,
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseTable,
    [Option.name]: Option,
    [Select.name]: Select,
    [OptionGroup.name]: OptionGroup,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    BaseAlert,
    ShareProject,
    ReportProject,
    ProjectsConflicts,
    AlertProject,
    ArrowIcon,
    Badge
  },
  filters: {
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY hh:mm:ss');
    }
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    isAdmin() {
      return (window.localStorage.getItem('role')==='admin' || window.localStorage.getItem('role')==='adminGis');
    },
    isUser() {
      return window.localStorage.getItem('role')==='user';
    },
    isUserRes() {
      return window.localStorage.getItem('role')==='userRes';
    },
    isViewer() {
      return window.localStorage.getItem('role')==='viewer';
    },
    isViewerRes() {
      return window.localStorage.getItem('role')==='viewerRes';
    },
    isShare() {
      if ((this.userRes.length > 0) || (this.viewerRes.length > 0)) {
        return true;
      } else {
        return false;
      }
    },
    arrowDirection() {
      return this.sortBy.order
    },
  },
  created() {
    let  instance = JSON.parse(window.localStorage.getItem('instance'));
    this.city=instance.city;
    this.user = JSON.parse(window.localStorage.getItem('user'));
    this.tags = tags[this.city];
    this.features = features[this.city];

    if(!!this.features.quickCreateProject && this.features.quickCreateProject.show){
      this.publishFilter.push({value: 'draft', label: this.$i18n.t('select.draft')})  
    }
    //delete this.filters.city;
    let format = {
      'district': this.$i18n.t('projectList.ladelDistrict'),
      'entity':  this.$i18n.t('projectList.labelProjectEntity'),
      'step': this.$i18n.t('projectList.labelProjectStep'),
      'city': this.$i18n.t('projectList.labelProjectCity'),
      'status': this.$i18n.t('projectList.labelProjectStatus'),
      'workType': this.$i18n.t('projectList.labelProjectType')/*,
      'serviceInfra': this.$i18n.t('projectList.workingLabel2'),
      'servicePublic': this.$i18n.t('projectList.workingLabel3')*/
    };
    let _this = this;
    let keys = Object.keys(this.tags);
    keys.forEach(function(key) {
      _this.tags[key].value.forEach((element)=> {
        if (_this.user.lang=='fr') {
          if (element.label_fr) {
            element.label=element.label_fr;
          }
        } else {
          if (element.label_en) {
            element.label=element.label_en;
          }
        }
      });
      if (_this.tags[key].show && _this.tags[key].search) {
        var label;
        if (_this.user.lang=='fr') {
          label = (_this.tags[key].title && _this.tags[key].title.label_fr) || format[key];
        } else {
          label = (_this.tags[key].title && _this.tags[key].title.label_en) || format[key];
        } 
        let element = {
          key: key,
          label: label,
          options: _this.tags[key].value
        };
        _this.options.push(element);
      }
      if (_this.user.lang=='fr') {
        if (_this.tags[key].title && _this.tags[key].title.label_fr) {
          _this.tags[key].title.label=_this.tags[key].title.label_fr;
        }
      } else {
        if (_this.tags[key].title && _this.tags[key].title.label_en) {
          _this.tags[key].title.label=_this.tags[key].title.label_en;
        }
      }
    });
    filtersKeys.forEach((e) => {
      if (_this.tags[e] && _this.tags[e].show && _this.tags[e].showInList) {
        _this.listFilters.push(e);
      }
    });
    if (!_this.filters.tags.step) {
      _this.filters.tags.step=[''];
      _this.tags['step'].value.forEach(element => {
        if((element.value!='7') && (element.value!='8')) {
          _this.filters.tags.step.push(element.value);    
        }
      });
    } 
    this.getProjects();
  },
  mounted() {
    this.restrictUsers();
  },
  data() {
    return {
      selectAll: false,
      reportProject: false,
      reportProjectUUID: null,
      alertProjectUUID: null,
      alertProjectPublish: null,
      reportProjectInfo: {
        reportDistrict: null,
        reportWorkType: null,
        reportPhasesLen: 0,
        reportPhases: []
      },
      filters: {
        tags: {
          search: ""
        },
        linear: true,
        ponctuel: true
      },
      tags: null,
      features: null,
      options: [],
      value: null,
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      published: '',
      publishFilter: [
        {value: '', label: this.$i18n.t('select.all')},
        {value: 'published', label: this.$i18n.t('select.publish')},
        {value: 'nopublished', label: this.$i18n.t('select.nopublish')},
      ],
      date: '',
      tableData: [],
      total: 0,
      listFilters: [],
      selectedProject: [],
      action: null,
      shareProject: false,
      alertProject: false,
      userRes: [],
      viewerRes: [],
      loading:false,
      showConflicts: false,
      componentKey: 0,
      city: '',
      sortBy:{
        column:"period",
        order: '-1'
      },
      sDate : null,
      eDate : null,
      exportProject: false,
      user:null 
    };
  },
  methods: {
    handleColumnSorting(name) {
        const { column, order } = this.sortBy;
        let sortingOrder = '1';
        if(column === name){
          sortingOrder = order === "1" ? "-1": "1"
        }
        this.sortBy = {
          column: name,
          order: sortingOrder
        }
    },
    formatImage(project) {
      let path=icons.cone;
      let color='#ED732E';
      let img='';
      let link = '#/app?uuid=' + project.uuid;
      if(this.city == "mississauga"){
        path = 'static/img/icons/cone.svg';
        let entityStr = project.data.informations.entity;
        if(entityStr != ""){
          entityStr = entityStr.split(',')[0].trim();
          let matchedEntity = this.tags.entity.value.find(obj => obj.label.toLowerCase() === entityStr.toLowerCase()).value;
          path = helpersDrawProject.fetchEntityIcon(matchedEntity);
          color = helpersDrawProject.fetchEntityIconColor(matchedEntity);
        }
        img=`<a href="#/app?uuid=${project.uuid}" style="margin-left:6px;width:30px;height:34px"><img src="${path}" style="width:30px;height:34px"/></a>`;
      }else
      {
        if (project.typeProject=='linear' && project.publish) {
          path=icons.publishLineair;
          color='#fd77a4';
        } else if (project.typeProject=='ponctuel' && project.publish) {
          path=icons.publishPonctuel;
          color='#419ef9';
        } else if (project.typeProject=='linear' && !project.publish) {
          path=icons.draftLineair;
          color='#ff9f89';
        } else if (project.typeProject=='ponctuel' && !project.publish) {
          path=icons.draftPonctuel;
          color='#ff9f89';
        } 
        img='<a href="' + link + '"><svg viewBox="0 -4 1 32" height="32" width="32"><path d="'+ path +'" fill="' + color + '"/></svg></a>';
      }
      return img;
    },
    canAlert(row) {
      let dates = row.data.entraves.date;
      let now = new Date().getTime();
      return (now < dates[1]);
    },
    canEmail(row) {
      let closeNb=0;
      row.data.phases.forEach((e) => {closeNb+=e.closing.length;});
      if((this.isDraftState(row) && !!features.quickCreateProject && features.quickCreateProject.show)) return false;
      // if ((this.city==='gatineau') && (closeNb===0)) return false
      return true;
    },
    isExceed(row) {
      let dates = row.data.entraves.date;
      let now = new Date().getTime();
      return (now > dates[1]);
    },
    isQuick(row){
      if(!!row.data.projectType && row.data.projectType.isQuick){
        return true;
      }
      else{
        return false;
      }
    },
    isDraftState(row){
      if(!!row.data.projectType && row.data.projectType.isDraft){
        return true;
      }
      else{
        return false;
      }
    },
    isPublishState(row){
      if(!!row.data.projectType && row.data.projectType.isPublished){
        return true;
      }
      else{
        return false;
      }
    },
    showModalReport(uuid) {
      let _this = this;
      this.reportProjectUUID=uuid;
      this.reportProject = true;
      this.tableData.forEach((e) => {
        if (e.uuid==uuid) {
          let data = e.data;
          _this.reportProjectInfo.reportDistrict=data.informations.district;
          _this.reportProjectInfo.reportWorkType=data.informations.workType;
          _this.reportProjectInfo.site=data.informations.distp;
          _this.reportProjectInfo.reportPhasesLen = data.phases.length;
          _this.reportProjectInfo.reportPhases = data.phases.map((e, i) => { 
            let closing = e.closing.map((close, index) => {
              return {index:index, closeId:close.closingId}
            });
            return {index:i, phaseId:e.phaseId, closing: closing}; 
          });
        }
      });
    },
    showModalAlert(row) {
      this.componentKey += 1
      this.alertProject = true;
      this.alertProjectUUID=row.uuid;
      this.alertProjectPublish=row.publish;
      this.sDate = row.sdate;
      this.eDate = row.edate;
    },
    hideAlertProject() {
      this.alertProject = false;
      this.alertProjectUUID=null;
      this.alertProjectPublish=null;
      this.sDate = null;
      this.eDate = null;
    },
    hideReportProject() {
      this.reportProject=false;
      this.reportProjectUUID=null;
      this.reportProjectInfo.reportDistrict=null;
      this.reportProjectInfo.reportWorkType=null;
      this.reportProjectInfo.reportPhasesLen=0;
      this.reportProjectInfo.reportPhases=[];
    },
    visibleChange(val) {
      if (!val) {
        //this.getProjects();
      }
    },
    removeTags(p) {
      //this.getProjects();
    },
    updateTags(params) {
      let _this=this;
      _this.filters.tags={};
      params.forEach(function(element) {
        let entry = element.split('.');
        if (_this.filters.tags[entry[0]]) {
          _this.filters.tags[entry[0]].push(entry[1]);
        } else {
          _this.filters.tags[entry[0]] = [entry[1]];
        }
      });
      if (!_this.filters.tags.step) {
        _this.filters.tags.step=[''];
        _this.tags['step'].value.forEach(element => {
          if((element.value!='7') && (element.value!='8')) {
            _this.filters.tags.step.push(element.value);    
          }
        });
      } 
      //this.getProjects();
    },
    getProjects() {
      let _this = this;
      _this.loading=true;
      if (_this.published==='published') {
        _this.filters.publish = true;
        _this.filters.nopublish = false;
        delete _this.filters.tags.isDraft;
      } else if (_this.published==='nopublished') {
        _this.filters.publish = false;
        _this.filters.nopublish = true;
        delete _this.filters.tags.isDraft;
      }else if (_this.published=== 'draft'){
        _this.filters.publish = true;
        _this.filters.nopublish = true;
        _this.filters.tags.isDraft = true;
      } 
      else {
        _this.filters.publish = true;
        _this.filters.nopublish = true;
        delete _this.filters.tags.isDraft;
      }
      
      _this.filters.ranges = _this.date || [];
      
      _this.filters.from = (_this.pagination.perPage * (_this.pagination.currentPage - 1)) + 1;
      _this.filters.limit = _this.pagination.perPage;

      _this.filters.sort_by =  _this.sortBy
      axios.post('project/get_projects', _this.filters).then(response => {
        let data = response.data;
        if (data.success) {
          _this.loading=false;
          _this.tableData = _this.formatSubTitle(_this.formatFilters(data.data));
          _this.total = data.total;
        } else {
          if (data.need_auth) {
            _this.$router.push({path: '/login'});
          } else {
            _this.$notify({
              message:  _this.$i18n.t('serverReply.errorProject'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch((e) => {
        console.log(e)
        _this.$notify({
          message:  _this.$i18n.t('serverReply.errorProject'),
          timeout: 10000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      }); 
    },
    formatFilters(data) {
      let _this = this;
      let differences = filtersKeys.filter(x => !_this.listFilters.includes(x));
      let List = data.map(function(element) {
        _this.listFilters.forEach(function(filter) {
          let NValue = _this.getFilterValue(filter, element.data.informations[filter]);
          element.data.informations[filter] = NValue;
        });
        differences.forEach(function(filter) {
          delete element.data.informations[filter];
        });
        return element;
      });
      return List;
    },
    getFilterValue(filter, data) {
      let selectedFilter = this.FiltersObject(this.tags[filter].value);
      if (Array.isArray(data)) {
        return data.map(function(element) {
          return selectedFilter[element]; 
        }).join(', ');
      } else {
        return selectedFilter[data];
      }
    },
    formatSubTitle(data) {
      let _this=this;
      return data.map(function(element) {
        if (element.data && element.data.entraves && element.data.entraves.entraves.length) {
          element.subTitle = element.data.entraves.entraves[0].value;
        } else {
         element.subTitle = '';
        }
        element.icon=_this.formatImage(element);
        return element;
      });
    },
    FiltersObject(list) {
      let obj = {};
      list.forEach((e) => {
        obj[e.value]=e.label;
      });
      return obj;
    },
    onChangePublish() {
      this.getProjects();
    },
    onChangeDate() {
      this.getProjects();
    },
    updateProject(row) {
      if(this.isQuick(row)){
        this.$router.push({path: '/projects/quick/edit?uuid=' + row.uuid});
      }
      else{
        this.$router.push({path: '/projects/edit?uuid=' + row.uuid});
      }
    },
    viewProject(uuid) {
      this.$router.push({path: '/projects/view?uuid=' + uuid});
    },
    showInMap(uuid) {
      this.$router.push({path: '/focus?uuid=' + uuid});
    },
    onChangeAction(event) {
      this.action=event;
      if (this.selectedProject.length > 0) {
        if (this.action=='shared') {
          this.shareProject = true;
        } else if (this.action=='export') {
          this.exportProjects(this.selectedProject);
        }else {
          this.deleteProject(this.selectedProject);
        }
      } else {
        this.$notify({
          message: 'Please select your project(s)..',
          timeout: 30000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
         this.action=null;
      }
    },
    shareProject1(uuid) {
      let _this=this;
      this.selectedProject.forEach(function(e, i) {
        if (e===uuid) {
          _this.selectedProject.splice(i, 1);
        }
      });
      this.selectedProject.push(uuid);
      this.shareProject = true;
    },
    hideShareProject() {
      this.shareProject = false;
      document.querySelectorAll('div.projects-selected input').forEach((e) =>{ if (e.checked) e.click();});
      this.selectedProject = [];
      this.action=null;
    },
    onSelectProject(checked, uuid) {
      if (checked) {
        this.selectedProject.push(uuid);  
      } else {
        let _this=this;
        this.selectedProject.forEach((e,i) => {
          if (e===uuid) {
            _this.selectedProject.splice(i, 1);
          }
        });
      }  
    },
    onSelectAll(checked) {
      document.querySelectorAll('div.form-check.projects-selected input.form-check-input').forEach((e, index) =>{ if (index!=0) {e.click();}});
    },
    archiveProject(uuid) {
      let _this = this;
      swal({
        title: this.$i18n.t('projectList.titleProjectArchive'),
        text:  this.$i18n.t('projectList.textProjectArchive'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: this.$i18n.t('projectList.textConfirmProjectArchive'),
        cancelButtonText: this.$i18n.t('projectList.textCancelProjectArchive'),
        buttonsStyling: false
      }).then((resp) => {
        if (resp.value) {
          axios.post('project/archive', {uuid: uuid}).then(response => {
            let _this = this;
            let data = response.data;
            if (data.success) {
              swal({
                title: this.$i18n.t('projectList.titleArchiveProject'),
                text:  this.$i18n.t('projectList.textArchiveProject'),
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              }).then(() => {
                _this.getProjects();
              });
            } else {
              if (data.need_auth) {
                _this.$router.push({path: '/login'});
              } else {
                _this.$notify({
                  message: _this.$i18n.t('serverReply.errorDeleteProject'),
                  timeout: 10000,
                  icon: 'tim-icons icon-bell-55',
                  horizontalAlign: 'center',
                  verticalAlign: 'top',
                  type: 'danger'
                });
              }
            }
          }).catch(() => {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorDeleteProject'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          });
        }
      });
    },
    deleteProject(uuid) {
      let obj={};
      if (Array.isArray(uuid)) {
        obj={uuid: uuid};
      } else {
        obj={uuid: [uuid]};
      }
      let _this = this;
      swal({
        title: this.$i18n.t('projectList.titleProjectDelete'),
        text:  this.$i18n.t('projectList.textProjectDelete'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: this.$i18n.t('projectList.textConfirmProjectDelete'),
        cancelButtonText: this.$i18n.t('projectList.textCancelProjectDelete'),
        buttonsStyling: false
      }).then((resp) => {
        if (resp.value) {
          axios.post('project/delete', obj).then(response => {
            let _this = this;
            let data = response.data;
            if (data.success) {
              swal({
                title: this.$i18n.t('projectList.titleDeletedProject'),
                text:  this.$i18n.t('projectList.textDeletedProject'),
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              }).then(() => {
                _this.getProjects();
              });
              document.querySelectorAll('div.projects-selected input').forEach((e) =>{ if (e.checked) e.click();});
              _this.selectedProject=[];
              _this.action=null;
            } else {
              if (data.need_auth) {
                _this.$router.push({path: '/login'});
              } else {
                _this.$notify({
                  message: _this.$i18n.t('serverReply.errorExportProject'),
                  timeout: 10000,
                  icon: 'tim-icons icon-bell-55',
                  horizontalAlign: 'center',
                  verticalAlign: 'top',
                  type: 'danger'
                });
              }
            }
          }).catch(() => {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorExportProject'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          });
        }
      });
    },
    exportProjects(uuid) {
      let obj={};
      let user = JSON.parse(window.localStorage.getItem('user'))
      if (Array.isArray(uuid)) {
        obj={uuids: uuid , lang:`${user.lang}`};
      } else {
        obj={uuids: [uuid], lang:`${user.lang}`};
      }
      let _this = this;
      // swal({
      //   title: this.$i18n.t('projectList.titleProjectExport'),
      //   text:  this.$i18n.t('projectList.textProjectExport'),
      //   type: 'warning',
      //   showCancelButton: true,
      //   confirmButtonClass: 'btn btn-success btn-fill',
      //   cancelButtonClass: 'btn btn-danger btn-fill',
      //   confirmButtonText: this.$i18n.t('projectList.textConfirmProjectExport'),
      //   cancelButtonText: this.$i18n.t('projectList.textCancelProjectExport'),
      //   buttonsStyling: false
      // }).then((resp) => {
      //   if (resp.value) {
          axios.post('project/exportProjects', obj).then(response => {          
            if (response.status===200) {
            console.log("response123", response)
            const filename = response.headers["content-disposition"].split("=")[1];
            const blob = new Blob([JSON.stringify(response.data)], { type: "application/json" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            URL.revokeObjectURL(link.href);         
            let _this = this;            
            document.querySelectorAll('div.projects-selected input').forEach((e) =>{ if (e.checked) e.click();});
              _this.selectedProject=[];
              _this.action=null;
            } else {
              if (data.need_auth) {
                _this.$router.push({path: '/login'});
              } else {
                _this.$notify({
                  message: _this.$i18n.t('serverReply.errorExportProject'),
                  timeout: 10000,
                  icon: 'tim-icons icon-bell-55',
                  horizontalAlign: 'center',
                  verticalAlign: 'top',
                  type: 'danger'
                });
              }
            }
          }).catch(() => {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorExportProject'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          });
        // }
      // });
    },
    getAllUUIDS() {
      let _this = this;
      let uuidArray = [];
      // _this.tableData.map((val)=>{
      // uuidArray.push(val.uuid);
      // })
      _this.exportProjects(uuidArray);
      
    },
    exportProjectGeoJson(uuid) {
      let user = JSON.parse(window.localStorage.getItem('user'));
      window.open('project/exportProj?uuid='+uuid+"&lang="+user.lang,"geojson");
    },
    restrictUsers() {
      let shared = ((window.localStorage.getItem('role')=='user') ||  (window.localStorage.getItem('role')==='admin') ||(window.localStorage.getItem('role')==='adminGis'));
      if (shared) {
        let _this = this;
        axios.post('/user/restricted', {}).then(response => {
          let data = response.data;
          if (data.success) {
            _this.userRes=data.data.userRes.map((e) => {e.checked=false; return e;});
            _this.viewerRes=data.data.viewerRes.map((e) => {e.checked=false; return e;});
          } else {
            if (data.need_auth) {
              _this.$router.push({path: '/login'});
            } else {
              _this.$notify({
                message: this.$i18n.t('serverReply.errorShareProject'),
                timeout: 10000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              });
            }
          }
        }).catch(() => {
          _this.$notify({
            message: this.$i18n.t('serverReply.errorShareProject'),
            timeout: 10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
        }); 
      }
    },
    activateTab(params) {
      if ((params.$el.getAttribute('id')=='conflicts')) {
        this.showConflicts=true;
      } else {
        this.showConflicts=false;
      }
    },
    onSearchInputChange(e) {
      if(e.keyCode === 13 || e.target.value === "") {
        this.getProjects();
      }
    },
    exportProjectsList(){
      console.log("Export projects");
      this.exportProject = true;
    },
    exportCsv(){
      window.open("project/csv?lang="+this.user.lang, "download");
    },
    exportXls(){
      window.open("project/xls?lang="+this.user.lang, "download");
    },
  },
  watch: {
    'pagination.currentPage'() {
      this.selectAll=false;
      this.getProjects();
    },
    'value'(val) {
      this.updateTags(val);
      if (this.pagination.currentPage==1) {
        this.getProjects();
      } else {
        this.pagination.currentPage=1;
      }
    },
    'showConflicts'(val) {
      if (!val) {
        this.getProjects();
      }
    },
    sortBy: {
      handler(newVal, oldVal) {
        this.getProjects()
      },
      deep: true
    }
  }
};
</script>
<style>
  .title {
    cursor: inherit !important;
  }
  .publish-header {
    float: right;
  }
  .bootstrap-switch{
    margin-bottom: 5px !important;
  }
  .filter-publish {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
  }
  .project-table-head,
  .project-table-body {
    font-size: 12px !important;
  }
  div.card.projects-card {
    box-shadow: none !important;
  }
  div.paginate-container {
    display: inherit;
  }
  div.paginate-container p {
    margin-top: 7px;
  }
  div.card.projects-card div.card-body {
    min-height: 350px;
  }
  div.loading {
    position: absolute;
    margin-left: 50%;
    margin-right: -20px;
    top: 50%;
  }
  tbody.project-table-body tr div.btn-container {
    position: relative;
    visibility: hidden;
    opacity: 0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
  }
  tbody.project-table-body tr:hover div.btn-container {
    visibility: visible;
    opacity: 1;
  }
  tbody.project-table-body tr div.btn-container  {
    width: 200px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .column{
    cursor: pointer;
  }
  .search-input input {
    border-color: #dcdfe6 !important;
  }
  .search-input input:active{
    border-color: #dcdfe6 !important;
  }
  .search-input input:empty {
    border-color: #dcdfe6 !important;
  }
  .search-input.has-danger:after, .search-input.has-success:after {display: none !important;}
  .form-group.search-input{
    width: 100%;
  }
  .badge-workflow-step{
    border-radius: 20px;
    padding: 0.5rem 1rem;
    min-width: 75px;
  }
  .icon-draft{
    color: #fd5d93;
    font-size: 16px;
  }
  .icon-row{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center
  }
  .icon-row span{
    /* margin: 7px auto; */
  }
</style>
