import BusLines from './busesLines';

export default {
  quebec: {
    url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/Voies_Publiques_Quebec2/FeatureServer',
    fields: ["ID", "TYPE", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPOGRAPHIE", "COTE", "ORIENTATION"],
    searchFields: ["LIAISON", "SPECIFIQUE", "NOM_TOPOGRAPHIE"],
    displayField: "NOM_TOPOGRAPHIE",
    queryOutFields: ["ID", "TYPE", "GENERIQUE", "DIRECTION", "NOM_TOPOGRAPHIE"],
    fieldId: "ID",
    displayTags: true,
    dynamicLayers: {
      road: {
        url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/Classification_routière_centre_de_chaussée/FeatureServer/0',
        label_en: 'Arterial network',
        label_fr: 'Réseau artériel',
        isLegend: false
      },
      cycling: false,
      roadWorks: {
        url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/Quebec_Travaux_Routiers/FeatureServer/0',
        label_en: 'Roadworks',
        label_fr: 'Travaux routiers',
        isLegend: false
      },
      parking: false,
      eos: {
        url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/ArcGIS/rest/services/EosLocatePointDemo/FeatureServer/0?token=9Zieu52_a2r3xb0_4gvbCEzeP_ZYZECldDbmIc_OEX16eQ6Kr1KEMAdEQ8n3ujkbjYVqSf7geiF4Ei7Qw2Bi8d35lcfbXosSKO79pg5fyMTIev3Hc3ljioCN3pCFcgdFxq4YzVGgJUdnvvkUYZkaJyGi3_yabX0j4_LDkXCX_9PEtOwopeEl3uBPHeHzy9pVbelhB9pQxCfFXzPIAvG6HSuTNIgCHPsotf-SNIeRr0uGZevq2ODpvlGbQRBDxfQK',
        label_en: 'EOS-gnss',
        label_fr: 'EOS-gnss',
        isLegend: false
      },
      infra1: {
        url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/Infra_layers/FeatureServer/1',
        label_en: 'Infra Networks',
        label_fr: 'Infra réseaux',
        isLegend: false
      },
      infra2: {
        url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/Infra_layers/FeatureServer/0',
        label_en: 'Infra Ponctual',
        label_fr: 'Infra ponctuelle',
        isLegend: false
      }
    },
    bus: {
      url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/Ville_Quebec_Transports/FeatureServer/1',
      filterLine: 'Nom',
      lines: BusLines.quebec.lines
    },
    isMultiCity: false,
    groupEntrave: false
  },
  montreal: {
    url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
    fields: ["ID", "TYPE", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPOGRAPHIE", "COTE", "ORIENTATION"],
    searchFields: ["LIAISON", "SPECIFIQUE", "NOM_TOPOGRAPHIE"],
    displayField: "NOM_TOPOGRAPHIE",
    queryOutFields: ["ID", "TYPE", "GENERIQUE", "DIRECTION", "NOM_TOPOGRAPHIE"],
    fieldId: "ID",
    displayTags: true,
    dynamicLayers: {
      road: false,
      cycling: {
        url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/ArcGIS/rest/services/Montrealreseau_cyclable/FeatureServer/0',
        label_en: 'Bikeways',
        label_fr: 'Piste cyclable',
        isLegend: false
      },
      roadWorks: false,
      parking: {
        url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/MontRealstationnements_h_2019_2020/FeatureServer/0',
        label_en: 'parking',
        label_fr: 'Stationnement',
        isLegend: false
      },
    },
    bus: {
      url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/MTLL001/FeatureServer/1',
      filterLine: 'route_name',
      lines: BusLines.montreal.lines
    },
    isMultiCity: false,
    groupEntrave: false
  },
  gatineau: {
    url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/GAT_ResRoutier_Ajout_PisteCycl_DemCAROLINE/FeatureServer/0',
    fields: ["CODEID", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPO"],
    searchFields: ["LIAISON", "SPECIFIQUE", "NOM_TOPO"],
    displayField: "NOM_TOPO",
    queryOutFields: ["CODEID", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPO"],
    fieldId: "CODEID",
    displayTags: false,
    dynamicLayers: {
      road: false,
      cycling: false,
      roadWorks: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Roadworks/FeatureServer/0',
        label_en: 'Roadworks',
        label_fr: 'Travaux routiers',
        isLegend: false
      },
      /*building: {
        url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/ArcGIS/rest/services/Gatineau_ADRESSE/FeatureServer/0',
        label_en: 'Building',
        label_fr: 'Immeuble'
      },*/
      secteur: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Secteur/FeatureServer/0',
        label_en: 'Sectors',
        label_fr: 'Secteurs',
        isLegend: false
      },
      sigParking: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Règlement_de_stationnement/FeatureServer/0',
        label_en: 'Parking',
        label_fr: 'Réglementation de stationnement',
        isLegend: false
      },
      sigCorridSecur: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Corridors_scolaires_Gatineau/FeatureServer/0',
        label_en: 'School routes',
        label_fr: 'Corridors scolaires',
        isLegend: false
      },
      sigTruck: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Réseau_camionnage/FeatureServer/0',
        label_en: 'Truck routes',
        label_fr: 'Réseau de camionnage',
        isLegend: false
      },
      sigTruck2:{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Reseau_camionnage_CLIP/FeatureServer',
        label_en: 'Truck routes 2',
        label_fr: 'Réseau de camionnage 2',
        isLegend: false
      },
      limElect: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Districs_électoraux/FeatureServer/0',
        label_en: 'Election districts',
        label_fr: 'Districts électoraux',
        isLegend: false
      },
      scolaire: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Etablissement_Scolaire_CLIP_Gatineau/FeatureServer/0',
        label_en: 'Educational establishment',
        label_fr: 'Établissement scolaire',
        isLegend: false
      },
      parking: false
    },
    bus: false,
    isMultiCity: false,
    groupEntrave: false
  },
  brossard:{
    url: 'https://geomatique.longueuil.quebec/public/rest/services/ServicesBrossard/ServicesBrossard/MapServer/3',
    fields: ["OBJECTID", "NomDeRue", "ClasseRue"],
    searchFields: ["NomDeRue"],
    displayField: "NomDeRue",
    queryOutFields: ["OBJECTID", "NomDeRue", "ClasseRue"],
    fieldId: "OBJECTID",
    displayTags: false,
    dynamicLayers: {
      road: false,
      cycling: false,
      roadWorks: false,
      parking: false
    },
    bus: false,
    isMultiCity: false,
    groupEntrave: true
  },
  montroyal: {
    url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
    fields: ["ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE","NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "ObjectId"],
    searchFields: ["NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
    displayField: "NOM_VOIE",
    queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
    fieldId: "ID_TRC",
    displayTags: true,
    dynamicLayers: {
      road:  {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/panneaux_VMR/FeatureServer/0',
        label_en: 'Mont-Royal panels (zoom 1:10,000)',
        label_fr: 'Panneaux Mont-Royal (zoom 1:10,000)',
        isLegend: false
      },
      layer1:{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Bornes_fontaines/FeatureServer',
        label_en: 'Standpipe - filling and cleaning',
        label_fr: 'Borne fontaine - remplissage et nettoyage',
        isLegend: true
      },
      layer2:{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Établissements_scolaires/FeatureServer/',
        label_en: 'Educational institutions',
        label_fr: 'Établissements scolaires',
        isLegend: false
      },
      layer3:{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Corridors_scolaires/FeatureServer/',
        label_en: 'School corridors',
        label_fr: 'Corridors scolaires',
        isLegend: false,
        isTransparent: 0.7,
      },
      layer4:{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Réseau_camionnage_MTQ/FeatureServer/',
        label_en: 'Trucking network',
        label_fr: 'Réseau de camionnage',
        isLegend: true,
        isTransparent: 0.7,
      },
      cycling: false,
      roadWorks: false,
      parking: false,
    },
    
    isMultiCity: false,
    groupEntrave: false
  },
  can: {
    city : {
      quebec :{
        url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/Voies_Publiques_Quebec2/FeatureServer',
        fields: ["ID", "TYPE", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPOGRAPHIE", "COTE", "ORIENTATION"],
        searchFields: ["LIAISON", "SPECIFIQUE", "NOM_TOPOGRAPHIE"],
        displayField: "NOM_TOPOGRAPHIE",
        queryOutFields: ["ID", "TYPE", "GENERIQUE", "DIRECTION", "NOM_TOPOGRAPHIE"],
        fieldId: "ID",
        displayTags: true,
        location:[-71.282841,46.843608],
      },
      montreal :{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
        fields: ["ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE","NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "ObjectId"],
        searchFields: ["NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        displayField: "NOM_VOIE",
        queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        fieldId: "ID_TRC",
        displayTags: true,
        location:[-73.61457185794858,45.49316535542119],
      },
      montroyal :{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
        fields: ["ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE","NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "ObjectId"],
        searchFields: ["NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        displayField: "NOM_VOIE",
        queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        fieldId: "ID_TRC",
        displayTags: true,
        location:[-73.643059,45.516109],
      },
      gatineau :{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Gatineau_center_lines/FeatureServer/0',
        fields: ["CODEID", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPO"],
        searchFields: ["LIAISON", "SPECIFIQUE", "NOM_TOPO"],
        displayField: "NOM_TOPO",
        queryOutFields: ["CODEID", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPO"],
        fieldId: "CODEID",
        displayTags: false,
        location:[-75.7474,45.488],
      },
      brossard :{
        url: 'https://geomatique.longueuil.quebec/public/rest/services/ServicesBrossard/ServicesBrossard/MapServer/3',
        fields: ["OBJECTID", "NomDeRue", "ClasseRue"],
        searchFields: ["NomDeRue"],
        displayField: "NomDeRue",
        queryOutFields: ["OBJECTID", "NomDeRue", "ClasseRue"],
        fieldId: "OBJECTID",
        displayTags: false,
        location:[-73.45093565036832,45.452101577869804],
      },
    },
    dynamicLayers: {
      road: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Mascouche_Panneaux_OPA/FeatureServer/0',
        label_en: 'mascouche layer 1',
        label_fr: 'mascouche layer 1',
        isLegend: false
      },
      permit: {
        url: 'https://cwpll.esriservices.ca/pll158x/gis/1/10513/rest/services/cw/FeatureServer/5',
        label_en: 'Permit Layer',
        label_fr: 'Permit Layer',
        isLegend: false
      },
      roadWorks: false,
    },
    groupEntrave: false,
    isMultiCity: true,
  },
  us: {
    city:{
      California :{
        url: "https://services3.arcgis.com/2mASLsnOB7w414Il/ArcGIS/rest/services/California_Roads_2017/FeatureServer/0",
        fields: ["FID", "OBJECTID", "LINEARID", "FULLNAME", "RTTYP", "MTFCC", "Shape_Leng", "Shape__Length", "GlobalID"],
        searchFields: ["FULLNAME"],
        displayField: "FULLNAME",
        queryOutFields: ["FULLNAME"],
        fieldId: "FID",
        displayTags: true,
        location:[-117.3961,33.9533],
      },
      North_Carolina :{
        url: "https://gis.buncombecounty.org/arcgis/rest/services/opendata/FeatureServer/3",
        fields: ["ObjectId", "RoadClass", "StreetPrefix", "StreetName", "StreetType", "StreetPostDirection", "FullStreetName"],
        searchFields: ["StreetName"],
        displayField: "StreetName",
        queryOutFields: ["StreetName"],
        fieldId: "ObjectId",
        displayTags: true,
        location:[-82.661907,35.554156],
      },
    },
    dynamicLayers: {
      road:  {
        url: 'https://spider.esriservices.ca/CWOPAIntegration/gis/1/2/rest/services/cw/FeatureServer/5',
        label_en: 'Permit layer',
        label_fr: 'Permit layer',
        isLegend: false
      },
      roadWorks: false,
    },
    groupEntrave: false,
    isMultiCity: true,
  },
  gis: {
    layers : {
      roadworks :{
        key: "roadworks",
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
        fields: ["ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE","NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "ObjectId"],
        searchFields: ["NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        displayField: "NOM_VOIE",
        queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        fieldId: "ID_TRC",
        displayTags: true,
        label_en: 'Road Neworks',
        label_fr: 'Réseau Routier',
      },
      cycle :{
        key : "cycle",
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/ArcGIS/rest/services/RTSS_MTL_CLIP/FeatureServer',
        fields: ["FID","nom_sous_r"],
        searchFields: ["nom_sous_r"],
        displayField: "nom_sous_r",
        queryOutFields: ["nom_sous_r"],
        fieldId: "FID",
        displayTags: true,
        label_en: 'Route',
        label_fr: 'Itinéraire',
      }
      
    },
    dynamicLayers: {
      road:  {
      //  url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/1',
        url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/2',
        label_en: 'Electoral District',
        label_fr: 'District Électoral',
        isLegend: false
      },
      cycling: false,
       layer1: {
          url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/8',
          label_en: 'Charging station',
          label_fr: 'Borne Recharge',
          isLegend: false
       },
       layer2:{
          url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/7',
          label_en: 'Fire Hydrant',
          label_fr: 'Borne incendie',
          isLegend: false
        },
        layer3:{
           url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/11',
           label_en: 'Bus stop',
           label_fr: 'Arrêt d’autobus',
           isLegend: false
        },
    },
    bus: false,
    isMultiCity: false,
    isMultiLayer: true,
    groupEntrave: false
  },
  boisbriand: {

    layers : {
      roadworks :{
        key: "roadworks",
        url: 'https://services3.arcgis.com/x2965icj4V1l01th/arcgis/rest/services/SegmentRue/FeatureServer',
        fields: ["OBJECTID","NUMEROCIVIQUEORIGINEGAUCHE","NUMEROCIVIQUEORIGINEDROITE","NUMEROCIVIQUEDESTINATIONGAUCHE","NUMEROCIVIQUEDESTINATIONDROITE","MUNICIPALITEGAUCHE","MUNICIPALITEDROITE","ARRONDISSEMENTGAUCHE","ARRONDISSEMENTDROITE","NOMGENERIQUE","TYPERUE","LIEN","DIRECTION","CLASSEURBANISME","TYPESEGMENTRUE","TYPECAMIONNAGE","VITESSE","PROPRIETAIRE","STATUTSEGMENT","TYPESENSUNIQUE","PRESENCETROTTOIR","SEGMENTRUE_IDG","UTILISATEURCREATION","DATECREATION","UTILISATEURMODIFICATION","DATEMODIFICATION","METHODECAPTAGEID","TOPONYMIE","CAPACITEAUTOBUS","NUMEROROUTE","toponymieMAJ","CORRIDORSCO","GlobalID","Shape__Length"],
        searchFields: ["NOMGENERIQUE", "TOPONYMIE"],
        displayField: "TOPONYMIE",
        queryOutFields: ["NOMGENERIQUE", "TOPONYMIE"],
        fieldId: "OBJECTID",
        displayTags: true,
        label_en: 'Road Neworks',
        label_fr: 'Réseau Routier',
      },
      cycle :{
        key : "cycle",
        url: 'https://services3.arcgis.com/x2965icj4V1l01th/arcgis/rest/services/Piste_Cyclable/FeatureServer/0',
        fields: ["OBJECTID","PISTECYCLABLEID","NOM","TYPE","TYPEREVETEMENT","LARGEUR","INDICATEURDENEIGEMENT","CLASSE","PISTECYCLABLE_IDG","UTILISATEURCREATION","DATECREATION","UTILISATEURMODIFICATION","DATEMODIFICATION","METHODECAPTAGEID","INDICATEURROUTEVERTE","UTILISATEURCREATION","DATECREATION","UTILISATEURMODIFICATION","DATEMODIFICATION","METHODECAPTAGEID","STATUT","ANNEECONSTRUCTION","GlobalID","Shape__Length"],
        searchFields: ["NOM"],
        displayField: "NOM",
        queryOutFields: ["NOM"],
        fieldId: "OBJECTID",
        displayTags: true,
        label_en: 'Cycle network',
        label_fr: 'Réseau Cyclable',
      }
      
    },
    dynamicLayers: {
      road:  {
          url: 'https://services3.arcgis.com/x2965icj4V1l01th/ArcGIS/rest/services/DistrictElectoral/FeatureServer/0',
          label_en: 'Electoral District',
          label_fr: 'District Électoral',
          isLegend: false
        },
      cycling: {
        url: "https://services3.arcgis.com/x2965icj4V1l01th/arcgis/rest/services/Piste_Cyclable/FeatureServer",
        label_en: 'Bike Path',
        label_fr: 'Piste cyclable',
        isLegend: false
      },
     
    },
    bus: false,
    isMultiCity: false,
    isMultiLayer: true,
    groupEntrave: false
  },
  repentigny: {
    layers : {
      roadworks :{
        key: "roadworks",
        url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/4',
        fields: ["NOMGENERIQUE","OBJECTID"],
        searchFields: ["NOMGENERIQUE"],
        displayField: "NOMGENERIQUE",
        queryOutFields: ["NOMGENERIQUE"],
        fieldId: "OBJECTID",
        displayTags: true,
        label_en: 'Road Neworks',
        label_fr: 'Réseau Routier',
      },
      cycle :{
        key : "cycle",
        url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/6',
        fields: ["OBJECTID","PISTECYCLABLEID","NOM","TYPE","TYPEREVETEMENT","LARGEUR","INDICATEURDENEIGEMENT","CLASSE","PISTECYCLABLE_IDG","UTILISATEURCREATION","DATECREATION","UTILISATEURMODIFICATION","DATEMODIFICATION","METHODECAPTAGEID","INDICATEURROUTEVERTE","STATUT","GlobalID","Shape__Length"],
        searchFields: ["NOM"],
        displayField: "NOM",
        queryOutFields: ["NOM"],
        fieldId: "OBJECTID",
        displayTags: true,
        label_en: 'Cycle network',
        label_fr: 'Réseau Cyclable',
      }
      
    },
    dynamicLayers: {
      road:  {
      //  url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/1',
        url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/2',
        label_en: 'Electoral District',
        label_fr: 'District Électoral',
        isLegend: false
      },
      cycling: false,
       layer1: {
          url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/8',
          label_en: 'Charging station',
          label_fr: 'Borne Recharge',
          isLegend: false
       },
       layer2:{
          url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/7',
          label_en: 'Fire Hydrant',
          label_fr: 'Borne incendie',
          isLegend: false
        },
        layer3:{
           url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/11',
           label_en: 'Bus stop',
           label_fr: 'Arrêt d’autobus',
           isLegend: false
        },
    },
    bus: false,
    isMultiCity: false,
    isMultiLayer: true,
    groupEntrave: false
  },
  hampstead: {
    
    layers : {
      roadworks :{
        key: "roadworks",
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/HAMPSTEAD_center_lines/FeatureServer/0',
        fields: ["FID", "ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE", "NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "Longueur",],
        searchFields: ["NOM_VOIE", "TYP_VOIE"],
        displayField: "NOM_VOIE",
        queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE"],
        fieldId: "ID_TRC",
        displayTags: true,
        label_en: 'Road Neworks Hampstead',
        label_fr: 'Réseau Routier Hampstead',
      },
      cycle :{
        key : "cycle",
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
        fields: ["ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE","NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "ObjectId"],
        searchFields: ["NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        displayField: "NOM_VOIE",
        queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        fieldId: "ID_TRC",
        displayTags: true,
        label_en: 'Road Neworks Montreal',
        label_fr: 'Réseau Routier Montreal',
      }
    },
    dynamicLayers: {
      road: false,
      cycling: false,
     
    },
    bus: false,
    isMultiCity: false,
    isMultiLayer: true,
    groupEntrave: false
  },
  aquadata: {
    url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
    fields: ["ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE","NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "ObjectId"],
    searchFields: ["NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
    displayField: "NOM_VOIE",
    queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
    fieldId: "ID_TRC",
    displayTags: true,
    dynamicLayers: {
      road: false,
      cycling: false,
     
    },
    bus: false,
    isMultiCity: false,
    groupEntrave: false
  },
  bell:{
    url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/toronto_centreline_wgs84/FeatureServer/0',
    fields: ["FID", "LF_NAME"],
    searchFields : ["LF_NAME"],
    displayField : "LF_NAME",
    queryOutFields: ["FID", "LF_NAME"],
    fieldId: "FID",
    displayTags: true,
    dynamicLayers: {
      road: false,
      cycling: false,
      roadWorks: false,
      parking: false
    },
    bus: false,
    isMultiCity: false,
    groupEntrave: false
  },
  sjsr: {
    url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/SJSR_Centre_Rues/FeatureServer/0',
    fields: ["OBJECTID","idrte","version","nomrte","norte","clsrte","caractrte","gestion","producteur","idproduct","Shape__Length"],
    searchFields: ["nomrte"],
    displayField: "nomrte",
    queryOutFields: ["nomrte"],
    fieldId: "OBJECTID",
    displayTags: true,
    dynamicLayers: {
      road:  false,
      cycling: false,
    },
    bus: false,
    isMultiCity: false,
    groupEntrave: false
  },
  safelane:{
    city : {
      Charlotte :{
        url: "https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/BASEMAP_Streets/FeatureServer",
        fields: ["FID", "wholestnam"],
        searchFields: ["wholestnam"],
        displayField: "wholestnam",
        queryOutFields: ["wholestnam"],
        fieldId: "FID",
        displayTags: true,
        location:[-80.843719,35.229121],
      },
      quebec :{
        url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/Voies_Publiques_Quebec2/FeatureServer',
        fields: ["ID", "TYPE", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPOGRAPHIE", "COTE", "ORIENTATION"],
        searchFields: ["LIAISON", "SPECIFIQUE", "NOM_TOPOGRAPHIE"],
        displayField: "NOM_TOPOGRAPHIE",
        queryOutFields: ["ID", "TYPE", "GENERIQUE", "DIRECTION", "NOM_TOPOGRAPHIE"],
        fieldId: "ID",
        displayTags: true,
        location:[-71.282841,46.843608],
      },
      montreal :{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
        fields: ["ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE","NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "ObjectId"],
        searchFields: ["NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        displayField: "NOM_VOIE",
        queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        fieldId: "ID_TRC",
        displayTags: true,
        location:[-73.61457185794858,45.49316535542119],
      },
      montroyal :{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
        fields: ["ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE","NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "ObjectId"],
        searchFields: ["NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        displayField: "NOM_VOIE",
        queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        fieldId: "ID_TRC",
        displayTags: true,
        location:[-73.643059,45.516109],
      },
      gatineau :{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Gatineau_center_lines/FeatureServer/0',
        fields: ["CODEID", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPO"],
        searchFields: ["LIAISON", "SPECIFIQUE", "NOM_TOPO"],
        displayField: "NOM_TOPO",
        queryOutFields: ["CODEID", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPO"],
        fieldId: "CODEID",
        displayTags: false,
        location:[-75.7474,45.488],
      },
      brossard :{
        url: 'https://geomatique.longueuil.quebec/public/rest/services/ServicesBrossard/ServicesBrossard/MapServer/3',
        fields: ["OBJECTID", "NomDeRue", "ClasseRue"],
        searchFields: ["NomDeRue"],
        displayField: "NomDeRue",
        queryOutFields: ["OBJECTID", "NomDeRue", "ClasseRue"],
        fieldId: "OBJECTID",
        displayTags: false,
        location:[-73.45093565036832,45.452101577869804],
      },
      California :{
        url: "https://services3.arcgis.com/2mASLsnOB7w414Il/ArcGIS/rest/services/California_Roads_2017/FeatureServer/0",
        fields: ["FID", "OBJECTID", "LINEARID", "FULLNAME", "RTTYP", "MTFCC", "Shape_Leng", "Shape__Length", "GlobalID"],
        searchFields: ["FULLNAME"],
        displayField: "FULLNAME",
        queryOutFields: ["FULLNAME"],
        fieldId: "FID",
        displayTags: true,
        location:[-117.3961,33.9533],
      },
      North_Carolina :{
        url: "https://gis.buncombecounty.org/arcgis/rest/services/opendata/FeatureServer/3",
        fields: ["ObjectId", "RoadClass", "StreetPrefix", "StreetName", "StreetType", "StreetPostDirection", "FullStreetName"],
        searchFields: ["StreetName"],
        displayField: "StreetName",
        queryOutFields: ["StreetName"],
        fieldId: "ObjectId",
        displayTags: true,
        location:[-82.661907,35.554156],
      },
    },
    dynamicLayers: {
      road: false,
      cycling: false,
      roadWorks: false,
      parking: false,
      infraHealth: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/INFRA_Health/FeatureServer',
        label_en: 'Infra Health',
        label_fr: 'Infra Santé',
        isLegend: true
      },
      infraPoliceStation: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/INFRA_Police_Station/FeatureServer',
        label_en: 'Infra - Police Station',
        label_fr: 'Infra - Commissariat de Police',
        isLegend: true
      },
      infraFireStation: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/INFRA_Fire_Station/FeatureServer',
        label_en: 'Infra - Fire Station',
        label_fr: 'Infra - Caserne de pompiers',
        isLegend: true
      },
      street: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/BASEMAP_Streets/FeatureServer',
        label_en: 'Street',
        label_fr: 'Rue',
        isLegend: true
      },
      hydrography: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/BASEMAP_Hydrography/FeatureServer',
        label_en: 'Hydrography',
        label_fr: 'Hydrographie',
        isLegend: true
      },
      urgenceAreaField: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/URGENCY_Field_input/FeatureServer',
        label_en: 'Urgence area (field)',
        label_fr: "Zone d'urgence (champ)",
        isLegend: true
      },
      urgenceAreaMerge: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/URGENCY_Merge_Field/FeatureServer',
        label_en: 'Urgence area (merge)',
        label_fr: "Zone d'urgence (fusion)",
        isLegend: true
      },
      UrgencyAutoClosure: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Urgency_Street_status_Complete_Closure/FeatureServer',
        label_en: 'Urgency auto-closure',
        label_fr: "Fermeture automatique d'urgence",
        isLegend: true
      },
      UrgencyZones: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/URGENCY_Zones/FeatureServer',
        label_en: 'Urgency zones',
        label_fr: "Zones d'urgence",
        isLegend: true
      },
      UrgencyStreetStatus: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Urgency_Street_status/FeatureServer',
        label_en: 'Urgency street status',
        label_fr: "Statut de la rue d'urgence",
        isLegend: true
      },
    },
    bus: false,
    isMultiCity: true,
    groupEntrave: true
  },
  roadworks:{
    city : {
      quebec :{
        url: 'https://services9.arcgis.com/A9g6i2NCgdRS8T0v/arcgis/rest/services/Voies_Publiques_Quebec2/FeatureServer',
        fields: ["ID", "TYPE", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPOGRAPHIE", "COTE", "ORIENTATION"],
        searchFields: ["LIAISON", "SPECIFIQUE", "NOM_TOPOGRAPHIE"],
        displayField: "NOM_TOPOGRAPHIE",
        queryOutFields: ["ID", "TYPE", "GENERIQUE", "DIRECTION", "NOM_TOPOGRAPHIE"],
        fieldId: "ID",
        displayTags: true,
        location:[-71.282841,46.843608],
      },
      montreal :{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
        fields: ["ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE","NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "ObjectId"],
        searchFields: ["NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        displayField: "NOM_VOIE",
        queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        fieldId: "ID_TRC",
        displayTags: true,
        location:[-73.61457185794858,45.49316535542119],
      },
      montroyal :{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
        fields: ["ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE","NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "ObjectId"],
        searchFields: ["NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        displayField: "NOM_VOIE",
        queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
        fieldId: "ID_TRC",
        displayTags: true,
        location:[-73.643059,45.516109],
      },
      gatineau :{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Gatineau_center_lines/FeatureServer/0',
        fields: ["CODEID", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPO"],
        searchFields: ["LIAISON", "SPECIFIQUE", "NOM_TOPO"],
        displayField: "NOM_TOPO",
        queryOutFields: ["CODEID", "GENERIQUE", "LIAISON", "SPECIFIQUE", "DIRECTION", "NOM_TOPO"],
        fieldId: "CODEID",
        displayTags: false,
        location:[-75.7474,45.488],
      },
      brossard :{
        url: 'https://geomatique.longueuil.quebec/public/rest/services/ServicesBrossard/ServicesBrossard/MapServer/3',
        fields: ["OBJECTID", "NomDeRue", "ClasseRue"],
        searchFields: ["NomDeRue"],
        displayField: "NomDeRue",
        queryOutFields: ["OBJECTID", "NomDeRue", "ClasseRue"],
        fieldId: "OBJECTID",
        displayTags: false,
        location:[-73.45093565036832,45.452101577869804],
      },
      repentigny :{
        url: 'https://geomatique.ville.repentigny.qc.ca/arcgis/rest/services/EXTERNE/OPA/MapServer/4',
        fields: ["NOMGENERIQUE","OBJECTID"],
        searchFields: ["NOMGENERIQUE"],
        displayField: "NOMGENERIQUE",
        queryOutFields: ["NOMGENERIQUE"],
        fieldId: "OBJECTID",
        displayTags: true,
        location:[-73.43608,45.73366],
      },
      hampstead :{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/HAMPSTEAD_center_lines/FeatureServer/0',
        fields: ["FID", "ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE", "NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "Longueur",],
        searchFields: ["NOM_VOIE", "TYP_VOIE"],
        displayField: "NOM_VOIE",
        queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE"],
        fieldId: "ID_TRC",
        displayTags: true,
        location:[-73.640827,45.483777],
      },
      sjsr :{
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/SJSR_Centre_Rues/FeatureServer/0',
        fields: ["OBJECTID","idrte","version","nomrte","norte","clsrte","caractrte","gestion","producteur","idproduct","Shape__Length"],
        searchFields: ["nomrte"],
        displayField: "nomrte",
        queryOutFields: ["nomrte"],
        fieldId: "OBJECTID",
        displayTags: true,
        location:[-73.260392,45.332019],
      },
      boisbriand :{
        url: 'https://services3.arcgis.com/x2965icj4V1l01th/arcgis/rest/services/SegmentRue/FeatureServer',
        fields: ["OBJECTID","NUMEROCIVIQUEORIGINEGAUCHE","NUMEROCIVIQUEORIGINEDROITE","NUMEROCIVIQUEDESTINATIONGAUCHE","NUMEROCIVIQUEDESTINATIONDROITE","MUNICIPALITEGAUCHE","MUNICIPALITEDROITE","ARRONDISSEMENTGAUCHE","ARRONDISSEMENTDROITE","NOMGENERIQUE","TYPERUE","LIEN","DIRECTION","CLASSEURBANISME","TYPESEGMENTRUE","TYPECAMIONNAGE","VITESSE","PROPRIETAIRE","STATUTSEGMENT","TYPESENSUNIQUE","PRESENCETROTTOIR","SEGMENTRUE_IDG","UTILISATEURCREATION","DATECREATION","UTILISATEURMODIFICATION","DATEMODIFICATION","METHODECAPTAGEID","TOPONYMIE","CAPACITEAUTOBUS","NUMEROROUTE","toponymieMAJ","CORRIDORSCO","GlobalID","Shape__Length"],
        searchFields: ["NOMGENERIQUE", "TOPONYMIE"],
        displayField: "TOPONYMIE",
        queryOutFields: ["NOMGENERIQUE", "TOPONYMIE"],
        fieldId: "OBJECTID",
        displayTags: true,
        location:[-73.838768,45.613110],
      },
      Mississauga :{
        url: 'https://services1.arcgis.com/TJH5KDher0W13Kgo/arcgis/rest/services/Ontario_Road_Network_Composite_Service_(Beta_Release)/FeatureServer/4',
        fields: ["OBJECTID","ROAD_NET_ELEMENT_ID","FULL_STREET_NAME","L_FIRST_HOUSE_NUMBER","L_LAST_HOUSE_NUMBER","L_HOUSE_NUMBER_STRUCTURE","L_STANDARD_MUNICIPALITY","ARRONDISSR_FIRST_HOUSE_NUMBEREMENTGAUCHE","R_LAST_HOUSE_NUMBER","R_HOUSE_NUMBER_STRUCTURE","R_STANDARD_MUNICIPALITY","ALT_STREET_NAME","EFFECTIVE_DATETIME","SPEED_LIMIT","NUMBER_OF_LANES","PAVEMENT_STATUS","ROAD_CLASS","JURISDICTION","ROUTE_NUMBER","DIRECTION_OF_TRAFFIC_FLOW","GEOMETRY_UPDATE_DATETIME","Shape__Length","SHIELD_TYPE","ROAD_ELEMENT_TYPE","LENGTH"],
        searchFields: ["FULL_STREET_NAME"],
        displayField: "FULL_STREET_NAME",
        queryOutFields: ["FULL_STREET_NAME"],
        fieldId: "OBJECTID",
        displayTags: true,
        location:[-79.645245,43.586732],
         
      },
      Scarborough :{
        url: 'https://services1.arcgis.com/TJH5KDher0W13Kgo/arcgis/rest/services/Ontario_Road_Network_Composite_Service_(Beta_Release)/FeatureServer/4',
        fields: ["OBJECTID","ROAD_NET_ELEMENT_ID","FULL_STREET_NAME","L_FIRST_HOUSE_NUMBER","L_LAST_HOUSE_NUMBER","L_HOUSE_NUMBER_STRUCTURE","L_STANDARD_MUNICIPALITY","ARRONDISSR_FIRST_HOUSE_NUMBEREMENTGAUCHE","R_LAST_HOUSE_NUMBER","R_HOUSE_NUMBER_STRUCTURE","R_STANDARD_MUNICIPALITY","ALT_STREET_NAME","EFFECTIVE_DATETIME","SPEED_LIMIT","NUMBER_OF_LANES","PAVEMENT_STATUS","ROAD_CLASS","JURISDICTION","ROUTE_NUMBER","DIRECTION_OF_TRAFFIC_FLOW","GEOMETRY_UPDATE_DATETIME","Shape__Length","SHIELD_TYPE","ROAD_ELEMENT_TYPE","LENGTH"],
        searchFields: ["FULL_STREET_NAME"],
        displayField: "FULL_STREET_NAME",
        queryOutFields: ["FULL_STREET_NAME"],
        fieldId: "OBJECTID",
        displayTags: true,
        location:[-79.231209,43.778168],
      },
      California :{
        url: "https://services3.arcgis.com/2mASLsnOB7w414Il/ArcGIS/rest/services/California_Roads_2017/FeatureServer/0",
        fields: ["FID", "OBJECTID", "LINEARID", "FULLNAME", "RTTYP", "MTFCC", "Shape_Leng", "Shape__Length", "GlobalID"],
        searchFields: ["FULLNAME"],
        displayField: "FULLNAME",
        queryOutFields: ["FULLNAME"],
        fieldId: "FID",
        displayTags: true,
        location:[-117.3961,33.9533],
      },
      North_Carolina :{
        url: "https://gis.buncombecounty.org/arcgis/rest/services/opendata/FeatureServer/3",
        fields: ["ObjectId", "RoadClass", "StreetPrefix", "StreetName", "StreetType", "StreetPostDirection", "FullStreetName"],
        searchFields: ["StreetName"],
        displayField: "StreetName",
        queryOutFields: ["StreetName"],
        fieldId: "ObjectId",
        displayTags: true,
        location:[-82.661907,35.554156],
      },
    },
    dynamicLayers: {
      road: false,
      cycling: false,
      roadWorks: false,
      parking: false
    },
    bus: false,
    isMultiCity: true,
    groupEntrave: true
  },
  cigo: {
    url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/Street_QC_ON_MERGE/FeatureServer',
    fields: ["FID","NomRte","Shape__Length"],
    searchFields: ["NomRte"],
    displayField: "NomRte",
    queryOutFields: ["NomRte"],
    fieldId: "FID",
    displayTags: true,
    dynamicLayers: {
      road:  false,
      cycling: false,
    },
    bus: false,
    isMultiCity: false,
    groupEntrave: false
  },
  mississauga:{
    city:{
      Guelph :{
        url: 'https://gismaps.guelph.ca/hosting/rest/services/OpenData/OpenData1/FeatureServer/1',
        fields: ["OBJECTID","STREET"],
        searchFields: ["STREET"],
        displayField: "STREET",
        queryOutFields: ["STREET"],
        fieldId: "OBJECTID",
        displayTags: true,
        location:[-80.250792,43.546335],
      },
      Mississauga :{
        url: 'https://services6.arcgis.com/hM5ymMLbxIyWTjn2/arcgis/rest/services/Streetscape_Street_View/FeatureServer/0',
        fields: ["OBJECTID","STNAME","SUFFIX","DIRECTION","FULLNAME","MFES_NAME","FROMLEFT","TOLEFT","FROMRIGHT","TORIGHT","MUNC","MUNICIPALITY_DESC","ONEWAY","OWNER","OWNER_DESC","STATUS","STATUS_DESC","TYPE","TYPE_DESC","SEG_ID","MSLINK","SPEED_LIMIT","ST_LENGTH"],
        searchFields: ["FULLNAME"],
        displayField: "FULLNAME",
        queryOutFields: ["FULLNAME"],
        fieldId: "OBJECTID",
        displayTags: true,
        location:[-79.645245,43.586732],
      },
    },
    dynamicLayers: {
      layer1: {
        url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/GUELP_Current_traffic_interruptions/FeatureServer/',
        label_en: 'Guelph City Project',
        label_fr: 'Guelph City Project',
        isLegend: false
     },
      road:  false,
      cycling: false,
    },
    bus: false,
    isMultiCity: true,
    isMultiLayer: false,
    groupEntrave: false
  },
  admtl: {
    url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
    fields: ["ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE","NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "ObjectId"],
    searchFields: ["NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
    displayField: "NOM_VOIE",
    queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
    fieldId: "ID_TRC",
    dynamicLayers: {
      road: false,
      cycling: false,
      roadWorks: false,
    },
    isMultiCity: false,
    groupEntrave: false,
  },
  admtldev: {
    url: 'https://services7.arcgis.com/Jv5Zt4NwNN3D7R2S/arcgis/rest/services/MontrealReseauRoutier/FeatureServer/0',
    fields: ["ID_TRC", "DEB_GCH", "FIN_GCH", "ARR_GCH", "SENS_CIR", "CLASSE", "LIE_VOIE", "TYP_VOIE", "DIR_VOIE","NOM_VOIE", "DEB_DRT", "FIN_DRT", "ARR_DRT", "LIM_GCH", "LIM_DRT", "ObjectId"],
    searchFields: ["NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
    displayField: "NOM_VOIE",
    queryOutFields: ["ID_TRC", "NOM_VOIE", "TYP_VOIE", "DIR_VOIE"],
    fieldId: "ID_TRC",
    dynamicLayers: {
      road: false,
      cycling: false,
      roadWorks: false,
    },
    isMultiCity: false,
    groupEntrave: false,
  },
};