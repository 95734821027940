<template>
  <div>
    <!--<h3 class="info-text">{{ $t('projectForm.titleEntraves') }}</h3>-->
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 project-label">
        <base-input
          name="name"
          required
          v-bind:label="$t('projectForm.labelProjectName')"
          v-bind:placeholder="$t('projectForm.placeholderProjectName')"
          v-model="informations.name"
          :disabled="statusOfUser"
          v-validate="modelValidations.name"
          :error="getError('name')">
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-8 col-md-8 col-lg-8 create-project-date project-label">
        <label> {{ $t('projectForm.labelProjectSchedule')}} *</label>
        <base-input
            name="date"
            required
            v-validate="modelValidations.date"
            :error="getError('date')"
            v-bind:value="calculatedDate">
          <el-date-picker
            type="datetimerange"
            value-format="timestamp"
            range-separator="-"
            format="yyyy-MM-dd HH:mm"
            lang="fr"
            :disabled="statusOfUser"
            :clearable="false"
            v-bind:start-placeholder="$t('projectForm.labelProjectStart')"
            v-bind:end-placeholder="$t('projectForm.labelProjectEnd')"
            v-model="entraves.date">
          </el-date-picker>
        </base-input>
      </div>
      <div class="col-sm-2 col-md-2 col-lg-2">
        <label>{{ $t('projectForm.labelPerimeter')}} (m)</label>
        <div class="margin-top-10">
          <slider v-model="entraves.perimeter" :disabled="statusOfUser"></slider>
          {{entraves.perimeter}}
        </div>
      </div>
      <!--<div class="col-md-3">
        <label>{{ $t('projectForm.labelConflict')}}</label>
        <div class="margin-top-10">
          <base-switch v-model="entraves.conflict" on-text="" off-text=""></base-switch>
        </div>
      </div>-->
    </div>
    
    <div class="row margin-top-10">
      <div class="col-sm-11 col-md-11 col-lg-11">
        <label> {{ $t('projectForm.labelProjectEntrave')}}</label>
        <div class="entraves-container">
          <entrave-component v-for="(item,index) in entraves.entraves" v-bind:index="index" v-bind:item="item" v-bind:key="index" :disabled="statusOfUser"
                             v-on:showModal="showModal($event)"
                             v-on:input="inputEntrave($event)"
                             v-on:remove="removeEntrave($event)" >
          </entrave-component>
        </div>
        <modal-map-component v-if="!statusOfUser" v-bind:modalMap="modalMap" v-bind:selectedEntrave="selectedEntrave" v-bind:layer="layer" v-bind:date="entraves.date" v-bind:perimeter="entraves.perimeter"
                             v-bind:mapMode="mode" v-bind:uuid="uuid"
                             v-on:saveMapModal="saveMapModal($event)"
                             v-on:closeMapModal="closeMapModal()"
                             v-bind:name="informations.name"
                             v-on:onChangeLayer="onChangeLayer($event)">
        </modal-map-component>
      </div>
      <div class="col-sm-1 col-md-1 col-lg-1">
        <button class="btn btn-icon btn-simple btn-dribbble entrave-add" @click="addEntrave()" :disabled="statusOfUser">
          <i class="tim-icons icon-simple-add"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { TimeSelect, DatePicker } from 'element-ui';
import { uuid } from 'vue-uuid';

import {
  BaseSwitch,
  Slider
} from 'src/components/index';

import EntraveComponent from './EntraveComponent.vue';
import ModalMapComponent from './../map/ModalMapComponentNew.vue';
 
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    Slider,
    BaseSwitch,
    EntraveComponent,
    ModalMapComponent
  },
  props: ['uuid', 'entraves', 'mode','statusOfUser','layer','informations'],
  computed: {
    calculatedDate: function() {
      if (this.entraves.date && this.entraves.date.length==2) {
        return 'true';
      } else {
        return '';
      }
    }
  },
  data() {
    return {
      modelValidations: {
        date: {
          required: true
        },
        name: {
          required: true
        },
      },
      selectedEntraveIndex: 0,
      selectedEntrave: null,
      modalMap: false,
      name:'',
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.validateEntraves();
        this.$emit('on-validated', res && (this.entraves.validateEntraves), 'entraves', this.entraves);
        return res && (this.entraves.validateEntraves);
      });
    },
    validateEntraves () {
      this.entraves.validateEntraves=true;
      var that=this;
      this.entraves.entraves.forEach(function(e) {
        if (e.value==='') {
          e.showError=true;
          that.entraves.validateEntraves=false;
        } else {
          e.showError=false;
        }
      }); 
    },
    showModal(params) {
      this.selectedEntraveIndex = params[0];
      this.selectedEntrave = params[1];
      this.geometries = [];
      if (!this.entraves.date) {
        this.$validator.validateAll();
      } else {
        this.modalMap = true; 
      }
    },
    addEntrave() {
      this.entraves.entraves.push({
        entraveId: uuid.v1(),
        selected: {
          path : [],
          startPoint : null,
          endPoint : null,
          streetsTrack : []
        },
        type: 'linear',
        value: '',
        label: '',
        name: '',
        showError: false
      });
    },
    removeEntrave(index) {
      this.entraves.entraves.splice(index, 1);
    },
    inputEntrave(index) {
      this.entraves.entraves[index].showError = false;
      this.selectedEntraveIndex = index;
      //this.modalMap = true;
      
    },
    closeMapModal() {
      this.modalMap = false;
    },
    onChangeLayer(value){
      this.layer = value;
      this.$emit('onChangeLayer', value);
    },
    saveMapModal(selected) {
      var that = this;
      this.entraves.entraves.forEach(function(e, i) {
        if(that.selectedEntraveIndex==i) {
          e.name=selected.name;
          e.value=selected.value;
          e.label=selected.label;
          e.type=selected.type;
          e.selected.path=selected.selected.path;
          e.selected.startPoint=selected.selected.startPoint || null;
          e.selected.endPoint=selected.selected.endPoint|| null;
          e.selected.streetsTrack=selected.selected.streetsTrack|| null;
          e.showError=false;
        } 
      });
      this.modalMap = false;
      this.validate();
    }
  }
};
</script>
<style>
  .slider.slider-.noUi-target.noUi-ltr.noUi-horizontal {
    margin-top: 15px;
  }
  
  .entrave-add {
    margin-top: 25px !important;
  }
  .el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange {
    padding-top: 3px !important;
  }
  div.entraves-container {
    display: grid !important;
  }

  .el-range-editor.is-disabled input{
    background: transparent !important;
  }
</style>
