<template>
  <div class="wrapper-container">
    <!--<a v-show='projects.length > 0' class="settings-icon" :class="{ active: showStat }" @click="toggleStat()" ><i class="tim-icons icon-chart-pie-36"></i></a>-->
    <div class="timeline-container" :class="{ active: showStat }">
      <div v-if='projects.length > 0' class='projects-timeline'>
        <div class="row gantt_control" style="margin-left: 0px !important; margin-right: 0px !important;"> 
          <div class="col-sm-12 col-md-12 col-lg-12" style="display: inherit !important; padding-left: 0px !important; padding-right: 0px !important;">
            <div class="col-sm-6 col-md-6 col-lg-6" style="padding-left: 0px !important; padding-right: 0px !important;">
              <div style="text-align: left; padding-left: 10px;">
                <label class="control-label" style="margin-bottom: 0px !important; margin-top:8px">{{ $t('gantt.scale') }}</label>
              </div>
              <div class="col-sm-3" style="padding-left: 10px !important;">
                  <el-select
                    class="test-select select-primary select-inline"
                    name="retentionType"
                    size="small"                    
                    v-model="scale">
                      <el-option
                      v-for="option in scaleOptions"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                    </el-option>
                  </el-select>
                </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3" style="padding-left: 0px !important; padding-right: 0px !important;">
              <div style="text-align: left; padding-left: 5px;">
                <label class="control-label" style="margin-bottom: 0px !important"></label>
              </div>
              <div style="display: flex; padding-left: 10px;">
              </div>
            </div>
          </div>
        </div>
        <div id="projects_timeline" class="projects-timeline-gantt"></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { BaseRadio } from 'src/components/index';
import { BaseAlert } from 'src/components';
import locales from './../../locales/gantt';
import icons from './../shared/icons';
import swal from 'sweetalert2';
import { Select, Option } from 'element-ui';
import { gantt } from 'dhtmlx-gantt';
import helpersDrawProject from '../helpers/helpersDrawProject';
export default {
  name: 'projects-timeline',
  components: {
    BaseRadio,
    BaseAlert,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props: ['projects'],
  filters: {
  },
  watch: {
    'projects'(val) {
      this.loading = true;
      let _this = this;
      window.setTimeout(() => {
        _this.drawProjects(val, () => { _this.areProjectsLoaded += 1; });
        _this.loading = false;
        if (val.length) {
          const headerContainer = document.querySelector('.gantt_grid_scale');
          if (headerContainer) {
            headerContainer.addEventListener('click', this.handleHeaderClick);
          }
        }
      }, 100);
    },
    'scale'(val) {
      gantt.ext.zoom.setLevel(this.scale);
    },
    'showStat'(val) {
      this.loading=true;
      let _this=this;
      _this.drawProjects(val);
      this.loading=false;
    },
    areProjectsLoaded(val) {
      if (!val) return;
      let arrowIcon;
      let columnToBeSorted;
      switch (this.sortBy.column) {
        case 'text':
          if (this.sortBy.order === "1") {
            arrowIcon = this.getActiveDownArrowIcon("text");
          } else {
            arrowIcon = this.getActiveUpArrowIcon("text");
          }
          columnToBeSorted = document.querySelector('.gantt_grid_head_text');
          break;
        case 'start_date':
          if (this.sortBy.order === "1") {
            arrowIcon = this.getActiveDownArrowIcon("start_date");
          } else {
            arrowIcon = this.getActiveUpArrowIcon("start_date");
          }
          columnToBeSorted = document.querySelector('.gantt_grid_head_start_date');
          break;
        default:
          break;
      }
      if (!columnToBeSorted) return;
      if (columnToBeSorted.childElementCount) {
        columnToBeSorted.removeChild(columnToBeSorted.lastChild);
      }
      columnToBeSorted.innerHTML = `${columnToBeSorted.innerHTML} ${arrowIcon}`;
    }
  },
  mounted () {
    this.zoomConfig = {
      levels: [
        {
          name:"day",
          scale_height: 50,
          min_column_width:80,
          scales:[
            {unit: "month", format: "%F %Y"},
            {unit: "day", step: 1, format: "%j %D"}
          ]
        },
        {
          name:"week",
          scale_height: 50,
          min_column_width:50,
          scales:[
            {unit: "week", step: 1, format: function (date) {
              var dateToStr = gantt.date.date_to_str("%d %M %Y");
              var endDate = gantt.date.add(date, -6, "day");
              var weekNum = gantt.date.date_to_str("%W")(date);
              return "#" + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate);
            }},
            {unit: "day", step: 1, format: "%j %D"}
          ]
        },
        {
          name:"month",
          scale_height: 50,
          min_column_width:120,
          scales:[
            {unit: "month", format: "%F, %Y"},
            {unit: "week", format: "Week #%W"}
          ]
        },
        {
          name:"quarter",
          height: 50,
          min_column_width:90,
          scales:[
            {unit: "month", step: 1, format: "%M"},
            {
              unit: "quarter", step: 1, format: function (date) {
                var dateToStr = gantt.date.date_to_str("%M %Y");
                var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                return dateToStr(date) + " - " + dateToStr(endDate);
              }
            }
          ]
        },
        {
          name:"year",
          scale_height: 50,
          min_column_width: 30,
          scales:[
            {unit: "year", step: 1, format: "%Y"}
          ]
        }
      ]
    };
  },
  created(){
    this.instance = JSON.parse(window.localStorage.getItem('instance'))
  },
  data() {
    return {
      zoomConfig:{},
      tasks: {
        data: [],
        links: []
      },
      showStat: true,
      loading: true,
      scale: 'quarter', //day, week, month, quarter, year,
      // scaleOptions: [{ label: this.$i18n.t('gantt.day'), value: 'day' }, { label: this.$i18n.t('gantt.week'), value: 'week' }, { label: this.$i18n.t('gantt.month'), value: 'month' }, { label: this.$i18n.t('gantt.quarter'), value: 'quarter' }, { label: this.$i18n.t('gantt.year'), value: 'year' }]
      sortBy: {
        column:"period",
        order: '-1'
      },
      areProjectsLoaded: 0,
      instance: '',
    };
  },
  computed: {
    scaleOptions() {
         return [{ label: this.$i18n.t('gantt.day'), value: 'day' }, { label: this.$i18n.t('gantt.week'), value: 'week' }, { label: this.$i18n.t('gantt.month'), value: 'month' }, { label: this.$i18n.t('gantt.quarter'), value: 'quarter' }, { label: this.$i18n.t('gantt.year'), value: 'year' }];
    }
  },
  methods: {
    toggleStat() {
      this.showStat=!this.showStat;
    },
    getProgress(sdate, edate) {
      let now = new Date().getTime();
      let progress=0;
      if (now>edate) {
        progress=1;
      } else if (now<=sdate) {
        progress=0;
      } else {
        let lifetime=edate-sdate;
        let duration=now-sdate;
        progress=(duration/lifetime);
      }
      return progress;
    },
    getIcons(project) {
      let path='';
      let img='';
      let color='#fd77a4';
      if(this.instance.city == "mississauga"){
        let entity = project.data.informations.entity[0];
        path = helpersDrawProject.fetchEntityIcon(entity);
        color = helpersDrawProject.fetchEntityIconColor(entity);
        img=`<a href="#/app?uuid=${project.uuid}" style="margin-left:6px"><img src="${path}" style="width:30px;height:34px"/></a>`;
      }else
      {
        if (project.typeProject=='linear' && project.publish) {
          path=icons.publishLineair;
          color='#fd77a4';
        } else if (project.typeProject=='ponctuel' && project.publish) {
          path=icons.publishPonctuel;
          color='#419ef9';
        } else if (project.typeProject=='linear' && !project.publish) {
          path=icons.draftLineair;
          color='#ff9f89';
        } else if (project.typeProject=='ponctuel' && !project.publish) {
          path=icons.draftPonctuel;
          color='#ff9f89';
        } 
        img=`<a href="#/app?uuid=${project.uuid}"><svg viewBox="0 -4 1 32" height="32" width="32"><path d="${path}" fill="${color}"/></svg></a>`;
      }
      return img;
    },
    constProjects (callback) {
      this.tasks.data=[];
      let _this=this;
      this.projects.forEach( (project, index) => {
        let start = ''+moment.unix(project.data.entraves.date[0]/1000).format("DD-MM-YYYY");
        let duration = ''+parseInt(((project.data.entraves.date[1] - project.data.entraves.date[0])/ (24*60*60*1000)), 10);
        let progress = _this.getProgress(project.data.entraves.date[0], project.data.entraves.date[1]);
        let img = _this.getIcons(project);
        let element = {
          id: project.uuid,
          icon: img,
          text: project.name,
          start_date: start,
          duration: duration,
          progress: progress,
          open:false
        };
        _this.tasks.data.push(element);
        project.data.phases.forEach((phase, phaseIndex) => {
          phase.closing.forEach((close, closeIndex) => {
            close.dates.forEach((date, dateIndex) => {
              let start = ''+moment.unix(date.sdate/1000).format("DD-MM-YYYY");
              let duration = ''+parseInt(((date.edate - date.sdate)/ (24*60*60*1000)), 10);
              let progress = _this.getProgress(date.sdate, date.edate);
              let element1 = {
                id: date.dateId+'_phase',
                icon: '',
                color: '#fd77a4',
                text: _this.$i18n.t('gantt.phase')+(phaseIndex + 1)+' '+_this.$i18n.t('gantt.closing')+(closeIndex + 1)+(' ('+_this.$i18n.t('gantt.date')+(dateIndex+1))+')',
                parent: ''+project.uuid,
                start: date.sdate,
                start_date: start,
                duration: duration,
                progress: progress,
                open:false
              };
              _this.tasks.data.push(element1);
              let link = {
                id: ''+date.dateId,
                source: ''+project.uuid,
                target: ''+date.dateId,
                type: '0'
              };
              _this.tasks.links.push(link);
            });
          });
        });
        if(index === this.projects.length - 1) {
          callback();
        }
      });
    },
    editProject(uuid, name) {
      let title = this.$i18n.t('projectList.titleProjectEdit')+ name;
      if (this.$i18n.locale=='en') {
        title=title+'?';
      } else {
        title=title+' ?';
      }
      swal({
        title: title,
        //text:  this.$i18n.t('projectList.textProjectEdit'),
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: this.$i18n.t('projectList.textConfirmProjectEdit'),
        cancelButtonText: this.$i18n.t('projectList.textCancelProjectEdit'),
        buttonsStyling: false
      }).then((resp) => {
        if (resp.value) {
          window.location.href='/#/projects/edit?uuid='+uuid;
        }
      });
    },
    drawProjects(val, callback) {
      let _this=this;
      if (val && val.length) {
        _this.constProjects(() => {
          gantt.ext.zoom.init(_this.zoomConfig);
          gantt.ext.zoom.setLevel(_this.scale);
          gantt.ext.zoom.attachEvent("onAfterZoom", function(level, config) {
            if (document.querySelector(".gantt_radio[value='" +config.name+ "']")){
              document.querySelector(".gantt_radio[value='" +config.name+ "']").checked = true;  
            }
          });
          gantt.config.readonly = true;
          gantt.config.columns = [
            { name: "icon", label: '', align: 'center', width: 32 },
            { name: "text", label: `${this.$i18n.t('gantt.pName')} ${this.getDefaultArrowIcon("text")}`, width: "*", tree: true },
            { name: "start_date", label: `${this.$i18n.t('gantt.sDate')} ${this.getDefaultArrowIcon("start_date")}`, align: "left", width: 96 },
            { name: "duration", label: this.$i18n.t('gantt.duration'), align: "left", width: 85 }
          ];
          gantt.templates.tooltip_text = function(start,end,task){
            return "<b>" + _this.$i18n.t('gantt.pName')+ ":</b> " + task.text +
                  "<br/><b>" + _this.$i18n.t('gantt.sDate') + ":</b> " + moment(start).format('DD-MM-YYYY hh:mm:ss') +
                  "<br/><b>" + _this.$i18n.t('gantt.eDate') + ":</b> " + moment(end).format('DD-MM-YYYY hh:mm:ss');
          };
          gantt.attachEvent("onTemplatesReady", function () {
            var toggle = document.createElement("i");
            toggle.className = "fa fa-expand gantt-fullscreen";
            gantt.toggleIcon = toggle;
            gantt.$container.appendChild(toggle);
            toggle.onclick = function() {
              gantt.ext.fullscreen.toggle();
            };
          });
          gantt.attachEvent("onExpand", function () {
            document.querySelector('div.sidebar').style.display='none';
            document.querySelector('div.container-fluid').style.display='none';
            var icon = gantt.toggleIcon;
            if (icon) {
              icon.className = icon.className.replace("fa-expand", "fa-compress");
            }
          });
          gantt.attachEvent("onCollapse", function () {
            document.querySelector('div.sidebar').style.display='block';
            document.querySelector('div.container-fluid').style.display='';
            var icon = gantt.toggleIcon;
            if (icon) {
              icon.className = icon.className.replace("fa-compress", "fa-expand");
            }
          });
          gantt.attachEvent("onTaskDblClick", function(id,e){
              //any custom logic here
              if (id.indexOf('_phase')==-1) {
                let name = (e.srcElement && e.srcElement.innerText);
                _this.editProject(id, name);
              }
              return true;
          });
          gantt.locale=locales[this.$root.$i18n.locale];
          if(document.getElementById("projects_timeline")){
            gantt.init("projects_timeline");
          }
          gantt.clearAll();
          gantt.parse(this.tasks);
          if(callback){
            setTimeout(callback, 100);
          }
        });
      } else {
        _this.tasks.data=[];
        _this.tasks.links=[];
        gantt.clearAll();
      }
    },
    handleHeaderClick(event) {
      const columnName = event.target.dataset.columnName;
      const { column, order } = this.sortBy;
      let sortingOrder = '1';
      if (column === columnName) {
        sortingOrder = order === "1" ? "-1" : "1";
      }
      this.sortBy = {
        column: columnName,
        order: sortingOrder
      }
      switch (columnName) {
        case "text":
          if (sortingOrder === "1") {
            this.projects.sort((a, b) => a.name.localeCompare(b.name));
          } else {
            this.projects.sort((a, b) => b.name.localeCompare(a.name));
          }
          break;
        case "start_date":
          if (sortingOrder === "1") {
            this.projects.sort((a, b) => a.sdate - b.sdate);
          } else {
            this.projects.sort((a, b) => b.sdate - a.sdate);
          }
          break;
        default:
          break;
      }
    },
    getArrowIcon({dataColumnName, groupTransformation, upArrowColor, downArrowColor, upArrowDirection, downArrowDirection}) {
      const { sortingArrow } = icons;
      return `<svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" data-column-name="${dataColumnName}">
                <g id="Group_19317" data-name="Group 19317" transform="${groupTransformation}" data-column-name="${dataColumnName}">
                  <path id="Polygon_11" data-name="Polygon 11" d="${sortingArrow}" transform="${upArrowDirection}" fill="${upArrowColor}" data-column-name="${dataColumnName}" />
                  <path id="Polygon_12" data-name="Polygon 12" d="${sortingArrow}" transform="${downArrowDirection}" fill="${downArrowColor}" data-column-name="${dataColumnName}" />
                </g>
              </svg>`;
    },
    getDefaultArrowIcon(dataColumnName){
      return this.getArrowIcon({ dataColumnName, groupTransformation: "translate(-8413 592)", upArrowColor: "#bfc3cd", downArrowColor: "#bfc3cd", upArrowDirection: "translate(8413 -592)", downArrowDirection: "translate(8422 -578) rotate(180)" });
    },
    getActiveUpArrowIcon(dataColumnName){
      return this.getArrowIcon({ dataColumnName, groupTransformation: "translate(-8428 592)", upArrowColor: "#f400cc", downArrowColor: "#bfc3cd", upArrowDirection: "translate(8437 -578) rotate(180)", downArrowDirection: "translate(8428 -592)" });
    },
    getActiveDownArrowIcon(dataColumnName){
      return this.getArrowIcon({ dataColumnName, groupTransformation: "translate(-8440 592)", upArrowColor: "#bfc3cd", downArrowColor: "#f400cc", upArrowDirection: "translate(8449 -578) rotate(180)", downArrowDirection: "translate(8440 -592)" });
    }
  }
};
</script>
<style>
  div.wrapper-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    height: 100%;
    /*background: rgba(0,0,0,.3);*/
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  body:not(sidebar-mini) div.wrapper-container {
    left: 250px;
  }
  body.sidebar-mini div.wrapper-container {
    left: 100px;
  }
  div.wrapper-container a.settings-icon {
    left: 50%;
    font-size: 18px;
    margin-left: -10px;
    /*position: absolute;*/
    position:relative;
    height: 100%;
    bottom: 0px;
    background-color: white;
    color: #999;
    padding-top: 2px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 2px;
    cursor: pointer;
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    z-index: 999;
  }
  div.wrapper-container a.settings-icon.active {
    font-size: 12px;
    top: 13px;
  }
  div.wrapper-container div.timeline-container {
    height: 0px;
    position: relative;
    bottom: 0px;
    transition: height 0.25s ease-out;
    overflow: hidden;
    
  }
  div.wrapper-container div.timeline-container div.projects-timeline label.form-check-label,
   div.wrapper-container div.timeline-container div.projects-timeline div.gantt_tree_content {
    font-family: Poppins,sans-serif;
    font-size: .875rem;
    font-weight: 400;
    color:#525f7f;
  }
  div.form-check.form-check-radio {
    margin-top: 0px !important;
  }

  div.wrapper-container div.timeline-container.active,
  div.wrapper-container a.settings-icon.active + div.timeline-container {
    height: 100%;
    /*height: auto;*/
    transition: height 0.35s ease-in;
    /*background: rgba(0,0,0,.3);*/
  }
  .projects-timeline {
    min-height: 80px;
    height: 100%;
    width: 100%;
  }
  .left-container {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  div.wrapper-container div.projects-timeline {
    padding: 10px;
    z-index:9999;
  }
  div.wrapper-container div.projects-timeline.active  {
    display: block;
    height: 500px;
  }
  div.scale-checkbox-radios {
    padding-left: 20px;
    display: flex;
  }
  div.wrapper-container div.projects-timeline div.gantt_control {
    background: #f5f6fa !important;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
  }
  div.wrapper-container div.projects-timeline div.gantt_layout_cell.gantt_layout_root.gantt_layout.gantt_layout_y.gantt_container {
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  div.wrapper-container div.projects-timeline div.projects-timeline-gantt {
    width: 100%;
    position: absolute;
    bottom: 0px;
    top: 80px;
    right: 5px;
    /*height: 100%;*/
    /*height: auto; 
    height: 600px;*/
  }
  div.wrapper-container div.projects-timeline div.projects-timeline-gantt div.gantt_tree_icon.gantt_blank {
    width: 0px;
  }
  div.wrapper-container div.projects-timeline div.projects-timeline-gantt div.gantt_tree_indent {
    width: 15px;
  } 
  div.wrapper-container div.projects-timeline div.gantt_control input[type="button"] {
    font-family: Poppins,sans-serif;
    border-radius: 5px;
  }
  div.wrapper-container div.projects-timeline div.gantt_cell[data-column-name="icon"] {
    /*width: 32px !important;*/
  }
  div.wrapper-container div.projects-timeline label.control-label {
    font-family: Poppins,sans-serif;
  }
  .gantt-fullscreen {
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 30px;
      height: 30px;
      padding: 2px;
      font-size: 32px;
      background: transparent;
      cursor: pointer;
      opacity: 0.5;
      text-align: center;
      -webkit-transition: background-color 0.5s, opacity 0.5s;
      transition: background-color 0.5s, opacity 0.5s;
    }

    .gantt-fullscreen:hover {
      background: rgba(150, 150, 150, 0.5);
      opacity: 1;
    }
    .gantt_link_tooltip{
      border-radius: 5px;
    } 
    .gantt_grid_head_cell.gantt_grid_head_start_date, .gantt_grid_head_cell.gantt_grid_head_text {
      cursor: pointer;
    }
</style>

<style>
    @import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
</style>