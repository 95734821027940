import axios from 'axios';
import HelpersDrawProject from './../helpers/helpersDrawProject';
import HelpersMaps from './../helpers/helpersMap';
import Colors from './colors';
import { loadModules } from 'esri-loader';

export default {
  type: 'create',
  color: '#2782f0',//'#ea4335'
  loadProjects(filters, cb) {
    let _this = this;
    /*let filters = {
      publish: true,
      nopublish: true,
      linear: true,
      ponctuel: true,
      range: [],
      tags: {}
    };
    */
    filters.tags = {
      step: [
        "",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6"
      ]
    };
    filters.linear = filters.ponctuel = true;
    axios.post('project/get_projects', filters).then(response => {
      let data = response.data;
      if (data.success) {
        _this.reloadGeometries(data.data, cb);
      } else {
        if (data.need_auth) {
          window.location.href='/login';
        } else {
          cb(false);
        }
      }
    }).catch((e) => {
      console.log("Error While loading Projects ", e);
      cb(false);
    });
  },
  drawProjects(filters, projects, projectsLayer, Point, Polyline, Graphics, GeometryEngine, WebMercatorUtils, geodesicUtils) {
    let _this=this;
    projects.forEach((project, index) => {
      let color = Colors.list[index % projects.length];
      if (filters.entrave) {
        _this.drawEntraves(project.data.entraves, color, projectsLayer, Point, Polyline, Graphics);
        _this.drawPin(project.data, color, projectsLayer, Point, Polyline, Graphics, GeometryEngine, WebMercatorUtils); 
      }
      if (filters.closing || filters.detour) {
        _this.drawPhases(filters, project.data.phases, color, projectsLayer, Point, Polyline, Graphics, GeometryEngine, WebMercatorUtils, geodesicUtils); 
      }
    });
  },
  drawPin(project, color, projectsLayer, Point, Polyline, Graphic, GeometryEngine, WebMercatorUtils) {
    let graph = HelpersDrawProject.drawPin(project, Point, Polyline, Graphic, GeometryEngine, WebMercatorUtils, color);
    projectsLayer.add(graph);
  },
  drawEntraves(entraves, color, projectsLayer, Point, Polyline, Graphics) {
    let _this=this;
    entraves.entraves.forEach((entrave)=> {
      if (entrave.type=='ponctuel') {
        _this.drawPonctuelEntrave(entrave.selected.path[0], color, projectsLayer, Point, Graphics);
      } else {
        _this.drawLinearEntrave(entrave.selected.path, color, projectsLayer, Polyline, Graphics);
      }
    });
  },
  drawPonctuelEntrave(point, color, projectsLayer, Point, Graphic) {
    let graph = HelpersDrawProject.drawPoint(point, Graphic, this.type, color, Point);
    projectsLayer.add(graph);
  },
  drawLinearEntrave(paths, color, projectsLayer, Polyline, Graphic) {
    let graph;
    paths.forEach(function(path) {
      graph = HelpersDrawProject.drawPolyline(path, Polyline, Graphic, 'create', color);
      projectsLayer.add(graph);
    });
  },
  drawPhases(filters, phases, color, projectsLayer, Point, Polyline, Graphics, GeometryEngine, WebMercatorUtils, geodesicUtils) {
    let _this=this;
    phases.forEach((phase) => {
      phase.closing.forEach((close) => {
        if (filters.closing) {
          if (close.type=='linear') {
            _this.drawLinearClosing(close, color, projectsLayer, Point, Polyline, Graphics, GeometryEngine, WebMercatorUtils, geodesicUtils);
          } else {
            _this.drawPonctuelClosing(close.selected.path[0], color, projectsLayer, Point, Graphics);
          } 
        }
        if (filters.detour) {
          close.detours.forEach((detour) => {
            _this.drawLinearDetour(detour, color, projectsLayer, Point, Polyline, Graphics, GeometryEngine, WebMercatorUtils, geodesicUtils);
          });
        }
      });
    });
  },
  drawPonctuelClosing(point, color, projectsLayer, Point, Graphics) {
    let graph = HelpersDrawProject.drawPoint(point, Graphics, 'closing', color, Point);
    projectsLayer.add(graph);
  },
  drawLinearClosing(close, color, projectsLayer, Point, Polyline, Graphics, GeometryEngine, WebMercatorUtils, geodesicUtils) {
    let polyline = HelpersMaps.updateSimpleLine(close, Point, Polyline, GeometryEngine);
    let graphs = HelpersMaps.drawRepeated(polyline, color, 'closing', Polyline, Point, Graphics, GeometryEngine, WebMercatorUtils, geodesicUtils);
    let pinCloseGraph = this.drawPinLinearClose(close, color, Point, Polyline, Graphics, GeometryEngine, WebMercatorUtils);
    graphs.forEach((g) => {
      projectsLayer.add(g);
    });
    projectsLayer.add(pinCloseGraph);
  },
  drawPinLinearClose(close, color, Point, Polyline, Graphics, GeometryEngine, WebMercatorUtils) {
    let midPoint = HelpersMaps.polylineMidPoint(close, Point, Polyline, GeometryEngine, WebMercatorUtils);
    let symbol = HelpersDrawProject.pointMarker('closing', color);
    let graph = new Graphics({
      geometry   : midPoint,
      visible    : true,
      symbol: symbol
    });
    return graph;
  },
  drawLinearDetour(detour, color, projectsLayer, Point, Polyline, Graphics, GeometryEngine, WebMercatorUtils, geodesicUtils) {
    let polyline = HelpersMaps.updateSimpleLine(detour, Point, Polyline, GeometryEngine);
    let graphs = HelpersMaps.drawRepeated(polyline, color, 'detour', Polyline, Point, Graphics, GeometryEngine, WebMercatorUtils, geodesicUtils);
    graphs.forEach((g) => {
      projectsLayer.add(g);
    });
  },
  reloadGeometries(projects, cb){
    var reloaded;
    loadModules(["esri/geometry/Polyline", "esri/geometry/Point","esri/geometry/Polygon"])
    .then(([Polyline, Point,Polygon]) => {
      let _this = this;
      reloaded = projects.map(function(project){
        var entraves = project.data.entraves.entraves, phases = project.data.phases;
        var reloadedP = project;
        reloadedP.data.entraves.entraves = _this.reloadEntraves(entraves, Point, Polyline, Polygon);
        reloadedP.data.phases = phases.map(function(phase){
          var reloadedPh = phase;
          if (phase.closing){reloadedPh.closing = phase.closing.map(function(closing){return _this.reloadClosing(closing, Point, Polyline);});}
          return reloadedPh;
        });
        return reloadedP;
      });
      cb(true, reloaded);
    });
  },
  reloadEntraves(entraves, Point, Polyline, Polygon){
    let _this = this;
    var reloaded = entraves.map(function(entrave){
      var reloadedE = entrave;
      if(entrave.selected.path){reloadedE.selected.path = entrave.selected.path.map(function(object){
          var reloadedObj = object;
          if(object.geometry.type === "point"){
            reloadedObj.geometry = new Point(object.geometry);
          }else if(object.geometry.type === "polyline"){
            reloadedObj.geometry = new Polyline(object.geometry);
          }else if(object.geometry.type === "polygon"){
            reloadedObj.geometry = new Polygon(object.geometry);
          }
          if(object.segment){reloadedObj.segment.geometry = new Polyline(object.segment.geometry);}
          return reloadedObj;
        });
      if (entrave.selected.startPoint){reloadedE.selected.startPoint = new Point(entrave.selected.startPoint);}
      if (entrave.selected.endPoint){reloadedE.selected.endPoint = new Point(entrave.selected.endPoint);}
      }
    return entrave;
    });
    return reloaded;
  },
  reloadClosing(closing, Point, Polyline){
    let _this = this;
    var reloadedC = closing;
    if(closing.selected && closing.selected.path) {
      reloadedC.selected.path = closing.selected.path.map(function(object){
        var reloadedObj = object;
        if(object.geometry.type === "point"){
          reloadedObj.geometry = new Point(object.geometry);
        }else if(object.geometry.type === "polyline"){
          reloadedObj.geometry = new Polyline(object.geometry);
        }
        if(object.segment){reloadedObj.segment.geometry = new Polyline(object.segment.geometry);}
        return reloadedObj;
      });

      if (closing.selected.startPoint){reloadedC.selected.startPoint = new Point(closing.selected.startPoint);}
      if (closing.selected.endPoint){reloadedC.selected.endPoint = new Point(closing.selected.endPoint);}
      if(closing.detours){
        reloadedC.detours = closing.detours.map(function(detour){
          return _this.reloadDetour(detour, Point, Polyline);
        });
      }
    }
    return reloadedC;
  },
  reloadDetour(detour, Point, Polyline){
    var reloadedD = detour;
    if(detour.selected && detour.selected.path) {
      reloadedD.selected.path = detour.selected.path.map(function(object){
        var reloadedObj = object;
        if(object.geometry.type === "point"){
          reloadedObj.geometry = new Point(object.geometry);
        }else if(object.geometry.type === "polyline"){
          reloadedObj.geometry = new Polyline(object.geometry);
        }
        if(object.segment){reloadedObj.segment.geometry = new Polyline(object.segment.geometry);}
        return reloadedObj;
      });
      if (detour.selected.startPoint){reloadedD.selected.startPoint = new Point(detour.selected.startPoint);}
      if (detour.selected.endPoint){reloadedD.selected.endPoint = new Point(detour.selected.endPoint);}
    }
    return reloadedD;
  },
  checkConflicts(from, filters, uuid, projects, conflictsBuffer, conflictsUnits, geometryEngine) {
    let _this= this;
    let ProjectsConflict=[];
    if (conflictsBuffer) {
      projects.forEach((project) => {
        if (project.uuid!==uuid) {
          let selectedProject= {};
          selectedProject.uuid=project.uuid;
          selectedProject.name=project.name;
          selectedProject.inchargeof=project.inchargeof;
          selectedProject.phone=project.phone;
          selectedProject.type=project.typeProject;
          selectedProject.publish=project.publish;
          selectedProject.entraves=[];
          selectedProject.phases=[];
          var perimeter = (project.data.entraves.perimeter)?project.data.entraves.perimeter:1;
          if (filters.entrave) {
            project.data.entraves.entraves.forEach((entrave, index) => {
              let eRange = {sdate : project.data.entraves.date[0], edate : project.data.entraves.date[1]};
              let dists = [];
              let geoms = entrave.selected.path.map(function(object){
                dists.push(perimeter);
                return object.geometry;
              });
              let buff = geometryEngine.geodesicBuffer(geoms, dists, conflictsUnits, true);
              let obj = {
                entraveId: entrave.entraveId,
                entraveIndex: (index+1),
                value: entrave.value,
                label: entrave.label,
                dates: []
              };
              if (from=='entrave') {
                let filtersDate = {sDate: filters.date[0], eDate: filters.date[1]};
                let inConflict = _this.inConflict({dateRange: eRange, filtersDate: filtersDate, buffer: buff[0]}, "entrave", conflictsBuffer, geometryEngine);
                if (inConflict) {
                  obj.dates = inConflict.conflictDates;
                  selectedProject.entraves.push(obj); 
                }
              } else {
                filters.pdates.forEach((element) => {
                  let filtersDate = {sDate: element.sdate, eDate: element.edate};
                  let inConflict = _this.inConflict({dateRange: eRange, filtersDate: filtersDate, buffer: buff[0]}, "entrave", conflictsBuffer, geometryEngine);
                  if (inConflict) {
                    obj.dates.push(inConflict.conflictDates);
                  }
                });
                if(obj.dates.length > 0) {
                  selectedProject.entraves.push(obj);
                }
              }
            }); 
          }
          if (filters.closing || filters.detour) {
            project.data.phases.forEach((phase, index) => {
              var objPhase = {
                phaseId: phase.phaseId,
                phaseIndex: (index+1),
                closing: [],
                detours: []
              };
              phase.closing.forEach((closing, closeIndex) => {
                if (filters.closing) {
                  let cRanges = closing.dates.map(function(date){
                    return {sdate : date.sdate, edate : date.edate, closingType : date.closingType, days : date.days};
                  });
                  let dists = [];
                  let geoms = closing.selected.path.map(function(object){
                    dists.push(perimeter);
                    return object.geometry;
                  });
                  let buff = geometryEngine.geodesicBuffer(geoms, dists, conflictsUnits, true);
                  let objClose = {
                    closingId: closing.closingId,
                    closeIndex: (closeIndex+1),
                    value: closing.value,
                    label: closing.label,
                    dates: []
                  };
                  if (from=='entrave') {
                    let filtersDate = {sDate: filters.date[0], eDate: filters.date[1]};
                    let inConflict = _this.inConflict({dateRanges: cRanges, filtersDate: filtersDate, buffer: buff[0]}, "closing", conflictsBuffer, geometryEngine);
                    if (inConflict) {
                      objClose.dates=inConflict.conflictDates;
                      objPhase.closing.push(objClose);
                    }
                  
                  } else {
                    filters.pdates.forEach((element) => {
                      let filtersDate = {sDate: element.sdate, eDate: element.edate};
                      let inConflict = _this.inConflict({dateRanges: cRanges, filtersDate: filtersDate, buffer: buff[0]}, "closing", conflictsBuffer, geometryEngine);
                      if (inConflict) {
                        objClose.dates.push(inConflict.conflictDates);
                      }
                    });
                    if(objClose.dates.length > 0) {
                      objPhase.closing.push(objClose);
                    }
                  }
                }
                if (filters.detour) {
                  closing.detours.forEach((detour, detourIndex) => {
                    let dRanges = detour.dates.map(function(date){
                      return {sdate : date.sdate, edate : date.edate, closingType : date.closingType, days : date.days};
                    });
                    let dists = [];
                    let geoms = detour.selected.path.map(function(object){
                      dists.push(perimeter);
                      return object.geometry;
                    });
                    let buff = geometryEngine.geodesicBuffer(geoms, dists, _this.conflictsUnits, true);
                    let objDetour = {
                      detourId: detour.detourId,
                      detourIndex: (detourIndex+1),
                      value: detour.value,
                      label: detour.label,
                      dates: []
                    };
                    if (from=='entrave') {
                      let filtersDate = {sDate: filters.date[0], eDate: filters.date[1]};
                      let inConflict = _this.inConflict({dateRanges: dRanges, filtersDate: filtersDate, buffer: buff[0]}, "detour", conflictsBuffer, geometryEngine);
                      if (inConflict) {
                        objDetour.dates=inConflict.conflictDates;
                        objPhase.detours.push(objDetour);
                      }
                    } else {
                      filters.pdates.forEach((element) => {
                        let filtersDate = {sDate: element.sdate, eDate: element.edate};
                        let inConflict = _this.inConflict({dateRanges: dRanges, filtersDate: filtersDate, buffer: buff[0]}, "detour", conflictsBuffer, geometryEngine);
                        if (inConflict) {
                          objDetour.dates.push(inConflict.conflictDates);
                        }
                      });
                      if(objDetour.dates.length > 0) {
                        objPhase.detours.push(objDetour);
                      }
                    }
                  });
                }
              });
              if (objPhase.closing.length>0 || objPhase.detours.length>0) {
                selectedProject.phases.push(objPhase);  
              }
            }); 
          }
          if (selectedProject.entraves.length > 0 || selectedProject.phases.length > 0) {
            ProjectsConflict.push(selectedProject);
          }
        }
      });
    }
    return ProjectsConflict;
  },
  inConflict(data, what, conflictsBuffer, geometryEngine){
    let _this = this;
    let sDate = data.filtersDate.sDate, eDate = data.filtersDate.eDate;
    let days = [1,2,3];
    var dateRanges;// = data.dateRange;
    switch (what){
      case "entrave":
        dateRanges = [data.dateRange];
        break;
      default :
        dateRanges = data.dateRanges;
        break;
    }
    var buff = data.buffer;
    var dateCrossings = _this.dateRangesCrossings(sDate, eDate, days, dateRanges);
    if(dateCrossings && geometryEngine.intersects(conflictsBuffer, buff)){return {buffer : buff, conflictDates : dateCrossings};}
    else{return undefined;}
  },
  dateRangesCrossings(sDate, eDate, pathDays, dateRanges){
    let _this = this;
    var crossings = [];
    dateRanges.forEach(function(range, i){
      let start = range.sdate, end = range.edate;
      let days = _this.daysIntersect(pathDays, range.days);
      if(days === true || (Array.isArray(days) && days.length > 0)){
        if (sDate >= start && sDate <= end) {
          crossings.push({ranges : [sDate, end], days : days, dateRangeNb : i + 1});
        }else if(start >= sDate && start <= eDate){
          crossings.push({ranges : [start, eDate], days : days, dateRangeNb : i + 1});
        }
      }
    });
    if(crossings.length === 0){crossings = undefined;}
    return crossings;
  },
  daysIntersect(days1, days2){
    if (days1 && days2){
      return days1.filter(function(day){return days2.includes(day);});
    }else {return true;}
  },
  typeProject(entraves) {
    let type='ponctuel';
    entraves.forEach(function(element) {
      if (element.type=='linear') {
        type='linear';
      }
    });
    return type;
  }
};
