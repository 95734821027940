<template>
  <div class="public-map">
    <help-public v-bind:lang="filters.lang" v-bind:instance="filters.city" v-bind:enabledHelp="enabledHelp" v-bind:groupEntrave="groupEntrave" v-bind:openLegend="openLegend" ></help-public>
    <div id="map" class="full-screen-map">
      <div v-show="loading" class="loading-map">
        <img src="../../public/img/loading.gif" />
      </div>
    </div>
    <!--
      <div class="col-sm-6 col-md-6 col-lg-6 filters-step-element">
        <base-switch v-model="filters.step1" on-text="" off-text=""></base-switch><span class="step-title">{{ $t('publicPage.filterStep1') }}</span>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 filters-step-element">
        <base-switch v-model="filters.step2" on-text="" off-text=""></base-switch><span class="step-title">{{ $t('publicPage.filterStep2') }}</span>
      </div>
    -->
    <div v-if="filters.city!='gatineau'" v-show="enabledHelp" class="col-sm-12 col-md-12 col-lg-12 public-filters-content">
      <!--<div v-show="filterStep" class="col-sm-2 col-md-2 col-lg-2 filters-step-container">-->
      <div v-show="filterStep" class="filters-step-container">
        <div class="filters-step-content">
          <div class="dropdown show-dropdown dropup" :class="{ show: displayType }"  @mouseover="displayType = true" @mouseleave="displayType = false">
            <a data-toggle="dropdown" class="settings-icon">
              <!--<i class="fas map-icons list-icon"></i>-->
              <i class="tim-icons icon-bullet-list-67"></i>
            </a>
            <div class="dropdown-menu" :class="{ show: displayType }">
              <label class="dropdown-title">{{ $t('publicPage.title') }}</label>
              <div v-show="filters.step2" class="divider"></div>
              <div class="filters-step-element">
                <base-switch v-model="filters.step2" on-text="" off-text=""></base-switch><span class="step-title">{{ $t('publicPage.filterStep2') }}</span>
              </div>
              <div class="filters-step-element">
                <base-switch v-model="filters.step1" on-text="" off-text=""></base-switch><span class="step-title">{{ $t('publicPage.filterStep1') }}</span>
              </div>
              <div class="filters-step-element">
                <base-switch v-model="filters.year" on-text="" off-text=""></base-switch><span class="step-title">{{ $t('publicPage.filterYear') }} {{year}}</span>
              </div>
              <div v-show="filters.step2" class="divider"></div>
              <!--<label v-show="filters.step2" >{{ $t('publicPage.autoImpact') }}</label>-->
              <div v-show="filters.step2" class="filters-step-element">
                <base-switch v-model="filters.auto" @input="changeAuto" on-text="" off-text=""></base-switch><span class="step-title">{{ $t('publicPage.filterAuto') }}</span>
              </div>
              <div v-show="filters.step2" class="filters-step-element">
                <base-switch v-model="filters.cycliste" @input="changeAuto" on-text="" off-text=""></base-switch><span class="step-title">{{ $t('publicPage.filterCycliste') }}</span>
              </div>
              <div v-show="filters.step2" class="filters-step-element">
                <base-switch v-model="filters.pieton" @input="changeAuto" on-text="" off-text=""></base-switch><span class="step-title">{{ $t('publicPage.filterPieton') }}</span>
              </div>
              <!--<div class="divider"></div>-->
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="col-sm-2 col-md-2 col-lg-2">
      </div>
      -->
      <!--<div class="col-sm-6 col-md-6 col-lg-6 filters-range">-->
      <div v-show="enabledHelp" class="filters-range">
        <base-input>
          <el-date-picker
            type="datetimerange"
            value-format="timestamp"
            range-separator="-"
            format="yyyy-MM-dd HH:mm"
            lang="fr"
            :clearable="false"
            v-bind:start-placeholder="$t('projectForm.labelProjectStart')"
            v-bind:end-placeholder="$t('projectForm.labelProjectEnd')"
            v-model="filters.ranges">
          </el-date-picker>
        </base-input>
      </div>
    </div>
  </div>
</template>
<script>

import {TimeSelect, DatePicker} from 'element-ui';
import {BaseSwitch} from 'src/components/index';
import axios from 'axios';
import { loadModules } from 'esri-loader';
import colors from './../app/shared/colors';
import icons from './../app/shared/icons';
import RoadNetwork from './../app/shared/roadNetwork';
import Tags from './../app/shared/filters';
import HelpersMaps from './../app/helpers/helpersMap';
import HelpersDrawProject from './../app/helpers/helpersDrawProject';
import HelpPublic from './HelpPublic.vue';
import ProjectsTimeline from  './../app/map/TimelineProjectsComponent.vue';
import moment from 'moment';

const maxSlices = 20;

export default {
  components: {
    BaseSwitch,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    HelpPublic
  },
  data() {
    return {
      year: new Date().getFullYear(),
      displayType: false,
      loading: true,
      groupEntrave: true,
      enabledHelp: false,
      openLegend: false,
      display: {
        'entraves': false,
        'closing': false,
        'detours': false
      },
      colors: colors.list,
      icons: icons,
      user: null,
      pinLayer: null,
      geometryEngine: null,
      webMercatorUtils: null,
      geodesicUtils: null,
      GraphicsLayer: null,
      entravesLayer: null,
      detoursLayer: null,
      closingLayer: null,
      phasesLayer: null,
      Graphic  : null,
      Polyline : null,
      Point: null,
      Polygon: null,
      filterStep: false,
      onAuto: false,
      filters:  {
        long: -123.07931356264523,
        lat: 49.24675853467655,
        type: 'osm',
        publish: true,
        nopublish: true,
        ranges: [],
        city: 'vancouver',
        tags: {},
        step1: true,
        step2: true,
        auto: false,
        pieton: false,
        cycliste: false,
        year: false
      },
      projects: [],
      graphics: {},
      refs: [],
      proNames: [],
      zoom: 15,
          };
  },
  watch: {
    'filters.ranges'(val) {
      if (this.loading) {
        return;
      }
      //this.filters.ranges=val;
      if (this.filters.tags.step.length==0) {
        delete this.filters.tags.step;
      }
      this.resetLayer();
      this.getProjects();
    },
    'filters.step1'(val) {
      this.filters.tags.step=[];
      if (val) {
        this.filters.tags.step=['1', '2', '3', '4', '5'];
      }
      if (this.filters.step2) {
        this.filters.tags.step.push('6');
      }
      this.resetLayer();
      if (this.filters.tags.step.length>0) {
        this.getProjects();
      }
    },
    'filters.step2'(val) {
      this.filters.tags.step=[];
      if (val) {
        this.filters.tags.step=['6'];
      } else {
        this.filters.auto=false;
        this.filters.cycliste=false;
        this.filters.pieton=false;
      }
      if (this.filters.step1) {
        this.filters.tags.step = this.filters.tags.step.concat(['1', '2', '3', '4', '5']);
      }
      this.resetLayer();
      if (this.filters.tags.step.length>0) {
        this.getProjects();
      }
    },
    'filters.year'(val) {
      if (val) {
        let year = new Date().getFullYear();
        this.filters.ranges=[new Date(year + '-01-01').getTime(), new Date(year + '-12-31').getTime()];
      } else {
        this.filters.ranges=[];
      }
    }/*,
    'onAuto'(val) {
      this.filters.auto=val;
      this.resetLayer();
      this.getProjects();
    }*/
  },
  created() {
    this.user=JSON.parse(window.localStorage.getItem('user'));
    if (this.$route.query) {
      this.filters.long = parseFloat(this.$route.query.long, 10);
      this.filters.lat = parseFloat(this.$route.query.lat, 10);
      this.filters.city = this.$route.query.city || 'quebec';
      this.filters.type = this.$route.query.type || 'osm';
      this.filters.modeMap = this.$route.query.modeMap || '0';
      this.filters.lang = this.$route.query.lang || 'fr';
      this.$root.$i18n.locale = this.filters.lang;
      if (this.$route.query.uuid) {
        this.enabledHelp=false;
        this.filters.uuid = this.$route.query.uuid;
        this.filters.close = this.$route.query.close;
        this.zoom = this.$route.query.zoom || 15;
        if (this.$route.query.phases) {
          this.filters.phases = this.$route.query.phases;
        } else {
          this.filters.phases = 'all';
        }
      } else {
        this.enabledHelp=true;
        this.openLegend=true;
        let sdate = new Date().getTime() - (6*30*24*60*60*1000);
        let edate = new Date().getTime() + (6*30*24*60*60*1000);
        this.filters.publish = this.$route.query.publish || true;
        this.filters.nopublish = this.$route.query.nopublish || false;
        this.filters.ranges = [parseInt(this.$route.query.sdate, 10) || sdate, parseInt(this.$route.query.edate, 10) || edate];
        //this.display.entraves = JSON.parse(this.$route.query.entraves) || false;
        this.display.entraves = (this.$route.query.entraves==='true') || false;
        //this.display.closing  =  JSON.parse(this.$route.query.closing) || false;
        this.display.closing  =  (this.$route.query.closing==='true') || false;
        //this.display.detours  =  JSON.parse(this.$route.query.detours) || false;
        this.display.detours  =  (this.$route.query.detours==='true') || false;
      }
      this.filters.tags.step=[];
      Tags[this.filters.city].step.value.forEach(element => {
        if((element.value!='7') && (element.value!='8')) {
          this.filters.tags.step.push(element.value);    
        }
      });
    } 
  },
  mounted() {
    loadModules(["esri/Map",
    "esri/Basemap",
    "esri/views/MapView",
    "esri/layers/FeatureLayer",
    "esri/layers/GraphicsLayer",
    "esri/Graphic",
    "esri/geometry/Polyline",
    "esri/geometry/Point",
    "esri/geometry/geometryEngine",
    "esri/geometry/support/geodesicUtils",
    "esri/geometry/support/webMercatorUtils",
    "esri/layers/VectorTileLayer",
    "esri/geometry/Polygon"
    ]).then(([Map, BaseMap, MapView, FeatureLayer, GraphicsLayer, Graphic, Polyline, Point, geometryEngine, geodesicUtils, webMercatorUtils, VectorTileLayer,Polygon]) => {
        let location = [this.filters.long, this.filters.lat];
        let type = this.filters.type || 'osm';
        var basemap;
        if (this.filters.type && this.filters.modeMap==="1") {
          basemap = new BaseMap({
            portalItem: {id: this.filters.type}
          });
        }
        else if (this.filters.type && this.filters.modeMap==="2"){
          basemap = new BaseMap({
            baseLayers: [
              new VectorTileLayer({
                portalItem: {
                  id: this.filters.type
                }
              })
            ]
          });
        } 
        else {
          basemap = this.filters.type || 'osm'; 
        }
        this.map = new Map({
          basemap: basemap
        });
        /*
        this.map = new Map({
          basemap : type
        });
        */
        this.view = new MapView({
          container: "map",
          map: this.map,
          center: location,
          zoom: this.zoom,
          spatialReference: 102100,
          highlightOptions: {
            color: [255, 255, 255, 0],
            haloOpacity: 0,
            fillOpacity: 0
          }
        });
        this.view.ui.move("zoom", "top-left");
        this.layerStreets = new FeatureLayer({
          url : RoadNetwork[this.filters.city].url,
          visible : false,
          outFields: RoadNetwork[this.filters.city].fields
        });
        let _this=this;
        
        this.view.popup.on("trigger-action", function(evt){
          if (evt.action.id==='public-project-info') _this.activeAnimation(evt.action.uuid);
        });

        this.groupEntrave=RoadNetwork[this.filters.city].groupEntrave;
        this.filterStep = Tags[this.filters.city].step.show;

        this.Graphic = Graphic;
        this.Polyline = Polyline;
        this.Point = Point;
        this.webMercatorUtils = webMercatorUtils;
        this.geodesicUtils = geodesicUtils;
        this.geometryEngine = geometryEngine;
        this.Polygon = Polygon;
        
        this.pinLayer    = new GraphicsLayer();
        this.detoursLayer     = new GraphicsLayer();
        this.map.add(this.detoursLayer);
        this.map.add(this.pinLayer);
        this.entravesLayer = new GraphicsLayer();
        this.map.add(this.entravesLayer);
        this.closingLayer = new GraphicsLayer();
        this.map.add(this.closingLayer);
        this.phasesLayer = new GraphicsLayer();
        this.map.add(this.phasesLayer);
        this.detourLayer = new GraphicsLayer();
        this.map.add(this.detourLayer);
        this.getProjects();
        
    }).catch(() => {
      //console.error(err);
    });
  },
  methods: {
    resetLayer() {
      this.projects=[];
      this.pinLayer && this.pinLayer.removeAll();
      this.entravesLayer && this.entravesLayer.removeAll();
      this.detoursLayer.removeAll();
      this.closingLayer && this.closingLayer.removeAll();
      this.phasesLayer && this.phasesLayer.removeAll();
      this.detourLayer && this.detourLayer.removeAll();
      this.refs.forEach(element => {
        clearInterval(element);
      });
      this.refs=[];
    },
    /*getColorByEntity(project, defaultColor) {
      let color;
      let _this=this;
      if (project && project.informations && project.informations.entity) {
        let len = project.informations.entity.length;
        let entity=project.informations.entity[len-1];
        let entities = Tags[this.filters.city].entity.value;
        entities.forEach((element) => {
          if ((element.value==entity) && (element.class)) {
            let classes = Tags[_this.filters.city].entity.classes;
            color = classes[element.class] || defaultColor;
          }
        });
      }
      if (color) {
        return color;
      } else {
        return defaultColor;
      }
    },*/
    changeAuto(target) {
      this.resetLayer();
      this.getProjects();
    },
     getProjects() {
      this.loading=true;
      let _this = this;
      axios.post('public/get_projects', _this.filters).then(response => {
        //_this.loading=false;
        let data = response.data;
        var data1;
        if (data.success) {
          if (Array.isArray(data.data)) {
            data1 = data.data;
          } else {
            data1 = [data.data];
          }
          data1.forEach(function(element) {
            try {
              if (_this.checkAuto(element)) {
                _this.projects[element.data.uuid] = element.data;
              }
            } catch (error) {
              console.log("Error At checkAuto!: ", error);
            }
          });
          if (this.filters.uuid && this.filters.phases) {
            _this.drawProjects();
            _this.view.goTo(_this.pinLayer.graphics.toArray());

          } else {
            _this.drawProjects();
          }
        } else {
          if (data.need_auth) {
            this.$router.push({path: '/login'});
          } else {
            this.$notify({
              message: _this.$i18n.t('serverReply.errorProject'),
              timeout: 30000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
        _this.loading=false;
      }).catch((e) => {
        console.log("err load project::::", e);
      });
    },
    checkAuto(element) {
      let project = element.data;
      if ((!this.filters.auto) && (!this.filters.cycliste) && (!this.filters.pieton)) {
        return true;
      } else {
        let checkAuto=true;
        if (project.informations && project.informations.passanger && 
            (project.informations.passanger.value[1] && (project.informations.passanger.value[1].status==true && this.filters.auto==true)) || 
            (project.informations.passanger.value[2] && (project.informations.passanger.value[2].status==true && this.filters.cycliste==true)) ||
            (project.informations.passanger.value[3] && (project.informations.passanger.value[3].status==true && this.filters.pieton==true))) {
          project.phases.forEach((phase) => {
            phase.closing.forEach((close) => {
              if (close.detours.length > 0) {
                checkAuto=true;
              }  else {
                checkAuto=false;
              }
            });
          });
        } else {
          checkAuto=false;
        }
        return checkAuto;
      }
    },
    drawProjects() {
      let _this = this;
      let keys = Object.keys(this.projects);
      this.projectsPins = [];
      keys.forEach(function(element, index) {
        try {
          //_this.projects[element].color=_this.colors[index % keys.length];
          let len = _this.colors.length;
          _this.projects[element].color=_this.colors[index % len];
          let color = HelpersMaps.hexToRgb(_this.projects[element].color);
          let focused = (element==_this.focusUUID)?true:false;
          let colorEntraves = _this.getColorByEntity(_this.projects[element], color); 
          _this.drawEntraves(element, _this.projects[element].entraves.entraves, colorEntraves, focused);
          _this.drawPhases(element, _this.projects[element].phases, colorEntraves);
          _this.drawProjectPin(_this.projects[element], colorEntraves, focused);
          
          // _this.drawProjectConflict(_this.projects[element], _this.conflicts, color);
        } catch (error) {
          console.log("Error At drawProjects!: ", error);
        }
        _this.view.goTo(_this.pinLayer.graphics.toArray());
        // _this.view.goTo(_this.entravesLayer.graphics.toArray());
      });
    },
    drawEntravePolygone(paths, obj, type, color, focused) {
      let _this = this;
      paths.selected.path.forEach(graphic => {
        _this.entravesLayer.add(_this.Graphic.fromJSON(graphic));
      });
      
    },
    drawCloserPolygon(paths){
      let _this = this;
      paths.selected.path.forEach(graphic => {
        _this.closingLayer.add(_this.Graphic.fromJSON(graphic));
      });
    },
    drawDetourPolygon(paths){
      let _this = this;
      paths.forEach(path=>{
        path.selected.path.forEach(graphic => {
          _this.detoursLayer.add(_this.Graphic.fromJSON(graphic));
        });
      })
    },
    drawEntraves(pUUID, entraves, color, focused) {
      let _this = this;
      entraves.forEach(function(entrave) {
        if (entrave.type=='linear') {
          let obj = {pUUID: pUUID, elementId: entrave.entraveId, type: 'linear', entry: 'entrave', color: color};
          _this.drawEntravePolyline(entrave, obj, 'entrave', color, focused);
        }else if (entrave.type=='polygon') {
          let obj = {pUUID: pUUID, elementId: entrave.entraveId, type: 'polygon', entry: 'entrave', color: color};
          _this.drawEntravePolygone(entrave, obj, 'entrave', color, focused);
        }
         else {
          let obj = {pUUID: pUUID, elementId: entrave.entraveId, type: 'ponctuel', entry: 'entrave', color: color};
          _this.drawPoint(entrave.selected.path[0], obj, 'entrave', color, focused);
        }
      });
    },
    

    drawPhases(pUUID, phases, color) {
      let _this = this;
      phases.forEach(function(closing,phaseIndex) {
        closing.closing.forEach(function(close,closeIndex) {
          if (close.type=='linear') {
            //_this.drawPolyline(close.selected.path, obj, 'closing', color);
            let pinCloseGraph = _this.drawPinLinearClose(close, color, phaseIndex, closeIndex);
            let obj = {pUUID: pUUID, elementId: close.closingId, type: 'linear', entry: 'closing', pinCloseGraph: pinCloseGraph, color: color};
            _this.drawClosingPolyline(close, obj, 'closing', color);
          }else if (close.type=='polygon') {
              _this.drawCloserPolygon(close)
              _this.drawDetourPolygon(close.detours)
          } 
          else {
            let typeClosing = close.dates[0].closingType[0] || 'complete';
            let obj = {pUUID: pUUID, elementId: close.closingId, type: 'ponctuel', entry: 'closing', typeClosing: typeClosing, color: color};
            _this.drawPoint(close.selected.path[0], obj, 'closing', color);
          }
          // _this.drawDetours(pUUID, close, color, phaseIndex, closeIndex);
        });
      });
    },
    drawDetours(pUUID, close, color, phaseIndex, closeIndex) {
      let _this = this;
      close.detours.forEach(function(detour,detourIndex) {
        let obj = {pUUID: pUUID, elementId: detour.detourId, type: 'linear', entry: 'detour', color: color,  phaseIndex:phaseIndex+1, closeIndex:closeIndex+1, detourIndex:detourIndex+1};
        _this.drawDetourPolyline(detour, obj, 'detour', color);
      });
    },
    
    drawProjectPin(project, color, focused) {
      let graph = HelpersDrawProject.drawPin(project, this.Point, this.Polyline,this.Polygon ,this.Graphic, this.geometryEngine, this.webMercatorUtils, color,false,Tags[this.filters.city],this.filters.city);
      let geographic = this.webMercatorUtils.webMercatorToGeographic(graph.geometry);
      let converted = turf.point([geographic.longitude, geographic.latitude]);
      this.projectsPins.push({"x" : converted.geometry.coordinates[0], "y" : converted.geometry.coordinates[1]});
      this.pinLayer.add(graph);
      if (focused) {
        this.focusLayer.removeAll();
        this.focusLayer.add(graph);
        this.focusGraph = [graph];
      }
    },
    drawProjectEntraves(project, color) {
      let _this=this;
      if (_this.display.entraves) {
        project.entraves.entraves.forEach((entrave) => {
          if (entrave.type=='linear') {
            _this.drawEntravePolyline(entrave, {}, 'entrave', color);
          } else {
            //_this.drawPoint(entrave, {}, 'entrave', color);
            -this.drawEntravesPoint(entrave.selected.path[0], 'entrave', color);
          }
        });
      }
    },
    drawProjectPhases(project, color) {
      let _this=this;
      project.phases.forEach((phase, phaseIndex) => {
        phase.closing.forEach((close, closeIndex) => {
          if (_this.display.closing && (close.publish!=undefined && (close.publish==true) && ((project.informations.publicMapWork && project.informations.publicMapWork!=undefined && project.informations.publicMapWork==true)))) {
            if (close.type=='linear') {
              let pinCloseGraph = _this.drawPinLinearClose(close, phaseIndex+1, closeIndex+1, color);
              let obj = {pUUID: project.uuid, elementId: close.closingId, type: 'linear', entry: 'closing', pinCloseGraph: pinCloseGraph, color: color};
              //_this.drawProjectsClosingPolyline(close, obj, 'closing', color);
              if (_this.filters.uuid) {
                _this.drawProjectsClosingPolyline(close, obj, 'closing', color);
              } else {
                _this.drawEntravePolyline(close, obj, 'closing', color);
                _this.closingLayer.add(obj.pinCloseGraph);
              }
            } else {
              let typeClosing = close.dates[0].closingType[0] || 'complete';
              let obj = {pUUID: project.uuid, elementId: close.closingId, type: 'ponctuel', entry: 'closing', typeClosing: typeClosing, color: color};
              obj.closingPointPopupTemplate = _this.populatePopup(close, phaseIndex+1, closeIndex+1);
              _this.drawProjectsClosingPoint(close.selected.path[0], obj, 'closing', color);
            }
            if (_this.display.detours) {
              _this.drawDetours(project.uuid, close, color, phaseIndex, closeIndex);
            }
          }
        });
      });
    },
    drawDetours(pUUID, close, color, phaseIndex, closeIndex) {
      let _this=this;
      close.detours.forEach(function(detour,detourIndex) {
        let obj = {pUUID: pUUID, elementId: detour.detourId, type: 'linear', entry: 'detour', color: color,  phaseIndex:phaseIndex+1, closeIndex:closeIndex+1, detourIndex:detourIndex+1};
        _this.drawProjectsDetourPolyline(detour, obj, 'detour', color );
        //_this.drawAnimateDetour(pUUID, detour.detourId, obj);
      });
    },
    drawEntravePolyline(paths, obj, type, color) {
      let _this = this;
      let polyline = HelpersMaps.updateSimpleLine(paths, this.Point, this.Polyline, this.geometryEngine);
      let graph = new _this.Graphic({
        geometry   : polyline,
        //attributes : paths[0].attributes,
        visible : true,
        symbol : {
          type: 'simple-line',
          style: 'solid',
          color: color,
          width: '2px'
        },
      });
      let graphs = [graph];
      graphs.forEach((g) => {
        _this.entravesLayer.add(g);
      });
    },
    drawEntravesPoint(point, type, color) {
      let graph = HelpersDrawProject.drawPoint(point, this.Graphic, type, color, this.Point);
      this.entravesLayer.add(graph);
    },
    populatePopupDetour(detour, phaseIndex, closeIndex,detourIndex) {
      let content = "";
      detour.dates.forEach((element,index) => {
        content += '<strong>' + this.$i18n.t('projectForm.labelProjectPeriod') +'#' + (index+1) + ': </strong>';
        let sdate = this.formatDate(element.sdate);
        let edate = this.formatDate(element.edate);
        content += '<span>' + sdate + '</span> - <span>' + edate +'</span><br/>';
      });

      let popupTemplate = {
        title: (this.$i18n.t('mapPage.phase') + phaseIndex) + ' ' + (this.$i18n.t('mapPage.closing') + closeIndex + ' ' + (this.$i18n.t('mapPage.detours') + detourIndex)),
        content: content
      };
      return popupTemplate;
    },
    drawProjectsDetourPolyline(paths, obj, type, color) {
      let _this = this;
      let detourSensValue = paths.dates[0].detourSensValue;
      let polyline = HelpersMaps.updateSimpleLine(paths, this.Point, this.Polyline, this.geometryEngine);
      let graphs = HelpersMaps.drawRepeated(polyline, color, type, this.Polyline, this.Point, this.Graphic, this.geometryEngine, this.webMercatorUtils, this.geodesicUtils, detourSensValue);
      _this.populateGraph(obj, graphs);
      graphs.forEach((g) => {
        if(obj.phaseIndex){
          let popupTemplate = this.populatePopupDetour(paths, obj.phaseIndex, obj.closeIndex, obj.detourIndex);
          g.popupTemplate = popupTemplate;
        }
        _this.detourLayer.add(g);
      });
    },
    drawProjectsClosingPolyline(paths, obj, type, color) {
      let _this = this;
      let polyline = HelpersMaps.updateSimpleLine(paths, this.Point, this.Polyline, this.geometryEngine);
      let graphs = HelpersMaps.drawRepeated(polyline, color, type, this.Polyline, this.Point, this.Graphic, this.geometryEngine, this.webMercatorUtils, this.geodesicUtils);
      graphs.forEach((g) => {
        _this.closingLayer.add(g);
      });
      _this.closingLayer.add(obj.pinCloseGraph);
    },
    drawProjectsClosingPoint(point, obj, type, color) {
      let graph = HelpersDrawProject.drawPoint(point, this.Graphic, type, color, this.Point, obj.typeClosing);
      graph.popupTemplate = obj.closingPointPopupTemplate;
      this.closingLayer.add(graph);
    },
 
    drawCloses(close, closeIndex, index, closeColor, detourColor) {
      let _this=this;
      if (close.type=='linear') {
        let pinCloseGraph = _this.drawPinLinearClose(close, index+1, closeIndex+1, closeColor);
        let obj = {pUUID: _this.filters.uuid, 
                    elementId: close.closingId,
                    type: 'linear',
                    entry: 'closing',
                    pinCloseGraph: pinCloseGraph,
                    color: closeColor 
                  };
        _this.drawClosingPolyline(close, obj, 'closing', obj.color);
      } else {
        let typeClosing = close.dates[0].closingType[0] || 'complete';
        let obj = {pUUID: _this.filters.uuid, 
                  elementId: close.closingId, 
                  type: 'ponctuel',
                  entry: 'closing',
                  typeClosing: typeClosing,
                  color: closeColor};
        _this.drawPoint(close, index+1, closeIndex+1, obj, 'closing', obj.color);
      }
      close.detours.forEach(function(detour) {
        let obj = {pUUID: _this.filters.uuid, elementId: detour.detourId, type: 'linear', entry: 'detour', color: detourColor};
        _this.drawDetourPolyline(detour, obj, 'detour', detourColor);
      });
    },
    drawClosingPolyline(paths, obj, type, color) {
      let _this = this;
      paths.selected.path.forEach(function(object) {
        var geometry = object.geometry;
        var graph = new _this.Graphic({
            geometry   : geometry,
            attributes : object.attributes,
            visible    : true,
            symbol  : {
              type: "simple-fill",
              style: "none",
              outline: {
                color: color,
                width: 2
              }
            }
        });
        _this.phasesLayer.add(graph);
      });
      _this.phasesLayer.add(obj.pinCloseGraph);
      _this.update(paths.selected.path);
    },
    drawDetourPolyline(paths, obj, type, color) {
      let _this = this;
      paths.selected.path.forEach(function(object) {
        var geometry = object.geometry;
        var graph = new _this.Graphic({
            geometry   : geometry,
            attributes : object.attributes,
            visible    : true,
            symbol  : {
              type: "simple-fill",
              style: "none",
              outline: {
                color: color,
                width: 2
              }
            }
        });
        _this.phasesLayer.add(graph);
      });
      /*let _this = this;
      let polyline = HelpersMaps.updateSimpleLine(paths, this.Point, this.Polyline, this.geometryEngine);
      let graphs = HelpersMaps.drawRepeated(polyline, color, type, this.Polyline, this.Point, this.Graphic, this.geometryEngine, this.webMercatorUtils, this.geodesicUtils);
      graphs.forEach((g) => {
        _this.phasesLayer.add(g);
      });
      */
    },
    populateGraph(obj, graph) {
      if (!this.graphics[obj.pUUID]) {
        this.graphics[obj.pUUID]={};
      }
      obj.graphic=graph;
      obj.ref=null;
      this.graphics[obj.pUUID][obj.elementId]=obj;
    },
    update (path) {
      var geoms, dists = [];
      geoms = path.map(function(object){
        dists.push(55);
        return object.geometry;
      });
      var buff =  this.geometryEngine.geodesicBuffer(geoms, dists, 'meters', true);
      var g = new this.Graphic({
        geometry :buff[0],
        visible : true,
        symbol  : {
          type: "simple-fill",
          style: "none",
          outline: {  
            color: '#f84c6b',
            width: 2
          }
        }
      });
      this.closingLayer.add(g);
    },
    drawPinLinearClose(close, phaseIndex, closeIndex, color) {
      let typeClosing = close.dates[0].closingType[0] || 'complete';
      let midPoint = HelpersMaps.polylineMidPoint(close, this.Point, this.Polyline, this.geometryEngine, this.webMercatorUtils);
      let symbol = HelpersDrawProject.pointClosingTypeMarker(typeClosing, color);
      let popupTemplate = this.populatePopup(close, phaseIndex, closeIndex);
      let graph = new this.Graphic({
        geometry   : midPoint,
        popupTemplate: popupTemplate,
        visible    : true,
        symbol: symbol
      });
      return graph;
    },
    drawPoint(close, phaseIndex, closeIndex, obj, type, color) {
      let graph;
      graph = HelpersDrawProject.drawPoint(close.selected.path[0], this.Graphic, type, color, this.Point, obj.typeClosing);
      let popupTemplate = this.populatePopup(close, phaseIndex, closeIndex);
      graph.popupTemplate = popupTemplate;
      this.phasesLayer.add(graph);
    },
    populatePopup(close, phaseIndex, closeIndex) {
      let content = '<strong>' + this.$i18n.t('projectForm.closeDate') + ': </strong>';
      close.dates.forEach(element => {
        let sdate = this.formatDate(element.sdate);
        let edate = this.formatDate(element.edate);
        content += '<span>' + sdate + '</span> - <span>' + edate +'</span><br/>';
      });
      content += '<strong>' + this.$i18n.t('projectForm.street') + ': </strong>' + close.value + '<br/>'
      let popupTemplate = {
        title: (this.$i18n.t('mapPage.phase') + phaseIndex) + ' ' + (this.$i18n.t('mapPage.closing') + closeIndex),
        content: content
      };
      return popupTemplate;
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    getColorByEntity(project, defaultColor) {
      let color;
      let _this=this;
      if (project && project.informations && project.informations.entity && this.filters.city) {
        let len = project.informations.entity.length;
        let entity=project.informations.entity[len-1];
        let entities = Tags[this.filters.city].entity.value;
        entities.forEach((element) => {
          if ((element.value==entity) && (element.class)) {
            if (element.class1) {element.class=element.class1.replace(' hidden','');}
            let classes = Tags[_this.filters.city].entity.classes;
            color = classes[element.class] || defaultColor;
          }
        });
      }
      if (color) {
        return color;
      } else {
        return defaultColor;
      }
    },
    activeAnimation(pUUID) {
      let project = this.projects[pUUID];
      let _this=this;
      project.phases.forEach((phase) => {
        phase.closing.forEach((close) => {
          close.detours.forEach((detour)  => {
            let obj = _this.graphics[pUUID][detour.detourId];
            if (obj.animate) {
              obj.animate=false;
              _this.graphics[pUUID][detour.detourId]=obj;
              _this.detourLayer.removeMany(obj.graphic);
              _this.refs.forEach(element => {
                clearInterval(element);
              });
              _this.drawProjectsDetourPolyline(detour, obj, 'detour', obj.color);
            } else {
              obj.animate=true;
              _this.graphics[pUUID][detour.detourId]=obj;
              _this.detourLayer.removeMany(obj.graphic);
              _this.drawAnimateDetour(pUUID, detour.detourId, obj);
            }
          });
        });
      });
    },
    drawAnimateDetour(pUUID, detourId, element) {
      let _this=this;
      let project = this.projects[pUUID];
      let step = 0, i;
      let stepRev = 0;
      let options = {
        steps: 10,
        distance: 50,
        units: 'meters',
        boundMax: true
      };
      var ref, angle, graphCache = {};
      var graphCacheRev = {};
      project.phases.forEach((phase) => {
        phase.closing.forEach((close) => {
          close.detours.forEach((detour)  => {
            let detourSensValue = detour.dates[0].detourSensValue;
            if ((detour.detourId==detourId) && (element.type=='linear')) {
              if(detourSensValue == "1"){
                var obj = this.getRepeatedSymbole(detour, 'detour', element.color, options);
              }
              else if(detourSensValue == "2")
              {
                var objRev = this.getRepeatedSymboleRev(detour, 'detour', element.color, options);
              }
              else
              {
                var obj = this.getRepeatedSymbole(detour, 'detour', element.color, options);
                var objRev = this.getRepeatedSymboleRev(detour, 'detour', element.color, options);
              }
              let visible = [];

              ref = setInterval(() => {
                try {
                  _this.detourLayer.removeMany(visible);
                  if (!graphCache[step] || !graphCacheRev[stepRev]){
                    graphCache[step] = [];
                    graphCacheRev[stepRev] = [];
                    if(obj){
                      for (i = step; i < obj.slices * options.steps; i += options.steps){
                        graphCache[step].push(obj.graphs[i]);
                      }
                    }
                    if(objRev){
                      for (i = stepRev; i < objRev.slices * options.steps; i += options.steps){
                        graphCacheRev[stepRev].push(objRev.graphs[i]);
                      }
                    }
                  }
                  visible = [].concat(graphCache[step],graphCacheRev[stepRev]);
                  _this.detourLayer.addMany(visible);
                  step = (step + 1) % options.steps;
                  stepRev = (stepRev - 1) % options.steps;
                  
                  
                  element.graphic = [].concat(obj?obj.graphs:[],objRev?objRev.graphs:[]);

                 _this.graphics[pUUID][detourId] = element;
                } catch(e) {
                  console.log("error",e)
                  clearInterval(ref);
                }
              }, 80);
              element.ref=ref;
              _this.refs.push(ref);
            }
          });
        });
      });
    },
    getRepeatedSymbole(selected, type, color, options) {
      let symbol = {
        type: "simple-marker",
        style : "path",
        path : (type=='detour')?this.icons.arrowDetour:this.icons.pointClosing,
        color: color,
        size : 5,
        outline: {
          color: color,
          width: 1
        }
      };
      let pg, i, slices, percent, graphs = [];
      let polyline = HelpersMaps.updateSimpleLine(selected, this.Point, this.Polyline, this.geometryEngine);
      let fullD = this.geometryEngine.geodesicLength(polyline, options.units);
      if(fullD > (2 * options.distance)){
        slices = Math.floor(fullD / options.distance);
        if(options.boundMax && (slices >= maxSlices)){slices = maxSlices;}
      } else{slices = 2;}
      percent = 100 / (slices * options.steps);
      //("Slices ", slices, "\nPercent ", percent);
      let points = HelpersMaps.repeatedPoints(polyline, this.Point, this.geometryEngine, this.webMercatorUtils, {percent : percent, units : options.units});
      let angles = HelpersMaps.bearingAt(polyline, points, this.Polyline, this.geometryEngine, this.webMercatorUtils, this.geodesicUtils);
      for (i=0; i < angles.length; i++){
        let a = angles[i];
        symbol.angle = a.angle;
        symbol.xoffset = (Math.sign(a.angle) == -1)?"-4px":"0px",
        symbol.yoffset = (Math.sign(a.angle) == -1)?"0px":"4px",
        pg = new this.Graphic({
          geometry : a.point,
          symbol : symbol,
          
        });
        graphs.push(pg);
      }

      return {polyline : polyline, graphs: graphs, slices: slices};
    },
    getRepeatedSymboleRev(selected, type, color, options) {
      let symbol = {
        type: "simple-marker",
        style : "path",
        path : (type=='detour')?this.icons.arrowDetour:this.icons.pointClosing,
        color: color,
        size : 5,
        outline: {
          color: color,
          width: 1
        }
      };
      let pg1, i, slices, percent, graphs = [];
      let polyline = HelpersMaps.updateSimpleLine(selected, this.Point, this.Polyline, this.geometryEngine);
      let fullD = this.geometryEngine.geodesicLength(polyline, options.units);
      if(fullD > (2 * options.distance)){
        slices = Math.floor(fullD / options.distance);
        if(options.boundMax && (slices >= maxSlices)){slices = maxSlices;}
      } else{slices = 2;}
      percent = 100 / (slices * options.steps);
      //("Slices ", slices, "\nPercent ", percent);
      let points = HelpersMaps.repeatedPoints(polyline, this.Point, this.geometryEngine, this.webMercatorUtils, {percent : percent, units : options.units});
      let anglesRev = HelpersMaps.bearingAtRev(polyline, points, this.Polyline, this.geometryEngine, this.webMercatorUtils, this.geodesicUtils);
      // console.log("repeated",anglesRev);
      
      for (i=0; i < anglesRev.length; i++){
        let a = anglesRev[i];
        symbol.angle = a.angle;
        symbol.xoffset = (Math.sign(a.angle) == -1)?"0px":"4px",
        symbol.yoffset = (Math.sign(a.angle) == -1)?"-4px":"0px",
        pg1 = new this.Graphic({
          geometry : a.point,
          symbol : symbol,
          // xoffset : (Math.sign(a.angle) == -1)?"0px":"4px",
          // yoffset : (Math.sign(a.angle) == -1)?"-4px":"0px",
        });
        graphs.push(pg1);
      }
      return {polyline : polyline, graphs: graphs, slices: slices};
    },
    reportInfos(informations, lang) {
      let obj={
        'district': this.getFilterValue('district', informations.district, lang),
        'workType': this.getFilterValue('workType', informations.workType, lang),
      };
      return obj;
    },
    getFilterValue(filter, data, lang) {
      let selectedFilter = this.FiltersObject(Tags[this.filters.city][filter].value, lang);
      if (Array.isArray(data)) {
        return data.map(function(element) {
          return selectedFilter[element]; 
        }).join(', ');
      } else {
        return selectedFilter[data];
      }
    },
    FiltersObject(list, lang) {
      let obj = {};
      list.forEach((e) => {
        if (e.label_fr || e.label_en) {
          obj[e.value]=e['label_'+lang];
        } else {
          obj[e.value]=e.label;
        }
      });
      return obj;
    }
  }
};
</script>
<style>
  div.public-map  {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  div.public-map div.full-screen-map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  div.public-map #map {
    height: 100%;
    position: relative;
  }
  div.public-map h2.esri-popup__header-title {
    color: black;
    font-weight: bold;
  }
  div.public-map span.esri-popup__icon.esri-icon-description {
    display: none;
  }
  div.public-notes > *, div.public-notes span {
    color: rgb(50, 50, 50) !important;
  }
  div.public-filters-content {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9;
    display: flex;
  }
  div.filters-step-container {
    cursor: pointer;
    width: 10%;
  }
  div.filters-range {
    width: 70%;
  }
  div.filters-step-content {
    position: relative;
    top: 0;
    width: 32px;
    height: 32px;
    background-color: white;
    padding-top: 4px;
    padding-left: 4px;
    border-radius: 5px;
    display: flex;
    border: 1px solid #edeff2;
    position: absolute;
    bottom: -15px;
  }
  div.filters-step-content div.dropdown-menu.show {
    padding: 10px;
    /*width: 230px;*/
    min-width: 20rem;
  }
  div.filters-step-element {
    margin-bottom: 10px;
  }
  div.filters-step-element span.step-title {
    padding-left:10px;
  }
  /*
  span.step-title {
    background-color: white;
    padding: 7px;
    border-radius: 6px;
    border: 1px solid #edeff2;
    bottom: 2px;
    display: flex;
  }
  */
  div.loading-map {
    background: gray;
    opacity: 0.6;
  }
  div.loading-map img {
    position: absolute;
    top: 48%;
    left: 50%;
    margin-left: 20px;
  }
  div.divider {
    margin-bottom: 10px;
  }
  a.settings-icon i.tim-icons.icon-bullet-list-67 {
    font-size: 18px;
  }
  label.dropdown-title {
    font-weight: bold;
  }
  div.report-section {
    padding-top:5px;
    padding-bottom: 10px;
  }
  div.report-section a:hover,
  div.report-section a {
    cursor: pointer;
    /*color: #ba54f5;*/
    color: #1d8cf8;
    font-weight: bold;
  }
  div.documents-section p {
    color: #000;
    font-weight: bold;
    margin-bottom: 10px;
  }
  div.documents-section.hide {
    display: none;
  }
  div.documents-section.show {
    display: block;
  }
  div.documents-section a:focus,
  div.documents-section a:hover,
  div.documents-section a {
    color: #1d8cf8;
  }
  .esri-popup__navigation{
    margin: 0;
  }
  .esri-popup__inline-actions-container .esri-popup__action {
      margin-right: 30px;
  }
</style>